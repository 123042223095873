import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';

import {
    OrgNumberField,
    TextField
} from '../CommonComponents/Form';
import AuthContext from './AuthContext';
import AuthContainer from './AuthContainer';
import toast from '../../utils/toast';
import { signup } from '../../api-public/company';
import SimpleForm from '../CommonComponents/Form/SimpleForm';

export default function RegisterCompany() {
    const {
        values: {
            newCompanyName,
            newCompanyOrgNumber
        },
        onChange
    } = React.useContext(AuthContext);

    const navigate = useNavigate();

    const createCompany = React.useCallback(async () => {
        try {
            const newCompany = await signup({ orgNumber: newCompanyOrgNumber, name: newCompanyName });
            onChange({ tenantId: newCompany.id, tenantName: newCompanyName });
            navigate('/register-user', { replace: true });
        } catch (e) {
            toast(e, 'Kunde inte skapa företag, försök igen');
        }
    }, [onChange, newCompanyName, newCompanyOrgNumber]);

    return (
        <AuthContainer>
            <Typography variant="h5" sx={{ textAlign: 'center', mb: 4, mt: 4 }}>Registrera ditt företag</Typography>

            <SimpleForm
                onSubmit={createCompany}
                submitText="Nästa"
                submitDisabled={!newCompanyName || !newCompanyOrgNumber}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            name="newCompanyName"
                            label="Företagsnamn"
                            value={newCompanyName}
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <OrgNumberField
                            variant="outlined"
                            name="newCompanyOrgNumber"
                            label="Organisationsnummer"
                            value={newCompanyOrgNumber}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>
            </SimpleForm>

            <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs>
                    <Button variant="text" onClick={() => navigate('/register-company')}>
                        Tillbaka
                    </Button>
                </Grid>
            </Grid>
        </AuthContainer>
    );
}
