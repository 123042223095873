import React from 'react';
import { useParams } from 'react-router-dom';
import {
    Divider,
    Grid,
    Typography
} from '@mui/material';

import {
    Block,
    Description,
    DriveLine,
    Fetch,
    Header,
    List,
    Numbers
} from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';
import { FetchComponentPropsType } from '../CommonComponents/Fetch/Fetch';
import OrderMap from '../Orders/OrderMap';

import { getJob } from '../../api/jobs';
import OrderStatus from '../Orders/OrderStatus';
import {
    isJobFullType,
    type JobType,
    type OrderTypeType
} from '../../types';
import ChangeOrderContact from '../Orders/ChangeOrderContact';
import JobOffer from './JobOffer';
import JobBookmark from './JobBookmark';
import DHLContactBox from './DhlContactBox';
import { getJobPublishedMetaText } from '../../utils/order';
import OfferChat from '../Offers/OfferChat';
import OrderStatusAction from '../Orders/OrderStatusAction';

const JobDetailsView: React.FC<FetchComponentPropsType<JobType>> = (props) => {
    const { entity, refetch } = props;

    const showBookmarkButton = React.useMemo(() => (
        !isJobFullType(entity) && entity.isOpen
    ), [entity]);

    const showChangeStatusButton = React.useMemo(() => (
        isJobFullType(entity) && ['ACCEPTED', 'STARTED'].some((s) => s === entity.status)
    ), [entity]);

    return !entity ? null : (
        <Container>
            <Header
                title="Kör uppdrag"
                CustomComponent={(
                    <>
                        {showBookmarkButton && (
                            <JobBookmark job={entity} refetch={refetch} />
                        )}
                        {showChangeStatusButton && (
                            <OrderStatusAction data={entity} refetch={refetch} />
                        )}
                    </>
                )}
            />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Block>
                        <OrderMap
                            entityType={entity.type}
                            approximate={isJobFullType(entity)}
                            pickupLat={entity.pickupLat}
                            pickupLng={entity.pickupLng}
                            deliveryLat={entity.deliveryLat}
                            deliveryLng={entity.deliveryLng}
                        />
                    </Block>
                </Grid>

                {isJobFullType(entity) && ['ACCEPTED', 'STARTED', 'COMPLETED'].some((s) => s === entity.status) && (
                    <Grid item xs={12}>
                        <OrderStatus data={entity} />
                    </Grid>
                )}

                <Grid item xs={12} lg={8}>
                    <Paper>
                        <DriveLine small data={entity} />
                        <Numbers
                            small
                            data={[
                                { title: 'Totalvikt [kg]', value: entity.grossWeight },
                                { title: 'FDR-vikt [kg]', value: entity.chargeableWeight },
                                { title: 'Volym [m³]', value: entity.volume },
                                { title: 'Flakmeter', value: entity.loadingMeters },
                                { title: 'Pallplatser', value: entity.palletPlaces },
                                { title: 'Pallar', value: entity.pallets }
                            ]}
                        />
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            {entity.publicDescription && (
                                <Grid item xs={12} lg>
                                    <Description
                                        title="Beskrivning"
                                        text={entity.publicDescription}
                                    />
                                </Grid>
                            )}
                            {isJobFullType(entity) && entity.privateDescription && (
                                <Grid item xs={12} lg>
                                    <Description
                                        title="Privat beskrivning"
                                        text={entity.privateDescription}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Divider />
                                <Typography variant="caption" display="block" align="center" sx={{ mt: 1 }}>
                                    {getJobPublishedMetaText(entity)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} lg={4}>
                    {entity.type === 'LL' && (
                        <JobOffer job={entity} refetch={refetch} />
                    )}
                    {entity.type === 'DHL' && (
                        <DHLContactBox job={entity} />
                    )}
                </Grid>

                <Grid item xs={12} lg={4}>
                    {isJobFullType(entity) && ['ACCEPTED', 'STARTED', 'COMPLETED'].some((s) => s === entity.status) && (
                        <Block
                            title="Kontaktuppgifter"
                            ActionComponent={entity ? (
                                <ChangeOrderContact
                                    orderId={entity.id}
                                    contactId={entity.acceptedTenantContactId}
                                    contactName={entity.acceptedTenantContactName}
                                    contactType="carrier-contact"
                                    refetch={refetch}
                                />
                            ) : undefined}
                        >
                            <List
                                data={[
                                    {
                                        name: 'Företag',
                                        value: entity.tenantName
                                    },
                                    {
                                        name: 'Företag, e-post',
                                        value: entity.tenantEmail,
                                        type: 'email'
                                    },
                                    {
                                        name: 'Företag, telefon',
                                        value: entity.tenantPhone,
                                        type: 'phone'
                                    },
                                    {
                                        name: 'Kontaktperson',
                                        value: entity.tenantContactName
                                    },
                                    {
                                        name: 'Kontaktperson, e-post',
                                        value: entity.tenantContactEmail,
                                        type: 'email'
                                    },
                                    {
                                        name: 'Kontaktperson, mobil',
                                        value: entity.tenantContactPhone,
                                        type: 'phone'
                                    },
                                    {
                                        name: 'Kontaktperson, telefon',
                                        value: entity.tenantContactPhoneAlt,
                                        type: 'phone'
                                    }
                                ]}
                            />
                        </Block>
                    )}
                </Grid>

                <Grid item xs={12} lg={4}>
                    {isJobFullType(entity) && ['ACCEPTED', 'STARTED', 'COMPLETED'].some((s) => s === entity.status) && (
                        <Block title="Faktureringsuppgifter">
                            <List
                                data={[
                                    {
                                        name: 'Företag',
                                        value: entity.tenantName
                                    },
                                    {
                                        name: 'Märkning',
                                        value: entity.tenantBillingMark
                                    },
                                    {
                                        name: 'Gata',
                                        value: entity.tenantBillingStreet
                                    },
                                    {
                                        name: 'Postnummer',
                                        value: entity.tenantBillingZip
                                    },
                                    {
                                        name: 'Postort',
                                        value: entity.tenantBillingCity
                                    },
                                    {
                                        name: 'E-post',
                                        value: entity.tenantBillingEmail,
                                        type: 'email'
                                    }
                                ]}
                            />
                        </Block>
                    )}
                </Grid>

                {!!entity.offer && (
                    <Grid item xs={12} lg={4}>
                        <Block title="Meddelanden" contentPadding>
                            <OfferChat
                                recipient={entity.tenantName || 'Uppdragsgivaren'}
                                id={entity.offer.id}
                                canWrite={entity.isOpen}
                                maxHeight="705px"
                            />
                        </Block>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default function JobDetails() {
    const { type, id } = useParams() as unknown as { type: string, id: number };
    const fetchJob = React.useCallback(() => getJob({ type: type as OrderTypeType, id }), [id, type]);

    return (
        <Fetch<JobType>
            fetch={fetchJob}
            Component={JobDetailsView}
            name="uppdraget"
            notFoundDescription="Det har antingen tagits bort eller förmedlats till någon annan."
        />
    );
}
