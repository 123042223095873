/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserForListType, UserProfileType } from '../../types';

type UsersStateType = {
    profile: UserProfileType | null,
    all: UserForListType[],
    fetchingProfile: boolean,
    fetchingAll: boolean
};

const initialState: UsersStateType = {
    profile: null,
    all: [],
    fetchingProfile: false,
    fetchingAll: false
};

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        fetchProfile: (state) => {
            state.fetchingProfile = true;
        },
        profileFetched: (state, action: PayloadAction<{data: UsersStateType['profile']}>) => {
            state.profile = action.payload.data;
            state.fetchingProfile = false;
        },
        fetchAll: (state) => {
            state.fetchingAll = true;
        },
        allFetched: (state, action: PayloadAction<{data: UsersStateType['all']}>) => {
            state.all = action.payload.data;
            state.fetchingAll = false;
        }
    }
});

export default slice;
