import { OfferType } from '../types';

export const orderStatus = {
    DRAFTED: 'DRAFTED',
    PUBLISHED: 'PUBLISHED',
    ACCEPTED: 'ACCEPTED',
    STARTED: 'STARTED',
    COMPLETED: 'COMPLETED'
};

export const offerStatus = {
    PENDING: 'PENDING',
    EXPIRED: 'EXPIRED',
    REJECTED: 'REJECTED',
    ACCEPTED: 'ACCEPTED'
};

export function getOfferStatus(offer: OfferType | null) {
    if (!offer) {
        return null;
    }
    const { expiresAt, rejectedAt, isAccepted } = offer;

    if (isAccepted) {
        return offerStatus.ACCEPTED;
    }

    if (rejectedAt) {
        return offerStatus.REJECTED;
    }

    if (expiresAt && new Date(expiresAt) <= new Date()) {
        return offerStatus.EXPIRED;
    }

    return offerStatus.PENDING;
}
