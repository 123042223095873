import React from 'react';
import { Delete, Edit } from '@mui/icons-material';
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
import DataImportRowForm from './DataImportRowForm';
import DataImportTableValue from './DataImportTableValue';

type PropsType = {
    rows: any,
    columns: any,
    changeRow: ({ rowIndex, row }: { rowIndex: number, row: (string | boolean | number)[] }) => void
    changeColumn: ({ columnIndex, key }: { columnIndex: number, key: string }) => void,
    removeRow: ({ rowIndex }: { rowIndex: number }) => void,
    isPending: boolean
};

const DataImportTable: React.FC<PropsType> = (props) => {
    const {
        rows,
        columns,
        changeRow,
        changeColumn,
        removeRow,
        isPending
    } = props;

    const [editingEntity, setEditingEntity] = React.useState<{[key: string]: any} | null>(null);

    const editRow = React.useCallback(({ rowIndex }: { rowIndex: number }) => {
        setEditingEntity(columns.reduce((result: { [key: string]: any }, column: any, columnIndex: number) => ({
            ...result,
            [column.key]: rows[rowIndex][columnIndex],
            rowIndex
        }), []));
    }, [columns, rows]);

    return (
        <>
            <DataImportRowForm
                columns={columns}
                editingEntity={editingEntity}
                setEditingEntity={setEditingEntity}
                changeRow={changeRow}
            />

            <Table
                stickyHeader
                aria-label="simple table"
                size="small"
            >
                <TableHead>
                    <TableRow>
                        {columns.map((column: any, columnIndex: number) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <TableCell key={columnIndex}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="separator-label">Fält</InputLabel>
                                    <Select
                                        labelId="separator-label"
                                        id="separator"
                                        value={column.key}
                                        label="Fält"
                                        onChange={({ target: { value } }) => (
                                            changeColumn({ columnIndex, key: value })
                                        )}
                                        disabled={isPending}
                                    >
                                        {columns.map((c: any) => (
                                            <MenuItem key={c.key} value={c.key}>{c.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </TableCell>
                        ))}
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row: string[], rowIndex: number) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={rowIndex}>
                            {columns.map((column: any, columnIndex: number) => (
                                <DataImportTableValue
                                    column={column}
                                    columnIndex={columnIndex}
                                    row={row}
                                    rowIndex={rowIndex}
                                    rows={rows}
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={columnIndex}
                                />
                            ))}

                            <TableCell align="right" width={120}>
                                <IconButton
                                    aria-label="Redigera"
                                    onClick={() => editRow({ rowIndex })}
                                    disabled={isPending}
                                >
                                    <Edit />
                                </IconButton>
                                <IconButton
                                    aria-label="Ta bort"
                                    onClick={() => removeRow({ rowIndex })}
                                    disabled={isPending}
                                >
                                    <Delete />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

export default DataImportTable;
