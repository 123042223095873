import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ListIcon from '@mui/icons-material/List';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Badge from '@mui/material/Badge';

import MarketList, { MARKET_LIST_NAME, marketListSortOptions } from './MarketList';
import MarketFilter from './MarketFilter';
import MarketMap from './MarketMap';
import MarketSettings from './MarketSettings';
import EntityDetails from './EntityDetails/EntityDetails';

import {
    setMarketFilterOpen,
    setMarketListOpen,
    selectMarketFilterOpen,
    selectMarketListOpen
} from '../../redux/actions/settings';
import { selectMarketFiltersCount } from '../../redux/actions/market';
import { useDispatch, useSelector } from '../../redux/hooks';
import { Select } from '../CommonComponents';
import { selectTableSort, setTableSort } from '../../redux/actions/tables';

const rightDrawerWidth = 420;
const leftDrawerWidth = 360;

const Main = styled('main', {
    shouldForwardProp: (prop) => !['leftOpen', 'rightOpen'].some((p) => p === prop)
})<{ leftOpen?: boolean; rightOpen?: boolean; }>(({ theme, leftOpen, rightOpen }) => ({
    position: 'relative',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -rightDrawerWidth - leftDrawerWidth,
    ...(rightOpen && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: -leftDrawerWidth
    }),
    marginLeft: 0,
    ...(leftOpen && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: leftDrawerWidth
    })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export default function MarketLayout() {
    const dispatch = useDispatch();
    const marketFilterCount = useSelector(selectMarketFiltersCount());
    const leftOpen = useSelector(selectMarketFilterOpen());
    const rightOpen = useSelector(selectMarketListOpen());
    const marketListSort = useSelector(selectTableSort(MARKET_LIST_NAME)) || {
        orderBy: marketListSortOptions[0].value.split('-')[0],
        orderDirection: marketListSortOptions[0].value.split('-')[1] === 'asc'
            ? 'asc'
            : 'desc'
    };

    const setLeftOpen = React.useCallback((isOpen: boolean) => {
        dispatch(setMarketFilterOpen(isOpen));
    }, [dispatch]);

    const setRightOpen = React.useCallback((isOpen: boolean) => {
        dispatch(setMarketListOpen(isOpen));
    }, [dispatch]);

    return (
        <Box
            sx={{
                display: 'flex',
                position: 'relative',
                height: '100%',
                overflowX: 'hidden'
            }}
        >
            <Main rightOpen={rightOpen} leftOpen={leftOpen}>
                <Box
                    sx={{
                        ...(leftOpen && { display: 'none' }),
                        position: 'absolute',
                        top: 20,
                        left: 20,
                        zIndex: 10
                    }}
                >
                    <Badge badgeContent={marketFilterCount} color="error">
                        <Fab
                            color="secondary"
                            aria-label="open filter"
                            onClick={() => setLeftOpen(true)}
                        >
                            <SearchIcon />
                        </Fab>
                    </Badge>
                </Box>

                <Fab
                    color="secondary"
                    aria-label="open list"
                    onClick={() => setRightOpen(true)}
                    sx={{
                        ...(rightOpen && { display: 'none' }),
                        position: 'absolute',
                        top: 20,
                        right: 20,
                        zIndex: 10
                    }}
                >
                    <ListIcon />
                </Fab>

                <MarketSettings
                    sx={{
                        position: 'absolute',
                        top: 20,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 9,
                        backgroundColor: '#FFFFFF'
                    }}
                />

                <MarketMap />

                <EntityDetails />
            </Main>

            <Drawer
                sx={{ width: leftDrawerWidth, flexShrink: 0, '& .MuiDrawer-paper': { width: leftDrawerWidth } }}
                variant="persistent"
                anchor="left"
                open={leftOpen}
                PaperProps={{ style: { position: 'absolute' } }}
                BackdropProps={{ style: { position: 'absolute' } }}
                ModalProps={{ keepMounted: true, style: { position: 'absolute' } }}
            >
                <DrawerHeader sx={{ justifyContent: 'space-between' }}>
                    <Typography variant="h6" sx={{ ml: 2 }}>
                        Filter
                    </Typography>
                    <IconButton onClick={() => setLeftOpen(false)}>
                        <ChevronLeftIcon />
                    </IconButton>
                </DrawerHeader>

                <Divider />

                <MarketFilter />
            </Drawer>

            <Drawer
                sx={{ width: rightDrawerWidth, flexShrink: 0, '& .MuiDrawer-paper': { width: rightDrawerWidth } }}
                variant="persistent"
                anchor="right"
                open={rightOpen}
                BackdropProps={{ style: { position: 'absolute' } }}
                PaperProps={{ style: { position: 'absolute' } }}
                ModalProps={{ keepMounted: true, style: { position: 'absolute' } }}
            >
                <DrawerHeader>
                    <IconButton onClick={() => setRightOpen(false)}>
                        <ChevronRightIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ ml: 2, mr: 6 }}>
                        Lista
                    </Typography>
                    <Select
                        size="small"
                        smallFont
                        label="Sortera efter"
                        value={`${marketListSort.orderBy}-${marketListSort.orderDirection}`}
                        options={marketListSortOptions}
                        onChange={({ target: { value } }) => dispatch(setTableSort({
                            name: MARKET_LIST_NAME,
                            orderBy: value.split('-')[0],
                            orderDirection: value.split('-')[1] === 'asc' ? 'asc' : 'desc'
                        }))}
                    />
                </DrawerHeader>

                <Divider />

                <MarketList />
            </Drawer>
        </Box>
    );
}
