import React from 'react';

import InputField from './InputField';
import { validateEmails } from '../validations/validations';

type PropsType = {
    minLength?: number,
    name: string,
    label: string,
    value: string,
    required?: boolean,
    helperText?: string,
    sx?: {[key: string]: string | number },
    variant?: 'filled' | 'outlined' | 'standard',
    disabled?: boolean,
    multiline?: boolean,
    minRows?: number,
    onChange: (a: any) => void
};

export default function TextField(props: PropsType) {
    const { minLength, ...baseProps } = props;

    const validations = [
        validateEmails({ value: baseProps.value })
    ];

    return (
        <InputField
            {...baseProps}
            validations={validations}
        />
    );
}
