import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { SaveAlt } from '@mui/icons-material';

import { Header, Table } from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';

import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchAllUsers, selectAllUsers } from '../../redux/actions/users';

const columns = [{
    name: 'Förnamn',
    key: 'firstName',
}, {
    name: 'Efternamn',
    key: 'lastName',
}, {
    name: 'Titel',
    key: 'title',
}, {
    name: 'E-post',
    key: 'email'
}, {
    name: 'Mobil',
    key: 'phone'
}, {
    name: 'Telefon',
    key: 'phoneAlt'
}, {
    name: 'Administratör',
    key: 'isAdmin',
    type: 'bool' as 'bool',
}];

export default function Users() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const users = useSelector(selectAllUsers());

    React.useEffect(() => {
        dispatch(fetchAllUsers());
    }, [dispatch]);

    const onRowClick = React.useCallback((user: { id: number }) => {
        navigate(`/users/${user.id}`);
    }, [navigate]);

    return (
        <Container>
            <Header
                title="Användare"
                addUrl="/users/create"
                CustomComponent={(
                    <Button
                        startIcon={<SaveAlt />}
                        variant="outlined"
                        color="primary"
                        onClick={() => navigate('/users/import')}
                    >
                        Importera
                    </Button>
                )}
            />

            <Paper padding={0}>
                <Table
                    name="users"
                    columns={columns}
                    data={users}
                    onRowClick={onRowClick}
                    emptyText="Ni har inga användare."
                />
            </Paper>
        </Container>
    );
}
