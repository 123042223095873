import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';

import { Table } from '../CommonComponents';

import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchAllNotifications, selectAllNotifications } from '../../redux/actions/notifications';

import { NotificationType } from '../../types';
import { deleteNotifications } from '../../api/notifications';
import toast from '../../utils/toast';
import NotificationActionButton from '../Notifications/NotificationActionButton';

export default function LatestNotifications() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const notifications = useSelector(selectAllNotifications());

    React.useEffect(() => {
        dispatch(fetchAllNotifications());
    }, [dispatch]);

    const getActionButton = React.useCallback((notification) => (
        <NotificationActionButton
            notification={notification}
            navigate={navigate}
        />
    ), [navigate]);

    const removeNotification = React.useCallback(async (notification: NotificationType) => {
        try {
            await deleteNotifications([notification.id]);
            dispatch(fetchAllNotifications());
            // also coupnt
            toast('Notis borttagen');
        } catch (e) {
            toast(e, 'Kunde inte ta bort notis, försök igen');
        }
    }, [dispatch]);

    const getDeleteButton = React.useCallback((notification: NotificationType) => (
        <IconButton
            aria-label="Ta bort"
            onClick={() => removeNotification(notification)}
        >
            <Delete />
        </IconButton>
    ), [removeNotification]);

    const columns = React.useMemo(() => ([{
        name: '',
        key: 'delete',
        padding: 'checkbox' as 'checkbox',
        getValue: getDeleteButton
    }, {
        name: 'Datum',
        key: 'createdAt',
        type: 'datetime' as 'datetime'
    }, {
        name: 'Meddelande',
        key: 'message',
    }, {
        name: '',
        key: 'action',
        align: 'right',
        padding: 'checkbox' as 'checkbox',
        getValue: getActionButton
    }]), [getActionButton, getDeleteButton]);

    return (
        <Table
            name="notifications"
            defaultOrderBy="createdAt"
            defaultOrderDirection="asc"
            columns={columns}
            data={notifications.slice(0, 5)}
            emptyText="Du har inga notiser."
        />
    );
}
