import apiRequest from './api-request';

export function getOffersByOrderId(orderId: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `orders/${orderId}/offers`
    });
}

export function getActiveOffers() {
    return apiRequest({
        method: 'GET',
        resource: 'active-offers'
    });
}

export function getOfferMessages(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `offers/${id}/messages`
    });
}

export function createOfferMessage(id: number | string, message: string) {
    return apiRequest({
        method: 'POST',
        resource: `offers/${id}/messages`,
        body: { message }
    });
}
