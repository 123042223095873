import React from 'react';
import { Check as CheckIcon } from '@mui/icons-material';

import { OfferStatusType, OrderStatusType } from '../types';

type DateType = 'datetime' | 'time';

function getTzDate(date: Date) {
    const time = date.getTime();
    const tzOffset = date.getTimezoneOffset() * 60 * 1000;

    return new Date(time - tzOffset);
}

function formatDateDateTime(input: unknown, dateType?: DateType) {
    if (!(input instanceof Date || typeof input === 'string')) {
        return '-';
    }
    const date = input instanceof Date ? input : new Date(input);

    if (Number.isNaN(date.getTime())) {
        return '-';
    }

    const dateString = (getTzDate(date)).toISOString();

    if (dateType === 'datetime') {
        return dateString.slice(0, 16).replace('T', ' ');
    }

    if (dateType === 'time') {
        return dateString.slice(11, 16);
    }

    return dateString.slice(0, 10);
}

export const formatDate = (s: unknown) => formatDateDateTime(s);
export const formatDateTime = (s: unknown) => formatDateDateTime(s, 'datetime');
export const formatTime = (s: unknown) => formatDateDateTime(s, 'time');

const msPerDay = 24 * 60 * 60 * 1000;
const msPerHour = 60 * 60 * 1000;
const msPerMinute = 60 * 1000;
const msPerSecond = 1000;

const pad = (n: number) => String(n).padStart(2, '0');

export function getTimeUntil(d?: string | Date) {
    return d ? (new Date(d)).getTime() - (new Date()).getTime() : 0;
}

export function formatTimeLeft(diff: number) {
    const days = Math.floor(diff / (msPerDay));
    const hours = Math.floor((diff - (days * msPerDay)) / msPerHour);
    const minutes = Math.floor((diff - (days * msPerDay) - (hours * msPerHour)) / msPerMinute);
    const seconds = Math.floor((diff - (days * msPerDay) - (hours * msPerHour) - (minutes * msPerMinute)) / msPerSecond);

    if (days > 0) {
        return `${days}d ${pad(hours)}t ${pad(minutes)}m ${pad(seconds)}s kvar`;
    }
    if (hours > 0) {
        return `${hours}t ${pad(minutes)}m ${pad(seconds)}s kvar`;
    }
    if (minutes > 0) {
        return `${minutes}m ${pad(seconds)}s kvar`;
    }
    if (seconds > 0) {
        return `${seconds}s kvar`;
    }
    return '0s kvar';
}

export const formatStatus = (status: OrderStatusType) => {
    switch (status) {
        case 'DRAFTED':
            return 'Skapad';

        case 'PUBLISHED':
            return 'Publicerad';

        case 'ACCEPTED':
            return 'Tilldelad';

        case 'STARTED':
            return 'Bekräftad';

        case 'COMPLETED':
            return 'Utförd';

        default:
            return '-';
    }
};

export const formatOfferStatus = (offerStatus: OfferStatusType) => {
    switch (offerStatus) {
        case 'ACCEPTED':
            return 'Tilldelat';

        case 'REJECTED':
            return 'Avböjt';

        case 'EXPIRED':
            return 'Utlöpt';

        case 'PENDING':
            return 'Ohanterat';

        default:
            return '-';
    }
};

export function formatNumber(value: unknown, unit?: string) {
    if (typeof value !== 'number') {
        return '-';
    }

    const digits = (Math.round(value * 100) / 100).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ');

    return unit
        ? `${digits} ${unit}`
        : digits;
}

export const formatSeconds = (sec: unknown) => {
    if (typeof sec !== 'number') {
        return '-';
    }
    const min = Math.floor(sec / 60);
    const hours = Math.floor(min / 60);

    return hours
        ? `${hours} h ${min % 60} min`
        : `${min} min`;
};

export const formatMeters = (meters: unknown) => (
    typeof meters === 'number'
        ? formatNumber(Math.floor(meters / 1000), 'km')
        : '-'
);

export function formatRange(low: number | null, high: number | null, unit?: string) {
    if (low !== null && high !== null) {
        return `${low} - ${high} ${unit}`;
    }
    if (low !== null && high === null) {
        return `Mer än ${low} ${unit}`;
    }
    if (low === null && high !== null) {
        return `Mindre än ${high} ${unit}`;
    }
    return '';
}

export const formatBool = (val: any) => (
    val ? <CheckIcon fontSize="small" /> : null
);

export const formatYesNo = (val: any) => (
    val ? 'Ja' : 'Nej'
);

export function formatGroupRole({ isOwner, isAccepted, isInvited }: { isOwner: boolean, isAccepted: boolean, isInvited: boolean }) {
    if (isOwner) {
        return 'Administratör';
    }
    if (isAccepted) {
        return 'Medlem';
    }
    if (isInvited) {
        return 'Inbjuden';
    }
    return 'Ej medlem';
}

export function formatGroupType({ isPublic }: { isPublic: boolean }) {
    return (isPublic ? 'Publik' : 'Privat');
}

export function formatLoadArray(item: {[key: string]: any }) {
    return [
        !!item.grossWeight && formatNumber(Number(item.grossWeight), 'kg tot'),
        !!item.chargeableWeight && formatNumber(Number(item.chargeableWeight), 'kg fdr'),
        !!item.volume && formatNumber(Number(item.volume), 'm³'),
        !!item.loadingMeters && formatNumber(Number(item.loadingMeters), 'flm'),
        !!item.palletPlaces && formatNumber(Number(item.palletPlaces), 'ppl'),
        !!item.pallets && formatNumber(Number(item.pallets), 'p')
    ].filter((v) => v);
}

export function formatLoad(item: {[key: string]: any }) {
    return formatLoadArray(item).join(', ');
}

export function formatCities(item: {[key: string]: any }) {
    return `${item.pickupCity} - ${item.deliveryCity}`;
}
