export function splitString(value: string): string[] {
    return value.length
        ? value.split(/[\s,;:]+/).filter((v) => !!v)
        : [];
}

export function filterKeys(entity: any, cols: string[] = [], removeWhitespaceColumns: string[] | null = []) {
    return cols.reduce(
        (mappedEntity, col) => (
            typeof entity[col] !== 'undefined'
                ? ({
                    ...mappedEntity,
                    [col]: typeof entity[col] === 'string'
                        ? (removeWhitespaceColumns?.some((rwc) => rwc === col)
                            ? entity[col].replace(/\s/g, '')
                            : entity[col].trim()
                        )
                        : entity[col]
                })
                : mappedEntity),
        {}
    );
}

type ObjType = {
    [key: string]: any
}

export function fillObject({ base, fill }: { base: ObjType, fill: ObjType }) {
    return Object.keys(base).reduce((acc, key) => ({
        ...acc,
        [key]: typeof fill[key] !== 'undefined' ? fill[key] : base[key]
    }), {});
}

export function getDiffCount(arr: any, refArr: any, whitelist: string[] = []) {
    return typeof arr === 'object' && typeof refArr === 'object'
        ? Object.entries(arr).filter(([key, value]) => (
            whitelist.indexOf(key) === -1 && refArr[key] !== value
        )).length
        : 0;
}

export function isSubset(first: any, second: any) {
    return Object.entries(first).reduce((acc, curr) => acc && second[curr[0]] === curr[1], true);
}

export function pickFirstByKey(data: any[], key: string) {
    return data.reduce((acc, curr) => (
        !acc || acc > curr[key]
            ? curr[key]
            : acc
    ), null);
}

export function pickLastByKey(data: any[], key: string) {
    return data.reduce((acc, curr) => (
        !acc || acc < curr[key]
            ? curr[key]
            : acc
    ), null);
}

export function sleep(timeInMs: number) {
    return new Promise((r) => {
        window.setTimeout(r, timeInMs);
    });
}

export function capitalize(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}
