import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import {
    useChangeable,
    FullForm,
    BoolField,
    TextField,
} from '../CommonComponents/Form';

import {
    createGroup,
    deleteGroup,
    getGroup,
    updateGroup
} from '../../api/groups';

import toast from '../../utils/toast';

const defaultGroup = {
    name: '',
    description: '',
    isPublic: false,
    canPublishDefault: false,
    canInviteDefault: false
};

const GroupForm: React.FC = () => {
    const { id } = useParams();
    const [entity, onChange] = useChangeable(defaultGroup);
    const navigate = useNavigate();

    React.useEffect(() => {
        (async () => {
            if (id) {
                const group = await getGroup(id);
                onChange(group);
            }
        })();
    }, [id, onChange]);

    const onSubmit = React.useCallback(async () => {
        try {
            if (id) {
                await updateGroup(id, entity);
                toast('Grupp uppdaterad');
                navigate(-1);
            } else {
                const createdGroup = await createGroup(entity);
                toast('Grupp skapad');
                navigate(`/groups/${createdGroup.id}`, { replace: true });
            }
        } catch (e: any) {
            toast(e, `Kunde inte ${id ? 'uppdatera' : 'skapa'}, försök igen`);
        }
    }, [id, entity, navigate]);

    const onDelete = React.useCallback(async () => {
        try {
            if (id) {
                await deleteGroup(id);
                toast('Grupp borttagen');
                navigate('/groups', { replace: true });
            }
        } catch (e: any) {
            toast(e, 'Kunde inte ta bort, försök igen');
        }
    }, [id, navigate]);

    return (
        <FullForm
            title={`${entity.id ? 'Redigera' : 'Skapa'} grupp`}
            entity={entity}
            onSubmit={onSubmit}
            onDelete={entity.id ? onDelete : undefined}
        >
            <Grid container spacing={2}>
                <Grid item md={12} lg={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name="name"
                                label="Namn"
                                value={entity.name}
                                onChange={onChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="description"
                                label="Beskrivning"
                                value={entity.description}
                                onChange={onChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <BoolField
                                name="isPublic"
                                label="Publik"
                                helperText="En publik grupp är synlig för alla och alla kan gå med i gruppen."
                                value={entity.isPublic}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BoolField
                                name="canPublishDefault"
                                value={entity.canPublishDefault}
                                label="Nya medlemmar kan publicera"
                                helperText="Defaultinställningen för nya medlemmar, du kan själv justera för enskilda medlemmar."
                                onChange={onChange}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <BoolField
                                name="canInviteDefault"
                                value={entity.canInviteDefault}
                                label="Nya medlemmar kan se/bjuda in medlemmar"
                                helperText="Defaultinställningen för nya medlemmar, du kan själv justera för enskilda medlemmar."
                                onChange={onChange}
                            />
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </FullForm>
    );
};

export default GroupForm;
