import React from 'react';
import { Marker } from '@react-google-maps/api';

type EntityType = 'LL' | 'DHL' | 'CAP';
type MarkerType = 'activePickup' | 'activeDelivery' | 'pickup' | 'delivery' | 'plain';
type PropsType = {
    entityType: EntityType,
    markerType: MarkerType,
    lat: number,
    lng: number,
    zIndex?: number,
    onClick?: () => void
};

function getIcon({ entityType, markerType }: { entityType: EntityType, markerType: MarkerType }) {
    const size = markerType === 'plain' ? 16 : 24;
    let url = '/marker-dot-dark.png';

    switch (entityType) {
        case 'DHL':
            switch (markerType) {
                case 'plain':
                    url = '/marker-dot-yellow.png';
                    break;
                case 'activePickup':
                    url = '/marker-a-yellow-red.png';
                    break;
                case 'activeDelivery':
                    url = '/marker-b-yellow-red.png';
                    break;
                case 'pickup':
                    url = '/marker-a-yellow-white.png';
                    break;
                case 'delivery':
                    url = '/marker-b-yellow-white.png';
                    break;
                default:
                    break;
            }
            break;
        case 'LL':
            switch (markerType) {
                case 'plain':
                    url = '/marker-dot-teal.png';
                    break;
                case 'activePickup':
                    url = '/marker-a-teal-red.png';
                    break;
                case 'activeDelivery':
                    url = '/marker-b-teal-red.png';
                    break;
                case 'pickup':
                    url = '/marker-a-teal-white.png';
                    break;
                case 'delivery':
                    url = '/marker-b-teal-white.png';
                    break;
                default:
                    break;
            }
            break;
        case 'CAP':
            switch (markerType) {
                case 'plain':
                    url = '/marker-dot-purple.png';
                    break;
                case 'activePickup':
                    url = '/marker-a-purple-red.png';
                    break;
                case 'activeDelivery':
                    url = '/marker-b-purple-red.png';
                    break;
                case 'pickup':
                    url = '/marker-a-purple-white.png';
                    break;
                case 'delivery':
                    url = '/marker-b-purple-white.png';
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }

    return ({
        url,
        size: new window.google.maps.Size(size, size),
        anchor: new window.google.maps.Point(size / 2, size / 2)
    });
}

export default function OrderMarker({
    entityType,
    markerType,
    lat,
    lng,
    zIndex,
    onClick
}: PropsType) {
    const icon = React.useMemo(() => getIcon({ entityType, markerType }), [entityType, markerType]);
    return (
        <Marker
            position={{ lat, lng }}
            icon={icon}
            zIndex={zIndex}
            onClick={onClick}
        />
    );
}
