import React from 'react';
import { Button } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

import { NotificationType } from '../../types';

export default function NotificationActionButton({
    notification: {
        orderId,
        jobId,
        watchlistId,
        groupId,
        capacityId
    },
    navigate
}: { notification: NotificationType, navigate: (url: string) => void }) {
    if (orderId) {
        return (
            <Button
                aria-label="Gå till uppdrag"
                onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/orders/${orderId}`);
                }}
                endIcon={<ChevronRight />}
                sx={{ whiteSpace: 'nowrap' }}
            >
                Gå till uppdrag
            </Button>
        );
    }

    if (jobId) {
        return (
            <Button
                aria-label="Gå till uppdrag"
                onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/jobs/${jobId}`);
                }}
                endIcon={<ChevronRight />}
                sx={{ whiteSpace: 'nowrap' }}
            >
                Gå till uppdrag
            </Button>
        );
    }

    if (watchlistId) {
        return (
            <Button
                aria-label="Gå till bevakning"
                onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/watchlists/${watchlistId}`);
                }}
                endIcon={<ChevronRight />}
                sx={{ whiteSpace: 'nowrap' }}
            >
                Gå till bevakning
            </Button>
        );
    }

    if (groupId) {
        return (
            <Button
                aria-label="Gå till grupp"
                onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/groups/${groupId}`);
                }}
                endIcon={<ChevronRight />}
                sx={{ whiteSpace: 'nowrap' }}
            >
                Gå till grupp
            </Button>
        );
    }

    if (capacityId) {
        return (
            <Button
                aria-label="Gå till kapacitet"
                onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/capacities/${capacityId}`);
                }}
                endIcon={<ChevronRight />}
                sx={{ whiteSpace: 'nowrap' }}
            >
                Gå till kapacitet
            </Button>
        );
    }
    return null;
}
