import React from 'react';

import { Checkbox, FormControlLabel, TextField } from '@mui/material';

export default function DataImportDefaultField(props: any) {
    const {
        column,
        columnIndex,
        setDefaultValue,
        isPending
    } = props;

    const value = column.defaultValue;

    const validation = (
        column.validate
            ? column.validate({ value })
            : { isValid: true }
    );

    const error = !validation.isValid;

    switch (column.type) {
        case 'bool':
            return (
                <FormControlLabel
                    label={`${column.name}${column.isRequired ? ' *' : ''}`}
                    control={(
                        <Checkbox
                            checked={!!value}
                            onChange={({ target: { checked: newValue } }) => setDefaultValue({
                                columnIndex,
                                value: newValue
                            })}
                        />
                    )}
                    disabled={isPending}
                />
            );
        default:
            return (
                <TextField
                    name={column.key}
                    label={`${column.name}${column.isRequired ? ' *' : ''}`}
                    value={value}
                    onChange={({ target: { value: newValue } }) => setDefaultValue({
                        columnIndex,
                        value: newValue
                    })}
                    error={error}
                    helperText={
                        // eslint-disable-next-line no-nested-ternary
                        !validation.isValid
                            ? validation.errorText
                            : (column.isUnique
                                ? 'Värdet måste vara unikt, så det kan inte ha något defaultvärde.'
                                : undefined
                            )
                    }
                    variant="outlined"
                    size="small"
                    disabled={isPending || column.isUnique}
                    fullWidth
                />
            );
    }
}
