import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import {
    fetchIntegrationLogsById,
    selectFetchingIntegrationLogsById,
    selectIntegrationLogsById
} from '../../redux/actions/integrations';
import { IntegrationLogForListType } from '../../types';
import { Table } from '../CommonComponents';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';

const columns = [{
    name: 'Datum',
    key: 'createdAt',
    type: 'datetime' as 'datetime'
}, {
    name: 'Meddelande',
    key: 'message',
    getValue: (log: IntegrationLogForListType) => (
        <>
            <Typography>{log.message}</Typography>
            {log.isError && (
                <Typography variant="body2">{log.errorMessage}</Typography>
            )}
        </>
    )
}];

function IntegrationLogs(props: FetchPropsType<IntegrationLogForListType[]>) {
    const { entity, isFetching } = props;

    return (
        <Table
            name="integrationLogs"
            columns={columns}
            data={entity}
            emptyText={isFetching ? 'Hämtar loggar...' : 'Det finns inga loggar.'}
            defaultOrderBy="createdAt"
            defaultOrderDirection="asc"
            getRowStyle={(log) => (
                log.isError
                    ? ({ backgroundColor: '#FCC' })
                    : undefined
            )}
        />
    );
}

export default function IntegrationLogsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<IntegrationLogForListType[]>
            fetchEntity={fetchIntegrationLogsById(id)}
            selectEntity={selectIntegrationLogsById(id)}
            selectIsFetching={selectFetchingIntegrationLogsById(id)}
            Component={IntegrationLogs}
            name="loggar"
            initialFetch={!hasRendered}
        />
    );
}
