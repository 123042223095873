import React from 'react';
import { Polyline } from '@react-google-maps/api';
import {
    COLOR_PURPLE_LIGHT,
    COLOR_RED,
    COLOR_TEAL,
    COLOR_WHITEISH,
    COLOR_YELLOW
} from '../../../theme';

type EntityType = 'DHL' | 'LL' | 'CAP' | 'ACTIVE';

type PropsType = {
    entityType: EntityType,
    isActive?: boolean,
    onClick?: () => void,
    pickupLat: number,
    pickupLng: number,
    deliveryLat: number,
    deliveryLng: number
}

function getColor(entityType: EntityType) {
    switch (entityType) {
        case 'LL':
            return COLOR_TEAL;
        case 'DHL':
            return COLOR_YELLOW;
        case 'CAP':
            return COLOR_PURPLE_LIGHT;
        case 'ACTIVE':
            return COLOR_RED;
        default:
            return COLOR_WHITEISH;
    }
}

function getZIndex(entityType: EntityType) {
    switch (entityType) {
        case 'LL':
            return 8888;
        case 'DHL':
            return undefined;
        case 'CAP':
            return 7777;
        case 'ACTIVE':
            return 9999;
        default:
            return undefined;
    }
}

export default function OrderArrow({
    entityType,
    isActive,
    onClick,
    pickupLat,
    pickupLng,
    deliveryLat,
    deliveryLng
}: PropsType) {
    const polylineOptions = React.useMemo(() => ({
        path: [
            { lat: pickupLat, lng: pickupLng },
            { lat: deliveryLat, lng: deliveryLng },
        ],
        strokeColor: getColor(entityType),
        strokeOpacity: isActive ? 1 : 0.5,
        strokeWeight: 4,
        icons: [{
            icon: {
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                scale: 4
            },
            offset: '50%'
        }],
        zIndex: getZIndex(entityType)
    }), [deliveryLat, deliveryLng, isActive, pickupLat, pickupLng, entityType]);

    return (
        <Polyline options={polylineOptions} onClick={onClick} />
    );
}
