import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import {
    Archive,
    Delete,
    Edit,
    Unarchive
} from '@mui/icons-material';

import {
    Block,
    Description,
    DriveLine,
    Fetch,
    Header,
    List,
    Numbers,
    Popup
} from '../CommonComponents';
import {
    Container,
    Paper
} from '../StyledComponents';

import OrderStatus from './OrderStatus';

import { getOrder, setOrdersArchived, setOrdersDeleted } from '../../api/orders';

import type { FetchComponentPropsType } from '../CommonComponents/Fetch/Fetch';
import OrderMap from './OrderMap';
import { OrderType } from '../../types';
import ChangeOrderContact from './ChangeOrderContact';
import { getFormattedOrderExpiryDate } from '../../utils/order';
import OrderOffers from './OrderOffers';
import OfferChat from '../Offers/OfferChat';
import toastMessage from '../../utils/toast';
import OrderStatusAction from './OrderStatusAction';

const OrderDetailsView: React.FC<FetchComponentPropsType<OrderType>> = (props) => {
    const { entity, refetch } = props;
    const navigate = useNavigate();

    const [showEditWarning, setShowEditWarning] = React.useState(false);
    const [showDeleteWarning, setShowDeleteWarning] = React.useState(false);
    const [showArchiveWarning, setShowArchiveWarning] = React.useState(false);

    const showDeleteButton = React.useMemo(() => (
        ['DRAFTED', 'PUBLISHED', 'COMPLETED'].some((s) => s === entity.status)
    ), [entity]);

    const showArchiveButton = React.useMemo(() => (
        ['DRAFTED', 'PUBLISHED', 'COMPLETED'].some((s) => s === entity.status)
    ), [entity]);

    const showEditButton = React.useMemo(() => (
        ['DRAFTED', 'PUBLISHED'].some((s) => s === entity.status)
    ), [entity]);

    const showChangeStatusButton = React.useMemo(() => (
        ['DRAFTED', 'PUBLISHED', 'ACCEPTED', 'STARTED'].some((s) => s === entity.status)
    ), [entity]);

    const onEdit = React.useCallback(() => {
        setShowEditWarning(false);
        if (entity.id) {
            navigate(`/orders/edit/${entity.id}`);
        }
    }, [entity.id, navigate]);

    const onDelete = React.useCallback(async () => {
        try {
            setShowDeleteWarning(false);
            const result = (await setOrdersDeleted({ ids: [entity.id] })).at(0);
            if (result.ok) {
                toastMessage('Uppdraget borttaget');
                navigate('/orders', { replace: true });
            } else {
                toastMessage(result.error?.message || 'Uppdraget kunde inte tas bort.');
            }
        } catch (e: any) {
            toastMessage(e, 'Uppdraget kund inte tas bort.');
        }
    }, [entity.id, navigate]);

    const onArchive = React.useCallback(async () => {
        try {
            setShowArchiveWarning(false);
            const result = (await setOrdersArchived({ ids: [entity.id], archive: !entity.isArchived })).at(0);
            refetch();
            if (result.ok) {
                toastMessage(`Uppdraget ${entity.isArchived ? 'av' : ''}arkiverat`);
            } else {
                toastMessage(
                    result.error?.message,
                    `Uppdraget kunde inte ${entity.isArchived ? 'avarkiveras' : 'arkiveras'}.`
                );
            }
        } catch (e: any) {
            toastMessage(e, `Uppdraget kunde inte ${entity.isArchived ? 'avarkiveras' : 'arkiveras'}.`);
        }
    }, [entity.id, entity.isArchived, refetch]);

    const onEditClick = React.useCallback(() => {
        if (entity.status === 'PUBLISHED' && !entity.isArchived) {
            setShowEditWarning(true);
        } else {
            onEdit();
        }
    }, [onEdit, entity.isArchived, entity.status]);

    const onDeleteClick = React.useCallback(() => {
        setShowDeleteWarning(true);
    }, []);

    const onArchiveClick = React.useCallback(() => {
        setShowArchiveWarning(true);
    }, []);

    return !entity ? null : (
        <Container>
            <Popup
                open={showEditWarning}
                title="Vill du verkligen ändra ditt publicerade uppdrag?"
                body="Notiser om uppdaterat uppdrag kommer att skickas ut till alla som har matchande bevakningar."
                okLabel="Ja"
                cancelLabel="Nej"
                handleOk={onEdit}
                handleClose={() => setShowEditWarning(false)}
            />

            <Popup
                open={showDeleteWarning}
                title="Vill du verkligen ta bort uppdraget?"
                body="Du kommer inte kunna återställa det igen när du tagit bort det."
                okLabel="Ja"
                cancelLabel="Nej"
                handleOk={onDelete}
                handleClose={() => setShowDeleteWarning(false)}
            />

            <Popup
                open={showArchiveWarning}
                title="Vill du verkligen arkivera uppdraget?"
                body={'Du hittar alla arkiverade uppdrag under fliken "Arkiverade", där du enkelt kan avarkivera dem igen.'}
                okLabel="Ja"
                cancelLabel="Nej"
                handleOk={onArchive}
                handleClose={() => setShowArchiveWarning(false)}
            />

            <Header
                title="Boka uppdrag"
                // eslint-disable-next-line no-nested-ternary
                CustomComponent={entity.isArchived ? (
                    <Button sx={{ ml: 1 }} variant="outlined" startIcon={<Unarchive />} onClick={onArchive}>
                        Avarkivera
                    </Button>
                ) : (
                    <>
                        {showDeleteButton && (
                            <Button sx={{ ml: 1 }} variant="outlined" color="error" startIcon={<Delete />} onClick={onDeleteClick}>
                                Ta bort
                            </Button>
                        )}
                        {showArchiveButton && (
                            <Button sx={{ ml: 1 }} variant="outlined" startIcon={<Archive />} onClick={onArchiveClick}>
                                Arkivera
                            </Button>
                        )}
                        {showEditButton && (
                            <Button sx={{ ml: 1 }} variant="outlined" startIcon={<Edit />} onClick={onEditClick}>
                                Redigera
                            </Button>
                        )}
                        {showChangeStatusButton && (
                            <OrderStatusAction data={entity} refetch={refetch} />
                        )}
                    </>
                )}
            />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Block>
                        <OrderMap
                            entityType="LL"
                            pickupLat={entity.pickupLat}
                            pickupLng={entity.pickupLng}
                            deliveryLat={entity.deliveryLat}
                            deliveryLng={entity.deliveryLng}
                        />
                    </Block>
                </Grid>

                <Grid item xs={12}>
                    <OrderStatus data={entity} />
                </Grid>

                <Grid item xs={12} lg={8}>
                    <Paper>
                        <DriveLine small data={entity} />
                        <Numbers
                            small
                            data={[
                                { title: 'Totalvikt [kg]', value: entity.grossWeight },
                                { title: 'FDR-vikt [kg]', value: entity.chargeableWeight },
                                { title: 'Volym [m³]', value: entity.volume },
                                { title: 'Flakmeter', value: entity.loadingMeters },
                                { title: 'Pallplatser', value: entity.palletPlaces },
                                { title: 'Pallar', value: entity.pallets },
                                // { title: 'Stycken', value: entity.pieces }
                            ]}
                        />
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            <Grid item xs={12} lg={6}>
                                <Description
                                    title="Publik beskrivning"
                                    text={entity.publicDescription}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Description
                                    title="Privat beskrivning"
                                    text={entity.privateDescription}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} lg={4}>
                    <Block
                        title="Detaljer"
                    >
                        <List
                            data={[
                                {
                                    name: 'Publiceras för',
                                    value: entity.isPublic ? 'Alla' : entity.groups.map((g) => g.name).join(', ')
                                },
                                {
                                    name: 'Visa ert företagsnamn',
                                    value: entity.showTenantName,
                                    type: 'y/n'
                                },
                                {
                                    name: 'Riktpris',
                                    value: entity.listPrice,
                                    type: 'currency'
                                },
                                // {
                                //     name: 'Avgift',
                                //     value: entity.fee,
                                //     type: 'currency'
                                // },
                                {
                                    name: 'Intresseanmälan senast',
                                    value: getFormattedOrderExpiryDate(entity),
                                    type: 'datetime'
                                },
                                // {
                                //     name: 'Antal visningar',
                                //     value: entity.viewCount,
                                //     type: 'number'
                                // },
                            ]}
                        />
                    </Block>
                </Grid>

                {['ACCEPTED', 'STARTED', 'COMPLETED'].some((s) => s === entity.status) && (
                    <>
                        <Grid item xs={12} lg={6}>
                            <Block
                                title="Kontaktuppgifter"
                                ActionComponent={entity && !entity.isArchived ? (
                                    <ChangeOrderContact
                                        orderId={entity.id}
                                        contactId={entity.tenantContactId}
                                        contactName={entity.tenantContactName}
                                        contactType="shipper-contact"
                                        refetch={refetch}
                                    />
                                ) : undefined}
                            >
                                <List
                                    data={[
                                        {
                                            name: 'Företag',
                                            value: entity.acceptedTenantName
                                        },
                                        {
                                            name: 'Företag, e-post',
                                            value: entity.acceptedTenantEmail,
                                            type: 'email'
                                        },
                                        {
                                            name: 'Företag, telefon',
                                            value: entity.acceptedTenantPhone,
                                            type: 'phone'
                                        },
                                        {
                                            name: 'Kontaktperson',
                                            value: entity.acceptedTenantContactName
                                        },
                                        {
                                            name: 'Kontaktperson, e-post',
                                            value: entity.acceptedTenantContactEmail,
                                            type: 'email'
                                        },
                                        {
                                            name: 'Kontaktperson, mobil',
                                            value: entity.acceptedTenantContactPhone,
                                            type: 'phone'
                                        },
                                        {
                                            name: 'Kontaktperson, telefon',
                                            value: entity.acceptedTenantContactPhoneAlt,
                                            type: 'phone'
                                        }
                                    ]}
                                />
                            </Block>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Block title="Meddelanden" contentPadding>
                                <OfferChat
                                    id={entity.acceptedOfferId}
                                    recipient={entity.acceptedTenantName}
                                    canWrite={!entity.isArchived} // Todo: use entity.isOpen here when implemented for both offer and order.
                                    maxHeight="290px"
                                />
                            </Block>
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <OrderOffers
                        orderId={entity.id}
                        refetch={refetch}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default function OrderDetails() {
    const { id } = useParams() as unknown as { id: number };
    const fetchOrder = React.useCallback(() => getOrder(id), [id]);

    return (
        <Fetch<OrderType>
            fetch={fetchOrder}
            Component={OrderDetailsView}
            name="uppdraget"
        />
    );
}
