import { Home } from '@mui/icons-material';
import {
    Badge,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
    selectNotificationsCount,
    fetchNotificationsCount
} from '../../redux/actions/notifications';

type PropsType = {
    backgroundColor: string,
    textColor: string,
    onClick: () => void
};

const FETCH_INTERVAL = 2 * 60 * 1000;

const HomeMenuItem: React.FC<PropsType> = (props) => {
    const {
        backgroundColor,
        textColor,
        onClick
    } = props;

    const dispatch = useDispatch();
    const notificationsCount = useSelector(selectNotificationsCount());

    React.useEffect(() => {
        dispatch(fetchNotificationsCount());
        const intervalId = window.setInterval(() => {
            dispatch(fetchNotificationsCount());
        }, FETCH_INTERVAL);
        return () => window.clearInterval(intervalId);
    }, [dispatch]);

    return (
        <ListItemButton
            key="notifications"
            onClick={onClick}
            sx={{ background: backgroundColor }}
        >
            <ListItemIcon sx={{ color: textColor }}>
                <Badge
                    badgeContent={notificationsCount}
                    color="error"
                >
                    <Home />
                </Badge>
            </ListItemIcon>
            <ListItemText primary="Hem" sx={{ color: textColor }} />
        </ListItemButton>
    );
};

export default HomeMenuItem;
