const pinSVGHole = 'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z';
const labelOriginHole = new window.google.maps.Point(12, 15);
const pinSVGFilled = 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z';
const labelOriginFilled = new window.google.maps.Point(12, 9);

// const newPath = 'M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z';
// 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z';

// https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
type PropsType = {
    fillColor: string,
    strokeColor: string,
    filled?: boolean
}
export default function getMarkerIcon({ fillColor, strokeColor, filled }: PropsType) {
    return {
        path: filled ? pinSVGFilled : pinSVGHole,
        anchor: new window.google.maps.Point(12, 17),
        fillOpacity: 1,
        fillColor,
        strokeWeight: 2,
        strokeColor,
        scale: 1.5,
        labelOrigin: filled ? labelOriginFilled : labelOriginHole
    };
}
