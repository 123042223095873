import type { RootStateType, ThunkDispatchType } from '../index';
import tenants from '../slices/tenants';
import { getTenantProfile } from '../../api/tenants';

export function fetchTenantProfile() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(tenants.actions.fetchProfile());
            const data = await getTenantProfile();
            dispatch(tenants.actions.profileFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching tenant profile, set redux error state and handle in errorBoundary.', e);
            dispatch(tenants.actions.profileFetched({ data: null }));
        }
    };
}

export const selectTenantProfile = () => (state: RootStateType) => state.tenants.profile;
export const selectFetchingTenantProfile = () => (state: RootStateType) => state.tenants.fetchingProfile;
