import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    fetchAllStartedJobs,
    selectFetchingAllStartedJobs,
    selectAllStartedJobs
} from '../../../redux/actions/jobs';
import { JobForListType } from '../../../types';
import { Table } from '../../CommonComponents';
import { FetchPropsType } from '../../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../../CommonComponents/Fetch/FetchRedux';
import { getOrderDate } from '../../../utils/order';
import { formatNumber } from '../../../utils/parsing';

const columns = [{
    name: 'Transportdatum',
    key: 'orderDate',
    getValue: getOrderDate
}, {
    name: 'Upphämtningsort',
    key: 'pickupCity'
}, {
    name: 'Leveransort',
    key: 'deliveryCity'
}, {
    name: 'Körsträcka',
    key: 'km',
    type: 'number' as 'number',
    getValue: (r: { drivingDistance: number }) => formatNumber(Math.round(r.drivingDistance / 1000), 'km')
}, {
    name: 'Totalvikt',
    key: 'grossWeight',
    type: 'kg' as 'kg'
}, {
    name: 'Uppdragsgivare',
    key: 'tenantName'
}];

function StartedJobs(props: FetchPropsType<JobForListType[]>) {
    const { entity, isFetching } = props;
    const navigate = useNavigate();

    const onRowClick = React.useCallback(({ id }: { id: number | string }) => (
        navigate(`/jobs/${id}`)
    ), [navigate]);

    return (
        <Table
            name="offeredJobs"
            columns={columns}
            data={entity}
            onRowClick={onRowClick}
            emptyText={isFetching ? 'Hämtar bekräftade uppdrag...' : 'Ni har inga bekräftade uppdrag.'}
        />
    );
}

export default function StartedJobsFetched() {
    return (
        <FetchRedux<JobForListType[]>
            fetchEntity={fetchAllStartedJobs()}
            selectEntity={selectAllStartedJobs()}
            selectIsFetching={selectFetchingAllStartedJobs()}
            Component={StartedJobs}
            name="bekräftade uppdragen"
        />
    );
}
