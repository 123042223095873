import React from 'react';
import { createUser } from '../../api/users';
import { DataImport } from '../CommonComponents';
import { validateEmail, validatePhone } from '../CommonComponents/Form/validations/validations';

const UserImport: React.FC = () => {
    return (
        <DataImport
            entityName="användare"
            importData={createUser}
            fields={[{
                key: 'firstName',
                name: 'Förnamn',
                defaultValue: ''
            }, {
                key: 'lastName',
                name: 'Efternamn',
                defaultValue: ''
            }, {
                key: 'title',
                name: 'Titel',
                defaultValue: ''
            }, {
                key: 'email',
                name: 'E-post',
                defaultValue: '',
                isRequired: true,
                isUnique: true,
                validate: validateEmail
            }, {
                key: 'phone',
                name: 'Mobil',
                defaultValue: '',
                isRequired: true,
                validate: validatePhone
            }, {
                key: 'isAdmin',
                name: 'Administratör',
                type: 'bool',
                defaultValue: false
            }, {
                key: 'sendInvitation',
                name: 'Skicka e-postinbjudan',
                type: 'bool',
                defaultValue: false
            }]}
        />
    );
};

export default UserImport;
