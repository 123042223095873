import React from 'react';
import {
    Badge,
    Button,
    Chip,
    Tooltip
} from '@mui/material';
import { Check, Close, Message } from '@mui/icons-material';

import { Block, Popup, Table } from '../CommonComponents';
import { acceptOrderOffer, rejectOrderOffer } from '../../api/orders';
import { resetLastAllOpenJobsFetch } from '../../redux/actions/market';
import { useDispatch, useSelector } from '../../redux/hooks';
import toastMessage from '../../utils/toast';
import OfferChatPopup from '../Offers/OfferChatPopup';
import { fetchAllOffersByOrderId, selectAllOffersByOrderId } from '../../redux/actions/offers';
import { OrderOfferType } from '../../types';
import { offerStatus } from '../../utils/status';
import { formatDateTime } from '../../utils/parsing';

type PropsType = {
    orderId: number,
    refetch: () => void
}

const FETCH_INTERVAL = 60 * 1000; // 60 seconds

export default function OrderOffers({ orderId, refetch }: PropsType) {
    const dispatch = useDispatch();
    const offers = useSelector(selectAllOffersByOrderId(orderId));

    const [offerForMessages, setOfferForMessages] = React.useState<OrderOfferType | null>(null);
    const [offerForAccept, setOfferForAccept] = React.useState<OrderOfferType | null>(null);

    const fetchOffers = React.useCallback(() => {
        if (orderId) {
            dispatch(fetchAllOffersByOrderId(orderId));
        }
    }, [dispatch, orderId]);

    React.useEffect(() => {
        fetchOffers();
        const intervalId = window.setInterval(fetchOffers, FETCH_INTERVAL);

        return () => window.clearInterval(intervalId); // Runs before every effect-run, and on unmount
    }, [fetchOffers]);

    const closeMessagePopup = React.useCallback(() => {
        fetchOffers();
        setOfferForMessages(null);
    }, [fetchOffers]);

    const acceptOffer = React.useCallback(async () => {
        if (offerForAccept?.id) {
            try {
                await acceptOrderOffer({ orderId, offerId: offerForAccept?.id });
                dispatch(resetLastAllOpenJobsFetch()); // Refetch allJobs to get correct group/watchlist stats
                toastMessage('Uppdraget tilldelat!');
            } catch (e) {
                toastMessage(e, 'Kunde inte tilldela uppdraget, försök igen');
            }
            refetch();
            fetchOffers();
            setOfferForAccept(null);
        }
    }, [offerForAccept?.id, refetch, fetchOffers, orderId, dispatch]);

    const rejectOffer = React.useCallback(async (offerId) => {
        if (offerId) {
            try {
                await rejectOrderOffer({ orderId, offerId });
                toastMessage('Intresseanmälan avböjd.');
            } catch (e) {
                toastMessage(e, 'Kunde inte avböja intresseanmälan, försök igen');
            }
            fetchOffers();
        }
    }, [fetchOffers, orderId]);

    const getButtons = React.useCallback((offer) => (
        <>
            {offer.status === offerStatus.ACCEPTED && (
                <Chip label="Tilldelat" color="primary" />
            )}
            {offer.status === offerStatus.REJECTED && (
                <Tooltip title={`${formatDateTime(offer.rejectedAt)} av ${offer.rejectedByName}`}>
                    <Chip label="Avböjt" color="error" />
                </Tooltip>
            )}
            {offer.status === offerStatus.EXPIRED && (
                <Chip label="Utlöpt" color="warning" />
            )}
            {offer.status === offerStatus.PENDING && (
                <>
                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<Close />}
                        onClick={() => rejectOffer(offer.id)}
                        sx={{ ml: 1 }}
                    >
                        Avböj
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<Check />}
                        onClick={() => {
                            fetchOffers();
                            setOfferForAccept(offer);
                        }}
                        sx={{ ml: 1 }}
                    >
                        Tilldela
                    </Button>
                </>
            )}
            {offer.status !== offerStatus.ACCEPTED && (
                <Badge color="error" badgeContent={offer.unreadMessageCount}>
                    <Button
                        variant="contained"
                        color="info"
                        startIcon={<Message />}
                        onClick={() => setOfferForMessages(offer)}
                        sx={{ ml: 1 }}
                    >
                        {`${offer.messageCount} Meddelande${offer.messageCount !== 1 ? 'n' : ''}`}
                    </Button>
                </Badge>
            )}
        </>
    ), [fetchOffers, rejectOffer]);

    return (
        <Block title="Intresseanmälningar">
            <Popup
                open={!!offerForAccept?.id}
                title="Tilldela uppdraget"
                body={`Vill du verkligen tilldela uppdraget till ${offerForAccept?.tenantName || 'företaget'} och därmed också avböja övriga intresseanmälningar?`}
                okLabel="Tilldela"
                cancelLabel="Avbryt"
                handleOk={acceptOffer}
                handleClose={() => setOfferForAccept(null)}
            />

            <OfferChatPopup
                id={offerForMessages?.id || null}
                recipient={offerForMessages?.tenantName || ''}
                canWrite={offerForMessages?.isOpen}
                close={closeMessagePopup}
            />

            <Table
                name="dashboardActiveOffers"
                columns={[{
                    name: 'Företag',
                    key: 'tenantName'
                }, {
                    name: 'Önskat pris',
                    key: 'price',
                    type: 'currency'
                }, {
                    name: 'Skickat',
                    key: 'updatedAt',
                    type: 'datetime'
                }, {
                    name: 'Svara senast',
                    key: 'expiresAt',
                    type: 'datetime'
                }, {
                    name: '',
                    key: 'custom',
                    align: 'right',
                    getValue: getButtons
                }]}
                data={offers}
                emptyText="Det finns inga intresseanmälningar."
            />
        </Block>
    );
}
