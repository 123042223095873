import React from 'react';
import {
    InputLabel,
    Slider,
    styled
} from '@mui/material';

// import { validateNumberRange } from '../validations/validations';

type PropsType = {
    name: string,
    label: string,
    lowValue: number,
    highValue: number,
    minValue: number,
    maxValue: number,
    step?: number,
    required?: boolean,
    // helperText?: string,
    onChange: (values: [number, number]) => void
};

const StyledSlider = styled(Slider)({
    '& .MuiSlider-valueLabel': {
        top: 55,
        '&:before': { top: -7 }
    },
});
export default function RangeField(props: PropsType) {
    const {
        name,
        label,
        lowValue,
        highValue,
        minValue,
        maxValue,
        step,
        onChange,
        required
        // ...baseProps
    } = props;

    const [value, setValue] = React.useState<number[]>([Math.max(lowValue, minValue), Math.min(highValue, maxValue)]);

    React.useEffect(() => {
        setValue([Math.max(lowValue, minValue), Math.min(highValue, maxValue)]);
    }, [lowValue, highValue, minValue, maxValue]);

    const handleChange = React.useCallback((event, val) => {
        setValue(val);
    }, []);

    const commitChange = React.useCallback((event, val) => {
        onChange([val[0] === minValue ? null : val[0], val[1] === maxValue ? null : val[1]]);
    }, [maxValue, minValue, onChange]);

    const stepValue = React.useMemo(() => (
        step || Math.round((maxValue - minValue) / Math.min(100, maxValue - minValue))
    ), [minValue, maxValue, step]);

    const displayLabelState = React.useMemo(() => (
        value[0] === minValue && value[1] === maxValue
            ? 'off'
            : 'on'
    ), [value, maxValue, minValue]);

    const getLabel = React.useCallback((val) => {
        if (val === minValue) {
            return 'Min';
        }
        if (val === maxValue) {
            return 'Max';
        }
        return val;
    }, [minValue, maxValue]);

    // const validations = [];
    // if (typeof minValue === 'number' || typeof maxValue === 'number') {
    //     validations.push(validateNumberRange({ value: baseProps.value, minValue, maxValue }));
    // }

    return (
        <>
            <InputLabel>
                {`${label}${required ? ' *' : ''}`}
            </InputLabel>
            <div style={{
                paddingBottom: '20px',
                paddingLeft: '30px',
                paddingRight: '30px'
            }}
            >
                <StyledSlider
                    name={name}
                    // getAriaLabel={getLabel}
                    value={value}
                    max={maxValue}
                    min={minValue}
                    step={stepValue}
                    onChange={handleChange}
                    onChangeCommitted={commitChange}
                    // valueLabelDisplay="on"
                    valueLabelFormat={getLabel}
                    valueLabelDisplay={displayLabelState}
                // getAriaValueText={getLabel}
                />
            </div>
        </>
    );
}
