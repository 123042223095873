/* eslint-disable no-param-reassign */
// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { OrderOfferType, OfferMessageType, ActiveOfferType } from '../../types';

type OffersStateType = {
    activeOffers: ActiveOfferType[],
    fetchingActiveOffers: boolean,

    allByOrderId: { [key: number | string]: OrderOfferType[] },
    fetchingAllByOrderId: { [key: number | string]: boolean },

    messagesById: { [key: number | string]: OfferMessageType[] },
    fetchingMessagesById: { [key: number | string]: boolean }
};

const initialState: OffersStateType = {
    activeOffers: [],
    fetchingActiveOffers: false,
    allByOrderId: {},
    fetchingAllByOrderId: {},
    messagesById: {},
    fetchingMessagesById: {}
};

const slice = createSlice({
    name: 'offers',
    initialState,
    reducers: {
        fetchActiveOffers: (state) => {
            state.fetchingActiveOffers = true;
        },
        activeOffersFetched: (state, action: PayloadAction<{ data?: ActiveOfferType[] }>) => {
            state.activeOffers = action.payload.data || [];
            state.fetchingActiveOffers = false;
        },
        fetchAllByOrderId: (state, action) => {
            state.fetchingAllByOrderId[action.payload.orderId] = true;
        },
        allByOrderIdFetched: (state, action: PayloadAction<{ orderId: number | string, data?: OrderOfferType[] }>) => {
            state.allByOrderId[action.payload.orderId] = action.payload.data || [];
            state.fetchingAllByOrderId[action.payload.orderId] = false;
        },
        fetchMessagesById: (state, action) => {
            state.fetchingMessagesById[action.payload.id] = true;
        },
        messagesByIdFetched: (state, action: PayloadAction<{ id: number | string, data?: OfferMessageType[] }>) => {
            state.messagesById[action.payload.id] = action.payload.data || [];
            state.fetchingMessagesById[action.payload.id] = false;
        }
    }
});

export default slice;
