import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Button } from '@mui/material';
import { Message } from '@mui/icons-material';

import { Block, Table } from '../CommonComponents';
import { getOrderDate } from '../../utils/order';
import { formatCities, formatLoad } from '../../utils/parsing';
import OfferChatPopup from './OfferChatPopup';
import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchActiveOffers, selectActiveOffers } from '../../redux/actions/offers';
import { ActiveOfferType } from '../../types';

const FETCH_INTERVAL = 60 * 1000; // 60 seconds

export default function ActiveOffers() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const activeOffers = useSelector(selectActiveOffers());

    const refetchActiveOffers = React.useCallback(() => {
        dispatch(fetchActiveOffers());
    }, [dispatch]);

    React.useEffect(() => {
        refetchActiveOffers();
        const intervalId = window.setInterval(refetchActiveOffers, FETCH_INTERVAL);

        return () => window.clearInterval(intervalId); // Runs before every effect-run, and on unmount
    }, [dispatch, refetchActiveOffers]);

    const [offerForMessages, setOfferForMessages] = React.useState<ActiveOfferType | null>(null);
    const closeMessagePopup = React.useCallback(() => {
        refetchActiveOffers();
        setOfferForMessages(null);
    }, [refetchActiveOffers]);

    const onRowClick = React.useCallback(({ type, orderId }: any) => {
        if (type === 'ORDER') {
            navigate(`/orders/${orderId}`);
        } else {
            navigate(`/jobs/LL/${orderId}`);
        }
    }, [navigate]);

    const getMsgBtn = React.useCallback((offer) => (
        <Badge color="error" badgeContent={offer.unreadMessageCount}>
            <Button
                variant="contained"
                color="info"
                startIcon={<Message />}
                onClick={(e) => {
                    e.stopPropagation();
                    setOfferForMessages(offer);
                }}
            >
                {`${offer.messageCount} Meddelande${offer.messageCount !== 1 ? 'n' : ''}`}
            </Button>
        </Badge>
    ), []);

    return (
        <Block
            title="Aktiva intresseanmälningar"
        >
            <OfferChatPopup
                id={offerForMessages?.id || null}
                recipient={offerForMessages?.tenantName || 'Meddelanden'}
                canWrite // Can safely assume true since backend only passes active
                close={closeMessagePopup}
            />

            <Table
                name="dashboardActiveOffers"
                columns={[{
                    name: 'Typ',
                    key: 'type',
                    getValue: (o) => (o.type === 'ORDER' ? 'Boka' : 'Kör')
                }, {
                    name: 'Datum',
                    key: 'date',
                    getValue: getOrderDate
                }, {
                    name: 'Sträcka',
                    key: 'cities',
                    getValue: formatCities
                }, {
                    name: 'Gods',
                    key: 'load',
                    getValue: formatLoad
                }, {
                    name: 'Företag',
                    key: 'tenantName'
                }, {
                    name: 'Önskat pris',
                    key: 'price',
                    type: 'currency'
                }, {
                    name: 'Svara senast',
                    key: 'expiresAt',
                    type: 'datetime'
                }, {
                    name: '',
                    key: 'customMessages',
                    getValue: getMsgBtn,
                    align: 'right'
                }]}
                data={activeOffers}
                onRowClick={onRowClick}
                defaultOrderBy="date"
                emptyText="Ni har inga aktiva intresseanmälningar"
            />
        </Block>
    );
}
