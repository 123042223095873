import React from 'react';
import { Button } from '@mui/material';
import { Phone } from '@mui/icons-material';

export default function DhlCall() {
    return (
        <Button
            variant="contained"
            startIcon={<Phone />}
            onClick={() => window.open('tel:0771-183600')}
        >
            0771 - 183600
        </Button>
    );
}
