import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import auth from './slices/auth';
import capacities from './slices/capacities';
import groups from './slices/groups';
import integrations from './slices/integrations';
import jobs from './slices/jobs';
import market from './slices/market';
import notifications from './slices/notifications';
import orders from './slices/orders';
import offers from './slices/offers';
import settings from './slices/settings';
import tables from './slices/tables';
import tabs from './slices/tabs';
import tenants from './slices/tenants';
import toast from './slices/toast';
import users from './slices/users';
import watchlists from './slices/watchlists';

const store = configureStore({
    reducer: {
        auth: auth.reducer,
        capacities: capacities.reducer,
        groups: groups.reducer,
        integrations: integrations.reducer,
        jobs: jobs.reducer,
        market: market.reducer,
        notifications: notifications.reducer,
        orders: orders.reducer,
        offers: offers.reducer,
        settings: settings.reducer,
        tables: tables.reducer,
        tabs: tabs.reducer,
        tenants: tenants.reducer,
        toast: toast.reducer,
        users: users.reducer,
        watchlists: watchlists.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
});

export default store;

export type DispatchType = typeof store.dispatch;

export type RootStateType = ReturnType<typeof store.getState>;

export type ThunkDispatchType = ThunkDispatch<RootStateType, unknown, AnyAction>;
// // If returning promise, use: ThunkType<Promise<SomeReturnType>>
// export type ThunkType<ReturnType = void> = ThunkAction<
//     ReturnType,
//     RootState,
//     unknown,
//     AnyAction
// >;
