import { getCurrentUser } from '../utils/auth';
import logout from '../utils/logout';

const apiUri = process.env.REACT_APP_API_URI;

type RequestPropsType = {
    method: string,
    resource: string,
    id?: number | string,
    body?: any
};

export default async function apiRequest({
    method = 'GET',
    resource,
    id,
    body
}: RequestPropsType) {
    const user = await getCurrentUser();
    const idJwt = user.signInUserSession.idToken.jwtToken;

    const endpoint = `${apiUri}/${resource}${id ? `/${id}` : ''}`;
    const options: any = {
        method,
        headers: {
            Authorization: idJwt,
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(body)
    };

    const response = await fetch(endpoint, options);
    const json = await response.json();

    if (response.status >= 400) {
        if (response.status === 401) {
            logout();
        } else {
            throw new Error(json);
        }
    }
    return json;
}
