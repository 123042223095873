export const TRANSPORTOREN_OPTER = 'TRANSPORTOREN_OPTER';
export const TCHALLAND_OPTER = 'TCHALLAND_OPTER';
export const SNABBUD_OPTER = 'SNABBUD_OPTER';

export default function parseIntegraitonType(type: string) {
    switch (type) {
        case TRANSPORTOREN_OPTER:
            return 'Opter (Transportören AB)';
        case TCHALLAND_OPTER:
            return 'Opter (TransportCentralen i Halland AB)';
        case SNABBUD_OPTER:
            return 'Opter (SNABBUD i Nyköping AB)';
        default:
            return '';
    }
}
