import { BookmarkAdded, BookmarkAddOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';

import { addBookmark, removeBookmark } from '../../api/jobs';
import { OrderTypeType } from '../../types';
import toast from '../../utils/toast';

type PropsType = {
    job?: {
        id: number,
        type: OrderTypeType,
        isBookmarked: boolean
    } | null,
    refetch: () => void
};

const JobBookmark: React.FC<PropsType> = (props) => {
    const {
        job,
        refetch
    } = props;

    const bookmarkJob = React.useCallback(async () => {
        if (job) {
            const { type, id, isBookmarked } = job;
            try {
                if (isBookmarked) {
                    await removeBookmark({ type, id });
                    toast('Bokmärke borttaget');
                    refetch();
                } else {
                    await addBookmark({ type, id });
                    toast('Bokmärke tillagt');
                    refetch();
                }
            } catch (e) {
                toast(e, `Kunde inte ${isBookmarked ? 'ta bort bokmärket' : 'lägga till bokmärket'}, försök igen`);
            }
        }
    }, [job, refetch]);

    if (!job) {
        return null;
    }

    return job.isBookmarked ? (
        <Button
            startIcon={<BookmarkAdded />}
            color="primary"
            aria-label="Bokmärkt"
            onClick={bookmarkJob}
        >
            Bokmärkt
        </Button>
    ) : (
        <Button
            startIcon={<BookmarkAddOutlined />}
            color="primary"
            aria-label="Bokmärk"
            onClick={bookmarkJob}
        >
            Bokmärk
        </Button>
    );
};

export default JobBookmark;
