import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    Typography
} from '@mui/material';
import {
    Email as EmailIcon,
    Logout as LogoutIcon,
    Phone as PhoneIcon
} from '@mui/icons-material';
import logout from '../../utils/logout';
import VerifyEmail from './VerifyEmail';
import VerifyPhone from './VerifyPhone';

const supportMailAddress = encodeURIComponent('"Logic Link Support" <support@logiclink.se>');
const supportMailSubject = encodeURIComponent('Jag väntar på akrivering');
const supportMailUrl = `mailto:${supportMailAddress}?subject=${supportMailSubject}`;
const supportPhoneUrl = 'tel:0470-515110';

export default function PendingActivation() {
    return (
        <Box sx={{ minHeight: '100vh', backgroundColor: 'primary.main' }}>
            <Container component="main" maxWidth="xs" sx={{ p: 4 }}>
                <Box sx={{ pt: 2, textAlign: 'center' }}>
                    <Avatar
                        variant="square"
                        sx={{
                            ml: 'auto',
                            mr: 'auto',
                            width: 150,
                            height: 150,
                        }}
                        src="/logiclink_logo_white.png"
                    />
                </Box>

                <Typography variant="h5" color="secondary.main" sx={{ textAlign: 'center', mb: 4, mt: 6 }}>
                    Tack för din registrering!
                </Typography>

                <Typography color="white" sx={{ textAlign: 'center', mb: 2, mt: 2 }}>
                    Innan vi aktiverar ditt konto behöver vi knyta dina uppgifter till ett behörigt företag.
                </Typography>

                <Typography color="white" sx={{ textAlign: 'center', mb: 2, mt: 2 }}>
                    Börja med att verifiera dina kontaktuppgifter.
                </Typography>

                <Grid container spacing={1} justifyContent="space-between">
                    <Grid item xs={6}>
                        <VerifyEmail />
                    </Grid>
                    <Grid item xs={6}>
                        <VerifyPhone />
                    </Grid>
                </Grid>

                <Typography color="white" sx={{ textAlign: 'center', mb: 2, mt: 3 }}>
                    Inom en arbetsdag kommer du att få besked från oss.
                    Kontakta oss under tiden om du har några frågor.
                </Typography>

                <Grid container spacing={1} justifyContent="space-between">
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            startIcon={<PhoneIcon />}
                            color="secondary"
                            href={supportPhoneUrl}
                            target="_blank"
                        >
                            0470 - 51 51 10
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            startIcon={<EmailIcon />}
                            color="secondary"
                            href={supportMailUrl}
                            target="_blank"
                        >
                            support@logiclink.se
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            startIcon={<LogoutIcon />}
                            color="secondary"
                            onClick={logout}
                        >
                            Logga ut
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
