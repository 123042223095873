/* eslint-disable no-param-reassign */
// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WatchlistForListType } from '../../types';

type WatchlistsStateType = {
    all: WatchlistForListType[],
    fetchingAll: boolean
};

const initialState: WatchlistsStateType = {
    all: [],
    fetchingAll: false
};

const slice = createSlice({
    name: 'watchlists',
    initialState,
    reducers: {
        fetchAll: (state) => {
            state.fetchingAll = true;
        },
        allFetched: (state, action: PayloadAction<{data: WatchlistsStateType['all']}>) => {
            state.all = action.payload.data;
            state.fetchingAll = false;
        },
    }
});

export default slice;
