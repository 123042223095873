import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
    CheckBoxOutlineBlank,
    CheckBox,
    ExpandMore,
    ExpandLess
} from '@mui/icons-material';
import {
    Box,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
    setMarketFilters,
    selectMarketFilters,
    selectMarketSettings,
    setMarketSettings
} from '../../redux/actions/market';
import { BoolField } from '../CommonComponents/Form';
import { updateUserProfile } from '../../api/users';
import { capitalize } from '../../utils';

export default function MarketSettings({ sx }: { sx: any }) {
    const dispatch = useDispatch();
    const {
        isJob,
        isCapacity,
        takesOffer
    } = useSelector(selectMarketFilters());
    const {
        settingsOpen,
        autoFocus,
        showPickupPin,
        showDeliveryPin,
        showDistanceLine
    } = useSelector(selectMarketSettings());

    const setValue = React.useCallback(async (key, value) => {
        if (['isJob', 'isCapacity', 'takesOffer'].includes(key)) {
            dispatch(setMarketFilters({ [key]: value }));
        }
        if (['settingsOpen', 'autoFocus', 'showPickupPin', 'showDeliveryPin', 'showDistanceLine'].includes(key)) {
            dispatch(setMarketSettings({ [key]: value }));
        }
        if ([
            'isJob',
            'isCapacity',
            'takesOffer',
            'autoFocus',
            'showPickupPin',
            'showDeliveryPin',
            'showDistanceLine'
        ].includes(key)) {
            try {
                await updateUserProfile({ [`marketSetting${capitalize(key)}`]: value });
            } catch {
                // Fail silently
            }
        }
    }, [dispatch]);

    return (
        <Box sx={{ ...sx, display: 'block', borderRadius: 1 }}>
            <ToggleButtonGroup aria-label="Typ">
                <ToggleButton
                    value="isJob"
                    color="primary"
                    selected={isJob}
                    onClick={() => setValue('isJob', !isJob)}
                    sx={{ width: 140 }}
                >
                    {
                        isJob
                            ? <CheckBox fontSize="small" />
                            : <CheckBoxOutlineBlank fontSize="small" />
                    }
                    <Typography variant="button" sx={{ pt: '1px', pl: '4px' }}>
                        Uppdrag
                    </Typography>
                </ToggleButton>
                <ToggleButton
                    value="isCapacity"
                    color="info"
                    selected={isCapacity}
                    onClick={() => setValue('isCapacity', !isCapacity)}
                    sx={{ width: 140 }}
                >
                    {
                        isCapacity
                            ? <CheckBox fontSize="small" />
                            : <CheckBoxOutlineBlank fontSize="small" />
                    }
                    <Typography variant="button" sx={{ pt: '1px', pl: '4px' }}>
                        Kapacitet
                    </Typography>
                </ToggleButton>
            </ToggleButtonGroup>

            <IconButton
                sx={{
                    position: 'absolute',
                    top: 28,
                    right: '43%',
                    background: '#FAFAFA',
                    opacity: '80%',
                    zIndex: 10
                }}
                onClick={() => setValue('settingsOpen', !settingsOpen)}
            >
                {settingsOpen ? (
                    <ExpandLess />
                ) : (
                    <ExpandMore />
                )}
            </IconButton>

            {settingsOpen && (
                <>
                    <Grid
                        container
                        justifyContent="space-between"
                        sx={{ pl: 2, pt: 1, mb: '-5px' }}
                    >
                        <Grid item>
                            <BoolField
                                name="a"
                                label="Dölj externa"
                                value={takesOffer}
                                onChange={() => setValue('takesOffer', !takesOffer)}
                            />
                        </Grid>
                        <Grid item>
                            <BoolField
                                name="autoFocus"
                                label="Autofokus"
                                value={!!autoFocus}
                                onChange={() => setValue('autoFocus', !autoFocus)}
                            />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="space-between" sx={{ pl: 2 }}>
                        <Grid item>
                            <BoolField
                                name="showPickupPin"
                                label="Start"
                                value={!!showPickupPin}
                                onChange={() => {
                                    if (showDistanceLine || showDeliveryPin || !showPickupPin) {
                                        setValue('showPickupPin', !showPickupPin);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <BoolField
                                name="showDistanceLine"
                                label="Sträcka"
                                value={!!showDistanceLine}
                                onChange={() => {
                                    if (showPickupPin || showDeliveryPin || !showDistanceLine) {
                                        setValue('showDistanceLine', !showDistanceLine);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <BoolField
                                name="showDeliveryPin"
                                label="Mål"
                                value={!!showDeliveryPin}
                                onChange={() => {
                                    if (showPickupPin || showDistanceLine || !showDeliveryPin) {
                                        setValue('showDeliveryPin', !showDeliveryPin);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </Box>
    );
}
