import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from 'lodash';

import getLocationDetails from '../../../utils/getLocationDetails';
// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
// const GOOGLE_MAPS_API_KEY = 'AIzaSyAuGKaL8YtUG-9jjZjKgha22Redp71SUyc';

// function loadScript(src: string, position: HTMLElement | null, id: string) {
//     if (!position) {
//         return;
//     }

//     const script = document.createElement('script');
//     script.setAttribute('async', '');
//     script.setAttribute('id', id);
//     script.src = src;
//     position.appendChild(script);
// }

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}
interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
    place_id: string,
    description: string;
    structured_formatting: StructuredFormatting;
}
export type LocationType = {
    lat: number,
    lng: number,
    street: string,
    zip: string,
    city: string,
    country: string
};

type PropsType = {
    label: string,
    defaultText?: string,
    error?: boolean,
    onChange: (location: LocationType | null) => void
};

function getDefaultValue(text?: string) {
    return (
        text
            ? {
                place_id: 'custom',
                description: text,
                structured_formatting: {
                    main_text: '',
                    secondary_text: '',
                    main_text_matched_substrings: []
                }
            }
            : null
    );
}
export default function LocationPicker(props: PropsType): JSX.Element {
    const {
        defaultText,
        error,
        label,
        onChange
    } = props;
    const [value, setValue] = React.useState<PlaceType | null>(getDefaultValue(defaultText));
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
    // const loaded = React.useRef(false);

    // if (typeof window !== 'undefined' && !loaded.current) {
    //     if (!document.querySelector('#google-maps')) {
    //         loadScript(
    //             `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
    //             document.querySelector('head'),
    //             'google-maps',
    //         );
    //     }

    //     loaded.current = true;
    // }

    React.useEffect(() => {
        setValue(getDefaultValue(defaultText));
    }, [defaultText]);

    const fetch = React.useMemo(() => debounce(
        (
            input: string,
            callback: (results?: readonly PlaceType[]) => void,
        ) => {
            (autocompleteService.current as any).getPlacePredictions(
                {
                    input,
                    componentRestrictions: { country: 'se' }
                },
                callback,
            );
        },
        500,
        // { leading: false }
    ), []);

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (
                window as any
            ).google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch(inputValue, (results?: readonly PlaceType[]) => {
            if (active) {
                let newOptions: readonly PlaceType[] = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            noOptionsText="Inga resultat, justera din sökning"
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            clearText="Rensa"
            onChange={async (event: any, newValue: PlaceType | null) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                const locationDetails = await getLocationDetails(newValue?.place_id);
                onChange(locationDetails);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label={label} error={error} fullWidth size="small" />
            )}
            renderOption={(renderProps, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [match.offset, match.offset + match.length]),
                );

                return (
                    <li {...renderProps}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Box
                                    component={LocationOnIcon}
                                    sx={{ color: 'text.secondary', mr: 2 }}
                                />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part: { highlight: boolean, text: string }, index: number) => (
                                    <span
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}
