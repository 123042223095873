import settings from '../slices/settings';
import type { RootStateType } from '../index';

export const {
    setMarketFilterOpen,
    setMarketListOpen
} = settings.actions;

export const selectMarketFilterOpen = () => (state: RootStateType) => state.settings.marketFilterOpen;
export const selectMarketListOpen = () => (state: RootStateType) => state.settings.marketListOpen;
