import { IntegrationType } from '../types';
import apiRequest from './api-request';

const resource = 'integrations';

export function getIntegrations() {
    return apiRequest({
        method: 'GET',
        resource
    });
}

export function getIntegration(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource,
        id
    });
}

export function getIntegrationMeta(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `${resource}/${id}/meta`
    });
}

export function getIntegrationLogs(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `${resource}/${id}/logs`
    });
}

export function createIntegration(body: Partial<IntegrationType>) {
    return apiRequest({
        method: 'POST',
        resource,
        body
    });
}

export function updateIntegration(id: number | string, body: Partial<IntegrationType>) {
    return apiRequest({
        method: 'PUT',
        resource,
        id,
        body
    });
}

export function deleteIntegration(id: number | string) {
    return apiRequest({
        method: 'DELETE',
        resource,
        id
    });
}
