import React from 'react';
import {
    Box,
    Chip,
    Grid,
    Typography
} from '@mui/material';

import {
    LocationOn as LocationOnIcon
} from '@mui/icons-material';

import {
    formatDate,
    formatMeters,
    formatTime
} from '../../utils/parsing';

type PropsType = {
    data: {
        firstPickupDate: string,
        lastPickupDate?: string | null,
        firstDeliveryDate?: string,
        lastDeliveryDate: string | null,
        firstPickupTime?: string | null,
        lastPickupTime?: string | null,
        firstDeliveryTime?: string | null,
        lastDeliveryTime?: string | null,
        drivingDistance: number | null,
        pickupCity: string,
        pickupAttention?: string | null,
        pickupStreet?: string | null,
        pickupZip?: string | null,
        deliveryCity: string
        deliveryAttention?: string | null,
        deliveryStreet?: string | null,
        deliveryZip?: string | null
    },
    small?: boolean
};

const LINE_HEIGHT = 4;

const DriveLine: React.FC<PropsType> = (props) => {
    const {
        data,
        small
    } = props;

    return (
        <Box sx={{ m: small ? 1 : 2, minWidth: 480 }}>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center" wrap="nowrap">
                <Grid item>
                    <Typography variant={small ? 'h5' : 'h4'}>
                        {data.pickupCity}
                    </Typography>
                    {(data.pickupStreet || data.pickupZip) && (
                        <Typography variant={small ? 'subtitle1' : 'h6'}>
                            {data.pickupStreet}
                            {data.pickupStreet && data.pickupZip && ', '}
                            {data.pickupZip}
                        </Typography>
                    )}
                    {data.pickupAttention && (
                        <Typography variant={small ? 'subtitle1' : 'h6'}>
                            {`Ref: ${data.pickupAttention}`}
                        </Typography>
                    )}
                </Grid>
                <Grid item textAlign="right">
                    <Typography variant={small ? 'h5' : 'h4'}>
                        {data.deliveryCity}
                    </Typography>
                    {(data.deliveryStreet || data.deliveryZip) && (
                        <Typography variant={small ? 'subtitle1' : 'h6'}>
                            {data.deliveryStreet}
                            {data.deliveryStreet && data.deliveryZip && ', '}
                            {data.deliveryZip}
                        </Typography>
                    )}
                    {data.deliveryAttention && (
                        <Typography variant={small ? 'subtitle1' : 'h6'}>
                            {`Ref: ${data.deliveryAttention}`}
                        </Typography>
                    )}
                </Grid>
            </Grid>

            <Box
                sx={{
                    flex: 1,
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 6,
                    marginRight: 6,
                    position: 'relative'
                }}
            >
                <Box
                    sx={{
                        height: LINE_HEIGHT,
                        marginLeft: 1,
                        marginRight: 1,
                        backgroundColor: 'primary.main',
                        position: 'relative'
                    }}
                />
                <Box
                    sx={{
                        height: LINE_HEIGHT * 3,
                        width: LINE_HEIGHT * 3,
                        borderRadius: (LINE_HEIGHT * 3) / 2,
                        position: 'absolute',
                        top: -LINE_HEIGHT,
                        left: 0,
                        backgroundColor: 'primary.main'
                    }}
                />
                <Box
                    sx={{
                        height: LINE_HEIGHT * 3,
                        width: LINE_HEIGHT * 3,
                        borderRadius: LINE_HEIGHT * (3 / 2),
                        borderColor: 'primary.main',
                        borderStyle: 'solid',
                        borderWidth: LINE_HEIGHT,
                        backgroundColor: 'white',
                        position: 'absolute',
                        top: -LINE_HEIGHT,
                        right: 0,
                    }}
                />
            </Box>

            <Grid container spacing={2} justifyContent="space-between" alignItems="flex-start" wrap="nowrap">
                <Grid item>
                    {data.lastPickupDate && (
                        <Typography variant="body2">
                            Från&nbsp;
                        </Typography>
                    )}

                    <Typography variant={small ? 'h6' : 'h5'}>
                        {formatDate(data.firstPickupDate)}
                        {data.firstPickupTime ? `, ${formatTime(data.firstPickupTime)}` : ''}
                    </Typography>
                    {data.lastPickupDate && (
                        <>
                            <Typography variant="body2">
                                Till&nbsp;
                            </Typography>
                            <Typography variant={small ? 'h6' : 'h5'}>
                                {formatDate(data.lastPickupDate)}
                                {data.lastPickupTime ? `, ${formatTime(data.lastPickupTime)}` : ''}
                            </Typography>
                        </>
                    )}
                </Grid>
                <Grid item sx={{ textAlign: 'center' }}>
                    {(typeof data.drivingDistance === 'number' || data.drivingDistance) && (
                        <Chip sx={{ m: 1 }} icon={<LocationOnIcon />} label={formatMeters(data.drivingDistance)} />
                    )}
                </Grid>
                <Grid item>
                    {(data.firstDeliveryDate && !data.lastDeliveryDate) && (
                        <Typography variant={small ? 'h6' : 'h5'}>
                            {formatDate(data.firstDeliveryDate)}
                            {data.firstDeliveryTime ? `, ${formatTime(data.firstDeliveryTime)}` : ''}
                        </Typography>
                    )}
                    {(!data.firstDeliveryDate && data.lastDeliveryDate) && (
                        <Typography variant={small ? 'h6' : 'h5'}>
                            {formatDate(data.lastDeliveryDate)}
                            {data.lastDeliveryTime ? `, ${formatTime(data.lastDeliveryTime)}` : ''}
                        </Typography>
                    )}
                    {(data.firstDeliveryDate && data.lastDeliveryDate) && (
                        <>
                            <Typography variant="body2">
                                Från&nbsp;
                            </Typography>
                            <Typography variant={small ? 'h6' : 'h5'}>
                                {formatDate(data.firstDeliveryDate)}
                                {data.firstDeliveryTime ? `, ${formatTime(data.firstDeliveryTime)}` : ''}
                            </Typography>
                            <Typography variant="body2">
                                Till&nbsp;
                            </Typography>
                            <Typography variant={small ? 'h6' : 'h5'}>
                                {formatDate(data.lastDeliveryDate)}
                                {data.lastDeliveryTime ? `, ${formatTime(data.lastDeliveryTime)}` : ''}
                            </Typography>
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default DriveLine;
