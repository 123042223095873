import React from 'react';
import {
    Box,
    CircularProgress,
    IconButton,
    InputAdornment,
    OutlinedInput
} from '@mui/material';
import { Send } from '@mui/icons-material';

import toastMessage from '../../utils/toast';
import OfferChatMessage from './OfferChatMessage';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
    fetchOfferMessagesById,
    selectOfferMessagesById
} from '../../redux/actions/offers';
import { createOfferMessage } from '../../api/offers';
import { InfoText } from '../CommonComponents';

type PropsType = {
    recipient?: string | null,
    id?: number | null,
    canWrite?: boolean,
    maxHeight?: string
}

const FETCH_INTERVAL = 60 * 1000; // 60 seconds

export default function OfferChat({
    recipient = 'Motpart',
    id,
    canWrite,
    maxHeight
}: PropsType) {
    const dispatch = useDispatch();
    const messages = useSelector(selectOfferMessagesById(id)) || [];

    const [isSending, setIsSending] = React.useState(false);

    const newMessageRef = React.useRef<HTMLInputElement>();
    const messageContainerRef = React.useRef<HTMLDivElement>();

    React.useEffect(() => {
        if (id) {
            dispatch(fetchOfferMessagesById(id));

            const intervalId = window.setInterval(() => {
                dispatch(fetchOfferMessagesById(id));
            }, FETCH_INTERVAL);

            return () => window.clearInterval(intervalId); // Runs before every effect-run, and on unmount
        }

        return () => { };
    }, [dispatch, id]);

    React.useEffect(() => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    }, [messages?.length]);

    const sendMessage = React.useCallback(async () => {
        if (id && newMessageRef.current?.value) {
            try {
                setIsSending(true);
                await createOfferMessage(id, newMessageRef.current?.value);
                dispatch(fetchOfferMessagesById(id));
                setIsSending(false);
                newMessageRef.current.value = '';
                newMessageRef.current?.focus();
            } catch (e) {
                setIsSending(false);
                toastMessage(e, 'Kunde inte skicka meddelande, försök igen');
            }
        }
    }, [dispatch, id]);

    const onKeyDown = React.useCallback((e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    }, [sendMessage]);

    return (
        <>
            <Box
                ref={messageContainerRef}
                sx={{ maxHeight, overflowY: 'auto', marginBottom: 1 }}
            >
                {!messages || messages.length < 1 ? (
                    <InfoText>
                        Ni har inga meddelanden
                    </InfoText>
                ) : messages.map((message) => (
                    <OfferChatMessage
                        key={message.id}
                        isCurrentTenant={message.isCurrentTenant}
                        userName={message.createdByName}
                        createdAt={message.createdAt}
                        message={message.message}
                    />
                ))}
            </Box>

            {canWrite ? (
                <OutlinedInput
                    id="new-message"
                    inputRef={newMessageRef}
                    disabled={isSending}
                    fullWidth
                    multiline
                    color="info"
                    placeholder={`Skriv något till ${recipient}`}
                    endAdornment={(
                        <InputAdornment position="end">
                            {isSending ? <CircularProgress size={30} color="info" /> : (
                                <IconButton
                                    aria-label="Skicka"
                                    color="info"
                                    onClick={sendMessage}
                                    edge="end"
                                >
                                    <Send />
                                </IconButton>
                            )}
                        </InputAdornment>
                    )}
                    onKeyDown={onKeyDown}
                />
            ) : (
                <InfoText showBorder>
                    Chatten är inte längre aktiv, så ni kan inte skriva några nya meddelanden.
                </InfoText>
            )}
        </>
    );
}
