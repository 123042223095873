import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    fetchAllBookmarkedJobs,
    selectFetchingAllBookmarkedJobs,
    selectAllBookmarkedJobs
} from '../../../redux/actions/jobs';
import { JobForMarketListType } from '../../../types';
import { Table } from '../../CommonComponents';
import { FetchPropsType } from '../../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../../CommonComponents/Fetch/FetchRedux';
import { getFormattedOrderExpiryDate, getOrderDate } from '../../../utils/order';
import { formatNumber } from '../../../utils/parsing';

const columns = [{
    name: 'Transportdatum',
    key: 'orderDate',
    getValue: getOrderDate
}, {
    name: 'Upphämtningsort',
    key: 'pickupCity'
}, {
    name: 'Leveransort',
    key: 'deliveryCity'
}, {
    name: 'Körsträcka',
    key: 'km',
    type: 'number' as 'number',
    getValue: (r: { drivingDistance: number }) => formatNumber(Math.round(r.drivingDistance / 1000), 'km')
}, {
    name: 'Totalvikt',
    key: 'grossWeight',
    type: 'kg' as 'kg'
}, {
    name: 'Uppdragsgivare',
    key: 'tenantName'
}, {
    name: 'Anmäl senast',
    key: 'customOfferExpiryDate',
    getValue: (o: any) => (o.type === 'LL' ? getFormattedOrderExpiryDate(o) : '')
}];

function BookmarkedJobs(props: FetchPropsType<JobForMarketListType[]>) {
    const { entity, isFetching } = props;
    const navigate = useNavigate();

    const onRowClick = React.useCallback(({ type, id }: { id: number | string, type: string }) => (
        navigate(!type || type === 'LL' ? `/jobs/${id}` : `/jobs/${type}/${id}`)
    ), [navigate]);

    return (
        <Table
            name="bookmarkedJobs"
            columns={columns}
            data={entity}
            onRowClick={onRowClick}
            emptyText={isFetching ? 'Hämtar bokmärkta uppdrag...' : 'Ni har inga bokmärkta uppdrag.'}
        />
    );
}

export default function BookmarkedJobsFetched() {
    return (
        <FetchRedux<JobForMarketListType[]>
            fetchEntity={fetchAllBookmarkedJobs()}
            selectEntity={selectAllBookmarkedJobs()}
            selectIsFetching={selectFetchingAllBookmarkedJobs()}
            Component={BookmarkedJobs}
            name="bokmärkta uppdragen"
        />
    );
}
