import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Table } from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';

import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchAllWatchlists, selectAllWatchlists } from '../../redux/actions/watchlists';
import { fetchAllOpenJobs, fetchAllOpenCapacities } from '../../redux/actions/market';
import { WatchlistForListType } from '../../types';

import { formatWatchlist } from './formatWatchlist';

const columns = [{
    name: 'Namn',
    key: 'name',
}, {
    name: 'Filter',
    key: 'customFilter',
    getValue: formatWatchlist
}, {
    name: 'Direktnotiser',
    key: 'instantNotifications',
    type: 'bool' as 'bool',
}, {
    name: 'Dagliga notiser',
    key: 'dailyNotifications',
    type: 'bool' as 'bool',
}, {
    name: 'Senast publicerat',
    key: 'lastPublishedDate',
    type: 'datetime' as 'datetime',
}, {
    name: 'Publicerat',
    key: 'publishedCount',
    type: 'number' as 'number'
}];

export default function Watchlists() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const watchlists = useSelector(selectAllWatchlists());

    React.useEffect(() => {
        dispatch(fetchAllWatchlists());
        dispatch(fetchAllOpenJobs(true)); // Only fetch if not recently fetched
        dispatch(fetchAllOpenCapacities(true)); // Only fetch if not recently fetched
    }, [dispatch]);

    const onRowClick = React.useCallback((watchlist: WatchlistForListType) => {
        navigate(`/watchlists/${watchlist.id}`);
    }, [navigate]);

    return (
        <Container>
            <Header title="Bevakningar" addUrl="/watchlists/create" />

            <Paper padding={0}>
                <Table
                    name="watchlists"
                    columns={columns}
                    data={watchlists}
                    onRowClick={onRowClick}
                    emptyText="Du har inga bevakningar."
                />
            </Paper>
        </Container>
    );
}
