import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
    Check as CheckIcon,
    Close as CloseIcon,
    Delete as DeleteIcon
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';

type PropsType = {
    children?: React.ReactNode;
    open: boolean;
    title: string;
    body?: string;
    okLabel?: string;
    cancelLabel?: string;
    deleteLabel?: string;
    handleOk?: () => any;
    handleClose: (a: any) => any;
    handleDelete?: () => any;
    okDisabled?: boolean;
    deleteDisabled?: boolean;
    isSubmitting?: boolean;
    fullWidth?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    CustomTitleContent?: JSX.Element;
    CustomButton?: JSX.Element;
    closeOnBackdropClick?: boolean;
    closeOnEscapeKeyDown?: boolean;
};

export default function Popup({
    open,
    title,
    body = '',
    okLabel = 'Ok',
    cancelLabel = 'Avbryt',
    deleteLabel = 'Ta bort',
    handleOk,
    handleClose,
    handleDelete,
    okDisabled = false,
    deleteDisabled = false,
    isSubmitting = false,
    children,
    fullWidth,
    maxWidth,
    CustomTitleContent,
    CustomButton,
    closeOnBackdropClick,
    closeOnEscapeKeyDown
}: PropsType) {
    const handleSpecificClose = useCallback((event, reason) => {
        if (typeof handleClose !== 'function') {
            return;
        }
        if (reason === 'backdropClick' && !closeOnBackdropClick) {
            return;
        }
        if (reason === 'escapeKeyDown' && !closeOnEscapeKeyDown) {
            return;
        }

        handleClose(event);
    }, [closeOnBackdropClick, closeOnEscapeKeyDown, handleClose]);

    return (
        <Dialog
            open={open}
            onClose={handleSpecificClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={fullWidth}
            maxWidth={maxWidth}
        >
            <DialogTitle id="alert-dialog-title">
                <Grid container>
                    <Grid item xs>
                        {title}
                    </Grid>
                    <Grid item>
                        {CustomTitleContent}
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {children || (
                    <DialogContentText id="alert-dialog-description">
                        {body?.trim().split('\n')?.map((part, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <span key={i}>
                                {part.trim()}
                                <br />
                            </span>
                        ))}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="text"
                    startIcon={<CloseIcon />}
                    onClick={handleClose}
                    disabled={isSubmitting}
                >
                    {cancelLabel}
                </Button>

                {CustomButton}

                {handleDelete && (
                    <LoadingButton
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                        loading={isSubmitting}
                        loadingPosition="start"
                        startIcon={<DeleteIcon />}
                        disabled={deleteDisabled}
                    >
                        {deleteLabel}
                    </LoadingButton>
                )}

                {handleOk && (
                    <LoadingButton
                        variant="contained"
                        onClick={handleOk}
                        loading={isSubmitting}
                        loadingPosition="start"
                        startIcon={<CheckIcon />}
                        disabled={okDisabled}
                    >
                        {okLabel}
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
}
