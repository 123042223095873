import React from 'react';
import { Grid } from '@mui/material';
import { searchCompanies } from '../../api-public/company';
import { createGroupInvitation, deleteGroupInvitation, updateGroupInvitation } from '../../api/groups';
import { GroupInvitationType } from '../../types';
import toast from '../../utils/toast';
import { InfoText } from '../CommonComponents';
import {
    AutocompleteField,
    BoolField,
    EmailField,
    PhoneField,
    PopupForm,
    useChangeable
} from '../CommonComponents/Form';

type PropsType = {
    groupId: number,
    isOwner: boolean,
    tenants: {
        tenantId: number,
        tenantName: string,
        isOwner: boolean,
        isInvited: boolean,
        isAccepted: boolean,
        canInvite: boolean,
        canPublish: boolean
    }[],
    invitation: GroupInvitationType | null,
    setInvitation: (gi: GroupInvitationType | null) => void
    refetch: () => void
};

// type InvitationFormEntity = {
//     tenantId: number | null,
//     tenantName: string | null,
//     tenantOrgNumber: string | null,
//     tenantIsRegistered: boolean | null,
//     tenantIsMember: boolean | null,
//     isOwner?: boolean,
//     isInvited?: boolean,
//     isAccepted?: boolean,
//     canInvite: boolean,
//     canPublish: boolean,
//     invitedEmail: string | null,
//     invitedPhone: string | null
// };

const defaultEntity = {
    tenantId: null,
    tenantName: null,
    tenantOrgNumber: null,
    tenantIsRegistered: null,
    tenantIsMember: null,
    canInvite: false,
    canPublish: false,
    invitedEmail: null,
    invitedPhone: null
};

const GroupInvitationForm: React.FC<PropsType> = (props) => {
    const {
        groupId,
        isOwner,
        tenants,
        invitation,
        setInvitation,
        refetch
    } = props;

    const [entity, setEntity] = useChangeable(defaultEntity);

    const onChange = React.useCallback((partialInvitation: { [key: string]: any }) => {
        setEntity({ ...entity, ...partialInvitation });
    }, [entity, setEntity]);

    React.useEffect(() => {
        if (invitation === null) {
            setEntity(null);
        } else {
            setEntity({ ...defaultEntity, ...invitation });
        }
    }, [invitation, setEntity]);

    const submit = React.useCallback(async () => {
        if (invitation) {
            try {
                if (invitation.tenantId) {
                    await updateGroupInvitation(groupId, entity);
                    toast('Gruppmedlem uppdaterad');
                } else {
                    await createGroupInvitation(groupId, entity);
                    toast('Gruppinbjudan skickad');
                }
                refetch();
                setInvitation(null);
            } catch (e) {
                toast(e, `Kunde inte ${
                    invitation.tenantId ? 'redigera gruppmedlemmen' : 'skicka inbjudan'
                }, försök igen`);
            }
        }
    }, [groupId, invitation, setInvitation, entity, refetch]);

    const remove = React.useCallback(async () => {
        if (invitation?.tenantId) {
            try {
                await deleteGroupInvitation(groupId, invitation.tenantId);
                toast('Gruppmedlemmen borttagen');
                refetch();
                setInvitation(null);
            } catch (e) {
                toast(e, 'Kunde inte ta bort gruppmedlemmen, försök igen');
            }
        }
    }, [groupId, invitation?.tenantId, refetch, setInvitation]);

    const cancel = React.useCallback(() => {
        setInvitation(null);
    }, [setInvitation]);

    return !invitation ? null : (
        <PopupForm
            open={!!invitation}
            title={invitation.tenantId ? 'Redigera gruppmedlem' : 'Bjud in företag till gruppen'}
            okLabel={invitation.tenantId ? 'Spara' : 'Bjud in'}
            handleOk={submit}
            handleDelete={invitation.tenantId ? remove : undefined}
            handleClose={cancel}
            okDisabled={
                !invitation.isInvited && ( // invitation.isInvited = Editing exsisting member
                    entity.tenantIsMember // Dont register member twice
                    || (!entity.tenantIsRegistered && !entity.invitedEmail && !entity.invitedPhone)
                )
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AutocompleteField<{
                        id: number | string,
                        name: string,
                        orgNumber?: string,
                        isRegistered?: boolean
                    }>
                        label="Företag"
                        optionIdKey="id"
                        defaultOption={!invitation.tenantId ? null : {
                            id: invitation.tenantId,
                            name: invitation.tenantName || ''
                        }}
                        fetchOptions={searchCompanies}
                        minQueryLength={3}
                        getOptionName={(o) => o.name}
                        getOptionDescription={(o) => o.orgNumber || ''}
                        onChange={(company) => onChange({
                            tenantId: company === null ? null : company.id,
                            tenantName: company === null ? null : company.name,
                            tenantOrgNumber: company === null ? null : company.orgNumber,
                            tenantIsRegistered: company === null ? null : company.isRegistered,
                            tenantIsMember: company === null ? null : tenants.some((t) => t.tenantId === company.id)
                        })}
                        disabled={!!invitation.tenantId}
                    />
                </Grid>

                {entity.tenantIsMember && (
                    <Grid item xs={12}>
                        <InfoText>
                            Företaget är redan inbjudet till gruppen.
                        </InfoText>
                    </Grid>
                )}

                {!entity.tenantIsMember && entity.tenantIsRegistered && (
                    <Grid item xs={12}>
                        <InfoText>
                            Företaget kommer att bjudas in till gruppen.
                        </InfoText>
                    </Grid>
                )}

                {!invitation.tenantId && !entity.tenantIsMember && !entity.tenantIsRegistered && (
                    <>
                        <Grid item xs={12}>
                            {
                                entity.tenantId
                                    ? (
                                        <InfoText>
                                            Företaget har ännu inte registrerat sig.
                                            Komplettera med e-postadress eller mobilnummer för att skicka inbjudan.
                                        </InfoText>
                                    )
                                    : (
                                        <InfoText>
                                            Sök efter företaget du vill bjuda in till gruppen.
                                            Får du ingen träff så kan du fylla i e-postadress eller mobilnummer
                                            för att skicka en inbjudan.
                                        </InfoText>
                                    )
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <EmailField
                                name="invitedEmail"
                                label="E-postadress"
                                value={entity.invitedEmail}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneField
                                name="invitedPhone"
                                label="Mobilnummer"
                                value={entity.invitedPhone}
                                onChange={onChange}
                            />
                        </Grid>
                    </>
                )}
                {isOwner && entity.tenantId && !entity.tenantIsMember && (
                    <>
                        <Grid item xs={12}>
                            <BoolField
                                name="canPublish"
                                value={entity.canPublish}
                                label="Kan publicera"
                                onChange={onChange}
                            />
                        </Grid>
                        {/* <Grid item xs={6}>
                            <BoolField
                                name="canInvite"
                                value={entity.canInvite}
                                label="Kan se/bjuda in medlemmar"
                                onChange={onChange}
                            />
                        </Grid> */}
                    </>
                )}
            </Grid>
        </PopupForm>
    );
};

export default GroupInvitationForm;
