import React from 'react';

import { Header, Tabs } from '../CommonComponents';
import { Container } from '../StyledComponents';

import Capacities from './JobTabs/Capacities';
import BookmarkedJobs from './JobTabs/BookmarkedJobs';
import OfferedJobs from './JobTabs/OfferedJobs';
import AcceptedJobs from './JobTabs/AcceptedJobs';
import StartedJobs from './JobTabs/StartedJobs';
import CompletedJobs from './JobTabs/CompletedJobs';

import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import { fetchJobStatusCount, selectFetchingJobStatusCount, selectJobStatusCount } from '../../redux/actions/jobs';

const tabs = [{
    key: 'capacities',
    label: 'Min lediga kapacitet',
    Component: Capacities
}, {
    key: 'bookmarked',
    label: 'Bokmärkta',
    Component: BookmarkedJobs
}, {
    key: 'offered',
    label: 'Intresseanmälda',
    Component: OfferedJobs
}, {
    key: 'accepted',
    label: 'Tilldelade',
    Component: AcceptedJobs
}, {
    key: 'started',
    label: 'Bekräftade',
    Component: StartedJobs
}, {
    key: 'completed',
    label: 'Utförda',
    Component: CompletedJobs
}];

type JobMetaType = {
    [key: string]: number | null
};

function JobTabs(props: FetchPropsType<any>) {
    const { entity } = props;

    const getTitle = React.useCallback((tab) => {
        if (!entity) {
            return tab.label;
        }
        const count = entity[tab.key.toUpperCase() as string];
        return typeof count === 'number'
            ? `${tab.label} (${count})`
            : tab.label;
    }, [entity]);

    return (
        <Container>
            <Header
                title="Kör uppdrag"
                subtitle={entity.ownerTenantName}
                addUrl="/capacities/create"
                addUrlTitle="Ny ledig kapacitet"
            />
            <Tabs
                tabsKey="jobs"
                tabs={tabs}
                getTitle={getTitle}
            />
        </Container>
    );
}

export default function JobTabsFetched() {
    return (
        <FetchRedux<JobMetaType>
            fetchEntity={fetchJobStatusCount()}
            selectEntity={selectJobStatusCount()}
            selectIsFetching={selectFetchingJobStatusCount()}
            Component={JobTabs}
            name="uppdragen"
        />
    );
}
