import apiRequest from './api-request';
import { TenantProfileSettablesType } from '../types';

export function getTenantProfile() {
    return apiRequest({
        method: 'GET',
        resource: 'tenant-profile'
    });
}

export function updateTenantProfile(body: TenantProfileSettablesType) {
    return apiRequest({
        method: 'PUT',
        resource: 'tenant-profile',
        body
    });
}

export function searchTenants(body: string[]) {
    return apiRequest({
        method: 'POST',
        resource: 'tenant-search',
        body
    });
}
