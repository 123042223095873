import React from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { Phone as PhoneIcon } from '@mui/icons-material';

type PropsType = {
    job: { consignorOffice?: string },
    elevation?: number
};

const DHLContactBox: React.FC<PropsType> = (props) => {
    const { job, elevation = 0 } = props;

    return (
        <Paper sx={{ p: 3, backgroundColor: 'secondary.main' }} elevation={elevation}>
            <Typography variant="subtitle2" align="center" gutterBottom>
                Detta är ett uppdrag från DHL Overflow
            </Typography>
            <Typography variant="body2" align="center" gutterBottom>
                Ring DHL Trafikcenter för mer info.
            </Typography>
            <div style={{ textAlign: 'center', marginBottom: 5 }}>
                <Button startIcon={<PhoneIcon />} onClick={() => window.open('tel:0771-183600')}>
                    0771 - 183600
                </Button>
            </div>
            <Typography variant="body2" align="center">
                {`Välj "avgående område", kontor ${job.consignorOffice}.`}
            </Typography>
        </Paper>
    );
};

export default DHLContactBox;
