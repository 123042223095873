import React from 'react';
import {
    Circle,
    GoogleMap,
    Marker,
    // MarkerClusterer
} from '@react-google-maps/api';

import OrderArrow from '../CommonComponents/GoogleMap/OrderArrow';
import OrderMarker from '../CommonComponents/GoogleMap/OrderMarker';
import ApproximationCircle from '../CommonComponents/GoogleMap/ApproximationCircle';

import getMarkerIcon from '../../utils/getMarkerIcon';

import { useDispatch, useSelector } from '../../redux/hooks';
import {
    selectFilteredMarketEntities,
    selectMarketFilters,
    setMarketFilters,
    selectSelectedMarketEntity,
    setSelectedMarketEntity,
    selectMarketSettings
} from '../../redux/actions/market';
import { COLOR_RED, COLOR_SKIN } from '../../theme';

const defaultCenter = { lat: 63, lng: 16 };
const defaultZoomLevel = 5;
const options = {
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    clickableIcons: false,
    minZoom: 4,
    maxZoom: 20
};

const circleOptions = {
    strokeColor: COLOR_RED,
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: COLOR_RED,
    fillOpacity: 0.2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    // radius: 30000,
    zIndex: 1
};

// type SortOrderType = { DHL: number, CAP: number, LL: number };
// type SortObjectType = { type: 'DHL' | 'CAP' | 'LL' };
// function sortJobs(a: SortObjectType, b: SortObjectType) {
//     const order: SortOrderType = { DHL: 1, CAP: 2, LL: 3 };

//     if (order[a.type] < order[b.type]) {
//         return -1;
//     }
//     if (order[a.type] > order[b.type]) {
//         return 1;
//     }
//     return 0;
// }

function getBoundsZoomLevel(bounds: any, mapDim: any) {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 12;

    function latRad(lat: number) {
        const sin = Math.sin((lat * Math.PI) / 180);
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx: number, worldPx: number, fraction: number) {
        return Math.floor(Math.log((mapPx / worldPx / fraction) / Math.LN2));
    }

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    const lngDiff = ne.lng() - sw.lng();
    const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    const customAdjustment = 1;
    return Math.min(latZoom, lngZoom, ZOOM_MAX) + customAdjustment;
}

const filterMarkerIcon = getMarkerIcon({ strokeColor: '#555', fillColor: COLOR_SKIN, filled: true });

let mapClickTimeout: number | undefined;

function getZIndexFromEntityType({ type }: { type: 'LL' | 'DHL' | 'CAP' }) {
    if (type === 'CAP') {
        return 600;
    }
    if (type === 'LL') {
        return 500;
    }
    return undefined;
}

export default function MarketMap() {
    const dispatch = useDispatch();
    const entities = useSelector(selectFilteredMarketEntities());
    const selectedEntity = useSelector(selectSelectedMarketEntity());
    const jobFilters = useSelector(selectMarketFilters());
    const {
        autoFocus,
        showPickupPin,
        showDeliveryPin,
        showDistanceLine
    } = useSelector(selectMarketSettings());

    const [map, setMap] = React.useState<any>(null);

    // OLD:
    // React.useEffect(() => {
    //     if (map) {
    //         const bounds = new window.google.maps.LatLngBounds();
    //         if (selectedEntity) {
    //             bounds.extend({ lat: selectedEntity.pickupLat, lng: selectedEntity.pickupLng });
    //             bounds.extend({ lat: selectedEntity.deliveryLat, lng: selectedEntity.deliveryLng });
    //         } else {
    //             entities.forEach((entity) => bounds.extend({ lat: entity.pickupLat, lng: entity.pickupLng }));
    //         }
    //         if (entities.length) {
    //             // Can adjust for offset to driveline here as well
    //             const { offsetHeight, offsetWidth } = map.getDiv();
    //             map.setZoom(getBoundsZoomLevel(bounds, { height: offsetHeight, width: offsetWidth }));
    //             map.panTo(bounds.getCenter());
    //             // map.fitBounds(bounds); // Does not pan (just jumps) if same zoom-level
    //         }
    //     }
    // }, [map, entities, selectedEntity]);

    React.useEffect(() => {
        // Only pan to pickup (do not zoom) if there is a selected entity
        if (autoFocus && selectedEntity && map) {
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend({ lat: selectedEntity.pickupLat, lng: selectedEntity.pickupLng });
            bounds.extend({ lat: selectedEntity.deliveryLat, lng: selectedEntity.deliveryLng });
            // Can adjust for offset to driveline here as well
            const { offsetHeight, offsetWidth } = map.getDiv();
            map.setZoom(getBoundsZoomLevel(bounds, { height: offsetHeight, width: offsetWidth }));
            map.panTo(bounds.getCenter());
            // map.fitBounds(bounds); // Does not pan (just jumps) if same zoom-level
            map.panBy(0, 100); // Offsetting due to the JobDescription at bottom
        }
    }, [autoFocus, entities, map, selectedEntity]);

    const onMapClick = React.useCallback(() => {
        if (selectedEntity) {
            mapClickTimeout = window.setTimeout(() => dispatch(setSelectedMarketEntity(null)), 200);
        }
    }, [dispatch, selectedEntity]);

    const onLoad = React.useCallback((newMap) => {
        // newMap.addListener('click', () => {
        //     // This should only run if entity is selected
        //     dispatch(setSelectedMarketEntity(null));
        // });
        // Allow zooming in by dblclick without deselecting selected marker
        newMap.addListener('dblclick', () => {
            window.clearTimeout(mapClickTimeout);
        });
        setMap(newMap);
    }, []);

    const onMarkerClick = React.useCallback((entity: { key: string }) => {
        dispatch(setSelectedMarketEntity(entity.key));
    }, [dispatch]);

    const setPickupLocationFilter = React.useCallback((d: any) => {
        dispatch(setMarketFilters({
            pickupLocationLat: d.latLng.lat(),
            pickupLocationLng: d.latLng.lng(),
            pickupLocationQuery: '',
            pickupLocationName: 'Egen plats'
        }));
    }, [dispatch]);

    const setDeliveryLocationFilter = React.useCallback((d: any) => {
        dispatch(setMarketFilters({
            deliveryLocationLat: d.latLng.lat(),
            deliveryLocationLng: d.latLng.lng(),
            deliveryLocationQuery: '',
            deliveryLocationName: 'Egen plats'
        }));
    }, [dispatch]);

    return (
        <GoogleMap
            mapContainerStyle={{ height: '100%', overflow: 'visible' }}
            center={defaultCenter}
            zoom={defaultZoomLevel}
            onLoad={onLoad}
            options={options}
            onClick={onMapClick}
        >
            {entities
                .filter((entity) => selectedEntity?.key !== entity.key)
                // .slice().sort(sortJobs)
                .map((entity) => (
                    <React.Fragment key={entity.key}>
                        {showPickupPin && (
                            <OrderMarker
                                entityType={entity.type}
                                markerType="plain"
                                lat={entity.pickupLat}
                                lng={entity.pickupLng}
                                onClick={() => onMarkerClick(entity)}
                                zIndex={getZIndexFromEntityType(entity)}
                            />
                        )}
                        {showDeliveryPin && (
                            <OrderMarker
                                entityType={entity.type}
                                markerType="plain"
                                lat={entity.deliveryLat}
                                lng={entity.deliveryLng}
                                onClick={() => onMarkerClick(entity)}
                                zIndex={getZIndexFromEntityType(entity)}
                            />
                        )}
                        {showDistanceLine && (
                            <OrderArrow
                                entityType={entity.type}
                                pickupLat={entity.pickupLat}
                                pickupLng={entity.pickupLng}
                                deliveryLat={entity.deliveryLat}
                                deliveryLng={entity.deliveryLng}
                                onClick={() => onMarkerClick(entity)}
                            />
                        )}
                    </React.Fragment>
                ))}

            {selectedEntity && (
                <>
                    <OrderMarker
                        entityType={selectedEntity.type}
                        markerType="activePickup"
                        lat={selectedEntity.pickupLat}
                        lng={selectedEntity.pickupLng}
                        zIndex={700}
                    />
                    <OrderMarker
                        entityType={selectedEntity.type}
                        markerType="activeDelivery"
                        lat={selectedEntity.deliveryLat}
                        lng={selectedEntity.deliveryLng}
                        zIndex={700}
                    />
                    <ApproximationCircle
                        lat={selectedEntity.pickupLat}
                        lng={selectedEntity.pickupLng}
                    />
                    <ApproximationCircle
                        lat={selectedEntity.deliveryLat}
                        lng={selectedEntity.deliveryLng}
                    />
                    <OrderArrow
                        entityType="ACTIVE"
                        isActive
                        pickupLat={selectedEntity.pickupLat}
                        pickupLng={selectedEntity.pickupLng}
                        deliveryLat={selectedEntity.deliveryLat}
                        deliveryLng={selectedEntity.deliveryLng}
                    />
                </>
            )}

            {jobFilters.pickupLocationLat && jobFilters.pickupLocationLng && jobFilters.pickupLocationRadius && (
                <>
                    <Marker
                        key={Date()}
                        position={{ lat: jobFilters.pickupLocationLat, lng: jobFilters.pickupLocationLng }}
                        label={{ text: 'U', color: '#555', fontWeight: '600' }}
                        icon={filterMarkerIcon}
                        zIndex={9999}
                        draggable
                        onDragEnd={setPickupLocationFilter}
                    />
                    <Circle
                        center={{ lat: jobFilters.pickupLocationLat, lng: jobFilters.pickupLocationLng }}
                        radius={jobFilters.pickupLocationRadius}
                        options={{
                            ...circleOptions,
                            strokeColor: '#CCAAA0',
                            fillColor: COLOR_SKIN,
                            fillOpacity: 0.4
                        }}
                    />
                </>
            )}
            {jobFilters.deliveryLocationLat && jobFilters.deliveryLocationLng && jobFilters.deliveryLocationRadius && (
                <>
                    <Marker
                        key={Date()}
                        position={{ lat: jobFilters.deliveryLocationLat, lng: jobFilters.deliveryLocationLng }}
                        icon={filterMarkerIcon}
                        label={{ text: 'L', color: '#555', fontWeight: '600' }}
                        zIndex={9999}
                        draggable
                        onDragEnd={setDeliveryLocationFilter}
                    />
                    <Circle
                        center={{ lat: jobFilters.deliveryLocationLat, lng: jobFilters.deliveryLocationLng }}
                        radius={jobFilters.deliveryLocationRadius}
                        options={{
                            ...circleOptions,
                            strokeColor: '#CCAAA0',
                            fillColor: COLOR_SKIN,
                            fillOpacity: 0.4
                        }}
                    />
                </>
            )}
        </GoogleMap>
    );
}
