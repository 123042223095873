import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import {
    Edit as EditIcon,
    Verified as VerifiedIcon
} from '@mui/icons-material';

import {
    Block,
    Fetch,
    Header,
    List,
} from '../CommonComponents';
import { Container } from '../StyledComponents';

import { updateUserProfile } from '../../api/users';

import type { FetchComponentPropsType } from '../CommonComponents/Fetch/Fetch';
import { UserProfileType } from '../../types';
import {
    BoolField,
    PasswordField,
    PopupForm,
    TextField
} from '../CommonComponents/Form';
import toast from '../../utils/toast';
import {
    refreshSession,
    resetPassword,
    sendResetPasswordCode,
    sendVerifyEmailCode,
    sendVerifyPhoneCode,
    verifyEmail,
    verifyPhone
} from '../../utils/auth';
import { fetchUserProfile, selectUserProfile } from '../../redux/actions/users';
import { useDispatch, useSelector } from '../../redux/hooks';

const UserProfileDetailsView: React.FC<FetchComponentPropsType<UserProfileType>> = (props) => {
    const { entity, refetch } = props;
    const navigate = useNavigate();

    const [showPasswordResetPopup, setShowPasswordResetPopup] = React.useState(false);
    const [showEmailVerificationPopup, setShowEmailVerificationPopup] = React.useState(false);
    const [showPhoneVerificationPopup, setShowPhoneVerificationPopup] = React.useState(false);

    const [newPassword, setNewPassword] = React.useState('');
    const [passwordResetCode, setPasswordResetCode] = React.useState('');
    const [emailVerificationCode, setEmailVerificationCode] = React.useState('');
    const [phoneVerificationCode, setPhoneVerificationCode] = React.useState('');

    const closePasswordResetPopup = React.useCallback(() => {
        setShowPasswordResetPopup(false);
        setPasswordResetCode('');
        setNewPassword('');
    }, []);

    const closeEmailVerificationPopup = React.useCallback(() => {
        setShowEmailVerificationPopup(false);
        setEmailVerificationCode('');
    }, []);

    const closePhoneVerificationPopup = React.useCallback(() => {
        setShowPhoneVerificationPopup(false);
        setPhoneVerificationCode('');
    }, []);

    const initiatePasswordReset = React.useCallback(async () => {
        if (!entity.emailVerified) {
            toast('Du måste verifiera din e-postadress innan du kan byta lösenord.');
            return;
        }
        try {
            setShowPasswordResetPopup(true);
            await sendResetPasswordCode({ email: entity.email });
        } catch (e: any) {
            setShowPasswordResetPopup(false);
            if (e.code === 'LimitExceededException') {
                toast('För många verifieringskoder har skickat, försök igen senare.');
            } else {
                toast('Kunde inte skicka verifieringskoden, försök igen.');
            }
        }
    }, [entity.email, entity.emailVerified]);

    const commitPasswordReset = React.useCallback(async () => {
        try {
            await resetPassword({
                email: entity.email,
                resetCode: passwordResetCode,
                newPassword
            });
            closePasswordResetPopup();
            toast('Din lösenord är nu bytt.');
        } catch (e) {
            toast('Fel verifieringskod, försök igen.');
        }
    }, [entity.email, passwordResetCode, newPassword]);

    const initiateEmailVerification = React.useCallback(async () => {
        try {
            setShowEmailVerificationPopup(true);
            await sendVerifyEmailCode();
        } catch (e: any) {
            setShowEmailVerificationPopup(false);
            if (e.code === 'LimitExceededException') {
                toast('För många verifieringskoder har skickat, försök igen senare.');
            } else {
                toast('Kunde inte skicka verifieringskoden, försök igen.');
            }
        }
    }, []);

    const commitEmailVerification = React.useCallback(async () => {
        try {
            await verifyEmail(emailVerificationCode);
            const cognitoUser = await refreshSession();
            await updateUserProfile({ emailVerified: cognitoUser?.attributes?.email_verified });
            refetch();
            closeEmailVerificationPopup();
            toast('Din e-postadress är nu verifierad.');
        } catch (e) {
            toast('Fel verifieringskod, försök igen.');
        }
    }, [emailVerificationCode]);

    const initiatePhoneVerification = React.useCallback(async () => {
        try {
            setShowPhoneVerificationPopup(true);
            await sendVerifyPhoneCode();
        } catch (e: any) {
            setShowPhoneVerificationPopup(false);
            if (e.code === 'LimitExceededException') {
                toast('För många verifieringskoder har skickat, försök igen senare.');
            } else {
                toast('Kunde inte skicka verifieringskoden, försök igen.');
            }
        }
    }, []);

    const commitPhoneVerification = React.useCallback(async () => {
        try {
            await verifyPhone(phoneVerificationCode);
            const cognitoUser = await refreshSession();
            await updateUserProfile({ phoneVerified: cognitoUser?.attributes?.phone_number_verified });
            refetch();
            closePhoneVerificationPopup();
            toast('Ditt mobilnummer är nu verifierat.');
        } catch (e) {
            toast('Fel verifieringskod, försök igen.');
        }
    }, [phoneVerificationCode]);

    const setNotifications = React.useCallback(async (partialProfile) => {
        let notificationText = '';
        let notificationValue = false;

        if (partialProfile.pushNotifications !== undefined) {
            notificationText = 'Pushnotiser';
            notificationValue = partialProfile.pushNotifications;
        } else if (partialProfile.emailNotifications !== undefined) {
            notificationText = 'E-postnotiser';
            notificationValue = partialProfile.emailNotifications;
        } else if (partialProfile.smsNotifications !== undefined) {
            notificationText = 'SMS-notiser';
            notificationValue = partialProfile.smsNotifications;
        }
        try {
            await updateUserProfile(partialProfile);
            refetch();
            toast(`${notificationText} ${notificationValue ? 'aktiverade' : 'avstängda'}`);
        } catch (e) {
            toast(e, `Kunde inte ${notificationValue ? 'aktivera' : 'stänga av'} ${notificationText.toLowerCase()}, försök igen`);
        }
    }, [entity.id]);

    return (
        <Container>
            <Header
                title="Min profil"
                CustomComponent={(
                    <Button
                        variant="contained"
                        startIcon={<EditIcon />}
                        onClick={() => navigate('/user-profile/edit')}
                    >
                        Redigera
                    </Button>
                )}
            />
            <PopupForm
                open={showPasswordResetPopup}
                title="Byt lösenord"
                okLabel="Byt lösenord"
                cancelLabel="Avbryt"
                handleOk={commitPasswordReset}
                handleClose={closePasswordResetPopup}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            Fyll i verifieringskoden som skickats till dig och välj ett nytt lösenord.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="verificationCodeForPassword"
                            label="Verifieringskod"
                            value={passwordResetCode}
                            onChange={({ target: { value } }) => setPasswordResetCode(value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordField
                            name="newPassword"
                            label="Nytt lösenord"
                            value={newPassword}
                            onChange={({ target: { value } }) => setNewPassword(value)}
                            required
                        />
                    </Grid>
                </Grid>
            </PopupForm>

            <PopupForm
                open={showEmailVerificationPopup}
                title="Verifiera e-postadress"
                okLabel="Verifiera"
                cancelLabel="Avbryt"
                handleOk={commitEmailVerification}
                handleClose={closeEmailVerificationPopup}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            Fyll i verifieringskoden som skickats till din e-postadress (
                            {entity.email}
                            ).
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="verificationCodeForEmail"
                            label="Verifieringskod"
                            value={emailVerificationCode}
                            onChange={({ target: { value } }) => setEmailVerificationCode(value)}
                            required
                        />
                    </Grid>
                </Grid>
            </PopupForm>

            <PopupForm
                open={showPhoneVerificationPopup}
                title="Verifiera mobilnummer"
                okLabel="Verifiera"
                cancelLabel="Avbryt"
                handleOk={commitPhoneVerification}
                handleClose={closePhoneVerificationPopup}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            {/* För att få smsnotiser behöver du verifiera ditt mobilnummer. */}
                            Fyll i verifieringskoden som skickats till ditt mobilnummer (
                            {entity.phone}
                            ).
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="verificationCodeForPhone"
                            label="Verifieringskod"
                            value={phoneVerificationCode}
                            onChange={({ target: { value } }) => setPhoneVerificationCode(value)}
                            required
                        />
                    </Grid>
                </Grid>
            </PopupForm>

            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Block
                        title="Kontaktuppgifter"
                        ActionComponent={(
                            <Button variant="outlined" onClick={initiatePasswordReset}>
                                Byt lösenord
                            </Button>
                        )}
                    >
                        <List
                            data={[{
                                name: 'Namn',
                                value: `${entity.firstName} ${entity.lastName}`
                            }, {
                                name: 'Titel',
                                value: entity.title
                            }, {
                                name: 'E-post',
                                type: 'raw',
                                value: (entity.emailVerified
                                    ? (
                                        <Grid container alignItems="center">
                                            <Typography variant="body2" sx={{ pr: 1 }}>{entity.email}</Typography>
                                            <VerifiedIcon color="primary" />
                                        </Grid>
                                    ) : (
                                        <Grid container alignItems="center">
                                            <Typography variant="body2" sx={{ pr: 1 }}>{entity.email}</Typography>
                                            <VerifiedIcon color="disabled" />
                                            <Button
                                                variant="text"
                                                onClick={initiateEmailVerification}
                                            >
                                                Verifiera
                                            </Button>
                                        </Grid>
                                    ))
                            }, {
                                name: 'Mobil',
                                type: 'raw',
                                // eslint-disable-next-line no-nested-ternary
                                value: (entity.phone ? (entity.phoneVerified
                                    ? (
                                        <Grid container alignItems="center">
                                            <Typography variant="body2" sx={{ pr: 1 }}>
                                                {entity.phone}
                                            </Typography>
                                            <VerifiedIcon color="primary" />
                                        </Grid>
                                    ) : (
                                        <Grid container alignItems="center">
                                            <Typography variant="body2" sx={{ pr: 1 }}>
                                                {entity.phone}
                                            </Typography>
                                            <VerifiedIcon color="disabled" />
                                            <Button
                                                variant="text"
                                                onClick={initiatePhoneVerification}
                                            >
                                                Verifiera
                                            </Button>
                                        </Grid>
                                    )) : '')
                            }, {
                                name: 'Telefon',
                                value: entity.phoneAlt
                            }]}
                        />
                    </Block>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Block
                        title="Notiser"
                    >
                        <Grid container spacing={2} sx={{ p: 2 }}>
                            <Grid item xs={12}>
                                <BoolField
                                    name="pushNotifications"
                                    label="Pushnotiser"
                                    helperText="Få notiser från Logic Link direkt till dina mobila enheter."
                                    value={entity.pushNotifications}
                                    variant="switch"
                                    onChange={setNotifications}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BoolField
                                    name="emailNotifications"
                                    label="E-postnotiser"
                                    helperText={
                                        entity.email && entity.emailVerified
                                            ? 'Få notiser från Logic Link direkt till din e-postadress.'
                                            : 'För att få notiser via e-post måste du först verifiera din e-postadress.'
                                    }
                                    value={!!entity.email && entity.emailVerified && entity.emailNotifications}
                                    disabled={!entity.email || !entity.emailVerified}
                                    variant="switch"
                                    onChange={setNotifications}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BoolField
                                    name="smsNotifications"
                                    label="SMS-notiser"
                                    helperText={
                                        entity.phone && entity.phoneVerified
                                            ? 'Få notiser från Logic Link direkt via sms.'
                                            // eslint-disable-next-line max-len
                                            : 'För att få notiser via sms måste du först fylla i och verifiera ditt mobilnummer.'
                                    }
                                    value={!!entity.phone && entity.phoneVerified && entity.smsNotifications}
                                    disabled={!entity.phone || !entity.phoneVerified}
                                    variant="switch"
                                    onChange={setNotifications}
                                />
                            </Grid>
                        </Grid>
                    </Block>
                </Grid>
            </Grid>
        </Container>
    );
};

export default function UserProfileDetails() {
    const dispatch = useDispatch();
    const userProfile = useSelector(selectUserProfile());

    const fetchProfile = React.useCallback(() => {
        dispatch(fetchUserProfile());
    }, [dispatch]);

    return (
        <Fetch<UserProfileType>
            fetch={fetchProfile}
            data={userProfile}
            Component={UserProfileDetailsView}
            name="din profil"
        />
    );
}
