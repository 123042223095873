/* eslint-disable no-param-reassign */
// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TableActionType = {
    name: string,
//    orderBy: string,
//    orderDirection: 'asc' | 'desc',
    query?: string,
    page?: number,
    orderBy?: string,
    orderDirection?: 'asc' | 'desc',
    filterValues?: {
        [key: string]: string | number | boolean
    }
};

type TableStateType = {
    // sortByName should be removed when new table is fully implemented
    sortByName: {[key: string]: {
        orderBy?: string,
        orderDirection?: 'asc' | 'desc'
    }},
    options: {[key: string]: {
        query?: string,
        page?: number,
        orderBy?: string,
        orderDirection?: 'asc' | 'desc',
        filterValues?: {}
    }} // For new table
};

const initialState: TableStateType = {
    // sortByName should be removed when new table is fully implemented
    sortByName: {},
    options: {}
};

const slice = createSlice({
    name: 'tables',
    initialState,
    reducers: {
        // setTableSort should be removed when new table is fully implemented
        setTableSort: (state, action: PayloadAction<TableActionType>) => {
            const { name, ...values } = action.payload;
            state.sortByName[name] = values;
        },
        setTableOptions: (state, action: PayloadAction<TableActionType>) => {
            const { name, ...values } = action.payload;
            state.options[name] = { ...state.options[name], ...values };
        }
    }
});

export default slice;
