import { createContext } from 'react';

export type AuthValueType = {
    email: string,
    password: string,
    tenantId: number | null,
    tenantName: string,
    tenantOrgNumber: string,
    tenantIsRegistered: boolean,
    name: string,
    phone: string,
    newCompanyName: string,
    newCompanyOrgNumber: string
};

export type AuthContextValueType = {
    values: AuthValueType,
    onChange: (a: any) => void
};

export const defaultAuthValues: AuthValueType = {
    email: '',
    password: '',
    tenantId: null,
    tenantName: '',
    tenantOrgNumber: '',
    tenantIsRegistered: false,
    name: '',
    phone: '',
    newCompanyOrgNumber: '',
    newCompanyName: ''
};

const defaultAuthContext = {
    values: defaultAuthValues,
    onChange: (a: any) => {}
};

const AuthContext = createContext(defaultAuthContext);

export default AuthContext;
