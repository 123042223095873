import apiRequest from './api-request';

const resource = 'watchlists';

export function getWatchlists() {
    return apiRequest({
        method: 'GET',
        resource
    });
}

export function getWatchlist(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource,
        id
    });
}

export function createWatchlist(body: any) {
    return apiRequest({
        method: 'POST',
        resource,
        body
    });
}

export function updateWatchlist(id: number | string, body: any) {
    return apiRequest({
        method: 'PUT',
        resource,
        id,
        body
    });
}

export function deleteWatchlist(id: number | string) {
    return apiRequest({
        method: 'DELETE',
        resource,
        id
    });
}
