import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Tooltip } from '@mui/material';

import {
    useChangeable,
    FullForm,
    TextField,
    EmailField,
    PhoneField,
    WebsiteField,
} from '../CommonComponents/Form';

import { useDispatch, useSelector } from '../../redux/hooks';
import { updateTenantProfile } from '../../api/tenants';

import toast from '../../utils/toast';
import { fetchTenantProfile, selectTenantProfile } from '../../redux/actions/tenants';
import { SectionDivider } from '../CommonComponents';

const defaultTenantProfile = {
    email: '',
    phone: '',
    website: '',
    billingEmail: '',
    billingMark: '',
    billingStreet: '',
    billingZip: '',
    billingCity: ''
};

const TenantProfileForm: React.FC = () => {
    const dispatch = useDispatch();
    const tenantProfile = useSelector(selectTenantProfile());
    const [entity, onChange] = useChangeable(defaultTenantProfile);
    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(fetchTenantProfile());
    }, []);

    React.useEffect(() => {
        onChange(tenantProfile);
    }, [tenantProfile]);

    const onSubmit = React.useCallback(async () => {
        try {
            await updateTenantProfile(entity);
            toast('Företagsuppgifter uppdaterade');
            navigate(-1);
        } catch (e: any) {
            toast(e, 'Kunde inte uppdatera företagsuppgifterna, försök igen');
        }
    }, [entity]);

    return (
        <FullForm
            title="Redigera företagsuppgifter"
            entity={entity}
            onSubmit={onSubmit}
        >
            <Grid container spacing={2}>
                <Grid item md={12} lg={6}>
                    <SectionDivider label="Kontaktuppgifter" marginTop={1} />
                    <Grid container spacing={2}>
                        <Tooltip title="Kontakta oss om du behöver ändra namn." placement="bottom-start">
                            <Grid item xs={12}>
                                <TextField
                                    name="name"
                                    label="Namn"
                                    value={entity.name}
                                    onChange={onChange}
                                    disabled
                                />
                            </Grid>
                        </Tooltip>
                        <Tooltip title="Kontakta oss om du behöver ändra org. nummer." placement="bottom-start">
                            <Grid item xs={12}>
                                <TextField
                                    name="orgNumber"
                                    label="Org. nummer"
                                    value={entity.orgNumber}
                                    onChange={onChange}
                                    disabled
                                />
                            </Grid>
                        </Tooltip>
                        <Grid item xs={12}>
                            <EmailField
                                name="email"
                                label="E-post"
                                value={entity.email}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneField
                                name="phone"
                                label="Telefon"
                                value={entity.phone}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <WebsiteField
                                name="website"
                                label="Hemsida"
                                value={entity.website}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} lg={6}>
                    <SectionDivider label="Faktureringsuppgifter" marginTop={1} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <EmailField
                                name="billingEmail"
                                label="E-post för faktura"
                                value={entity.billingEmail}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="billingMark"
                                label="Märkning"
                                value={entity.billingMark}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="billingStreet"
                                label="Gata"
                                value={entity.billingStreet}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="billingZip"
                                label="Postnummer"
                                value={entity.billingZip}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="billingCity"
                                label="Postort"
                                value={entity.billingCity}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FullForm>
    );
};

export default TenantProfileForm;
