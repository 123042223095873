import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import {
    useChangeable,
    FullForm,
    BoolField,
    TextField,
    EmailField,
    PhoneField
} from '../CommonComponents/Form';

import { SectionDivider } from '../CommonComponents';

import {
    createUser,
    deleteUser,
    getUser,
    updateUser
} from '../../api/users';

import toast from '../../utils/toast';
import { UserType } from '../../types';

const defaultUser = {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    phoneAlt: '',
    isAdmin: false,
    sendInvitation: false
};

const UserForm: React.FC = () => {
    const { id } = useParams();
    const [entity, onChange] = useChangeable(defaultUser);
    const navigate = useNavigate();

    const [user, setUser] = React.useState<UserType | null>(null);

    React.useEffect(() => {
        (async () => {
            if (id) {
                const u = await getUser(id);
                onChange(u);
                setUser(u);
            }
        })();
    }, [id, onChange]);

    const onSubmit = React.useCallback(async () => {
        try {
            if (id) {
                await updateUser(id, entity);
                toast('Användare uppdaterad');
                navigate(-1);
            } else {
                const createdUser = await createUser(entity);
                toast('Användare skapad');
                navigate(`/users/${createdUser.id}`, { replace: true });
            }
        } catch (e: any) {
            toast(e, `Kunde inte ${id ? 'uppdatera' : 'skapa'} användaren, försök igen`);
        }
    }, [id, entity, navigate]);

    const onDelete = React.useCallback(async () => {
        try {
            if (id) {
                await deleteUser(id);
                toast('Användare borttagen');
                navigate('/users', { replace: true });
            }
        } catch (e: any) {
            toast(e, 'Kunde inte ta bort användaren, försök igen');
        }
    }, [id, navigate]);

    return (
        <FullForm
            title={`${entity.id ? 'Redigera' : 'Skapa'} användare`}
            entity={entity}
            onSubmit={onSubmit}
            onDelete={entity.id ? onDelete : undefined}
        >
            <Grid container spacing={2}>
                <Grid item md={12} lg={6}>
                    <SectionDivider label="Kontaktuppgifter" marginTop={1} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name="firstName"
                                label="Förnamn"
                                value={entity.firstName}
                                onChange={onChange}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                name="lastName"
                                label="Efternamn"
                                value={entity.lastName}
                                onChange={onChange}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                name="title"
                                label="Titel"
                                value={entity.title}
                                onChange={onChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <EmailField
                                name="email"
                                label="E-post"
                                value={entity.email}
                                onChange={onChange}
                                required
                                helperText={
                                    user?.emailVerified && entity.email !== user?.email
                                        ? 'Användaren måste verifiera sin e-post igen om du ändrar den.'
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneField
                                name="phone"
                                label="Mobil"
                                value={entity.phone}
                                onChange={onChange}
                                helperText={
                                    user?.phoneVerified && entity.phone && entity.phone !== user?.phone
                                        ? 'Användaren måste verifiera sitt mobilnummer igen om du ändrar det.'
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="phoneAlt"
                                label="Telefon"
                                maxLength={20}
                                value={entity.phoneAlt}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} lg={6}>
                    <SectionDivider label="Behörigheter" marginTop={1} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <BoolField
                                name="isAdmin"
                                label="Administratör"
                                value={entity.isAdmin}
                                onChange={onChange}
                                helperText="Administratörer kan hantera användare, grupper och företagsuppgifter."
                            />
                        </Grid>
                        {!id && (
                            <Grid item xs={12}>
                                <SectionDivider label="Övrigt" marginTop={2} />
                                <BoolField
                                    name="sendInvitation"
                                    label="Skicka inbjudan"
                                    value={entity.sendInvitation}
                                    onChange={onChange}
                                    helperText="Användaruppgifter skickas till e-postadressen när användaren skapats."
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </FullForm>
    );
};

export default UserForm;
