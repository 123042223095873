import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import {
    Button,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import {
    CheckBoxOutlineBlank,
    CheckBoxOutlined,
    Info
} from '@mui/icons-material';

import {
    formatBool,
    formatDate,
    formatDateTime,
    formatNumber,
    formatYesNo
} from '../../../utils/parsing';

import TablePagination from './TablePagination';
import { useDispatch, useSelector } from '../../../redux/hooks';
import { selectTableSort, setTableSort } from '../../../redux/actions/tables';
import InfoText from '../InfoText';

type ColumnType = {
    name: string,
    key: string,
    type?: 'number'
    | 'date'
    | 'datetime'
    | 'bool'
    | 'y/n'
    | 'currency'
    // | 'pieces'
    | 'km'
    | 'kg',
    getValue?: (obj: any) => any,
    width?: number,
    align?: string,
    padding?: 'checkbox' | 'none' | 'normal'
};

type PropsType = {
    columns: ColumnType[],
    data: any[],
    showSearch?: boolean,
    maxHeight?: string,
    emptyText?: string,
    onRowClick?: (obj: any) => void,
    getRowStyle?: (obj: any) => any,
    keyField?: string,
    batchActions?: {
        name: string,
        icon?: JSX.Element,
        action: (a: any) => Promise<void>,
        color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
        variant?: 'text' | 'outlined' | 'contained',
        getDisabled?: (a: any[]) => boolean
    }[],
    name?: string,
    defaultOrderBy?: string,
    defaultOrderDirection?: 'asc' | 'desc',
    disableSort?: boolean
};

const rightAlignTypes = [
    'number',
    'currency',
    // 'pieces',
    'km',
    'kg'
];

function getAlign({ align, type }: { align?: string, type?: string }) {
    return align === 'right' || rightAlignTypes.some((t) => t === type)
        ? 'right'
        : 'left';
}

const getRowValue = ({ column, row }: { column: ColumnType, row: any }) => {
    if (typeof column.getValue === 'function') {
        return column.getValue(row);
    }
    if (column.key && column.key in row) {
        if (column.type === 'date') {
            return formatDate(row[column.key]);
        }
        if (column.type === 'datetime') {
            return formatDateTime(row[column.key]);
        }
        if (column.type === 'number') {
            return formatNumber(row[column.key]);
        }
        if (column.type === 'bool') {
            return formatBool(row[column.key]);
        }
        if (column.type === 'y/n') {
            return formatYesNo(row[column.key]);
        }
        if (column.type === 'currency') {
            return formatNumber(row[column.key], 'SEK');
        }
        // if (column.type === 'pieces') {
        //     return formatNumber(row[column.key], 'st');
        // }
        if (column.type === 'km') {
            return formatNumber(row[column.key], 'km');
        }
        if (column.type === 'kg') {
            return formatNumber(row[column.key], 'kg');
        }
        return row[column.key];
    }
    return '';
};

const { compare } = new Intl.Collator('sv', {
    numeric: true,
    sensitivity: 'accent',
    ignorePunctuation: true
});

export default function BasicTable(props: PropsType) {
    const {
        columns,
        data,
        showSearch = false,
        maxHeight,
        emptyText = 'Det finns ingen data.',
        onRowClick,
        getRowStyle,
        keyField = 'id',
        batchActions,
        name = '',
        defaultOrderBy,
        defaultOrderDirection,
        disableSort
    } = props;

    const dispatch = useDispatch();

    const tableSort = useSelector(selectTableSort(name)) || {
        orderBy: defaultOrderBy || columns[0].key,
        orderDirection: defaultOrderDirection || 'desc'
    };

    const sortedData = React.useMemo(() => {
        const column = columns.find((c) => c.key === tableSort.orderBy);
        return column && tableSort.orderBy
            ? data?.slice().sort((a, b) => compare(
                `${getRowValue({ column, row: a })}`.replace(/\s/g, ''),
                `${getRowValue({ column, row: b })}`.replace(/\s/g, '')
            ) * (tableSort.orderDirection === 'asc' ? -1 : 1))
            : data;
    }, [columns, data, tableSort]);

    const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

    const isItemSelected = React.useCallback((item) => {
        return selectedItems.some((i) => i[keyField] === item[keyField]);
    }, [selectedItems, keyField]);

    const toggleItemSelect = React.useCallback((item) => {
        if (isItemSelected(item)) {
            setSelectedItems((sis) => sis.filter((i) => i[keyField] !== item[keyField]));
        } else {
            setSelectedItems((sis) => [...sis, item]);
        }
    }, [isItemSelected, keyField]);

    const isAllSelected = React.useMemo(() => {
        return selectedItems.length === data?.length;
    }, [data, selectedItems]);

    const toggleAllSelect = React.useCallback(() => {
        if (isAllSelected) {
            setSelectedItems([]);
        } else {
            setSelectedItems(data);
        }
    }, [data, isAllSelected]);

    const SelectAllCheckbox = React.useMemo(() => {
        return isAllSelected ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />;
    }, [isAllSelected]);

    return data?.length ? (
        <TableContainer
            sx={{ ...(maxHeight ? { maxHeight } : {}) }}
        >
            {showSearch && (
                <Grid container spacing={2} justifyContent="space-between" sx={{ p: 2 }}>
                    <Grid item xs>
                        Sök och filter
                    </Grid>
                    <Grid item>
                        <TablePagination />
                    </Grid>
                </Grid>
            )}
            {batchActions && (
                <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ p: 2, pb: 0 }}>
                    <Grid item xs>
                        <Typography>
                            {selectedItems.length === 0 && 'Välj rader att hantera'}
                            {selectedItems.length === 1 && '1 rad vald'}
                            {selectedItems.length > 1 && `${selectedItems.length} rader valda`}
                        </Typography>
                    </Grid>
                    {batchActions.map((ba) => (
                        <Grid item key={ba.name}>
                            <Button
                                variant={ba.variant || 'outlined'}
                                startIcon={ba.icon}
                                onClick={() => {
                                    ba.action(selectedItems);
                                    setSelectedItems([]);
                                }}
                                disabled={!selectedItems.length || (ba.getDisabled && ba.getDisabled(selectedItems))}
                                color={ba.color}
                            >
                                {ba.name}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            )}
            <Table
                stickyHeader
                aria-label="simple table"
            >
                <TableHead>
                    <TableRow>
                        {batchActions && (
                            <TableCell
                                padding="checkbox"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleAllSelect();
                                }}
                            >
                                <IconButton
                                    aria-label="Markera"

                                >
                                    {SelectAllCheckbox}
                                </IconButton>
                            </TableCell>
                        )}
                        {columns.map((column) => (
                            <TableCell
                                key={column.key}
                                align={getAlign(column)}
                                sx={column.width ? { width: column.width } : {}}
                            >
                                {name ? (
                                    <TableSortLabel
                                        key={column.key}
                                        active={!disableSort && !!column.name && tableSort.orderBy === column.key}
                                        direction={tableSort.orderDirection === 'asc' ? 'asc' : 'desc'}
                                        hideSortIcon={disableSort}
                                        onClick={() => {
                                            if (!disableSort && name) {
                                                dispatch(setTableSort({
                                                    name,
                                                    orderBy: column.key,
                                                    orderDirection: tableSort.orderDirection === 'asc' ? 'desc' : 'asc'
                                                }));
                                            }
                                        }}
                                    >
                                        {column.name}
                                    </TableSortLabel>
                                ) : (
                                    column.name
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData.map((row) => (
                        <TableRow
                            key={row[keyField]}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                backgroundColor: '#FFF',
                                ...(batchActions && isItemSelected(row) ? { backgroundColor: 'secondary.main' } : {}),
                                ...(getRowStyle ? getRowStyle(row) : {}),
                                ':hover': {
                                    cursor: onRowClick || batchActions ? 'pointer' : 'inherit',
                                    backgroundColor: {
                                        filter: 'brightness(0.95)'
                                    }
                                }
                            }}
                            onClick={() => (
                                // eslint-disable-next-line no-nested-ternary
                                onRowClick
                                    ? onRowClick(row)
                                    : (
                                        batchActions
                                            ? toggleItemSelect(row)
                                            : null
                                    )
                            )}
                        >
                            {batchActions && (
                                <TableCell
                                    padding="checkbox"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleItemSelect(row);
                                    }}
                                >
                                    <IconButton
                                        aria-label="Markera"
                                    >
                                        {
                                            isItemSelected(row)
                                                ? <CheckBoxOutlined />
                                                : <CheckBoxOutlineBlank />
                                        }
                                    </IconButton>
                                </TableCell>
                            )}
                            {columns.map((column) => (
                                <TableCell
                                    key={column.key}
                                    align={getAlign(column)}
                                    padding={column.padding}
                                >
                                    {getRowValue({ column, row })}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ) : (
        <div style={{ padding: 10 }}>
            <InfoText>
                {emptyText}
            </InfoText>
        </div>
    );
}
