import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PublishedWithChanges as PublishedWithChangesIcon } from '@mui/icons-material';
import { endOfTomorrow } from 'date-fns';

import {
    useChangeable,
    FullForm
} from '../CommonComponents/Form';

import {
    createCapacity,
    deleteCapacity,
    getCapacity,
    updateCapacity
} from '../../api/capacities';

import toast from '../../utils/toast';
import { useDispatch } from '../../redux/hooks';
import { resetLastAllOpenCapacitiesFetch } from '../../redux/actions/market';
import CapacityFormFields from './CapacityFormFields';
import getDrivingDistance from '../../utils/getDrivingDistance';
import { getFakeCoordinate } from '../../utils/geometry';

async function addDistancesAndFakeLocationCoordinates(entity: { [key: string]: any }) {
    let d;
    try {
        d = await getDrivingDistance(entity);
    } catch {
        d = { drivingDistance: null, drivingDuration: null };
    }
    return ({
        ...entity,
        fakePickupLat: getFakeCoordinate(entity.pickupLat),
        fakePickupLng: getFakeCoordinate(entity.pickupLng),
        fakeDeliveryLat: getFakeCoordinate(entity.deliveryLat),
        fakeDeliveryLng: getFakeCoordinate(entity.deliveryLng),
        drivingDistance: d.drivingDistance,
        drivingDuration: d.drivingDuration
    });
}

const CapacityForm: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const defaultCapacity = React.useMemo(() => ({
        firstPickupDate: endOfTomorrow().toISOString().slice(0, 10),
        lastPickupDate: null,
        firstDeliveryDate: endOfTomorrow().toISOString().slice(0, 10),
        lastDeliveryDate: null,
        firstPickupTime: null,
        lastPickupTime: null,
        firstDeliveryTime: null,
        lastDeliveryTime: null,

        pickupLat: null,
        pickupLng: null,
        pickupAttention: '',
        pickupStreet: '',
        pickupZip: '',
        pickupCity: '',
        pickupCountry: '',
        deliveryLat: null,
        deliveryLng: null,
        deliveryAttention: '',
        deliveryStreet: '',
        deliveryZip: '',
        deliveryCity: '',
        deliveryCountry: '',
        fakePickupLat: null,
        fakePickupLng: null,
        fakeDeliveryLat: null,
        fakeDeliveryLng: null,

        grossWeight: null,
        chargeableWeight: null,
        volume: null,
        loadingMeters: null,
        palletPlaces: null,
        pallets: null,
        // pieces: null,

        publicDescription: '',
        isPublic: false,
        groups: [],
        listPrice: null,
        expiresAt: null
    }), []);

    const [entity, onChange] = useChangeable(defaultCapacity);

    React.useEffect(() => {
        (async () => {
            if (id) {
                const capacity = await getCapacity(id);
                onChange(capacity);
            }
        })();
    }, [id, onChange]);

    const onSubmit = React.useCallback(async () => {
        try {
            const capacity = await addDistancesAndFakeLocationCoordinates(entity);
            if (id) {
                await updateCapacity(id, capacity);
                dispatch(resetLastAllOpenCapacitiesFetch()); // Refetch to get correct group/watchlist stats
                toast('Ledig kapacitet uppdaterad');
                navigate(-1);
            } else {
                const createdCapacity = await createCapacity({ ...capacity, publishDirectly: true });
                toast('Ledig kapacitet publicerad');
                navigate(`/capacities/${createdCapacity.id}`, { replace: true });
            }
        } catch (e: any) {
            toast(e, `Kunde inte ${id ? 'uppdatera' : 'publicera'} ledig kapacitet, försök igen`);
        }
    }, [entity, id, navigate]);

    const onSubmitDraft = React.useCallback(async () => {
        try {
            const capacity = await addDistancesAndFakeLocationCoordinates(entity);
            const createdCapacity = await createCapacity({ ...capacity, publishDirectly: false });
            toast('Ledig kapacitet sparad som utkast');
            navigate(`/capacities/${createdCapacity.id}`, { replace: true });
        } catch (e: any) {
            toast(e, 'Kunde inte skapa ledig kapacitet, försök igen');
        }
    }, [entity, navigate]);

    const onDelete = React.useCallback(async () => {
        try {
            if (id) {
                await deleteCapacity(id);
                toast('Ledig kapacitet borttagen');
                navigate('/capacities', { replace: true });
            }
        } catch (e: any) {
            toast(e, 'Kunde inte ta bort ledig kapaciteten, försök igen');
        }
    }, [id, navigate]);

    return (
        <FullForm
            title={`${entity.id ? 'Redigera' : 'Dela'} ledig kapacitet`}
            entity={entity}
            onSubmit={onSubmit}
            onDelete={entity.id ? onDelete : undefined}
            submitText={entity.id ? 'Spara' : 'Publicera'}
            onAltSubmit={entity.id ? undefined : onSubmitDraft}
            altSubmitText="Spara utkast"
            submitStartIcon={entity.id ? undefined : <PublishedWithChangesIcon />}
            showBottomSave
        >
            <CapacityFormFields
                entity={entity}
                onChange={onChange}
            />
        </FullForm>
    );
};

export default CapacityForm;
