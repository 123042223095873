import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button } from '@mui/material';

import login from '../../utils/login';
import toast from '../../utils/toast';
import { TextField } from '../CommonComponents/Form';
import AuthContext from './AuthContext';
import AuthContainer from './AuthContainer';
import SimpleForm from '../CommonComponents/Form/SimpleForm';

export default function SignIn() {
    const {
        values: {
            email,
            password
        },
        onChange
    } = React.useContext(AuthContext);

    const navigate = useNavigate();

    const tryLogin = React.useCallback(async () => {
        if (!email) {
            toast('Kontrollera din e-postadress');
            return;
        }
        if (!password) {
            toast('Kontrollera ditt lösenord');
            return;
        }

        try {
            await login({ email, password });
        } catch (error) {
            toast('Fel inloggningsuppgifter, försök igen');
        }
    }, [email, password]);

    return (
        <AuthContainer>
            <SimpleForm
                submitText="Logga in"
                onSubmit={tryLogin}
            >
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                        <TextField
                            name="email"
                            label="E-post"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={onChange}
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="password"
                            label="Lösenord"
                            autoComplete="current-password"
                            value={password}
                            onChange={onChange}
                            type="password"
                            variant="outlined"
                            submitOnEnter
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
            <Grid container justifyContent="space-between">
                <Grid item xs>
                    <Button variant="text" onClick={() => navigate('/forgot-password')}>
                        Glömt lösenord?
                    </Button>
                </Grid>

                <Grid item>
                    <Button variant="text" onClick={() => navigate('/register-company')}>
                        Skapa konto
                    </Button>
                </Grid>
            </Grid>
        </AuthContainer>
    );
}
