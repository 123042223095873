import React from 'react';
import {
    Badge,
    Button,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import { Close, Message } from '@mui/icons-material';

import { Paper } from '../../StyledComponents';
import { Numbers, SectionDivider } from '../../CommonComponents';
import JobBookmark from '../../Jobs/JobBookmark';
import EntityDriveLine from './EntityDriveline';
import EntityStatus from './EntityStatus';
import JobOffer from './JobOffer';
import DhlCall from './DhlCall';
import CapacitySuggestion from './CapacitySuggestion';

import { formatDateTime } from '../../../utils/parsing';
import { useDispatch, useSelector } from '../../../redux/hooks';
import { setSelectedMarketEntity, selectSelectedMarketEntity } from '../../../redux/actions/market';
import { JobType, isJobFullType } from '../../../types';
import OfferChatPopup from '../../Offers/OfferChatPopup';

export default function EntityDetails() {
    const dispatch = useDispatch();
    const entity = useSelector(selectSelectedMarketEntity());

    const [offerForMessages, setOfferForMessages] = React.useState<JobType['offer'] | null>(null);
    const closeMessagePopup = React.useCallback(() => {
        if (entity?.key) {
            dispatch(setSelectedMarketEntity(entity.key));
        }
        setOfferForMessages(null);
    }, [dispatch, entity?.key]);

    const refetch = React.useCallback(() => {
        if (entity?.key) {
            dispatch(setSelectedMarketEntity(entity.key));
        }
    }, [dispatch, entity?.key]);

    const deselect = React.useCallback(() => {
        if (entity?.key) {
            dispatch(setSelectedMarketEntity(null));
        }
    }, [dispatch, entity?.key]);

    const publishedText = React.useMemo(() => {
        if (!entity) {
            return '';
        }
        if (entity.type === 'DHL') {
            // return `Uppdrag hämtat ${formatDateTime(entity.publishedAt)} från DHL-Overflow.`;
            return `Hämtat ${formatDateTime(entity.publishedAt)} från DHL-Overflow.`;
        }
        if (entity.type === 'LL') {
            // return `Uppdrag publicerat ${formatDateTime(entity.publishedAt)} av ${entity.tenantName || '[dold uppdragsgivare]'} för ${entity.isPublic ? 'Alla' : entity.groups.map((g) => g.name).join(', ')}.`;
            return `Publicerat ${formatDateTime(entity.publishedAt)} av ${entity.tenantName || '[dold uppdragsgivare]'} för ${entity.isPublic ? 'Alla' : entity.groups.map((g) => g.name).join(', ')}.`;
        }
        if (entity.type === 'CAP') {
            // return `Kapacitet publicerad ${formatDateTime(entity.publishedAt)} för ${entity.isPublic ? 'Alla' : entity.groups.map((g) => g.name).join(', ')}.`;
            return `Publicerad ${formatDateTime(entity.publishedAt)} för ${entity.isPublic ? 'Alla' : entity.groups.map((g) => g.name).join(', ')}.`;
        }
        return '';
    }, [entity]);

    return entity ? (
        <Paper
            padding={0}
            sx={{
                position: 'absolute',
                bottom: '20px',
                left: '50%',
                transform: 'translateX(-50%)',
                minWidth: '520px',
                maxWidth: '1200px',
                width: 'calc(100% - 40px)'
            }}
        >
            {entity.type === 'LL' && (
                <OfferChatPopup
                    id={offerForMessages?.id || null}
                    recipient={entity.tenantName || 'Uppdragsgivaren'}
                    canWrite={entity.isOpen}
                    close={closeMessagePopup}
                />
            )}
            <Grid
                container
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={1}
                sx={{ p: 1, pb: 0 }}
            >
                <Grid item>
                    <EntityStatus entity={entity} />
                </Grid>

                <Grid item xs />

                {entity.type !== 'CAP' && !isJobFullType(entity) && entity.isOpen && (
                    <Grid item>
                        <JobBookmark job={entity} refetch={refetch} />
                    </Grid>
                )}
                {entity.type === 'LL' && entity.offer && (
                    <Grid item>
                        <Badge color="error" badgeContent={entity.offer.unreadMessageCount}>
                            <Button
                                variant="contained"
                                color="info"
                                startIcon={<Message />}
                                onClick={() => setOfferForMessages(entity.offer)}
                            >
                                {`${entity.offer.messageCount} Meddelande${entity.offer.messageCount !== 1 ? 'n' : ''}`}
                            </Button>
                        </Badge>
                    </Grid>
                )}
                {entity.type === 'DHL' && (
                    <Grid item>
                        <DhlCall />
                    </Grid>
                )}
                {entity.type === 'LL' && entity.isOpen && (
                    <Grid item>
                        <JobOffer job={entity} refetch={refetch} />
                    </Grid>
                )}
                {entity.type === 'CAP' && (
                    <Grid item>
                        <CapacitySuggestion capacity={entity} refetch={refetch} />
                    </Grid>
                )}
                <Grid item>
                    <IconButton aria-label="" onClick={deselect}>
                        <Close />
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    <EntityDriveLine entity={entity} />

                    {entity.publicDescription && (
                        <Typography variant="body2" display="block" align="center" sx={{ mt: 1 }}>
                            {entity.publicDescription}
                        </Typography>
                    )}

                    <Numbers
                        small
                        data={[
                            { title: 'Totalvikt [kg]', value: entity.grossWeight },
                            { title: 'FDR-vikt [kg]', value: entity.chargeableWeight },
                            { title: 'Volym [m³]', value: entity.volume },
                            { title: 'Flakmeter', value: entity.loadingMeters },
                            { title: 'Pallplatser', value: entity.palletPlaces },
                            { title: 'Pallar', value: entity.pallets }
                        ]}
                    />

                    <SectionDivider
                        label={entity.type === 'CAP' ? 'Ledig kapacitet' : 'Uppdrag'}
                        marginTop={0}
                        marginBottom={-1}
                    />

                    <Typography variant="caption" display="block" align="center">
                        {publishedText}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    ) : null;
}
