import React from 'react';
import { Box, Typography } from '@mui/material';
import { formatDateTime } from '../../utils/parsing';
import { COLOR_PURPLE } from '../../theme';

type PropsType = {
    isCurrentTenant: boolean,
    userName: string,
    createdAt: string,
    message: string
}

const OWN_COLOR = COLOR_PURPLE;
const OTHER_COLOR = '#EEE';

export default function OfferChatMessage({
    isCurrentTenant,
    userName,
    createdAt,
    message
}: PropsType) {
    return (
        <Box
            sx={{
                maxWidth: '90%',
                ...(isCurrentTenant ? { marginLeft: 'auto' } : {}),
                borderRadius: '5px',
                backgroundColor: isCurrentTenant ? OWN_COLOR : OTHER_COLOR,
                color: isCurrentTenant ? 'white' : undefined,
                marginBottom: 1,
                padding: '5px 12px 3px 12px'
            }}
            display="table"
        >
            {message.split('\n').map((part, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Typography display="block" key={i}>
                    {part}
                </Typography>
            ))}
            <Typography variant="caption" display="block">
                {`${userName}, ${formatDateTime(createdAt)}`}
            </Typography>
        </Box>
    );
}
/*

*/
