import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { Edit as EditIcon, Verified as VerifiedIcon } from '@mui/icons-material';

import {
    Block,
    Fetch,
    Header,
    List,
} from '../CommonComponents';
import { Container } from '../StyledComponents';
import type { FetchComponentPropsType } from '../CommonComponents/Fetch/Fetch';

import { getUser } from '../../api/users';
import { UserType } from '../../types';

const UserDetailsView: React.FC<FetchComponentPropsType<UserType>> = (props) => {
    const { entity } = props;

    const navigate = useNavigate();

    return (
        <Container>
            <Header
                title="Användare"
                CustomComponent={(
                    <Button
                        variant="contained"
                        startIcon={<EditIcon />}
                        onClick={() => navigate(`/users/edit/${entity.id}`)}
                    >
                        Redigera
                    </Button>
                )}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Block
                        title="Kontaktuppgifter"
                    >
                        <List
                            data={[{
                                name: 'Namn',
                                value: `${entity.firstName} ${entity.lastName}`
                            }, {
                                name: 'Titel',
                                value: entity.title
                            }, {
                                name: 'E-post',
                                type: 'raw',
                                value: (entity.emailVerified
                                    ? (
                                        <Grid container alignItems="center">
                                            <Typography variant="body2" display="inline" sx={{ pr: 1 }}>{entity.email}</Typography>
                                            <VerifiedIcon color="primary" />
                                        </Grid>
                                    ) : (
                                        <Grid container alignItems="center">
                                            <Typography variant="body2" display="inline" sx={{ pr: 1 }}>{entity.email}</Typography>
                                            <VerifiedIcon color="disabled" />
                                        </Grid>
                                    ))
                            }, {
                                name: 'Mobil',
                                type: 'raw',
                                // eslint-disable-next-line no-nested-ternary
                                value: (entity.phone ? (entity.phoneVerified
                                    ? (
                                        <Grid container alignItems="center">
                                            <Typography variant="body2" display="inline" sx={{ pr: 1 }}>
                                                {entity.phone}
                                            </Typography>
                                            <VerifiedIcon color="primary" />
                                        </Grid>
                                    ) : (
                                        <Grid container alignItems="center">
                                            <Typography variant="body2" display="inline" sx={{ pr: 1 }}>
                                                {entity.phone}
                                            </Typography>
                                            <VerifiedIcon color="disabled" />
                                        </Grid>
                                    )) : '')
                            }, {
                                name: 'Telefon',
                                value: entity.phoneAlt
                            }]}
                        />
                    </Block>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Block
                        title="Behörigheter"
                    >
                        <List
                            data={[{
                                name: 'Administratör',
                                value: entity.isAdmin,
                                type: 'y/n'
                            }]}
                        />
                    </Block>
                </Grid>
            </Grid>
        </Container>
    );
};

export default function UserDetails() {
    const { id } = useParams() as unknown as { id: number };
    const fetchUser = React.useCallback(() => getUser(id), [id]);

    return (
        <Fetch<UserType>
            fetch={fetchUser}
            Component={UserDetailsView}
            name="användaren"
        />
    );
}
