import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Login as LoginIcon,
    Logout as LogoutIcon,
    SaveAlt,
    Search as SearchIcon
} from '@mui/icons-material';
import { Button, Grid } from '@mui/material';

import { deleteGroupInvitation, getGroup, joinGroup } from '../../api/groups';
import { GroupForViewType, GroupInvitationType } from '../../types';
import {
    Block,
    Fetch,
    Header,
    List,
    Popup,
    Table
} from '../CommonComponents';
import { FetchComponentPropsType } from '../CommonComponents/Fetch/Fetch';
import { Container } from '../StyledComponents';

import GroupInvitationForm from './GroupInvitationForm';

import {
    selectAllOpenJobs,
    fetchAllOpenJobs,
    fetchAllOpenCapacities,
    setMarketFiltersFromWatchlist,
    selectAllOpenCapacities
} from '../../redux/actions/market';

import { useDispatch } from '../../redux/hooks';
import { formatGroupRole, formatGroupType } from '../../utils/parsing';
import toast from '../../utils/toast';
import { selectUserProfile } from '../../redux/actions/users';
import { filterMarketEntities } from '../../utils/filter-market-entities';

const GroupDetailsView: React.FC<FetchComponentPropsType<GroupForViewType>> = (props) => {
    const { entity, refetch } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userProfile = useSelector(selectUserProfile());

    const [publishedCount, setPublishedCount] = React.useState<number | null>(null);

    const allOpenJobs = useSelector(selectAllOpenJobs());
    const allOpenCapacities = useSelector(selectAllOpenCapacities());

    React.useEffect(() => {
        setPublishedCount(filterMarketEntities({
            entities: [...allOpenJobs, ...allOpenCapacities],
            filters: { isJob: true, isCapacity: true, groupId: entity.id }
        }).length);
    }, [allOpenCapacities, allOpenJobs, entity.id]);

    const showResults = React.useCallback(() => {
        dispatch(setMarketFiltersFromWatchlist({ groupId: entity.id, groupName: entity.name }));
        navigate('/market');
    }, [dispatch, entity.id, entity.name, navigate]);

    const [invitationToEdit, setInvitationToEdit] = React.useState<GroupInvitationType | null>(null);
    const [askToLeavePrivateGroup, setAskToLeavePrivateGroup] = React.useState(false);

    const joinGroupLocal = React.useCallback(async () => {
        try {
            await joinGroup(entity.id);
            toast('Du är nu medlem i gruppen');
            refetch();
        } catch (e) {
            toast(e, 'Kunde inte gå med i grupp, försök igen');
        }
    }, [entity.id, refetch]);

    const commitLeaveGroup = React.useCallback(async () => {
        try {
            if (userProfile) {
                await deleteGroupInvitation(entity.id, userProfile.tenantId);
                toast('Du har nu lämnat gruppen');
                if (entity.isPublic) {
                    refetch();
                } else {
                    navigate('/groups', { replace: true });
                }
            }
        } catch (e) {
            toast(e, 'Kunde inte lämna grupp, försök igen');
        }
    }, [entity.id, entity.isPublic, navigate, refetch, userProfile]);

    const tryToLeaveGroup = React.useCallback(async () => {
        if (entity.isPublic) {
            commitLeaveGroup();
        } else {
            setAskToLeavePrivateGroup(true);
        }
    }, [entity, commitLeaveGroup]);

    const createInvitation = React.useCallback(() => {
        setInvitationToEdit({
            canPublish: entity.canPublishDefault,
            canInvite: entity.canInviteDefault
        });
    }, [entity]);

    const editInvitation = React.useCallback((invitation) => {
        if (invitation.isOwner) {
            toast('Du kan inte redigera administratören');
        } else {
            setInvitationToEdit(invitation);
        }
    }, []);

    const groupData = React.useMemo(() => (
        [{
            name: 'Administratör',
            value: entity.ownerTenantName
        }, {
            name: 'Grupptyp',
            value: formatGroupType(entity)
        }, {
            name: 'Din roll',
            value: formatGroupRole(entity)
        },
        ...(entity.isAccepted ? [{
            name: 'Du kan publicera',
            value: entity.canPublish,
            type: 'y/n' as 'y/n'
            // }, {
            //     name: 'Du kan se/bjuda in medlemmar',
            //     value: entity.canInvite,
            //     type: 'y/n' as 'y/n'
        }] : []),
        ...(entity.isOwner ? [{
            name: 'Nya medlemmar kan publicera',
            value: entity.canPublishDefault,
            type: 'y/n' as 'y/n'
            // }, {
            //     name: 'Nya medlemmar kan se/bjuda in medlemmar',
            //     value: entity.canInviteDefault,
            //     type: 'y/n' as 'y/n'
        }, {
            name: 'Skapades',
            value: entity.createdAt,
            type: 'datetime' as 'datetime'
        }, {
            name: 'Skapades av',
            value: entity.createdByName
        }, {
            name: 'Uppdaterades',
            value: entity.updatedAt,
            type: 'datetime' as 'datetime'
        }, {
            name: 'Uppdaterades av',
            value: entity.updatedByName
        }] : [])
        ]
    ), [entity]);

    return (
        <Container>
            {entity.isOwner && (
                // {(entity.isOwner || entity.canInvite) && (
                <GroupInvitationForm
                    groupId={entity.id}
                    isOwner={entity.isOwner}
                    tenants={entity.tenants}
                    invitation={invitationToEdit}
                    setInvitation={setInvitationToEdit}
                    refetch={refetch}
                />
            )}

            {askToLeavePrivateGroup && (
                <Popup
                    open={askToLeavePrivateGroup}
                    title="Lämna privat grupp?"
                    body="Om du lämnar en privat grupp kommer du att behöva en ny inbjudan för att gå med igen."
                    okLabel="Ja"
                    cancelLabel="Nej"
                    handleOk={commitLeaveGroup}
                    handleClose={() => setAskToLeavePrivateGroup(false)}
                />
            )}

            <Header
                title="Grupp"
                CustomComponent={entity.isOwner ? (
                    <Button
                        variant="contained"
                        startIcon={<EditIcon />}
                        onClick={() => navigate(`/groups/edit/${entity.id}`)}
                    >
                        Redigera
                    </Button>
                ) : undefined}
            />

            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} xl>
                    <Block
                        title={entity.name}
                        ActionComponent={(
                            <>
                                {!entity.isOwner && entity.isAccepted && (
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        startIcon={<LogoutIcon />}
                                        onClick={tryToLeaveGroup}
                                        sx={{ ml: 1 }}
                                    >
                                        Lämna grupp
                                    </Button>
                                )}
                                {!entity.isOwner && !entity.isAccepted && (entity.isInvited || entity.isPublic) && (
                                    <Button
                                        variant="contained"
                                        startIcon={<LoginIcon />}
                                        onClick={joinGroupLocal}
                                        sx={{ ml: 1 }}
                                    >
                                        Gå med i grupp
                                    </Button>
                                )}
                                {(entity.isOwner || entity.isAccepted) && (
                                    <Button
                                        variant="outlined"
                                        startIcon={<SearchIcon />}
                                        onClick={showResults}
                                        sx={{ ml: 1 }}
                                    >
                                        {`Visa ${publishedCount} publicering${publishedCount === 1 ? '' : 'ar'}`}
                                    </Button>
                                )}
                            </>
                        )}
                    >
                        <Grid container spacing={2} alignItems="stretch" sx={{ p: 2, pt: 0 }}>
                            <Grid item xs={12} md>
                                {entity.description}
                            </Grid>
                        </Grid>

                        <List data={groupData} />
                    </Block>
                </Grid>

                {entity.isOwner && (
                    // {(entity.isOwner || (entity.isAccepted && entity.canInvite)) && (
                    <Grid item xs={12} xl={6}>
                        <Block
                            title={`Medlemmar (${entity.tenants.filter((t) => t.isOwner || t.isAccepted).length})`}
                            ActionComponent={(
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Button
                                            startIcon={<SaveAlt />}
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => navigate(`/groups/${entity.id}/import-members`)}
                                        >
                                            Importera
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            startIcon={<AddIcon />}
                                            onClick={createInvitation}
                                        >
                                            Bjud in
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        >
                            <Table
                                name="groupDetailsTenants"
                                columns={[
                                    {
                                        name: 'Namn',
                                        key: 'tenantName',
                                    }, {
                                        name: 'Roll',
                                        key: 'customRole',
                                        getValue: formatGroupRole
                                    }, {
                                        name: 'Kan publicera',
                                        key: 'canPublish',
                                        type: 'bool'
                                        // }, {
                                        //     name: 'Kan se/bjuda in medlemmar',
                                        //     key: 'canInvite',
                                        //     type: 'bool'
                                    }
                                ]}
                                data={entity.tenants}
                                keyField="tenantId"
                                onRowClick={entity.isOwner ? editInvitation : undefined}
                                emptyText="Gruppen har inga medlemmar"
                            />
                        </Block>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default function GroupDetails() {
    const { id } = useParams() as unknown as { id: number };
    const dispatch = useDispatch();

    const fetchGroup = React.useCallback(() => {
        dispatch(fetchAllOpenJobs(true)); // Only fetch if not recently fetched
        dispatch(fetchAllOpenCapacities(true)); // Only fetch if not recently fetched

        return getGroup(id);
    }, [dispatch, id]);

    return <Fetch<GroupForViewType> fetch={fetchGroup} Component={GroupDetailsView} name="gruppen" />;
}
