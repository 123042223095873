import React from 'react';

import { Checkbox, FormControlLabel, TextField } from '@mui/material';

type PropsType = {
    column: any,
    editingEntity: { [key: string]: any } | null,
    setEditingEntity: (a: any) => void
};

export default function DataImportRowFormField(props: PropsType) {
    const {
        column,
        editingEntity,
        setEditingEntity
    } = props;

    const value = editingEntity ? editingEntity[column.key] : null;

    const validation = (
        column.validate
            ? column.validate({ value })
            : { isValid: true }
    );

    const error = (!value && column.isRequired) || !validation.isValid;

    switch (column.type) {
        case 'bool':
            return (
                <FormControlLabel
                    label={`${column.name}${column.isRequired ? ' *' : ''}`}
                    control={(
                        <Checkbox
                            checked={!!value}
                            onChange={({ target: { checked: newValue } }) => setEditingEntity(
                                (e: { [key: string]: any }) => ({
                                    ...e,
                                    [column.key]: newValue
                                })
                            )}
                        />
                    )}
                />
            );
        default:
            return (
                <TextField
                    name={column.key}
                    label={`${column.name}${column.isRequired ? ' *' : ''}`}
                    value={value}
                    onChange={({ target: { value: newValue } }) => setEditingEntity(
                        (e: { [key: string]: any }) => ({
                            ...e,
                            [column.key]: newValue
                        })
                    )}
                    error={error}
                    helperText={!validation.isValid ? validation.errorText : undefined}
                    variant="standard"
                    size="small"
                    fullWidth
                />
            );
    }
}
