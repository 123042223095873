import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';

import {
    EmailField,
    PasswordField,
    TextField,
    useChangeable
} from '../CommonComponents/Form';
import AuthContext from './AuthContext';
import AuthContainer from './AuthContainer';
import toast from '../../utils/toast';
import SimpleForm from '../CommonComponents/Form/SimpleForm';
import { sendResetPasswordCode } from '../../api-public/user';
import { resetPassword } from '../../utils/auth';
import login from '../../utils/login';

export default function RegisterCompany() {
    const navigate = useNavigate();
    const {
        values: {
            email
        },
        onChange
    } = React.useContext(AuthContext);

    const [pending, setPending] = React.useState(false);
    const [form, onChangeLocal] = useChangeable({
        verificationCode: '',
        newPassword: '',
    });

    const submit = React.useCallback(async () => {
        if (!pending) {
            try {
                await sendResetPasswordCode({ email: email.trim() });
                toast('Om e-postadressen är registrerad hos oss så har en verifieringskod skickats dit.');
                setPending(true);
            } catch (e) {
                toast(e, 'Kunde inte skicka verifieringskoden, försök igen');
            }
        } else {
            try {
                await resetPassword({
                    email: email.trim(),
                    resetCode: form.verificationCode,
                    newPassword: form.newPassword
                });
                await login({
                    email: email.trim(),
                    password: form.newPassword
                });
                navigate('/');
            } catch (e) {
                toast(e, 'Kunde inte återställa lösenordet, försök igen');
            }
        }
    }, [pending, email, form]);

    return (
        <AuthContainer>
            <Typography variant="h5" sx={{ textAlign: 'center', mb: 4, mt: 4 }}>Återställ lösenord</Typography>

            <SimpleForm
                onSubmit={submit}
                submitText={pending ? 'Återställ lösenord' : 'Skicka verifieringskod'}
                submitDisabled={
                    pending
                        ? !(email && form.verificationCode && form.newPassword)
                        : !email
                }
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <EmailField
                            variant="outlined"
                            name="email"
                            label="E-postadress"
                            value={email}
                            onChange={onChange}
                            disabled={pending}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ display: pending ? 'block' : 'none' }}>
                        <TextField
                            variant="outlined"
                            name="verificationCode"
                            label="Verifieringskod"
                            value={form.verificationCode}
                            onChange={onChangeLocal}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ display: pending ? 'block' : 'none' }}>
                        <PasswordField
                            variant="outlined"
                            name="newPassword"
                            label="Nytt lösenord"
                            value={form.newPassword}
                            onChange={onChangeLocal}
                        />
                    </Grid>
                </Grid>
            </SimpleForm>

            <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs>
                    <Button variant="text" onClick={() => navigate('/')}>
                        Tillbaka
                    </Button>
                </Grid>
            </Grid>
        </AuthContainer>
    );
}
