import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import {
    useChangeable,
    FullForm,
    TextField,
    EmailField,
    PhoneField
} from '../CommonComponents/Form';
import { SectionDivider } from '../CommonComponents';

import { useDispatch, useSelector } from '../../redux/hooks';
import { updateUserProfile } from '../../api/users';

import toast from '../../utils/toast';
import { fetchUserProfile, selectUserProfile } from '../../redux/actions/users';

const defaultUserProfile = {
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    phone: '',
    phoneAlt: ''
};

export default function UserProfileForm() {
    const dispatch = useDispatch();
    const userProfile = useSelector(selectUserProfile());
    const [entity, onChange] = useChangeable(defaultUserProfile);
    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(fetchUserProfile());
    }, [dispatch]);

    React.useEffect(() => {
        onChange(userProfile);
    }, [onChange, userProfile]);

    const onSubmit = React.useCallback(async () => {
        try {
            await updateUserProfile(entity);
            toast('Profil uppdaterad');
            navigate(-1);
        } catch (e: any) {
            toast(e, 'Kunde inte uppdatera profilen, försök igen');
        }
    }, [entity, navigate]);

    return (
        <FullForm
            title="Redigera profil"
            entity={entity}
            onSubmit={onSubmit}
        >
            <Grid container spacing={2}>
                <Grid item md={12} lg={6}>
                    <SectionDivider label="Kontaktuppgifter" marginTop={1} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name="firstName"
                                label="Förnamn"
                                value={entity.firstName}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="lastName"
                                label="Efternamn"
                                value={entity.lastName}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="title"
                                label="Titel"
                                value={entity.title}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EmailField
                                name="email"
                                label="E-post"
                                value={entity.email}
                                onChange={onChange}
                                helperText={
                                    userProfile?.emailVerified && entity.email !== userProfile?.email
                                        ? 'Du måste verifiera din e-post igen om du ändrar den.'
                                        : ''
                                }
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneField
                                name="phone"
                                label="Mobil"
                                value={entity.phone}
                                onChange={onChange}
                                helperText={
                                    userProfile?.phoneVerified && entity.phone && entity.phone !== userProfile?.phone
                                        ? 'Du måste verifiera ditt mobilnummer igen om du ändrar det.'
                                        : ''
                                }
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="phoneAlt"
                                label="Telefon"
                                value={entity.phoneAlt}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FullForm>
    );
}
