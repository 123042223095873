import React from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton
} from '@mui/material';
import { Close } from '@mui/icons-material';

import OfferChat from './OfferChat';

type PropsType = {
    id?: number | null,
    recipient: string,
    canWrite?: boolean,
    close: () => void
}

export default function OfferChatPopup({
    id,
    recipient,
    canWrite,
    close
}: PropsType) {
    return (
        <Dialog
            open={!!id}
            onClose={close}
            PaperProps={{
                sx: { minWidth: '500px' }
            }}
        >
            <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                <IconButton onClick={close}>
                    <Close />
                </IconButton>
            </Box>
            <DialogTitle>
                {recipient}
            </DialogTitle>
            <DialogContent>
                <OfferChat
                    id={id}
                    recipient={recipient}
                    canWrite={canWrite}
                    maxHeight="800px"
                />
            </DialogContent>
        </Dialog>
    );
}
