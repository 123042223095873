import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, Typography } from '@mui/material';
import {
    Email as EmailIcon,
    Phone as PhoneIcon
} from '@mui/icons-material';

import { AutocompleteField } from '../CommonComponents/Form';
import AuthContext from './AuthContext';
import AuthContainer from './AuthContainer';

import { searchCompanies } from '../../api-public/company';
import SimpleForm from '../CommonComponents/Form/SimpleForm';

const supportMailAddress = encodeURIComponent('"Logic Link Support" <support@logiclink.se>');
const supportMailSubject = encodeURIComponent('Vem är min lokala administratör?');
const supportMailUrl = `mailto:${supportMailAddress}?subject=${supportMailSubject}`;
const supportPhoneUrl = 'tel:0470-515110';

export default function RegisterCompany() {
    const {
        values: {
            tenantId,
            tenantName,
            tenantIsRegistered
        },
        onChange
    } = React.useContext(AuthContext);

    const navigate = useNavigate();

    return (
        <AuthContainer>
            <Typography variant="h5" sx={{ textAlign: 'center', mb: 4, mt: 4 }}>Anslut ditt företag</Typography>

            <SimpleForm
                submitText="Nästa"
                onSubmit={() => navigate('/register-user')}
                submitDisabled={!tenantId || tenantIsRegistered}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AutocompleteField<{
                            id: number | string,
                            name: string,
                            orgNumber?: string,
                            isRegistered?: boolean
                        }>
                            variant="outlined"
                            label="Välj ditt företag"
                            optionIdKey="id"
                            defaultOption={!tenantId ? null : {
                                id: tenantId,
                                name: tenantName || ''
                            }}
                            fetchOptions={searchCompanies}
                            minQueryLength={3}
                            getOptionName={(o) => o.name}
                            getOptionDescription={(o) => o.orgNumber || ''}
                            onChange={(company) => onChange({
                                tenantId: company === null ? null : company.id,
                                tenantName: company === null ? null : company.name,
                                tenantOrgNumber: company === null ? null : company.orgNumber,
                                tenantIsRegistered: company === null ? null : company.isRegistered
                            })}
                        />
                    </Grid>
                    {tenantIsRegistered ? (
                        <>
                            <Grid item xs={12} sx={{ m: 2, mb: 0 }}>
                                <Typography variant="body1" sx={{ textAlign: 'center' }}>
                                    Ditt företag är redan registrerat.
                                    Kontakta oss om du inte vet vem din lokala administratör är.
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    startIcon={<PhoneIcon />}
                                    href={supportPhoneUrl}
                                    target="_blank"
                                >
                                    0470 - 51 51 10
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    startIcon={<EmailIcon />}
                                    href={supportMailUrl}
                                    target="_blank"
                                >
                                    support@logiclink.se
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} sx={{ textAlign: 'center', m: 2, mb: 0 }}>
                            <Typography variant="body1" sx={{ textAlign: 'center' }}>
                                Vi uppdaterar kontinuerligt vårt register med företag som uppfyller våra kriterier.
                                Hittar du inte ditt företag i listan men vill ansluta dig ändå?
                            </Typography>
                            <Button variant="text" onClick={() => navigate('/create-company')}>
                                Klicka här
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </SimpleForm>

            <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs>
                    <Button variant="text" onClick={() => navigate('/')}>
                        Tillbaka
                    </Button>
                </Grid>
            </Grid>
        </AuthContainer>
    );
}
