import apiRequest from './api-request';

const resource = 'users';

export function getUsers() {
    return apiRequest({
        method: 'GET',
        resource
    });
}

export function getUser(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource,
        id
    });
}

export function getUserProfile() {
    return apiRequest({
        method: 'GET',
        resource: 'user-profile'
    });
}

export function updateUserProfile(body: any) {
    return apiRequest({
        method: 'PUT',
        resource: 'user-profile',
        body
    });
}

export function createUser(body: any) {
    return apiRequest({
        method: 'POST',
        resource,
        body
    });
}

export function updateUser(id: number | string, body: any) {
    return apiRequest({
        method: 'PUT',
        resource,
        id,
        body
    });
}

export function deleteUser(id: number | string) {
    return apiRequest({
        method: 'DELETE',
        resource,
        id
    });
}
