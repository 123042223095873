import React from 'react';
import { Button } from '@mui/material';

import FormContext from '../FormContext';

type PropsType = {
    label: string,
    onChange: (a: any) => void,
    variant?: 'text' | 'outlined' | 'contained',
    startIcon?: JSX.Element,
    disabled?: boolean
};

export default function ClientFileField(props: PropsType) {
    const {
        label,
        onChange,
        variant,
        startIcon,
        disabled
    } = props;

    const formContext = React.useContext(FormContext);
    const isSubmitting = formContext?.isSubmitting;

    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const handleClick = React.useCallback(() => {
        hiddenFileInput?.current?.click();
    }, [hiddenFileInput]);

    const getFileContent = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        // const file = event?.target?.files?.at(0);
        const file = event?.target?.files?.length ? event.target.files[0] : null;
        if (file) {
            const fileData = new FileReader();
            fileData.onloadend = (e) => onChange(e?.target?.result || '');
            fileData.readAsText(file);
        }
    }, [onChange]);

    return (
        <>
            <Button
                onClick={handleClick}
                variant={variant}
                startIcon={startIcon}
                disabled={disabled || isSubmitting}
            >
                {label}
            </Button>

            <input
                type="file"
                accept=".csv"
                ref={hiddenFileInput}
                style={{ display: 'none' }}
                onChange={getFileContent}
            />
        </>
    );
}
