const googleApiKey = 'AIzaSyALztzBcfBZ76oOan6ShJ2ylt0PAqc7wKM';
// const googleApiKey = 'AIzaSyAuGKaL8YtUG-9jjZjKgha22Redp71SUyc';
const geocodeBaseUrl = 'https://maps.google.com/maps/api/geocode/json?';
const autocompleteBaseUrl = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?';

export type GoogleAddressType = {
    placeId: string,
    formattedAddress: string,
    northeastBoundLat: number,
    northeastBoundLng: number,
    southwestBoundLat: number,
    southwestBoundLng: number,
    lat: number,
    lng: number,
    street: string,
    zip: string,
    city: string,
    country: string
};

function parseUrlParams(obj: {[key: string]: string | number | boolean }) {
    return Object.entries(obj).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
}

function getGeocodeUrl(queryParams: any) {
    const params = {
        key: googleApiKey,
        language: 'sv',
        ...(queryParams.place_id ? {} : { region: 'se' }), // cannot pass region if placeId is passed
        ...queryParams
    };
    return geocodeBaseUrl + parseUrlParams(params);
}

function getAutocompleteUrl(query: string) {
    const params = {
        key: googleApiKey,
        language: 'sv',
        region: 'se',
        input: query,
        components: 'country:se'
    };
    return autocompleteBaseUrl + parseUrlParams(params);
}

const getAddressComponentName = (data: any, type: any) => {
    const component = data?.address_components.find((c: any) => c?.types.some((t: any) => t === type));

    return component?.long_name || component?.short_name || '';
};

function parseAddressData(data: any) {
    const street = [
        getAddressComponentName(data, 'route'),
        getAddressComponentName(data, 'street_number')
    ].filter((c) => !!c).join(' ');

    return ({
        placeId: data?.place_id,
        formattedAddress: data?.formatted_address,
        northeastBoundLat: data?.geometry?.bounds?.northeast?.lat,
        northeastBoundLng: data?.geometry?.bounds?.northeast?.lng,
        southwestBoundLat: data?.geometry?.bounds?.southwest?.lat,
        southwestBoundLng: data?.geometry?.bounds?.southwest?.lng,
        lat: data?.geometry?.location?.lat,
        lng: data?.geometry?.location?.lng,
        street,
        zip: getAddressComponentName(data, 'postal_code'),
        city: getAddressComponentName(data, 'postal_town') || getAddressComponentName(data, 'locality'),
        country: getAddressComponentName(data, 'country')
    });
}

const autocompleteService: { current: any } = { current: null };
interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}
interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
    place_id: string,
    description: string;
    structured_formatting: StructuredFormatting;
}

export function getGoogleAutocomplete(query: string) {
    if (!autocompleteService.current && (window as any).google) {
        autocompleteService.current = new (
            window as any
        ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
        return undefined;
    }

    return new Promise<PlaceType[]>((resolve) => {
        autocompleteService.current?.getPlacePredictions(
            {
                input: query,
                componentRestrictions: { country: 'se' }
            },
            resolve
            // (results?: readonly PlaceType[]) => {
            //     resolve(results);
            // }
        );
    });
}

// export async function getGoogleAutocomplete(query: string) {
//     const response = await fetch(getAutocompleteUrl(query));
//     const jsonResponse = await response.json();
//     if (jsonResponse.status !== 'OK') {
//         return [];
//     }

//     return jsonResponse.predictions.map((p: any) => ({
//         placeId: p.place_id,
//         mainText: p.structured_formatting.main_text,
//         secondaryText: p.structured_formatting.secondary_text
//     }));
// }

export async function getLocationDetails(placeId?: string) {
    if (!placeId) {
        return null;
    }

    const response = await fetch(getGeocodeUrl({ place_id: placeId }));
    const jsonResponse = await response.json();

    if (jsonResponse.status !== 'OK' || !jsonResponse.results?.length) {
        return null;
    }

    return parseAddressData(jsonResponse.results[0]);
}

export async function getBestGuessLocationDetails(query: string) {
    const autocomplete = await getGoogleAutocomplete(query);

    if (autocomplete?.length) {
        return getLocationDetails(autocomplete[0].place_id);
    }
    return null;
}
