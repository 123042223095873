/* eslint-disable dot-notation */
import { capitalize, fillObject, sleep } from '../../../utils';
import { getFakeCoordinate } from '../../../utils/geometry';
import { getBestGuessLocationDetails, GoogleAddressType } from '../../../utils/googleMaps';
import { formatDate, formatDateTime } from '../../../utils/parsing';
import { parseDate } from '../../CommonComponents/SimpleDataImportXlsx/parsing';
import defaultOrder from './defaultOrder';

type InputDataType = { [key: string]: string | undefined }[];
type DataType = { [key: string]: string | number | null | undefined }[];

const FETCH_REQUEST_DELAY = 100;

function getLocationDetails(
    type: 'pickup' | 'delivery',
    query: string | number | null | undefined,
    locations: DataType
) {
    const l = locations.find((ul) => ul.query === query);

    if (l) {
        return Object.fromEntries([
            ...Object.entries({
                ...l,
            }).map(([key, val]) => ([type + capitalize(key), val])),
            // Add fake coordinates here for each entry:
            [`fake${capitalize(type)}Lat`, l.lat ? getFakeCoordinate(l.lat) : null],
            [`fake${capitalize(type)}Lng`, l.lng ? getFakeCoordinate(l.lng) : null],
        ]);
    }
    return ({});
}

async function getFullObjectsWithLocaitons(parsedInputData: DataType) {
    // Get best guess location for unique queries
    const locationQueries = parsedInputData.flatMap((d) => [d.pickupQuery ?? '', d.deliveryQuery ?? '']);
    const uniqueLocationQueries = [...new Set(locationQueries)];

    const results = await Promise.all<GoogleAddressType | null>(uniqueLocationQueries.map(async (ulq, i) => {
        await sleep(i * FETCH_REQUEST_DELAY);
        if (typeof ulq === 'string' && ulq.length > 0) {
            return getBestGuessLocationDetails(ulq);
        }
        return null;
    }));

    const uniqueLocations = results.map((result, i) => ({
        query: uniqueLocationQueries[i],
        lat: result?.lat ?? null,
        lng: result?.lng ?? null,
        street: result?.street ?? '',
        zip: result?.zip ?? '',
        city: result?.city ?? '',
        country: result?.country ?? ''
    }));

    // Remove undefined from parsedInputData, fill the base object, keep locationQueries
    return parsedInputData.map((pid) => Object.fromEntries(
        Object.entries(pid).filter(([key, value]) => value !== undefined)
    )).map((d) => fillObject({
        base: { pickupQuery: '', deliveryQuery: '', ...defaultOrder },
        fill: {
            ...d,
            ...getLocationDetails('pickup', d.pickupQuery, uniqueLocations),
            ...getLocationDetails('delivery', d.deliveryQuery, uniqueLocations)
        }
    }));
}

function getTimeDateString(s: string | undefined) {
    if (typeof s !== 'string') {
        return undefined;
    }

    const ns = s.split(':').map((n) => parseInt(n, 10));

    if (ns.length !== 2) {
        return undefined;
    }

    const d = new Date();
    d.setHours(ns[0], ns[1], 0, 0);

    return d.toISOString();
}

function getBarkforsLoadObject(s?: string) {
    if (typeof s !== 'string') {
        return ({});
    }
    const value = parseFloat(s.replaceAll(',', '.'));
    if (Number.isNaN(value)) {
        return ({});
    }
    if (s.includes('lm')) {
        return ({ loadingMeters: value });
    }
    if (s.includes('ppl')) {
        return ({ palletPlaces: value });
    }
    if (s.includes('cbm')) {
        return ({ volume: value });
    }

    return ({});
}

function getM3Date(s?: string) {
    if (typeof s !== 'string') {
        return null;
    }
    const ts = s.trim();
    return parseDate(`20${ts.at(0)}${ts.at(1)}-${ts.at(2)}${ts.at(3)}-${ts.at(4)}${ts.at(5)}`);
}

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
tomorrow.setHours(15, 0);
const tomorrowString = tomorrow.toISOString();

export default [{
    key: 'logicLink',
    name: 'Logic Link',
    templateCells: [[
        'Upphämtningsdatum',
        'Leveransdatum',
        'Upphämtningsplats',
        'Leveransplats',
        'Totalvikt',
        'FDR-vikt',
        'Volym',
        'Flakmeter',
        'Pallplatser',
        'Pallar',
        'Publik beskrivning',
        'Privat beskrivning',
        'Riktpris',
        'Anmäl intresse senast'
    ], [
        `Ex: ${tomorrowString.slice(0, 10)}`,
        `Ex: ${tomorrowString.slice(0, 10)}`,
        'Ex: Storgatan 22 Malmö',
        'Ex: Kungsgatan 11 Uppsala',
        'Ex: 34200',
        'Ex: 36000',
        'Ex: 2,2',
        'Ex: 3',
        'Ex: 4',
        'Ex: 5',
        'Ex: Text som visas för alla som kan se uppdraget',
        'Ex: Text som visas för den som tilldelas uppdraget',
        'Ex: 14600',
        `Ex: ${tomorrowString.slice(0, 16).replace('T', ' ')}`,
    ]],
    parseData: async (inputData: InputDataType) => {
        const parsedInputData = inputData
            .filter((d) => (
                typeof d['Upphämtningsdatum'] !== 'string'
                || !d['Upphämtningsdatum'].startsWith('Ex:')
            ))
            .map((d) => ({
                firstPickupDate: d['Upphämtningsdatum'] ? formatDate(d['Upphämtningsdatum']) : undefined,
                firstDeliveryDate: d['Leveransdatum'] ? formatDate(d['Leveransdatum']) : undefined,
                pickupQuery: `${d['Upphämtningsplats'] || ''}`,
                deliveryQuery: `${d['Leveransplats'] || ''}`,
                grossWeight: d['Totalvikt'] ? parseFloat(`${d['Totalvikt']}`.replaceAll(',', '.')) : undefined,
                chargeableWeight: d['FDR-vikt'] ? parseFloat(`${d['FDR-vikt']}`.replaceAll(',', '.')) : undefined,
                volume: d['Volym'] ? parseFloat(`${d['Volym']}`.replaceAll(',', '.')) : undefined,
                loadingMeters: d['Flakmeter'] ? parseFloat(`${d['Flakmeter']}`.replaceAll(',', '.')) : undefined,
                palletPlaces: d['Pallplatser'] ? parseFloat(`${d['Pallplatser']}`.replaceAll(',', '.')) : undefined,
                pallets: d['Pallar'] ? parseFloat(`${d['Pallar']}`.replaceAll(',', '.')) : undefined,
                publicDescription: `${d['Publik beskrivning'] || ''}`,
                privateDescription: `${d['Privat beskrivning'] || ''}`,
                listPrice: d['Riktpris'] ? parseFloat(`${d['Riktpris']}`.replaceAll(',', '.')) : undefined,
                expiresAt: d['Anmäl intresse senast'] ? formatDateTime(d['Anmäl intresse senast']) : undefined,
            }));

        return getFullObjectsWithLocaitons(parsedInputData);
    }
}, {
    key: 'barkfors',
    name: 'Barkfors',
    parseData: (inputData: InputDataType) => {
        const parsedInputData = inputData
            .filter((d) => typeof d.Ordernr === 'undefined')
            .map((d) => ({
                firstPickupDate: d['Lastdag'] ? formatDate(d['Lastdag']) : undefined,
                lastPickupDate: d['Lastdag'] && getTimeDateString(d['Last Tid2'])
                    ? formatDate(d['Lastdag']) : undefined,
                firstPickupTime: getTimeDateString(d['Last Tid']),
                lastPickupTime: getTimeDateString(d['Last Tid2']),
                firstDeliveryDate: d['Lev Dag'] ? formatDate(d['Lev Dag']) : undefined,
                lastDeliveryDate: d['Lev Dag'] && getTimeDateString(d['Lev Tid2'])
                    ? formatDate(d['Lev Dag']) : undefined,
                firstDeliveryTime: getTimeDateString(d['Lev Tid']),
                lastDeliveryTime: getTimeDateString(d['Lev Tid2']),
                pickupAttention: d['Avs Namn'] || d['Order avs. namn'] || undefined,
                pickupQuery: `${d['Avs Adress1'] || ''
                    } ${d['Avs Postnr'] || d['AvsPostnr'] || ''
                    } ${d['Avs Ort'] || d['Order avs. ort'] || ''
                    }`.trim(),
                deliveryAttention: d['Mott. Namn'] || d['Order mott. namn'] || undefined,
                deliveryQuery: `${d['Mott. Adress1'] ?? ''
                    } ${d['Mott. Postnr'] ?? d['MotPostnr'] ?? ''
                    } ${d['Mott. Ort'] ?? d['Order mott. ort'] ?? ''
                    }`.trim(),
                grossWeight: d['Vikt'] ? parseFloat(d['Vikt']) : undefined,
                pallets: d['Kolliantal'] ? parseFloat(d['Kolliantal']) : undefined,
                ...getBarkforsLoadObject(d['Skrymme']),
                publicDescription: `${d['LBH'] ?? ''
                    }${d['Lastinfo']
                        ? ` Lastinfo: ${d['Lastinfo']}`
                        : ''
                    }${d['Lossinfo']
                        ? ` Lossinfo: ${d['Lossinfo']}`
                        : ''
                    }`.trim()
            }));

        return getFullObjectsWithLocaitons(parsedInputData);
    }
}, {
    key: 'm3',
    name: 'M3',
    parseData: (inputData: InputDataType) => {
        const parsedInputData = inputData.map((d) => {
            const date = getM3Date(d['Avg dt']);
            return ({
                firstPickupDate: date,
                firstDeliveryDate: date,
                pickupQuery: `${d['Postn '] || ''} ${d['Adress 3    '] || ''}`.trim(),
                grossWeight: d['  Netto '] ? parseFloat(`${d['  Netto ']}`.replaceAll(',', '.')) : undefined
            });
        });

        return getFullObjectsWithLocaitons(parsedInputData);
    }
}];
