import {
    COLOR_GREY,
    COLOR_MINT,
    COLOR_PURPLE,
    COLOR_SKIN,
    COLOR_TEAL
} from '../../../theme';

export const colors = [
    COLOR_GREY,
    COLOR_TEAL,
    COLOR_MINT,
    COLOR_SKIN,
    COLOR_PURPLE
];

export { default as DoughnutChart } from './Doughnut';
export { default as PieChart } from './Pie';
