import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

import {
    Block,
    Description,
    DriveLine,
    Fetch,
    Header,
    List,
    Numbers,
    Popup,
    Table
} from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';
import type { FetchComponentPropsType } from '../CommonComponents/Fetch/Fetch';
import ToggleCheckbox from '../CommonComponents/ToggleCheckbox';
import OrderMap from '../Orders/OrderMap';

import {
    getCapacity,
    removeCapacityOrder,
    setCapacityPublished
} from '../../api/capacities';
import { CapacityType, OrderForCapacitySuggestionType } from '../../types';
import { getFillFactorPercentage, getFormattedOrderExpiryDate, getOrderDate } from '../../utils/order';
import { formatNumber } from '../../utils/parsing';
import toast from '../../utils/toast';

function CapacityDetailsView(props: FetchComponentPropsType<CapacityType>) {
    const { entity, refetch } = props;
    const navigate = useNavigate();

    const [showEditWarning, setShowEditWarning] = React.useState(false);

    const editCapacity = React.useCallback(() => {
        setShowEditWarning(false);
        if (entity.id) {
            navigate(`/capacities/edit/${entity.id}`);
        }
    }, [entity.id, navigate]);

    const onEditClick = React.useCallback(() => {
        if (entity.isPublished) {
            setShowEditWarning(true);
        } else {
            editCapacity();
        }
    }, [editCapacity, entity.isPublished]);

    const goToJob = React.useCallback((job: { id: number }) => {
        navigate(`/jobs/LL/${job.id}`);
    }, [navigate]);

    const RemoveSuggestedButton = React.useCallback((suggestedOrder) => {
        return (
            <IconButton
                onClick={async (e) => {
                    e.stopPropagation();
                    try {
                        await removeCapacityOrder({
                            capacityId: entity.id,
                            orderId: suggestedOrder.id
                        });
                        refetch();
                        toast('Föreslaget uppdrag borttagen');
                    } catch {
                        toast('Kunde inte ta bort föreslaget uppdrag, försök igen');
                    }
                }}
            >
                <Delete />
            </IconButton>
        );
    }, [entity.id, refetch]);

    const setPublished = React.useCallback(async (isPublished) => {
        await setCapacityPublished(entity.id, isPublished);
        refetch();
        toast(`Lediga kapaciteten är ${isPublished ? '' : 'av'}publicerad`);
    }, [entity.id, refetch]);

    return !entity ? null : (
        <Container>
            <Popup
                open={showEditWarning}
                title="Vill du verkligen ändra din publicerade lediga kapacitet?"
                body="Alla föreslagna uppdrag kommer att tas bort."
                okLabel="Ja"
                cancelLabel="Nej"
                handleOk={editCapacity}
                handleClose={() => setShowEditWarning(false)}
            />

            <Header
                title="Dela ledig kapacitet"
                CustomComponent={(
                    <Button variant="contained" startIcon={<Edit />} onClick={onEditClick}>
                        Redigera
                    </Button>
                )}
            />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Block>
                        <OrderMap
                            entityType="CAP"
                            pickupLat={entity.pickupLat}
                            pickupLng={entity.pickupLng}
                            deliveryLat={entity.deliveryLat}
                            deliveryLng={entity.deliveryLng}
                        />
                    </Block>
                </Grid>

                <Grid item xs={12} lg={8}>
                    <Paper>
                        <DriveLine small data={entity} />
                        <Numbers
                            small
                            data={[
                                { title: 'Totalvikt [kg]', value: entity.grossWeight },
                                { title: 'FDR-vikt [kg]', value: entity.chargeableWeight },
                                { title: 'Volym [m³]', value: entity.volume },
                                { title: 'Flakmeter', value: entity.loadingMeters },
                                { title: 'Pallplatser', value: entity.palletPlaces },
                                { title: 'Pallar', value: entity.pallets }
                            ]}
                        />
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            <Grid item xs={12}>
                                <Description
                                    title="Publik beskrivning"
                                    text={entity.publicDescription}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} lg={4}>
                    <Block
                        title="Detaljer"
                    >
                        <List
                            data={[{
                                name: 'Publicerad',
                                type: 'raw',
                                valuePadding: 1,
                                value: <ToggleCheckbox
                                    value={entity.isPublished}
                                    onToggle={setPublished}
                                    confirm={entity.isPublished}
                                    confirmText={entity.isPublished
                                        ? 'Vill du verkligen avpublicera din lediga kapacitet?'
                                        : ''}
                                />
                            }, {
                                name: 'Publiceras för',
                                value: entity.isPublic ? 'Alla' : entity.groups.map((g) => g.name).join(', ')
                            }, {
                                name: 'Föreslå uppdrag senast',
                                value: getFormattedOrderExpiryDate(entity),
                                type: 'datetime'
                            }]}
                        />
                    </Block>
                </Grid>

                <Grid item xs={12}>
                    <Block title="Föreslagna uppdrag">
                        <Table
                            name="suggestedOrders"
                            columns={[{
                                name: 'Transportdatum',
                                key: 'orderDate',
                                getValue: getOrderDate
                            }, {
                                name: 'Upphämtningsort',
                                key: 'pickupCity'
                            }, {
                                name: 'Leveransort',
                                key: 'deliveryCity'
                            }, {
                                name: 'Körsträcka',
                                key: 'drivingDistance',
                                type: 'number' as 'number',
                                getValue: (c: { drivingDistance: number }) => (
                                    c.drivingDistance
                                        ? formatNumber(Math.round(c.drivingDistance / 1000), 'km')
                                        : '-'
                                )
                            }, {
                                name: 'Totalvikt',
                                key: 'grossWeight',
                                type: 'kg' as 'kg'
                            }, {
                                name: 'Flakmeter',
                                key: 'loadingMeters',
                                type: 'number' as 'number'
                            }, {
                                name: 'Listpris',
                                key: 'listPrice',
                                type: 'currency' as 'currency'
                            // }, {
                            //     name: 'Extrasträcka',
                            //     key: 'extraDistance',
                            //     type: 'number' as 'number',
                            //     getValue: (r: OrderForCapacitySuggestionType) => formatNumber(Math.round(r.id), 'km')
                            }, {
                                name: 'Fyllnadsgrad',
                                key: 'fillFactor',
                                type: 'number' as 'number',
                                getValue: (r: OrderForCapacitySuggestionType) => formatNumber(Math.round(getFillFactorPercentage({
                                    capacity: entity,
                                    order: r
                                })), '%')
                            }, {
                                name: '',
                                key: 'removeSuggested',
                                padding: 'checkbox' as 'checkbox',
                                align: 'right',
                                getValue: RemoveSuggestedButton
                            }]}
                            data={entity.suggestedOrders}
                            onRowClick={goToJob}
                            emptyText="Ni har inga föreslagna uppdrag"
                        />
                    </Block>
                </Grid>
            </Grid>
        </Container>
    );
}

export default function CapacityDetails() {
    const { id } = useParams() as unknown as { id: number };
    const fetchCapacity = React.useCallback(() => getCapacity(id), [id]);

    return (
        <Fetch<CapacityType>
            fetch={fetchCapacity}
            Component={CapacityDetailsView}
            name="lediga kapaciteten"
        />
    );
}
