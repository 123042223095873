import { Chip } from '@mui/material';
import React from 'react';
import { OfferStatusType } from '../../types';
import { formatOfferStatus } from '../../utils/parsing';

type PropsType = {
    offerStatus: OfferStatusType
};

const getOfferStatusColor = (offerStatus: OfferStatusType) => {
    switch (offerStatus) {
        case 'ACCEPTED':
            return 'primary';

        case 'REJECTED':
            return 'error';

        case 'EXPIRED':
            return 'warning';

        case 'PENDING':
            return 'default';

        default:
            return 'default';
    }
};

const OfferStatusChip: React.FC<PropsType> = (props) => {
    const { offerStatus } = props;

    return (
        <Chip color={getOfferStatusColor(offerStatus)} label={formatOfferStatus(offerStatus)} />
    );
};

export default OfferStatusChip;
