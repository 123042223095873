import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { Edit } from '@mui/icons-material';

import { AutocompleteField, PopupForm } from '../CommonComponents/Form';
import { fetchAllUsers, selectAllUsers } from '../../redux/actions/users';
import toast from '../../utils/toast';
import { updateOrderContact } from '../../api/orders';

type PropsType = {
    orderId: number,
    contactId: number | null,
    contactName: string | null,
    refetch: () => void,
    contactType: 'shipper-contact' | 'carrier-contact'
}

const ChangeJobContact: React.FC<PropsType> = (props) => {
    const {
        orderId,
        contactId,
        contactName,
        refetch,
        contactType
    } = props;

    const dispatch = useDispatch();
    const users = useSelector(selectAllUsers());
    const fetchUsers = React.useCallback(() => {
        dispatch(fetchAllUsers());
    }, []);

    const [showContactPopup, setShowContactPopup] = React.useState(false);
    const [newContactId, setNewContactId] = React.useState(contactId);

    React.useEffect(() => {
        setNewContactId(contactId);
    }, [contactId]);

    const changeContact = React.useCallback(async () => {
        if (newContactId) {
            try {
                await updateOrderContact({ id: orderId, contactId: newContactId, contactType });
                setShowContactPopup(false);
                toast('Kontaktperson ändrad');
                refetch();
            } catch (e) {
                toast(e, 'Kunde inte ändra kontaktperson, försök igen');
            }
        }
    }, [orderId, newContactId]);

    return (
        <>
            <Button
                startIcon={<Edit />}
                variant="outlined"
                color="primary"
                aria-label="Ändra er kontaktperson"
                onClick={() => setShowContactPopup(true)}
            >
                {`Er kontakt: ${contactName}`}
            </Button>
            <PopupForm
                title="Ändra er kontaktperson"
                open={showContactPopup}
                okLabel="Ändra"
                handleOk={changeContact}
                handleClose={() => setShowContactPopup(false)}
                okDisabled={!newContactId}
            >
                <AutocompleteField<{ id: number, firstName: string, lastName: string, title?: string }>
                    label="Kontaktperson"
                    optionIdKey="id"
                    defaultOption={contactId ? {
                        id: contactId,
                        firstName: contactName || '',
                        lastName: ''
                    } : undefined}
                    fetchOptions={fetchUsers}
                    options={users}
                    getOptionName={(o) => `${o.firstName} ${o.lastName}`}
                    getOptionDescription={(o) => o.title || ''}
                    onChange={(user) => {
                        if (user?.id) {
                            setNewContactId(user.id);
                        }
                    }}
                    fetchOnlyOnce
                    disableClearable
                />
            </PopupForm>
        </>

    );
};

export default ChangeJobContact;
