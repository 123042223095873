import integrations from '../slices/integrations';
import type { RootStateType, ThunkDispatchType } from '../index';

import {
    getIntegration,
    getIntegrationLogs,
    getIntegrationMeta,
    getIntegrations
} from '../../api/integrations';

export function fetchAllIntegrations() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(integrations.actions.fetchAll());
            const data = await getIntegrations();
            dispatch(integrations.actions.allFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching integrations, set redux error state and handle in errorBoundary.', e);
            dispatch(integrations.actions.allFetched({ data: [] }));
        }
    };
}

export function fetchIntegrationById(id: number | string) {
    if (!id) {
        throw Error('No id supplied for integration');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(integrations.actions.fetchById({ id }));
            const data = await getIntegration(id);
            dispatch(integrations.actions.byIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching integration, set redux error state and handle in errorBoundary.', e);
            dispatch(integrations.actions.byIdFetched({ id }));
        }
    };
}

export function fetchIntegrationMetaById(id: number | string) {
    if (!id) {
        throw Error('No id supplied for integration');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(integrations.actions.fetchMetaById({ id }));
            const data = await getIntegrationMeta(id);
            dispatch(integrations.actions.metaByIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching integration meta, set redux error state and handle in errorBoundary.', e);
            dispatch(integrations.actions.metaByIdFetched({ id }));
        }
    };
}

export function fetchIntegrationLogsById(id: number | string) {
    if (!id) {
        throw Error('No id supplied for integration');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(integrations.actions.fetchLogsById({ id }));
            const data = await getIntegrationLogs(id);
            dispatch(integrations.actions.logsByIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching integration logs, set redux error state and handle in errorBoundary.', e);
            dispatch(integrations.actions.logsByIdFetched({ id }));
        }
    };
}

export const selectAllIntegrations = () => (state: RootStateType) => state.integrations.all;
export const selectFetchingAllIntegrations = () => (state: RootStateType) => state.integrations.fetchingAll;

export const selectIntegrationById = (id: number | string) => (state: RootStateType) => (
    state.integrations.byId[id]
);
export const selectFetchingIntegrationById = (id: number | string) => (state: RootStateType) => (
    state.integrations.fetchingById[id]
);

export const selectIntegrationMetaById = (id: number | string) => (state: RootStateType) => (
    state.integrations.metaById[id]
);
export const selectFetchingIntegrationMetaById = (id: number | string) => (state: RootStateType) => (
    state.integrations.fetchingMetaById[id]
);

export const selectIntegrationLogsById = (id: number | string) => (state: RootStateType) => (
    state.integrations.logsById[id]
);
export const selectFetchingIntegrationLogsById = (id: number | string) => (state: RootStateType) => (
    state.integrations.fetchingLogsById[id]
);
