import React, { useCallback, useEffect, useState } from 'react';
import { Popup } from '../CommonComponents';

const FIVE_MINUTES_MS = 5 * 60 * 1000;

export default function UpgradePrompter() {
    const [promptNewVersion, setPromptNewVersion] = useState(false);

    useEffect(() => {
        const scriptNames = Array
            .from(document.getElementsByTagName('script'))
            .reduce((acc: string[], script: HTMLScriptElement) => {
                const { src } = script;
                if (src.includes(window.location.origin)) {
                    return [...acc, src.substring(src.lastIndexOf('/'))];
                }
                return acc;
            }, []);

        const checkVersionInterval = setInterval(() => {
            fetch(window.location.origin, {
                headers: {
                    'Cache-Control': 'no-cache'
                }
            }).then((response) => response.text()).then((indexHtml) => {
                // The script names are hashed, if they've changed, it means we have a new version
                if (!scriptNames.every((script) => indexHtml.includes(script))) {
                    setPromptNewVersion(true);
                    clearInterval(checkVersionInterval);
                }
            }).catch(() => {
                console.log('Failed to check for new version');
            });
        }, FIVE_MINUTES_MS);
    }, []);

    const reload = useCallback(() => {
        setPromptNewVersion(false);
        window.location.reload();
    }, []);

    const cancel = useCallback(() => {
        setPromptNewVersion(false);
    }, []);

    return (
        <Popup
            open={promptNewVersion}
            title="Uppdatering"
            okLabel="Ladda om nu"
            cancelLabel="Ladda om senare"
            handleOk={reload}
            handleClose={cancel}
            body={`
                Det finns en ny version av Logic Link tillgänglig.
                För att få den senaste versionen krävs en sidomladdning.

                OBS! Allt ditt osparade arbete kommer att gå förlorat när du laddar om.
            `}
        />
    );
}
