import React from 'react';
import {
    Box,
    Grid,
    Typography
} from '@mui/material';
import { AddLink } from '@mui/icons-material';
import { Container, Paper } from '../StyledComponents';
import { Header, Popup } from '../CommonComponents';
import { createFeedback } from '../../api/feedback';
import toast from '../../utils/toast';

type IntegrationBlockProps = {
    title: string,
    // description: string,
    logoPath?: string,
    icon?: JSX.Element,
    // color: string,
    // backgroundColor: string,
    onClick: (name: string) => void
}

function IntegrationBlock({
    title,
    // description,
    logoPath,
    icon = <AddLink sx={{ fontSize: 96 }} color="primary" />,
    // color,
    // backgroundColor,
    onClick
}: IntegrationBlockProps) {
    return (
        <Paper
            sx={{
                textAlign: 'center',
                opacity: 0.7,
                // color,
                transition: 'opacity 0.3s ease',
                ':hover': {
                    cursor: 'pointer',
                    opacity: 1
                }
            }}
            onClick={() => onClick(title)}
        >
            <Box
                sx={{
                    height: 150,
                    p: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {logoPath ? (
                    <Box
                        component="img"
                        sx={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}
                        alt="Logo"
                        src={logoPath}
                    />
                ) : (
                    icon
                )}
            </Box>
            <Typography variant="h6">
                {title}
            </Typography>
            {/* <Typography>
                {description}
            </Typography> */}
        </Paper>
    );
}

export default function IntegraitonSignup() {
    const [popupIsOpen, setPopupIsOpen] = React.useState(false);
    const [integrationName, setIntegrationName] = React.useState('');

    const onClick = React.useCallback((name: string) => {
        setIntegrationName(name);
        setPopupIsOpen(true);
    }, []);

    const signup = React.useCallback(async () => {
        try {
            await createFeedback({
                message: `Jag är intresserad av en integration mot ${integrationName}, vänligen kontakta mig.`
            });
            toast('Tack för din intresseanmälan, vi kommer att höra av oss inom kort!');
        } catch (e: any) {
            toast(e, 'Kunde inte skicka intresseanmälan just nu, försök igen');
        }
        setPopupIsOpen(false);
    }, [integrationName]);

    return (
        <Container>
            <Popup
                open={popupIsOpen}
                title={`Integration - ${integrationName}`}
                handleOk={signup}
                handleClose={() => setPopupIsOpen(false)}
                okLabel="Ja, kontakta mig"
            >
                <Typography sx={{ pb: 2 }}>
                    Genom en integration kan du lägga ut uppdrag i Logic Link direkt från ditt transporthanteringssystem.
                </Typography>

                <Typography sx={{ pb: 2 }}>
                    {`Är du intresserad av en integration mot ${integrationName}?`}
                </Typography>

                <Typography>
                    Isf kontaktar vi dig inom kort för att hjälpa dig igång.
                </Typography>
            </Popup>

            <Header title="Integrationer" />

            <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={3}>
                    <IntegrationBlock
                        title="Opter"
                        // description="Description..."
                        logoPath="/opter-logo.png"
                        onClick={onClick}
                    // color="#999"
                    // backgroundColor="#FE6"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <IntegrationBlock
                        title="Barkfors T5"
                        // description="Description..."
                        logoPath="/barkfors-logo.png"
                        onClick={onClick}
                    // color="#999"
                    // backgroundColor="#FE6"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <IntegrationBlock
                        title="Hogia"
                        // description="Description..."
                        logoPath="/hogia-logo.png"
                        onClick={onClick}
                    // color="#999"
                    // backgroundColor="#FE6"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <IntegrationBlock
                        title="Workify"
                        // description="Description..."
                        logoPath="/workify-logo.png"
                        onClick={onClick}
                    // color="#999"
                    // backgroundColor="#FE6"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <IntegrationBlock
                        title="Eget system"
                        // description="Description..."
                        // icon={<AddLink />}
                        onClick={onClick}
                    // color="#999"
                    // backgroundColor="#FE6"
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
