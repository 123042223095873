/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
    Box,
    Chip,
    Divider,
    Grid,
    Typography
} from '@mui/material';

import {
    ArrowForward,
    Scale,
    Straighten,
    ModeOfTravel
} from '@mui/icons-material';

import { formatDate, formatMeters, formatNumber } from '../../utils/parsing';
import { OpenCapacityForMarketListType, JobForMarketListType } from '../../types';
import {
    COLOR_PURPLE,
    COLOR_TEAL,
    COLOR_WHITEISH,
    COLOR_YELLOW
} from '../../theme';

type PropsType = {
    entity: JobForMarketListType | OpenCapacityForMarketListType,
    isSelected: boolean,
    style: any,
    onClick: () => void
};

function getColor({ type }: { type: 'LL' | 'DHL' | 'CAP' }) {
    switch (type) {
        case 'LL':
            return COLOR_TEAL;
        case 'DHL':
            return COLOR_YELLOW;
        case 'CAP':
            return COLOR_PURPLE;
        default:
            return COLOR_WHITEISH;
    }
}

const MarketListItem: React.FC<PropsType> = (props) => {
    const {
        entity,
        isSelected,
        style,
        onClick
    } = props;

    return (
        <div style={style}>
            <Box
                sx={{
                    p: 2,
                    cursor: 'pointer',
                    ':hover': { background: isSelected ? '#F0F0F0' : '#F5F5F5' },
                    ...(isSelected && { backgroundColor: 'secondary.main' }),
                    borderLeftWidth: 5,
                    borderLeftStyle: 'solid',
                    borderLeftColor: getColor(entity)
                }}
                onClick={onClick}
            >
                <Grid container spacing={2} justifyContent="space-between" alignItems="center" wrap="nowrap" sx={{ mb: 1 }}>
                    <Grid item xs>
                        <Typography variant="subtitle1" maxWidth={150} noWrap>{entity.pickupCity}</Typography>
                        <Typography variant="subtitle2" maxWidth={150} noWrap>{formatDate(entity.firstPickupDate)}</Typography>
                    </Grid>
                    <Grid item sx={{ textAlign: 'center' }}>
                        <ArrowForward />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="subtitle1" maxWidth={150} noWrap sx={{ textAlign: 'right' }}>{entity.deliveryCity}</Typography>
                        <Typography variant="subtitle2" maxWidth={150} noWrap sx={{ textAlign: 'right' }}>{formatDate(entity.lastDeliveryDate)}</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={0} justifyContent="space-between" wrap="nowrap">
                    <Grid item xs>
                        <Chip icon={<Scale />} label={formatNumber(entity.grossWeight, 'kg')} />
                    </Grid>
                    <Grid item xs sx={{ textAlign: 'center' }}>
                        <Chip icon={<Straighten />} label={formatNumber(entity.loadingMeters, 'flm')} />
                    </Grid>
                    <Grid item xs sx={{ textAlign: 'right' }}>
                        <Chip icon={<ModeOfTravel />} label={formatMeters(entity.drivingDistance)} />
                    </Grid>
                </Grid>
            </Box>
            <Divider />
        </div>
    );
};

export default MarketListItem;
