import store from '../redux';

import { setIsLoggedIn } from '../redux/actions/auth';
import { setMessage } from '../redux/actions/toast';
import { fetchUserProfile } from '../redux/actions/users';

import { getCurrentUser, signIn } from './auth';

type PropsType = {
    email: string,
    password: string
};

// Errors must be handled when used
export default async function login({ email, password }: PropsType): Promise<void> {
    await signIn({ email, password });
    await getCurrentUser();
    store.dispatch(fetchUserProfile());
    store.dispatch(setIsLoggedIn(true));
    store.dispatch(setMessage('Välkommen till Logic Link!'));
}
