import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    fetchAllOfferedJobs,
    selectFetchingAllOfferedJobs,
    selectAllOfferedJobs
} from '../../../redux/actions/jobs';
import { OfferStatusType, JobForMarketListType } from '../../../types';
import { Table } from '../../CommonComponents';
import { FetchPropsType } from '../../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../../CommonComponents/Fetch/FetchRedux';
import { getOrderDate } from '../../../utils/order';
import { formatNumber } from '../../../utils/parsing';
import OfferStatusChip from '../../CommonComponents/OfferStatusChip';

const columns = [{
    name: 'Transportdatum',
    key: 'orderDate',
    getValue: getOrderDate
}, {
    name: 'Upphämtningsort',
    key: 'pickupCity'
}, {
    name: 'Leveransort',
    key: 'deliveryCity'
}, {
    name: 'Körsträcka',
    key: 'customDrivingDistance',
    type: 'number' as 'number',
    getValue: (r: { drivingDistance: number }) => formatNumber(Math.round(r.drivingDistance / 1000), 'km')
}, {
    name: 'Totalvikt',
    key: 'grossWeight',
    type: 'kg' as 'kg'
}, {
    name: 'Uppdragsgivare',
    key: 'tenantName'
}, {
    name: 'Status',
    key: 'offerStatus',
    getValue: ({ offerStatus }: { offerStatus: OfferStatusType }) => <OfferStatusChip offerStatus={offerStatus} />
}];

function OfferedJobs(props: FetchPropsType<JobForMarketListType[]>) {
    const { entity, isFetching } = props;
    const navigate = useNavigate();

    const onRowClick = React.useCallback(({ id }: { id: number | string }) => (
        navigate(`/jobs/${id}`)
    ), [navigate]);

    return (
        <Table
            name="offeredJobs"
            columns={columns}
            data={entity}
            onRowClick={onRowClick}
            emptyText={isFetching ? 'Hämtar intresseanmälda uppdrag...' : 'Ni har inga intresseanmälda uppdrag.'}
        />
    );
}

export default function OfferedJobsFetched() {
    return (
        <FetchRedux<JobForMarketListType[]>
            fetchEntity={fetchAllOfferedJobs()}
            selectEntity={selectAllOfferedJobs()}
            selectIsFetching={selectFetchingAllOfferedJobs()}
            Component={OfferedJobs}
            name="intresseanmälda uppdragen"
        />
    );
}
