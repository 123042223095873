import React from 'react';
import { PublishedWithChanges } from '@mui/icons-material';

import OrderFormFields from '../OrderFormComponents/OrderFormFields';
import DataImport, { DataType, ImportActionType } from '../../CommonComponents/SimpleDataImportXlsx/DataImport';
import { createOrder } from '../../../api/orders';
import getDrivingDistance from '../../../utils/getDrivingDistance';
import previewFields from './previewFields';
import importFormats from './importFormats';
import validations from './validations';
import defaultOrder from './defaultOrder';
import { fillObject } from '../../../utils';
import { getFakeCoordinate } from '../../../utils/geometry';

async function addDistancesAndFakeLocationCoordinates(entity: { [key: string]: any }) {
    let d;
    try {
        d = await getDrivingDistance(entity);
    } catch {
        d = { drivingDistance: null, drivingDuration: null };
    }
    return ({
        ...entity,
        fakePickupLat: getFakeCoordinate(entity.pickupLat),
        fakePickupLng: getFakeCoordinate(entity.pickupLng),
        fakeDeliveryLat: getFakeCoordinate(entity.deliveryLat),
        fakeDeliveryLng: getFakeCoordinate(entity.deliveryLng),
        drivingDistance: d.drivingDistance,
        drivingDuration: d.drivingDuration
    });
}

async function importOrders(orders: DataType[], publishDirectly: boolean) {
    const ordersWithDistances = await Promise.all(orders.map(async (order) => {
        const filledOrder = fillObject({ base: defaultOrder, fill: order });
        const completeOrder = await addDistancesAndFakeLocationCoordinates(filledOrder);
        return ({ ...completeOrder, publishDirectly });
    }));

    return createOrder(ordersWithDistances);
}

const importDraft: ImportActionType = (orders) => importOrders(orders, false);
const importPublish: ImportActionType = (orders) => importOrders(orders, true);

export default function OrderImport() {
    return (
        <DataImport
            entityName="uppdrag"
            importFormats={importFormats}
            validations={validations}
            previewFields={previewFields}
            defaultObject={defaultOrder}
            Form={OrderFormFields}
            importText="Publicera"
            importIcon={<PublishedWithChanges />}
            importAction={importPublish}
            importAltText="Spara som utkast"
            importAltAction={importDraft}
        />
    );
}
