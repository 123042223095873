import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Clear as ClearIcon, Send as SendIcon } from '@mui/icons-material';

import {
    FullForm,
    TextField,
    useChangeable
} from '../CommonComponents/Form';

import { createFeedback } from '../../api/feedback';
import toast from '../../utils/toast';

const Feedback: React.FC = () => {
    const [form, onChange] = useChangeable({ message: '' });

    const clear = React.useCallback(() => {
        onChange({ message: '' });
    }, []);

    const onSubmit = React.useCallback(async (feedbackForm) => {
        try {
            await createFeedback(feedbackForm);
            toast('Tack för dina synpunkter!');
            clear();
        } catch (e: any) {
            toast(e, 'Kunde inte skicka in synpunkter just nu, försök igen');
        }
    }, []);

    return (
        <FullForm
            title="Feedback"
            onSubmit={onSubmit}
            entity={form}
            submitText="Skicka"
            submitEndIcon={<SendIcon />}
            submitDisabled={!form.message}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Typography variant="h6" gutterBottom>
                        Vi vill bli bättre!
                    </Typography>
                    <Typography>
                        Hjälp oss att anpassa Logic Link efter dina behov genom att lämna dina synpunkter.
                    </Typography>
                    <ul>
                        <li>
                            <Typography>
                                Hur kan vi förenkla din arbetsdag?
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                Vad kan göras tydligare?
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                Vilka nya funktioner önskar du?
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                Har du hittat någon bugg?
                            </Typography>
                        </li>
                    </ul>
                    <Typography>
                        Beskriv din upplevelse så gott du kan så tar vi tag i det.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <div style={{ textAlign: 'right' }}>
                        <Button startIcon={<ClearIcon />} onClick={clear}>
                            Rensa
                        </Button>
                    </div>
                    <TextField
                        name="message"
                        label="Synpunkter"
                        value={form.message}
                        onChange={onChange}
                        multiline
                        minRows={6}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </FullForm>
    );
};

export default Feedback;
