import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import OrderArrow from '../CommonComponents/GoogleMap/OrderArrow';
import ApproximationCircle from '../CommonComponents/GoogleMap/ApproximationCircle';
import OrderMarker from '../CommonComponents/GoogleMap/OrderMarker';

type PropsType = {
    entityType: 'LL' | 'DHL' | 'CAP',
    pickupLat: number,
    pickupLng: number,
    deliveryLat: number,
    deliveryLng: number,
    approximate?: boolean,
    height?: number | string
};

const defaultCenter = { lat: 63, lng: 16 };
const defaultZoom = 5;
const mapOptions = {
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    clickableIcons: false,
    minZoom: 4,
    maxZoom: 20
};

export default function OrderMap(props: PropsType) {
    const {
        entityType,
        pickupLat,
        pickupLng,
        deliveryLat,
        deliveryLng,
        approximate,
        height = 500
    } = props;

    const [map, setMap] = React.useState<any>(null);

    React.useEffect(() => {
        if (map && pickupLat && pickupLng && deliveryLat && deliveryLng) {
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend({ lat: pickupLat, lng: pickupLng });
            bounds.extend({ lat: deliveryLat, lng: deliveryLng });
            window.setTimeout(() => map.fitBounds(bounds), 0);
        }
    }, [map, pickupLat, pickupLng, deliveryLat, deliveryLng]);

    return (
        <GoogleMap
            mapContainerStyle={{ height, overflow: 'visible' }}
            center={defaultCenter}
            zoom={defaultZoom}
            onLoad={setMap}
            options={mapOptions}
        >
            <OrderMarker
                entityType={entityType}
                markerType="pickup"
                lat={pickupLat}
                lng={pickupLng}
            />
            <OrderMarker
                entityType={entityType}
                markerType="delivery"
                lat={deliveryLat}
                lng={deliveryLng}
            />
            {approximate && (
                <>
                    <ApproximationCircle
                        lat={pickupLat}
                        lng={pickupLng}
                    />
                    <ApproximationCircle
                        lat={deliveryLat}
                        lng={deliveryLng}
                    />
                </>
            )}
            <OrderArrow
                entityType={entityType}
                isActive={false}
                pickupLat={pickupLat}
                pickupLng={pickupLng}
                deliveryLat={deliveryLat}
                deliveryLng={deliveryLng}
            />
        </GoogleMap>
    );
}
