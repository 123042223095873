import { formatRange } from '../../utils/parsing';

import { WatchlistType, WatchlistForListType } from '../../types';

export function formatPickup(w: WatchlistForListType | WatchlistType) {
    return w.pickupLocationLat && w.pickupLocationLng && w.pickupLocationRadius
        ? `${Math.round(w.pickupLocationRadius / 1000)} km från ${w.pickupLocationName || 'Namnlös plats'}`
        : '';
}

export function formatDelivery(w: WatchlistForListType | WatchlistType) {
    return w.deliveryLocationLat && w.deliveryLocationLng && w.deliveryLocationRadius
        ? `${Math.round(w.deliveryLocationRadius / 1000)} km från ${w.deliveryLocationName || 'Namnlös plats'}`
        : '';
}

export function formatType({ isJob, isCapacity, takesOffer }: WatchlistForListType | WatchlistType) {
    const types = [];
    if (isJob) {
        types.push(takesOffer ? 'Uppdrag (exkl. externa)' : 'Uppdrag');
    }
    if (isCapacity) {
        types.push('Kapacitet');
    }
    return types.join(' & ');
}

export function formatWatchlist(w: WatchlistForListType | WatchlistType) {
    return [
        formatType(w),
        formatPickup(w),
        formatDelivery(w),
        formatRange(w.minGrossWeight, w.maxGrossWeight, 'kg tot'),
        formatRange(w.minChargeableWeight, w.maxChargeableWeight, 'kg ldg'),
        formatRange(w.minVolume, w.maxVolume, 'm³'),
        formatRange(w.minLoadingMeters, w.maxLoadingMeters, 'flm'),
        formatRange(w.minPalletPlaces, w.maxPalletPlaces, 'ppl'),
        formatRange(w.minPallets, w.maxPallets, 'pallar'),
        // formatRange(w.minPieces, w.maxPieces, 'stycken'),
        w.groupName ? `I grupp ${w.groupName}` : '',
        w.hasListPrice ? 'Har riktpris' : '',
        w.takesOffer ? 'Tar intresseanmälningar' : ''
    ].filter((v) => !!v).join(', ');
}
