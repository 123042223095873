import React, { useContext } from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import FormContext from '../FormContext';
import useValidation from '../hooks/useValidation';
import { validateTime, validateRequired } from '../validations/validations';
import type { ValidationType } from '../validations/ValidationContext';
import { Input } from '../../../StyledComponents';

type PropsType = {
    name: string,
    label: string,
    value: string,
    onChange: (a: any) => void,
    required?: boolean,
    helperText?: string,
    sx?: { [key: string]: string | number }
};

const TimeField: React.FC<PropsType> = (props) => {
    const {
        name,
        label,
        value,
        onChange,
        required,
        helperText,
        ...restProps
    } = props;

    const formContext = useContext(FormContext);
    const isSubmitting = formContext?.isSubmitting;

    const validations = [
        ...(typeof required !== 'undefined'
            ? [validateRequired({ value: !required || value })]
            : []
        ),
        validateTime({ value })
    ];

    const errors = validations
        // useValidation registers the validation and returns isValid for filter
        // eslint-disable-next-line react-hooks/rules-of-hooks
        .filter((v: ValidationType) => useValidation({ name, type: v.type, isValid: v.isValid }));

    const errorMessage = errors.map((v: ValidationType) => v.errorText).join(' ');

    return (
        <TimePicker
            label={`${label}${required ? ' *' : ''}`}
            value={value}
            onChange={(newValue) => onChange({ [name]: newValue })}
            renderInput={(params: any) => (
                <Input
                    {...params}
                    {...restProps}
                    inputProps={{ ...params.inputProps, placeholder: 'tt:mm' }}
                    autoComplete="off"
                    error={!!errors.length}
                    helperText={errorMessage || helperText}
                />
            )}
            disabled={isSubmitting}
        />
    );
};

export default TimeField;
