import orders from '../slices/orders';
import type { RootStateType, ThunkDispatchType } from '../index';

import { getArchivedOrders, getOrders, getOrderStatusCount } from '../../api/orders';
import type { OrderStatusType } from '../../types';

export function fetchDraftedOrders() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(orders.actions.fetchDrafted());
            const data = await getOrders({ type: 'DRAFTED' });
            dispatch(orders.actions.draftedFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching orders, set redux error state and handle in errorBoundary.', e);
            dispatch(orders.actions.draftedFetched({ data: [] }));
        }
    };
}

export function fetchPublishedOrders() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(orders.actions.fetchPublished());
            const data = await getOrders({ type: 'PUBLISHED' });
            dispatch(orders.actions.publishedFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching orders, set redux error state and handle in errorBoundary.', e);
            dispatch(orders.actions.publishedFetched({ data: [] }));
        }
    };
}

export function fetchAcceptedOrders() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(orders.actions.fetchAccepted());
            const data = await getOrders({ type: 'ACCEPTED' });
            dispatch(orders.actions.acceptedFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching orders, set redux error state and handle in errorBoundary.', e);
            dispatch(orders.actions.acceptedFetched({ data: [] }));
        }
    };
}

export function fetchStartedOrders() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(orders.actions.fetchStarted());
            const data = await getOrders({ type: 'STARTED' });
            dispatch(orders.actions.startedFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching orders, set redux error state and handle in errorBoundary.', e);
            dispatch(orders.actions.startedFetched({ data: [] }));
        }
    };
}

export function fetchCompletedOrders() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(orders.actions.fetchCompleted());
            const data = await getOrders({ type: 'COMPLETED' });
            dispatch(orders.actions.completedFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching orders, set redux error state and handle in errorBoundary.', e);
            dispatch(orders.actions.completedFetched({ data: [] }));
        }
    };
}

export function fetchArchivedOrders() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(orders.actions.fetchArchived());
            const data = await getArchivedOrders();
            dispatch(orders.actions.archivedFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching orders, set redux error state and handle in errorBoundary.', e);
            dispatch(orders.actions.archivedFetched({ data: [] }));
        }
    };
}

export function fetchOrderStatusCount() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(orders.actions.fetchStatusCount());
            const data = await getOrderStatusCount();
            dispatch(orders.actions.statusCountFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching orders, set redux error state and handle in errorBoundary.', e);
        }
    };
}

export function setActiveOrderTab(orderStatus: OrderStatusType) {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(orders.actions.setActiveOrderTab({ data: orderStatus }));
        } catch (e) {
            console.log('ERROR fetching orders, set redux error state and handle in errorBoundary.', e);
        }
    };
}

export const selectDraftedOrders = () => (state: RootStateType) => state.orders.drafted;
export const selectFetchingDraftedOrders = () => (state: RootStateType) => state.orders.fetchingDrafted;

export const selectPublishedOrders = () => (state: RootStateType) => state.orders.published;
export const selectFetchingPublishedOrders = () => (state: RootStateType) => state.orders.fetchingPublished;

export const selectAcceptedOrders = () => (state: RootStateType) => state.orders.accepted;
export const selectFetchingAcceptedOrders = () => (state: RootStateType) => state.orders.fetchingAccepted;

export const selectStartedOrders = () => (state: RootStateType) => state.orders.started;
export const selectFetchingStartedOrders = () => (state: RootStateType) => state.orders.fetchingStarted;

export const selectCompletedOrders = () => (state: RootStateType) => state.orders.completed;
export const selectFetchingCompletedOrders = () => (state: RootStateType) => state.orders.fetchingCompleted;

export const selectArchivedOrders = () => (state: RootStateType) => state.orders.archived;
export const selectFetchingArchivedOrders = () => (state: RootStateType) => state.orders.fetchingArchived;

export const selectOrderStatusCount = () => (state: RootStateType) => state.orders.statusCount;
export const selectFetchingOrderStatusCount = () => (state: RootStateType) => state.orders.fetchingStatusCount;

export const selectActiveOrderTab = () => (state: RootStateType) => state.orders.activeOrderTab;
