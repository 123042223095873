import React from 'react';
import {
    Box,
    Chip,
    Grid,
    Typography
} from '@mui/material';

import { ModeOfTravel } from '@mui/icons-material';

import {
    formatDate,
    formatMeters,
    formatTime
} from '../../../utils/parsing';
import { OpenCapacityType, JobType } from '../../../types';
// import { COLOR_PURPLE, COLOR_TEAL, COLOR_WHITEISH, COLOR_YELLOW } from '../../../theme';

type PropsType = {
    entity: JobType | OpenCapacityType
};

const LINE_HEIGHT = 4;

type DateSpanPropsType = {
    firstDate: string | null,
    firstTime: string | null,
    lastDate: string | null,
    lastTime: string | null
}

function DateSpan({
    firstDate,
    firstTime,
    lastDate,
    lastTime
}: DateSpanPropsType) {
    return (
        <>
            <Typography variant="h6" display="inline" noWrap>
                {formatDate(firstDate)}
            </Typography>
            {firstTime && (
                <Typography variant="subtitle2" display="inline" noWrap sx={{ pl: 1 }}>
                    {formatTime(firstTime)}
                </Typography>
            )}
            {lastDate && (
                <>
                    <Typography variant="h6" display="inline" sx={{ pl: 1, pr: 1 }}>
                        -
                    </Typography>
                    <Typography variant="h6" display="inline" noWrap>
                        {formatDate(lastDate)}
                    </Typography>
                    {lastTime && (
                        <Typography variant="subtitle2" display="inline" noWrap sx={{ pl: 1 }}>
                            {formatTime(lastTime)}
                        </Typography>
                    )}
                </>
            )}
        </>
    );
}

// function getColor({ type }: { type: 'LL' | 'DHL' | 'CAP' }) {
//     switch (type) {
//         case 'LL':
//             return COLOR_TEAL;
//         case 'DHL':
//             return COLOR_YELLOW;
//         case 'CAP':
//             return COLOR_PURPLE;
//         default:
//             return COLOR_WHITEISH;
//     }
// }

export default function EntityDriveLine({ entity }: PropsType) {
    // const lineColor = React.useMemo(() => getColor(entity), [entity]);
    return (
        <Box sx={{ pl: 2, pr: 2, minWidth: 480 }}>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center" wrap="nowrap">
                <Grid item>
                    <Typography variant="h5">
                        {entity.pickupCity}
                    </Typography>
                </Grid>
                <Grid item textAlign="right">
                    <Typography variant="h5">
                        {entity.deliveryCity}
                    </Typography>
                </Grid>
            </Grid>

            <Box
                sx={{
                    flex: 1,
                    marginTop: 2,
                    marginBottom: 2,
                    marginLeft: 6,
                    marginRight: 6,
                    position: 'relative'
                }}
            >
                <Box
                    sx={{
                        height: LINE_HEIGHT,
                        marginLeft: 1,
                        marginRight: 1,
                        backgroundColor: 'primary.main', // lineColor,
                        position: 'relative'
                    }}
                />
                <Box
                    sx={{
                        height: LINE_HEIGHT * 3,
                        width: LINE_HEIGHT * 3,
                        borderRadius: (LINE_HEIGHT * 3) / 2,
                        position: 'absolute',
                        top: -LINE_HEIGHT,
                        left: 0,
                        backgroundColor: 'primary.main', // lineColor
                    }}
                />
                <Box
                    sx={{
                        height: LINE_HEIGHT * 3,
                        width: LINE_HEIGHT * 3,
                        borderRadius: LINE_HEIGHT * (3 / 2),
                        borderColor: 'primary.main', // lineColor,
                        borderStyle: 'solid',
                        borderWidth: LINE_HEIGHT,
                        backgroundColor: 'white',
                        position: 'absolute',
                        top: -LINE_HEIGHT,
                        right: 0,
                    }}
                />
            </Box>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center" wrap="nowrap">
                <Grid item xs>
                    <DateSpan
                        firstDate={entity.firstPickupDate}
                        firstTime={entity.firstPickupTime}
                        lastDate={entity.lastPickupDate}
                        lastTime={entity.lastPickupTime}
                    />
                </Grid>
                {entity.drivingDistance && (
                    <Grid item xs textAlign="center">
                        <Chip icon={<ModeOfTravel />} label={formatMeters(entity.drivingDistance)} />
                    </Grid>
                )}
                <Grid item xs textAlign="right">
                    <DateSpan
                        firstDate={entity.firstDeliveryDate}
                        firstTime={entity.firstDeliveryTime}
                        lastDate={entity.lastDeliveryDate}
                        lastTime={entity.lastDeliveryTime}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
