/* eslint-disable no-param-reassign */
// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GroupForListType, GroupTabType } from '../../types';

type GroupStateType = {
    my: GroupForListType[],
    other: GroupForListType[],
    fetchingMy: boolean,
    fetchingOther: boolean,
    activeGroupTab: GroupTabType
};

const initialState: GroupStateType = {
    my: [],
    other: [],
    fetchingMy: false,
    fetchingOther: false,
    activeGroupTab: 'MY'
};

const slice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        fetchMy: (state) => {
            state.fetchingMy = true;
        },
        fetchOther: (state) => {
            state.fetchingOther = true;
        },
        myFetched: (state, action: PayloadAction<{ data: GroupStateType['my'] }>) => {
            state.my = action.payload.data;
            state.fetchingMy = false;
        },
        otherFetched: (state, action: PayloadAction<{ data: GroupStateType['other'] }>) => {
            state.other = action.payload.data;
            state.fetchingOther = false;
        },
        setActiveGroupTab: (state, action: PayloadAction<GroupTabType>) => {
            state.activeGroupTab = action.payload;
        },
    }
});

export default slice;
