import groups from '../slices/groups';
import type { RootStateType, ThunkDispatchType } from '../index';
import {
    getGroups
} from '../../api/groups';
import { GroupForListType } from '../../types';
import { filterMarketEntities } from '../../utils/filter-market-entities';
import { pickLastByKey } from '../../utils';

export const {
    setActiveGroupTab
} = groups.actions;

export function fetchAllGroups() {
    return async (dispatch: ThunkDispatchType, getState: () => RootStateType) => {
        try {
            dispatch(groups.actions.fetchMy());
            dispatch(groups.actions.fetchOther());

            const data = await getGroups();
            const myGroups: GroupForListType[] = [];
            const otherGroups: GroupForListType[] = [];
            data.forEach((g: GroupForListType) => {
                (g.isOwner || g.isAccepted ? myGroups : otherGroups).push(g);
            });

            const myGroupsWithJobCount = myGroups.map((g: any) => {
                const myGroupMarketEntities = filterMarketEntities({
                    entities: [...getState().market.allOpenJobs, ...getState().market.allOpenCapacities],
                    filters: { isJob: true, isCapacity: true, groupId: g.id }
                });
                return ({
                    ...g,
                    publishedCount: myGroupMarketEntities.length,
                    lastPublishedDate: pickLastByKey(myGroupMarketEntities, 'publishedAt')
                });
            });

            dispatch(groups.actions.myFetched({ data: myGroupsWithJobCount }));
            dispatch(groups.actions.otherFetched({ data: otherGroups }));
        } catch (e) {
            console.log('ERROR fetching groups, set redux error state and handle in errorBoundary.', e);
            dispatch(groups.actions.myFetched({ data: [] }));
            dispatch(groups.actions.otherFetched({ data: [] }));
        }
    };
}

export const selectMyGroups = () => (state: RootStateType) => state.groups.my;
export const selectOtherGroups = () => (state: RootStateType) => state.groups.other;
export const selectFetchingMyGroups = () => (state: RootStateType) => state.groups.fetchingMy;
export const selectFetchingOtherGroups = () => (state: RootStateType) => state.groups.fetchingOther;
export const selectActiveGroupTab = () => (state: RootStateType) => state.groups.activeGroupTab;
