export function getUserLocation() {
    return ({
        latitude: 57.70887,
        longitude: 11.97456,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
    });
}

function degreesToRadians(degrees: number) {
    return (degrees * Math.PI) / 180;
}

export function getCoordinateDistance(
    { lat: lat1, lng: lng1 }: { lat: number, lng: number },
    { lat: lat2, lng: lng2 }: { lat: number, lng: number }
): number {
    const earthRadius = 6371200;

    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lng2 - lng1);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + (
        Math.sin(dLon / 2)
        * Math.sin(dLon / 2)
        * Math.cos(degreesToRadians(lat1))
        * Math.cos(degreesToRadians(lat2))
    );
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return earthRadius * c;
}

export function getFakeCoordinate(coordinate: number | string) {
    // Random number between -0.01 and 0.01
    return Number(coordinate) + (Math.random() * 2 - 1) / 100;
}
