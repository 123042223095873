import React from 'react';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import type { } from '@mui/lab/themeAugmentation';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import svLocale from 'date-fns/locale/sv';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppRouter from './AppRouter';
import Auth from '../Auth/Auth';
import Toast from '../Toast/Toast';
import UpgradePrompter from '../UpgradePrompter/UpgradePrompter';

import store from '../../redux';

import {
    COLOR_PURPLE,
    COLOR_SKIN,
    COLOR_TEAL
} from '../../theme';

const theme = createTheme({
    palette: {
        primary: {
            main: COLOR_TEAL
        },
        secondary: {
            main: COLOR_SKIN,
        },
        info: {
            main: COLOR_PURPLE
        }
    }
});

export default function App() {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={svLocale}>
                        <CssBaseline />
                        <Toast />
                        <UpgradePrompter />
                        <Auth>
                            <AppRouter />
                        </Auth>
                    </LocalizationProvider>
                </ThemeProvider>
            </Provider>
        </React.StrictMode>
    );
}
