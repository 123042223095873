import apiRequest from './api-request';

const resource = 'notifications';

export function getNotifications() {
    return apiRequest({
        method: 'GET',
        resource
    });
}

export function getNotificationsCount() {
    return apiRequest({
        method: 'GET',
        resource: `${resource}/count`,
    });
}

export function setReadNotifications(body: number[]) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/read`,
        body
    });
}

export function setUnreadNotifications(body: number[]) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/unread`,
        body
    });
}

export function deleteNotifications(body: number[]) {
    return apiRequest({
        method: 'DELETE',
        resource,
        body
    });
}
