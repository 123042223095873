import apiRequest from './api-request';
import type { OrderStatusType } from '../types';

const resource = 'orders';

export function getOrderStatusCount() {
    return apiRequest({
        method: 'GET',
        resource: 'order-status-count'
    });
}

export function getOrders({ type }: { type: OrderStatusType }) {
    return apiRequest({
        method: 'GET',
        resource: `${resource}?status=${type}`
    });
}

export function getArchivedOrders() {
    return apiRequest({
        method: 'GET',
        resource: `${resource}?archived=true`
    });
}

export function getOrdersForCapacitySuggestions() {
    return apiRequest({
        method: 'GET',
        resource: `${resource}?projection=capacity-suggestions`
    });
}

export function getOrder(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource,
        id
    });
}

export function createOrder(body: any) {
    return apiRequest({
        method: 'POST',
        resource,
        body
    });
}

export function updateOrder(id: number | string, body: any) {
    return apiRequest({
        method: 'PUT',
        resource,
        id,
        body
    });
}

export function acceptOrderOffer({ orderId, offerId }: { orderId: number | string, offerId: number | string }) {
    return apiRequest({
        method: 'POST',
        resource: `${resource}/${orderId}/offers/${offerId}/accept`
    });
}

export function rejectOrderOffer({ orderId, offerId }: { orderId: number | string, offerId: number | string }) {
    return apiRequest({
        method: 'POST',
        resource: `${resource}/${orderId}/offers/${offerId}/reject`
    });
}

export function setOrdersDrafted({ ids }: { ids: number[] }) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/draft`,
        body: { ids }
    });
}

export function setOrdersPublished({ ids }: { ids: number[] }) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/publish`,
        body: { ids }
    });
}

export function setOrdersStarted({ ids }: { ids: number[] }) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/start`,
        body: { ids }
    });
}

export function setOrdersCompleted({ ids }: { ids: number[] }) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/complete`,
        body: { ids }
    });
}

export function setOrdersArchived({ ids, archive }: { ids: number[], archive: boolean }) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/archive`,
        body: { ids, archive }
    });
}

export function setOrdersDeleted({ ids }: { ids: number[] }) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/delete`,
        body: { ids }
    });
}

export function updateOrderContact(
    { id, contactId, contactType }: {
        id: number,
        contactId: number,
        contactType: 'shipper-contact' | 'carrier-contact'
    }
) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/${id}/${contactType}/${contactId}`
    });
}
