import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { Edit as EditIcon, Search as SearchIcon } from '@mui/icons-material';

import {
    Block,
    Fetch,
    Header,
    List,
} from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';

import { getWatchlist, updateWatchlist } from '../../api/watchlists';
import toast from '../../utils/toast';

import type { FetchComponentPropsType } from '../CommonComponents/Fetch/Fetch';
import { WatchlistType } from '../../types';
import { useDispatch, useSelector } from '../../redux/hooks';

import {
    selectAllOpenJobs,
    fetchAllOpenJobs,
    fetchAllOpenCapacities,
    setMarketFiltersFromWatchlist,
    selectAllOpenCapacities
} from '../../redux/actions/market';
import FilterMap from '../CommonComponents/GoogleMap/FilterMap';
import { BoolField } from '../CommonComponents/Form';
import { filterMarketEntities } from '../../utils/filter-market-entities';
import { formatRange } from '../../utils/parsing';

import { formatPickup, formatDelivery, formatType } from './formatWatchlist';

function WatchlistDetailsView(props: FetchComponentPropsType<WatchlistType>) {
    const { entity, refetch, isFetching } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const allOpenJobs = useSelector(selectAllOpenJobs());
    const allOpenCapacities = useSelector(selectAllOpenCapacities());
    const [publishedCount, setPublishedCount] = React.useState<number | null>(null);

    React.useEffect(() => {
        // if (allOpenJobs.length === 0) {
        //     Sätt en flagga här också när det är kört
        // }
        setPublishedCount(filterMarketEntities({
            entities: [...allOpenJobs, ...allOpenCapacities],
            filters: entity
        }).length);
    }, [allOpenCapacities, allOpenJobs, entity]);

    const setInstantNotifications = React.useCallback(async ({ instantNotifications }) => {
        try {
            await updateWatchlist(entity.id, { instantNotifications });
            refetch();
            toast(`Direktnotiser ${instantNotifications ? 'aktiverade' : 'avstängda'}`);
        } catch (e) {
            toast(e, `Kunde inte ${instantNotifications ? 'aktivera' : 'stänga av'} direktnotiser, försök igen`);
        }
    }, [entity.id, refetch]);

    const setDailyNotifications = React.useCallback(async ({ dailyNotifications }) => {
        try {
            await updateWatchlist(entity.id, { dailyNotifications });
            refetch();
            toast(`Dagliga notiser ${dailyNotifications ? 'aktiverade' : 'avstängda'}`);
        } catch (e) {
            toast(e, `Kunde inte ${dailyNotifications ? 'aktivera' : 'stänga av'} dagliga notiser, försök igen`);
        }
    }, [entity.id, refetch]);

    const showResults = React.useCallback(() => {
        dispatch(setMarketFiltersFromWatchlist(entity));
        navigate('/market');
    }, [dispatch, entity, navigate]);

    return (
        <Container>
            <Header
                title="Bevakning"
                CustomComponent={(
                    <Button
                        variant="contained"
                        startIcon={<EditIcon />}
                        onClick={() => navigate(`/watchlists/edit/${entity.id}`)}
                    >
                        Redigera
                    </Button>
                )}
            />
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} lg={6}>
                    <Block
                        title={entity.name || 'Namnlös bevakning'}
                        ActionComponent={(
                            <Button variant="outlined" startIcon={<SearchIcon />} onClick={showResults}>
                                {`Visa ${publishedCount} träff${publishedCount === 1 ? '' : 'ar'}`}
                            </Button>
                        )}
                    >
                        <Grid container spacing={2} sx={{ p: 2 }}>
                            <Grid item xs={12} lg={6}>
                                <BoolField
                                    name="instantNotifications"
                                    label="Direktnotiser"
                                    helperText="Få notiser via push, sms eller e-post direkt när ett nytt uppdrag eller kapacitet dyker upp i bevakningen."
                                    value={entity.instantNotifications}
                                    variant="switch"
                                    onChange={setInstantNotifications}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <BoolField
                                    name="dailyNotifications"
                                    label="Dagliga notiser"
                                    helperText="Få en daglig summering av nya uppdrag och kapaciteter i bevakningen via push, sms eller e-post."
                                    variant="switch"
                                    value={entity.dailyNotifications}
                                    onChange={setDailyNotifications}
                                />
                            </Grid>
                        </Grid>
                        <List
                            data={[{
                                name: 'Typ',
                                value: formatType(entity)
                            }, {
                                name: 'Upphämtningsområde',
                                value: formatPickup(entity)
                            }, {
                                name: 'Leveransområde',
                                value: formatDelivery(entity)
                            }, {
                                name: 'Totalvikt [kg]',
                                value: formatRange(entity.minGrossWeight, entity.maxGrossWeight, 'kg')
                            }, {
                                name: 'FDR-vikt [kg]',
                                value: formatRange(entity.minChargeableWeight, entity.maxChargeableWeight, 'kg')
                            }, {
                                name: 'Volym [m³]',
                                value: formatRange(entity.minVolume, entity.maxVolume, 'm³')
                            }, {
                                name: 'Flakmeter',
                                value: formatRange(entity.minLoadingMeters, entity.maxLoadingMeters, 'flm')
                            }, {
                                name: 'Pallplatser',
                                value: formatRange(entity.minPalletPlaces, entity.maxPalletPlaces, 'st')
                            }, {
                                name: 'Pallar',
                                value: formatRange(entity.minPallets, entity.maxPallets, 'st')
                            // }, {
                            //     name: 'Stycken',
                            //     value: formatRange(entity.minPieces, entity.maxPieces, 'st')
                            }, {
                                name: 'Endast i grupp',
                                value: entity.groupName
                            }, {
                                name: 'Endast uppdrag med riktpris',
                                value: entity.hasListPrice,
                                type: 'bool'
                            }, {
                                name: 'Skapad',
                                value: entity.createdAt,
                                type: 'datetime'
                            }, {
                                name: 'Uppdaterad',
                                value: entity.updatedAt,
                                type: 'datetime'
                            }]}
                        />
                    </Block>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Paper padding={0} sx={{ height: '100%' }}>
                        <FilterMap
                            pickupLat={entity.pickupLocationLat}
                            pickupLng={entity.pickupLocationLng}
                            pickupRadius={entity.pickupLocationRadius}
                            deliveryLat={entity.deliveryLocationLat}
                            deliveryLng={entity.deliveryLocationLng}
                            deliveryRadius={entity.deliveryLocationRadius}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default function WatchlistDetails() {
    const { id } = useParams() as unknown as { id: number };
    const dispatch = useDispatch();

    const fetchWatchlist = React.useCallback(() => {
        dispatch(fetchAllOpenJobs(true)); // Only fetch if not recently fetched
        dispatch(fetchAllOpenCapacities(true)); // Only fetch if not recently fetched

        return getWatchlist(id);
    }, [dispatch, id]);

    return <Fetch<WatchlistType> fetch={fetchWatchlist} Component={WatchlistDetailsView} name="bevakningen" />;
}
