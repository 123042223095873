const googleApiKey = 'AIzaSyALztzBcfBZ76oOan6ShJ2ylt0PAqc7wKM';
const baseUrl = 'https://maps.google.com/maps/api/geocode/json?';

type AddressComponentType = {
    types: string[],
    short_name: string,
    long_name: string
};
type GeometryType = {
    location: {
        lat: number,
        lng: number
    }
};
type DataType = {
    address_components: AddressComponentType[],
    geometry: GeometryType,
    // formatted_address: string
};

function parseUrlParams(obj: {[key: string]: string}) {
    return Object.entries(obj).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
}

function getUrl(placeId: string) {
    const params = {
        key: googleApiKey,
        language: 'sv',
        place_id: placeId
    };
    return baseUrl + parseUrlParams(params);
}

const getAddressComponentName = (data: DataType, type: string) => {
    const component = data?.address_components.find((c: AddressComponentType) => c?.types.some((t) => t === type));
    return component?.long_name || component?.short_name || '';
};

function parseAddressData(data: DataType) {
    const street = [
        getAddressComponentName(data, 'route'),
        getAddressComponentName(data, 'street_number')
    ].filter((c) => !!c).join(' ');

    return ({
        lat: data?.geometry?.location?.lat,
        lng: data?.geometry?.location?.lng,
        street,
        zip: getAddressComponentName(data, 'postal_code'),
        city: getAddressComponentName(data, 'postal_town') || getAddressComponentName(data, 'locality'),
        country: getAddressComponentName(data, 'country')
    });
}

export default async function getLocationDetails(placeId?: string) {
    if (!placeId) {
        return null;
    }

    const response = await fetch(getUrl(placeId));
    const jsonResponse = await response.json();
    if (jsonResponse.status !== 'OK' || !jsonResponse.results?.length) {
        return null;
    }

    return parseAddressData(jsonResponse.results[0]);
}
