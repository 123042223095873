import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import {
    ChevronLeft,
    ChevronRight,
    Clear
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { Header, InfoText } from '../../CommonComponents';

import { Container, Paper } from '../../StyledComponents';
import GroupMemberImportTable from './GroupMemberImportTable';
import { createGroupInvitation, getGroup } from '../../../api/groups';
import { searchTenants } from '../../../api/tenants';
import { validateEmail, validatePhone } from '../../CommonComponents/Form/validations/validations';
import toastMessage from '../../../utils/toast';

export type RowType = {
    // From api:
    query: string,
    tenantId: number | null,
    name: string | null,
    orgNumber: string | null,
    isRegistered: boolean | null,
    // Is calculated:
    isMember: boolean,
    // From indata:
    canPublish: boolean,
    canInvite: boolean,
    invitedEmail: string,
    invitedPhone: string
};

type TenantSearchResult = {
    query: string,
    id: number | null,
    name: string | null,
    orgNumber: string | null,
    isRegistered: boolean | null
}

export function rowHasError(row: RowType) {
    const hasValidEmail = row.invitedEmail && validateEmail({ value: row.invitedEmail }).isValid;
    const hasValidPhone = row.invitedPhone && validatePhone({ value: row.invitedPhone }).isValid;
    return (!row.isRegistered && !hasValidEmail && !hasValidPhone);
}

export default function GroupMemberImport() {
    const { id: groupId } = useParams() as unknown as { id: number };
    const navigate = useNavigate();

    const [group, setGroup] = React.useState<any | null>(null);
    const [defaultCanInvite, setDefaultCanInvite] = React.useState(false);
    const [defaultCanPublish, setDefaultCanPublish] = React.useState(false);

    const [rawData, setRawData] = React.useState('');
    const [rows, setRows] = React.useState<null | RowType[]>(null);

    const [isPending, setIsPending] = React.useState(false);

    React.useEffect(() => {
        (async function () {
            setGroup(await getGroup(groupId));
        }());
    }, [groupId]);

    const loadData = React.useCallback(async () => {
        const queries = rawData
            .split(/\n|\t|,|;/)
            .map((row: string) => row.trim())
            .filter((row: string) => row.length);
        if (queries.length) {
            try {
                setIsPending(true);
                const results = await searchTenants(queries);
                setRows(
                    results.map((result: TenantSearchResult) => ({
                        query: result.query,
                        tenantId: result.id,
                        name: result.name,
                        orgNumber: result.orgNumber,
                        isRegistered: result.isRegistered,
                        isMember: group.tenants.some((t: { tenantId: number }) => t.tenantId === result.id),
                        canPublish: defaultCanPublish,
                        canInvite: defaultCanInvite,
                        invitedEmail: '',
                        invitedPhone: ''
                    }))
                );
                setIsPending(false);
            } catch (e) {
                console.log('error');
                toastMessage(e);
                setIsPending(false);
            }
        } else {
            toastMessage('Din indata är tom, fyll i innan du kan läsa in.');
        }
    }, [group, rawData, defaultCanPublish, defaultCanInvite]);

    const changeRow = React.useCallback(({ rowIndex, value }: { rowIndex: number, value: { [key: string]: any } }) => {
        setRows((r) => (r ? [
            ...r.slice(0, rowIndex),
            { ...r[rowIndex], ...value },
            ...r.slice(rowIndex + 1)
        ] : []));
    }, [setRows]);

    const removeRow = React.useCallback(({ rowIndex }: any) => {
        setRows((r) => (r ? [
            ...r.slice(0, rowIndex),
            ...r.slice(rowIndex + 1)
        ] : []));
    }, [setRows]);

    const reset = React.useCallback(() => {
        setRows(null);
    }, [setRows]);

    const runImport = React.useCallback(async () => {
        const importRows = rows?.filter((r) => !r.isMember) || [];
        if (importRows.length) {
            try {
                setIsPending(true);
                await createGroupInvitation(groupId, importRows);
                toastMessage(`Bjöd in ${importRows.length} företag till gruppen`);
                setIsPending(false);
                navigate(-1);
            } catch (e) {
                toastMessage(e);
                setIsPending(false);
            }
        } else {
            toastMessage('Det finns inga rader att importera');
        }
    }, [groupId, rows, navigate]);

    const errors = React.useMemo(() => {
        return rows?.filter((row) => rowHasError(row)).length ?? 0;
    }, [rows]);

    return (
        <Container>
            <Header
                title="Importera gruppmedlemmar"
            />

            {!rows ? (
                <Paper>
                    <Typography variant="h6">
                        Indata
                    </Typography>
                    {group?.isOwner && (
                        <>
                            <Typography>
                                Välj behörigheter för de företag du vill bjuda in till gruppen.
                            </Typography>
                            <Typography variant="caption">
                                Detta kan ändras för respektive företag i nästa steg.
                            </Typography>

                            <Grid container spacing={1} sx={{ mt: 1, mb: 2 }}>
                                <Grid item>
                                    <FormControlLabel
                                        label="Kan publicera"
                                        control={(
                                            <Checkbox
                                                checked={defaultCanPublish}
                                                onChange={({ target: { checked } }) => setDefaultCanPublish(checked)}
                                                disabled={isPending}
                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <Grid item>
                                    <FormControlLabel
                                        label="Kan se/bjuda in medlemmar"
                                        control={(
                                            <Checkbox
                                                checked={defaultCanInvite}
                                                onChange={({ target: { checked } }) => setDefaultCanInvite(checked)}
                                                disabled={isPending}
                                            />
                                        )}
                                    />
                                </Grid> */}
                            </Grid>
                        </>
                    )}

                    <Grid container spacing={2} sx={{ mb: 1 }} justifyContent="flex-end" alignItems="flex-end">
                        <Grid item xs={12} lg>
                            <Typography>
                                Klistra in namn eller orgnummer på de företag du vill bjuda in till gruppen.
                            </Typography>
                            <Typography variant="caption">
                                Separera företagen med ny rad, tabb, kommatecken eller semikolon.
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Button
                                variant="outlined"
                                startIcon={<Clear />}
                                onClick={() => setRawData('')}
                                disabled={!rawData || isPending}
                            >
                                Rensa
                            </Button>
                        </Grid>

                        <Grid item>
                            <LoadingButton
                                variant="contained"
                                endIcon={<ChevronRight />}
                                onClick={loadData}
                                disabled={!rawData || isPending}
                                loading={isPending}
                                loadingPosition="end"
                            >
                                Förhandsgrandska
                            </LoadingButton>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs>
                            <TextField
                                name="data"
                                label="Företag"
                                value={rawData}
                                onChange={({ target: { value } }) => setRawData(value)}
                                multiline
                                minRows={10}
                                variant="outlined"
                                fullWidth
                                error={!rawData}
                                disabled={isPending}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            ) : (
                <Paper padding={0}>
                    <Grid container sx={{ p: 2 }} justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h6">
                                Förhandsgrandska
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                startIcon={<ChevronLeft />}
                                onClick={reset}
                                disabled={isPending}
                            >
                                Ändra indata
                            </Button>
                            <LoadingButton
                                variant="contained"
                                endIcon={<ChevronRight />}
                                onClick={runImport}
                                sx={{ ml: 1 }}
                                disabled={!rows.filter((r) => !r.isMember).length || !!errors || isPending}
                                loading={isPending}
                                loadingPosition="end"
                            >
                                Importera
                            </LoadingButton>
                        </Grid>
                    </Grid>

                    <InfoText>
                        {rows.length === 0 && (
                            'Ändra indata för att läsa in på nytt.'
                        )}
                        {rows.length !== 0 && (
                            errors
                                ? `Det finns ${errors} fel, åtgärda innan du kan importera.`
                                : 'Kontrollera företagen i listan och tryck sedan på importera.'
                        )}
                    </InfoText>

                    {rows.length !== 0 && (
                        <GroupMemberImportTable
                            rows={rows}
                            changeRow={changeRow}
                            removeRow={removeRow}
                            isOwner={!!group?.isOwner}
                            tenants={group?.tenants || []}
                            isPending={isPending}
                        />
                    )}
                </Paper>
            )}
        </Container>
    );
}
