// CSV def RFC 4180 compliant
// eslint-disable-next-line max-len
// From: https://stackoverflow.com/questions/8493195/how-can-i-parse-a-csv-string-with-javascript-which-contains-comma-in-data
export function csvToArray({ text, separatorSymbol }: { text: string, separatorSymbol: string }) {
    let p = '';
    let row = [''];
    const ret = [row];
    let i = 0;
    let r = 0;
    let s = !0;
    let l;

    // eslint-disable-next-line no-restricted-syntax
    for (l of text) {
        if (l === '"') {
            if (s && l === p) {
                row[i] += l;
            }
            s = !s;
        } else if (l === separatorSymbol && s) {
            // eslint-disable-next-line no-multi-assign, no-plusplus
            l = row[++i] = '';
        } else if (l === '\n' && s) {
            if (p === '\r') {
                row[i] = row[i].slice(0, -1);
            }
            // eslint-disable-next-line no-multi-assign, no-plusplus
            row = ret[++r] = [l = '']; i = 0;
        } else {
            row[i] += l;
        }
        p = l;
    }
    return ret;
}

export function parseBool(v: string) {
    return v !== '';
}

export function parseNumber(v: string) {
    if (!(typeof v === 'string')) {
        return '';
    }
    const dotDecimalString = v.replaceAll(',', '.');
    const number = Number(dotDecimalString);
    return Number.isNaN(number)
        ? ''
        : number;
}

function dateParser(v: string, type: 'date' | 'datetime' | 'time') {
    if (!(typeof v === 'string')) {
        return '';
    }
    const date = new Date(v);
    if (Number.isNaN(date.getTime())) {
        return '';
    }
    if (type === 'date') {
        return date.toISOString().slice(0, 10);
    }
    if (type === 'datetime') {
        return date.toISOString().slice(0, 16).replace('T', ' ');
    }
    if (type === 'time') {
        return date.toISOString().slice(11, 16);
    }
    return '';
}

export function parseDate(v: string) {
    return dateParser(v, 'date');
}

export function parseDatetime(v: string) {
    return dateParser(v, 'datetime');
}

export function parseTime(v: string) {
    return dateParser(v, 'time');
}

export function parseString(v: string) {
    return typeof v === 'string' ? v : '';
}

type ParseDataThisType = {
    key: string,
    type: 'bool'
    | 'number'
    | 'string'
    | 'date'
    | 'datetime'
    | 'time'
}

export function parseData(this: ParseDataThisType, v: string) { // 'this' is just a fake ts-parameter
    let parse = (s: string): any => s;

    switch (this.type) {
        case 'bool':
            parse = parseBool;
            break;
        case 'number':
            parse = parseNumber;
            break;
        case 'date':
            parse = parseDate;
            break;
        case 'datetime':
            parse = parseDatetime;
            break;
        case 'time':
            parse = parseTime;
            break;
        case 'string':
            parse = parseString;
            break;
        default:
            break;
    }

    const trimmedValue = typeof v === 'string'
        ? v.trim()
        : '';

    return ({ [this.key]: parse(trimmedValue) });
}
