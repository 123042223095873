import React from 'react';
import {
    Button,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import { Verified, VerifiedOutlined } from '@mui/icons-material';

import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchUserProfile, selectUserProfile } from '../../redux/actions/users';
import { refreshSession, sendVerifyEmailCode, verifyEmail } from '../../utils/auth';
import toast from '../../utils/toast';
import { updateUserProfile } from '../../api/users';
import { PopupForm } from '../CommonComponents/Form';

export default function VerifyEmail() {
    const dispatch = useDispatch();
    const userProfile = useSelector(selectUserProfile());

    const [showEmailVerificationPopup, setShowEmailVerificationPopup] = React.useState(false);
    const [emailVerificationCode, setEmailVerificationCode] = React.useState('');

    const closeEmailVerificationPopup = React.useCallback(() => {
        setShowEmailVerificationPopup(false);
        setEmailVerificationCode('');
    }, []);

    const initiateEmailVerification = React.useCallback(async () => {
        try {
            setShowEmailVerificationPopup(true);
            await sendVerifyEmailCode();
        } catch (e: any) {
            setShowEmailVerificationPopup(false);
            if (e.code === 'LimitExceededException') {
                toast('För många verifieringskoder har skickat, försök igen senare.');
            } else {
                toast('Kunde inte skicka verifieringskoden, försök igen.');
            }
        }
    }, []);

    const commitEmailVerification = React.useCallback(async () => {
        try {
            await verifyEmail(emailVerificationCode);
            const cognitoUser = await refreshSession();
            await updateUserProfile({ emailVerified: cognitoUser?.attributes?.email_verified });
            dispatch(fetchUserProfile());
            closeEmailVerificationPopup();
            toast('Din e-postadress är nu verifierad.');
        } catch (e) {
            toast('Fel verifieringskod, försök igen.');
        }
    }, [closeEmailVerificationPopup, dispatch, emailVerificationCode]);

    return (
        userProfile?.emailVerified
            ? (
                <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    startIcon={<Verified />}
                    disableRipple
                    disableFocusRipple
                    disableElevation
                    sx={{ ':hover': { cursor: 'default' } }}
                >
                    E-post verifierad
                </Button>
            ) : (
                <>
                    <PopupForm
                        open={showEmailVerificationPopup}
                        title="Verifiera din e-postadress"
                        okLabel="Verifiera"
                        cancelLabel="Avbryt"
                        handleOk={commitEmailVerification}
                        handleClose={closeEmailVerificationPopup}
                        maxWidth="xs"
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>
                                    {`Fyll i koden som skickats till ${userProfile?.email}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="verificationCodeForEmail"
                                    label="Verifieringskod"
                                    value={emailVerificationCode}
                                    onChange={({ target: { value } }) => setEmailVerificationCode(value)}
                                    autoFocus
                                    required
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </PopupForm>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        startIcon={<VerifiedOutlined />}
                        onClick={initiateEmailVerification}
                    >
                        Verifiera e-post
                    </Button>
                </>
            )
    );
}
