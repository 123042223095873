import React from 'react';

import { useChangeable } from '../CommonComponents/Form';
import AuthRouter from './AuthRouter';
import Loading from './Loading';
import AccountLocked from './AccountLocked';
import PendingActivation from './PendingActivation';
import AuthContext, { AuthContextValueType, defaultAuthValues } from './AuthContext';

import { useSelector, useDispatch } from '../../redux/hooks';
import {
    setIsLoading,
    setIsLoggedIn,
    selectIsLoading,
    selectIsLoggedIn
} from '../../redux/actions/auth';
import { fetchUserProfile, selectUserProfile } from '../../redux/actions/users';
import { setMarketFilters, setMarketSettings } from '../../redux/actions/market';
import { getCurrentUser } from '../../utils/auth';

type PropsType = {
    children: React.ReactNode
};

const userProfilePollingInteval = 60 * 1000;

export default function Auth({ children }: PropsType) {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoading());
    const isLoggedIn = useSelector(selectIsLoggedIn());
    const userProfile = useSelector(selectUserProfile());

    const [values, onChange] = useChangeable(defaultAuthValues);
    const authContextValue = React.useMemo<AuthContextValueType>(() => ({
        values, onChange
    }), [onChange, values]);

    const timeoutId = React.useRef<number | null>(null);

    // Kolla initialt om användaren redan är inloggad, isf hämta userProfile
    React.useEffect(() => {
        (async () => {
            try {
                await getCurrentUser();
                dispatch(fetchUserProfile());
                dispatch(setIsLoggedIn(true));
                dispatch(setIsLoading(false));
            } catch (error) {
                dispatch(setIsLoggedIn(false));
                dispatch(setIsLoading(false));
            }
        })();
    }, [dispatch]);

    // Hämta userProfile varje minut om användaren är inloggad men ej accepterad eller rejectad.
    React.useEffect(() => {
        if (isLoggedIn && userProfile) {
            if (!userProfile.tenantIsAccepted || userProfile.tenantIsRejected) {
                // Hämta userProfile igen om en minut om inte är accepted eller är rejected
                timeoutId.current = window.setTimeout(() => {
                    dispatch(fetchUserProfile());
                }, userProfilePollingInteval);
            } else {
                // Annars sätt market settings utifrån userProfile (behöver bara göras en gång initialt)
                dispatch(setMarketFilters({
                    isJob: userProfile.marketSettingIsJob,
                    isCapacity: userProfile.marketSettingIsCapacity,
                    takesOffer: userProfile.marketSettingTakesOffer
                }));
                dispatch(setMarketSettings({
                    autoFocus: userProfile.marketSettingAutoFocus,
                    showPickupPin: userProfile.marketSettingShowPickupPin,
                    showDeliveryPin: userProfile.marketSettingShowDeliveryPin,
                    showDistanceLine: userProfile.marketSettingShowDistanceLine
                }));
            }
        }
        return () => { // Runs before every effect-run, and on unmount
            if (timeoutId.current) {
                window.clearTimeout(timeoutId.current);
                timeoutId.current = null;
            }
        };
    }, [dispatch, isLoggedIn, userProfile]);

    if (isLoading) {
        return <Loading />;
    }

    if (!isLoggedIn) {
        return (
            <AuthContext.Provider value={authContextValue}>
                <AuthRouter />
            </AuthContext.Provider>
        );
    }

    if (!userProfile) {
        return <Loading />;
    }

    if (userProfile.tenantIsRejected) {
        return <AccountLocked />;
    }

    if (userProfile.tenantIsAccepted === false) {
        return <PendingActivation />;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
}
