import offers from '../slices/offers';
import type { RootStateType, ThunkDispatchType } from '../index';

import {
    getOffersByOrderId,
    getOfferMessages,
    getActiveOffers
} from '../../api/offers';

export function fetchActiveOffers() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(offers.actions.fetchActiveOffers());
            const data = await getActiveOffers();
            dispatch(offers.actions.activeOffersFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching active offers, set redux error state and handle in errorBoundary.', e);
            dispatch(offers.actions.activeOffersFetched({ data: [] }));
        }
    };
}

export function fetchAllOffersByOrderId(orderId?: number | null | string) {
    if (!orderId) {
        throw Error('No orderId supplied for order');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(offers.actions.fetchAllByOrderId({ orderId }));
            const data = await getOffersByOrderId(orderId);
            dispatch(offers.actions.allByOrderIdFetched({ orderId, data }));
        } catch (e) {
            console.log('ERROR fetching offers for order, set redux error state and handle in errorBoundary.', e);
            dispatch(offers.actions.allByOrderIdFetched({ orderId }));
        }
    };
}

export function fetchOfferMessagesById(id?: number | null | string) {
    if (!id) {
        throw Error('No id supplied for offer');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(offers.actions.fetchMessagesById({ id }));
            const data = await getOfferMessages(id);
            dispatch(offers.actions.messagesByIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching offer messages, set redux error state and handle in errorBoundary.', e);
            dispatch(offers.actions.messagesByIdFetched({ id }));
        }
    };
}

export const selectActiveOffers = () => (state: RootStateType) => (
    state.offers.activeOffers
);

export const selectFetchingActiveOffers = () => (state: RootStateType) => (
    state.offers.fetchingActiveOffers
);

export const selectAllOffersByOrderId = (orderId?: number | null | string) => (state: RootStateType) => (
    orderId ? state.offers.allByOrderId[orderId] : []
);

export const selectFetchingAllOffersByOrderId = (orderId?: number | null | string) => (state: RootStateType) => (
    orderId ? state.offers.fetchingAllByOrderId[orderId] : false
);

export const selectOfferMessagesById = (id?: number | null | string) => (state: RootStateType) => (
    id ? state.offers.messagesById[id] : []
);
export const selectFetchingOfferMessagesById = (id?: number | null | string) => (state: RootStateType) => (
    id ? state.offers.fetchingMessagesById[id] : false
);
