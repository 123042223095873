import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import {
    ChevronLeft,
    ChevronRight,
    Clear,
    SaveAlt,
    UploadFile
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { Header, InfoText, Popup } from '..';

import { Container, Paper } from '../../StyledComponents';
import DataImportTable from './DataImportTable';
import DataImportDefaultField from './DataImportDefaultField';
import toastMessage, { formatMessage } from '../../../utils/toast';
import { ClientFileField } from '../Form';

type PropsType = {
    entityName: string,
    importData: (a: { [key: string]: any }[]) => Promise<any>,
    fields: {
        key: string,
        name: string,
        isRequired?: boolean,
        isUnique?: boolean,
        validate?: ({ value }: { value: string }) => { type: string; errorText: string; isValid: boolean; },
        defaultValue?: any,
        type?: 'bool'
    }[]
};

type SeparatorType = {
    name: string,
    symbol: string
};

const separators: SeparatorType[] = [{
    name: 'Kommatecken',
    symbol: ',',
}, {
    name: 'Semikolon',
    symbol: ';',
}, {
    name: 'Kolon',
    symbol: ':',
}, {
    name: 'Tabb',
    symbol: '\t',
}];

const MAX_NUM_ROWS = 50;

const DataImport: React.FC<PropsType> = (props) => {
    const {
        entityName,
        importData,
        fields
    } = props;

    const navigate = useNavigate();
    const [separator, setSeparator] = React.useState<SeparatorType>(separators[0]);
    const [rawData, setRawData] = React.useState('');
    const [rows, setRows] = React.useState<null | string[][]>(null);
    const [columns, setColumns] = React.useState(fields);
    const [isPending, setIsPending] = React.useState(false);

    const [successfulImport, setSuccessfulImport] = React.useState<boolean>(false);
    const [messages, setMessages] = React.useState<string[]>([]);

    const loadData = React.useCallback(async () => {
        setIsPending(true);
        try {
            const rs = rawData
                .split('\n')
                .map((row: string) => row.trim())
                .filter((row: string) => row.length)
                .map((row) => {
                    // Make sure array is of length columns.length, use defaults if not existing
                    const rowData = row.split(separator.symbol).map((r: string) => r.trim());
                    return columns.map((column, index) => (
                        rowData.at(index)
                        || column.defaultValue
                    ));
                });
            if (rs.length > MAX_NUM_ROWS) {
                toastMessage(`Max antal rader är ${MAX_NUM_ROWS}, endast de första har lästs in.`);
            }
            setRows(rs.slice(0, MAX_NUM_ROWS));
        } catch {
            console.log('error');
        }
        setIsPending(false);
    }, [rawData, separator, columns]);

    const changeColumn = React.useCallback(({ columnIndex, key }: any) => {
        const prevColumnIndex = columns.findIndex((c) => c.key === key);
        const firstIndex = Math.min(columnIndex, prevColumnIndex);
        const lastIndex = Math.max(columnIndex, prevColumnIndex);
        if (firstIndex !== lastIndex) {
            setColumns([
                ...columns.slice(0, firstIndex),
                columns[lastIndex],
                ...columns.slice(firstIndex + 1, lastIndex),
                columns[firstIndex],
                ...columns.slice(lastIndex + 1)
            ]);
        }
    }, [columns, setColumns]);

    const setDefaultValue = React.useCallback(({ columnIndex, value }: any) => {
        setColumns((cols: any) => ([
            ...cols.slice(0, columnIndex),
            {
                ...cols[columnIndex],
                defaultValue: value
            },
            ...cols.slice(columnIndex + 1)
        ]));
    }, [setColumns]);

    const changeRow = React.useCallback(({ rowIndex, row }: any) => {
        setRows((r) => (r ? [
            ...r.slice(0, rowIndex),
            row,
            ...r.slice(rowIndex + 1)
        ] : [[]]));
    }, [setRows]);

    const removeRow = React.useCallback(({ rowIndex }: any) => {
        setRows((r) => (r ? [
            ...r.slice(0, rowIndex),
            ...r.slice(rowIndex + 1)
        ] : [[]]));
    }, [setRows]);

    const errorRows = React.useMemo(() => (
        rows?.reduce((rowResult: number[], row, rowIndex) => ([
            ...rowResult,
            ...columns.reduce((columnResult: number[], column, columnIndex) => ([
                ...columnResult,
                ...(
                    (column.isRequired && !row[columnIndex])
                        || (column.validate ? !column.validate({ value: row[columnIndex] }).isValid : false)
                        || (!!column.isUnique && !!rows.some((r, rIndex) => (
                            rIndex !== rowIndex && r[columnIndex] === row[columnIndex]
                        )))
                        ? [rowIndex]
                        : []
                )
            ]), [])
        ]), [])
        || []
    ), [columns, rows]);

    const isValidDefaults = React.useMemo(() => {
        return columns.reduce((columnResult, column) => (
            columnResult && (
                column.validate ? column.validate({ value: column.defaultValue }).isValid : true
            )
        ), true);
    }, [columns]);

    const reset = React.useCallback(() => {
        setRows(null);
        setMessages([]);
        setSuccessfulImport(false);
    }, [setRows]);

    const closeMessagePopup = React.useCallback(() => {
        if (successfulImport) {
            navigate(-1);
        } else {
            setMessages([]);
            setSuccessfulImport(false);
        }
    }, [navigate, successfulImport]);

    const runImport = React.useCallback(async () => {
        if (rows) {
            try {
                setIsPending(true);
                const d = rows.map((row) => (
                    columns.reduce((result: { [key: string]: any }, column, columnIndex) => ({
                        ...result,
                        [column.key]: row[columnIndex]
                    }), {})
                ), []);

                const results = await importData(d);
                setIsPending(false);

                if (results.every((r: { ok: boolean }) => r.ok)) {
                    setSuccessfulImport(true);
                    setMessages(['Samtliga rader importerades.']);
                } else {
                    setSuccessfulImport(false);
                    const okCount = results.filter((r: { ok: boolean }) => r.ok).length;

                    const errorIndexes = results.reduce((acc: number[], curr: ({ ok: boolean }), index: number) => (
                        curr.ok ? acc : [...acc, index]
                    ), []);
                    setRows(rows.filter((_, index) => errorIndexes.some((ei: number) => ei === index)));

                    const errorMessages = results
                        .filter((r: { ok: boolean }) => !r.ok)
                        .map((r: { error: any }, index: number) => (
                            `Rad ${index + 1}: ${formatMessage(r.error, 'Fel vid import.')}`
                        ));
                    setMessages([
                        `${okCount} av ${d.length} rader importerades. Felmeddelanden:`,
                        ...errorMessages
                    ]);
                }
            } catch (e: any) {
                // Only happens or real errors, such as not admin etc
                // Remove imported rows
                toastMessage(e);
                setIsPending(false);
            }
        }
    }, [columns, rows, importData]);

    const downloadTemplate = React.useCallback(() => {
        const element = document.createElement('a');
        const file = new Blob(
            [columns.map((column) => column.name).join(separator.symbol), '\n'],
            {
                type: 'text/plain'
            }
        );
        element.href = URL.createObjectURL(file);
        element.download = `Logic_Link_importmall_${entityName}.csv`;
        document.body.appendChild(element);
        element.click();
    }, [entityName, columns, separator]);

    return (
        <Container>
            <Header
                title={`Importera ${entityName}`}
            />

            <Popup
                open={!!messages.length}
                title={successfulImport ? 'Import utförd' : 'Fel vid import'}
                handleOk={closeMessagePopup}
                handleClose={closeMessagePopup}
                cancelLabel="Stäng"
            >
                {messages.map((message, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Typography key={index}>
                        {message}
                    </Typography>
                ))}
            </Popup>

            {!rows ? (
                <Paper>
                    <Typography variant="h6">
                        Indata
                    </Typography>

                    <Typography>
                        Välj defaultvärden som används där indata saknar värden.
                    </Typography>
                    <Typography variant="caption">
                        Fält som är obligatoriska i indata är markerade med *, men de behöver inte ha något defaultvärde.
                        Unika fält kan inte ha något defaultvärde.
                    </Typography>

                    <Grid container spacing={1} sx={{ mt: 1, mb: 2 }}>
                        {columns.map((column: any, columnIndex: number) => (
                            <Grid item xs={12} sm={6} md={4} xl={2} key={column.key}>
                                <DataImportDefaultField
                                    column={column}
                                    columnIndex={columnIndex}
                                    setDefaultValue={setDefaultValue}
                                    isPending={isPending}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Grid container spacing={2} sx={{ mb: 1 }} justifyContent="flex-end" alignItems="flex-end">
                        <Grid item xs={12} xl>
                            <Typography>
                                {`Klistra in data, separera värden med valt skiljetecken och ${entityName} med ny rad.`}
                            </Typography>
                            <Typography variant="caption">
                                Om du vill kan du ladda ner och fylla i en csv-mall som du sedan klistrar här.
                            </Typography>
                        </Grid>

                        <Grid item>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                                <InputLabel id="separator-label">Skiljetecken</InputLabel>
                                <Select
                                    labelId="separator-label"
                                    id="separator"
                                    value={separator.name ?? ''}
                                    label="Skiljetecken"
                                    onChange={({ target: { value } }) => {
                                        const sep = separators.find((s) => s.name === value);
                                        if (sep) {
                                            setSeparator(sep);
                                        }
                                    }}
                                    disabled={isPending}
                                >
                                    {separators.map((s) => (
                                        <MenuItem key={s.name} value={s.name}>{s.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <Button
                                startIcon={<SaveAlt />}
                                variant="outlined"
                                onClick={downloadTemplate}
                                disabled={isPending}
                            >
                                Ladda ner mall
                            </Button>
                        </Grid>
                        <Grid item>
                            <ClientFileField
                                startIcon={<UploadFile />}
                                variant="outlined"
                                label="Ladda upp mall"
                                disabled={isPending}
                                onChange={(data) => {
                                    setRawData(data?.split('\n').slice(1).join('\n') || '');
                                }}
                            />
                        </Grid>

                        <Grid item>
                            <Button
                                variant="outlined"
                                startIcon={<Clear />}
                                onClick={() => setRawData('')}
                                disabled={isPending}
                            >
                                Rensa
                            </Button>
                        </Grid>

                        <Grid item>
                            <LoadingButton
                                variant="contained"
                                endIcon={<ChevronRight />}
                                onClick={loadData}
                                disabled={isPending || !rawData || !isValidDefaults}
                                loading={isPending}
                                loadingPosition="end"
                            >
                                Förhandsgrandska
                            </LoadingButton>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs>
                            <TextField
                                name="data"
                                label="Indata"
                                value={rawData}
                                onChange={({ target: { value } }) => setRawData(value)}
                                multiline
                                minRows={10}
                                variant="outlined"
                                fullWidth
                                error={!rawData}
                                disabled={isPending}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            ) : (
                <Paper padding={0}>
                    <Grid container sx={{ p: 2 }} justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h6">
                                Förhandsgrandska
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                startIcon={<ChevronLeft />}
                                onClick={reset}
                                disabled={isPending}
                            >
                                Ändra indata
                            </Button>
                            <LoadingButton
                                variant="contained"
                                endIcon={<ChevronRight />}
                                onClick={runImport}
                                sx={{ ml: 1 }}
                                disabled={isPending || !rows.length || !!errorRows.length}
                                loading={isPending}
                                loadingPosition="end"
                            >
                                Importera
                            </LoadingButton>
                        </Grid>
                    </Grid>

                    <InfoText>
                        {rows.length === 0 && (
                            'Ingen data, ändra indata för att läsa in på nytt.'
                        )}
                        {rows.length !== 0 && (
                            errorRows.length
                                ? `Det finns ${errorRows.length} fel, åtgärda nedan eller gå tillbaka och ändra indata.`
                                : `Klicka importera för att läsa in ${rows.length} rad${rows.length !== 1 ? 'er' : ''}.`
                        )}
                    </InfoText>

                    {rows.length !== 0 && (
                        <DataImportTable
                            rows={rows}
                            columns={columns}
                            changeColumn={changeColumn}
                            changeRow={changeRow}
                            removeRow={removeRow}
                            isPending={isPending}
                        />
                    )}
                </Paper>
            )}
        </Container>
    );
};

export default DataImport;
