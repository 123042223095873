import React from 'react';
import { Button, Grid } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

import { createOffer, deleteOffer } from '../../../api/jobs';
import { JobType, isJobFullType } from '../../../types';
import { orderStatus } from '../../../utils/status';
import { getOrderExpiryDate } from '../../../utils/order';
import { formatNumber } from '../../../utils/parsing';
import toast from '../../../utils/toast';
import { Popup } from '../../CommonComponents';
import {
    DateTimeField,
    NumberField,
    PopupForm,
    TextField,
    useChangeable
} from '../../CommonComponents/Form';

type PropsType = {
    job: JobType,
    refetch: () => void
};

function getDefaultOffer(job: JobType) {
    return job.offer ? ({
        price: job.offer.price,
        expiresAt: job.offer.expiresAt
    }) : ({
        price: job.listPrice,
        expiresAt: null,
        message: ''
    });
}

export default function JobOffer({ job, refetch }: PropsType) {
    const [showOfferPopup, setShowOfferPopup] = React.useState(false);
    const [showRemoveOfferPopup, setShowRemoveOfferPopup] = React.useState(false);
    const [newOffer, onChange] = useChangeable(getDefaultOffer(job));

    React.useEffect(() => {
        onChange(getDefaultOffer(job));
    }, [showOfferPopup, job, onChange]);

    const sendOffer = React.useCallback(async () => {
        try {
            await createOffer({ id: job.id, body: newOffer });
            toast('Intresseanmälan skickad!');
        } catch (e) {
            toast(e, 'Kunde inte skicka intresseanmälan, försök igen.');
        }
        refetch();
        setShowOfferPopup(false);
    }, [job.id, newOffer, refetch]);

    const removeOffer = React.useCallback(async () => {
        try {
            await deleteOffer({ id: job.id });
            toast('Intresseanmälan borttagen');
        } catch (e) {
            toast(e, 'Kunde inte ta bort intresseanmälan, försök igen.');
        }
        refetch();
        setShowOfferPopup(false);
        setShowRemoveOfferPopup(false);
    }, [job.id, refetch]);

    const action = React.useMemo(() => {
        if (isJobFullType(job) && job.status !== orderStatus.PUBLISHED) {
            return undefined;
        }

        if (getOrderExpiryDate(job) <= new Date()) {
            if (job.offer) {
                return (
                    <Button
                        color="primary"
                        startIcon={<Delete />}
                        variant="outlined"
                        aria-label="Ta bort"
                        onClick={() => setShowRemoveOfferPopup(true)}
                    >
                        Ta bort intresseanmälan
                    </Button>
                );
            }
            return undefined;
        }

        if (job.offer) {
            return (
                <Button
                    color="primary"
                    startIcon={<Edit />}
                    variant="outlined"
                    aria-label="Ändra"
                    onClick={() => setShowOfferPopup(true)}
                >
                    Ändra intresseanmälan
                </Button>
            );
        }

        return (
            <Button
                color="primary"
                variant="contained"
                aria-label="Anmäl intresse"
                onClick={() => setShowOfferPopup(true)}
            >
                Anmäl intresse
            </Button>
        );
    }, [job]);

    return (
        <>
            <Popup
                open={showRemoveOfferPopup}
                title="Ta bort er intresseanmälan?"
                body="Om anmälningstiden har löpt ut kommer ni inte kunna anmäla intresse igen."
                okLabel="Ja"
                cancelLabel="Nej"
                handleOk={removeOffer}
                handleClose={() => setShowRemoveOfferPopup(false)}
            />

            <PopupForm
                open={showOfferPopup}
                title="Anmäl intresse"
                okLabel="Skicka"
                cancelLabel="Avbryt"
                handleOk={sendOffer}
                handleClose={() => setShowOfferPopup(false)}
                handleDelete={job.offer ? removeOffer : undefined}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <NumberField
                            name="price"
                            label="Önskat pris"
                            helperText={job.listPrice ? `Riktpris: ${formatNumber(job.listPrice, 'SEK')}` : undefined}
                            value={newOffer.price}
                            onChange={onChange}
                            minValue={0}
                            maxValue={100000}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateTimeField
                            name="expiresAt"
                            label="Besvaras senast"
                            value={newOffer.expiresAt}
                            onChange={onChange}
                            minDateTime={new Date()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="message"
                            label="Meddelande"
                            value={newOffer.message}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>
            </PopupForm>

            {action}
        </>
    );
}
