import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from '@mui/material';
import React from 'react';

type PropsType = {
    label: string,
    value: string,
    options: {
        name: string,
        value: string | number
    }[]
    onChange: (e: SelectChangeEvent) => void,
    size?: 'small' | 'medium'
    smallFont?: boolean
};

const CommonSelect: React.FC<PropsType> = (props) => {
    const {
        label,
        value,
        options,
        onChange,
        size,
        smallFont
    } = props;

    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                label={label}
                onChange={onChange}
                size={size}
                sx={smallFont ? { fontSize: '0.9em' } : undefined}
            >
                {options.map((o) => (
                    <MenuItem
                        key={o.value}
                        value={o.value}
                        sx={smallFont ? { fontSize: '0.9em' } : undefined}
                    >
                        {o.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CommonSelect;
