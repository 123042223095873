import React from 'react';
import {
    Button,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import { Verified, VerifiedOutlined } from '@mui/icons-material';

import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchUserProfile, selectUserProfile } from '../../redux/actions/users';
import { PopupForm } from '../CommonComponents/Form';
import { refreshSession, sendVerifyPhoneCode, verifyPhone } from '../../utils/auth';
import { updateUserProfile } from '../../api/users';
import toast from '../../utils/toast';

export default function VerifyPhone() {
    const dispatch = useDispatch();
    const userProfile = useSelector(selectUserProfile());

    const [showPhoneVerificationPopup, setShowPhoneVerificationPopup] = React.useState(false);
    const [phoneVerificationCode, setPhoneVerificationCode] = React.useState('');

    const closePhoneVerificationPopup = React.useCallback(() => {
        setShowPhoneVerificationPopup(false);
        setPhoneVerificationCode('');
    }, []);

    const initiatePhoneVerification = React.useCallback(async () => {
        try {
            setShowPhoneVerificationPopup(true);
            await sendVerifyPhoneCode();
        } catch (e: any) {
            setShowPhoneVerificationPopup(false);
            if (e.code === 'LimitExceededException') {
                toast('För många verifieringskoder har skickat, försök igen senare.');
            } else {
                toast('Kunde inte skicka verifieringskoden, försök igen.');
            }
        }
    }, []);

    const commitPhoneVerification = React.useCallback(async () => {
        try {
            await verifyPhone(phoneVerificationCode);
            const cognitoUser = await refreshSession();
            await updateUserProfile({ phoneVerified: cognitoUser?.attributes?.phone_number_verified });
            dispatch(fetchUserProfile());
            closePhoneVerificationPopup();
            toast('Ditt mobilnummer är nu verifierat.');
        } catch (e) {
            toast('Fel verifieringskod, försök igen.');
        }
    }, [closePhoneVerificationPopup, dispatch, phoneVerificationCode]);

    return (
        userProfile?.phoneVerified
            ? (
                <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    startIcon={<Verified />}
                    disableRipple
                    disableFocusRipple
                    disableElevation
                    sx={{ ':hover': { cursor: 'default' } }}
                >
                    Mobil verifierad
                </Button>
            ) : (
                <>
                    <PopupForm
                        open={showPhoneVerificationPopup}
                        title="Verifiera ditt mobilnummer"
                        okLabel="Verifiera"
                        cancelLabel="Avbryt"
                        handleOk={commitPhoneVerification}
                        handleClose={closePhoneVerificationPopup}
                        maxWidth="xs"
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>
                                    {`Fyll i koden som skickats till ${userProfile?.phone}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="verificationCodeForPhone"
                                    label="Verifieringskod"
                                    value={phoneVerificationCode}
                                    onChange={({ target: { value } }) => setPhoneVerificationCode(value)}
                                    autoFocus
                                    required
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </PopupForm>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        startIcon={<VerifiedOutlined />}
                        onClick={initiatePhoneVerification}
                    >
                        Verifiera mobil
                    </Button>
                </>
            )
    );
}
