import React from 'react';
import {
    Box,
    Button,
    Grid
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

import { createOffer, deleteOffer } from '../../api/jobs';
import { JobType, isJobFullType } from '../../types';
import { getOfferStatus, offerStatus, orderStatus } from '../../utils/status';
import { getFormattedOrderExpiryDate, getOrderExpiryDate } from '../../utils/order';
import { formatNumber } from '../../utils/parsing';
import toast from '../../utils/toast';
import {
    Block,
    InfoText,
    List,
    Popup
} from '../CommonComponents';
import {
    DateTimeField,
    NumberField,
    PopupForm,
    TextField,
    useChangeable
} from '../CommonComponents/Form';
import { fetchOfferMessagesById } from '../../redux/actions/offers';
import { useDispatch } from '../../redux/hooks';

type PropsType = {
    job: JobType,
    refetch: () => void
};

function getDefaultOffer(job: JobType) {
    return job.offer ? ({
        price: job.offer.price,
        expiresAt: job.offer.expiresAt
    }) : ({
        price: job.listPrice,
        expiresAt: null,
        message: ''
    });
}

const JobOffer: React.FC<PropsType> = ({ job, refetch }) => {
    const dispatch = useDispatch();
    const [showOfferPopup, setShowOfferPopup] = React.useState(false);
    const [showRemoveOfferPopup, setShowRemoveOfferPopup] = React.useState(false);
    const [newOffer, onChange] = useChangeable(getDefaultOffer(job));

    React.useEffect(() => {
        onChange(getDefaultOffer(job));
    }, [showOfferPopup, job, onChange]);

    const sendOffer = React.useCallback(async () => {
        try {
            const createdOffer = await createOffer({ id: job.id, body: newOffer });
            dispatch(fetchOfferMessagesById(createdOffer.id));
            onChange({ message: '' });
            toast('Intresseanmälan skickad!');
        } catch (e) {
            toast(e, 'Kunde inte skicka intresseanmälan, försök igen.');
        }
        refetch();
        setShowOfferPopup(false);
    }, [dispatch, job.id, newOffer, onChange, refetch]);

    const removeOffer = React.useCallback(async () => {
        try {
            await deleteOffer({ id: job.id });
            toast('Intresseanmälan borttagen');
        } catch (e) {
            toast(e, 'Kunde inte ta bort intresseanmälan, försök igen.');
        }
        refetch();
        setShowOfferPopup(false);
        setShowRemoveOfferPopup(false);
    }, [job.id, refetch]);

    const title = React.useMemo(() => {
        if (isJobFullType(job) && ['ACCEPTED', 'STARTED', 'COMPLETED'].some((s) => s === job.status)) {
            return 'Ni har tilldelats uppdraget';
        }

        if (getOrderExpiryDate(job) <= new Date() && !job.offer) {
            return 'Anmälningstiden har löpt ut';
        }

        switch (getOfferStatus(job.offer)) {
            case offerStatus.REJECTED:
                return 'Er intresseanmälan har avböjts';
            case offerStatus.EXPIRED:
                return 'Er intresseanmälan har löpt ut';
            case offerStatus.PENDING:
                return 'Ni har anmält intresse';
            default:
                return 'Ni har inte anmält intresse';
        }
    }, [job]);

    const action = React.useMemo(() => {
        if (
            (isJobFullType(job) && job.status !== orderStatus.PUBLISHED)
            || !job.isOpen
        ) {
            return undefined;
        }

        if (getOrderExpiryDate(job) <= new Date()) {
            if (job.offer) {
                return (
                    <Button
                        color="primary"
                        startIcon={<Delete />}
                        variant="outlined"
                        aria-label="Ta bort"
                        onClick={() => setShowRemoveOfferPopup(true)}
                    >
                        Ta bort anmälan
                    </Button>
                );
            }
            return undefined;
        }

        if (job.offer) {
            return (
                <Button
                    color="primary"
                    startIcon={<Edit />}
                    variant="outlined"
                    aria-label="Ändra"
                    onClick={() => setShowOfferPopup(true)}
                >
                    Ändra
                </Button>
            );
        }

        return (
            <Button
                color="primary"
                variant="contained"
                aria-label="Anmäl intresse"
                onClick={() => setShowOfferPopup(true)}
            >
                Anmäl intresse
            </Button>
        );
    }, [job]);

    const data = React.useMemo(() => {
        const rejectedFields = [{
            name: 'Avböjdes',
            value: job.offer?.rejectedAt || '-',
            type: 'datetime' as 'datetime'
        }];
        const offerFields = [{
            name: 'Önskat pris',
            value: job.offer?.price || '-',
            type: 'currency' as 'currency'
        }, {
            name: 'Skickat av',
            value: job.offer?.sentByName || '-',
        }, {
            name: 'Skickat',
            value: job.offer?.sentAt || '-',
            type: 'datetime' as 'datetime'
        }, {
            name: 'Besvaras senast',
            value: job.offer?.expiresAt || '-',
            type: 'datetime' as 'datetime'
        }];
        const baseFields = [{
            name: 'Anmäl intresse senast',
            value: getFormattedOrderExpiryDate(job),
            type: 'datetime' as 'datetime'
        }, {
            name: 'Riktpris',
            value: job.listPrice,
            type: 'currency' as 'currency'
            // }, {
            //     name: 'Avgift',
            //     value: job.fee,
            //     type: 'currency' as 'currency'
        }];
        const fields = [];

        if (job.offer) {
            fields.push(...offerFields);
        }

        if (getOfferStatus(job.offer) === offerStatus.REJECTED) {
            fields.push(...rejectedFields);
        }

        return [...fields, ...baseFields];
    }, [job]);

    return (
        <>
            <Popup
                open={showRemoveOfferPopup}
                title="Ta bort er intresseanmälan?"
                body="Om anmälningstiden har löpt ut kommer ni inte kunna anmäla intresse igen."
                okLabel="Ja"
                cancelLabel="Nej"
                handleOk={removeOffer}
                handleClose={() => setShowRemoveOfferPopup(false)}
            />

            <PopupForm
                open={showOfferPopup}
                title="Anmäl intresse"
                okLabel="Skicka"
                cancelLabel="Avbryt"
                handleOk={sendOffer}
                handleClose={() => setShowOfferPopup(false)}
                handleDelete={job.offer ? removeOffer : undefined}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <NumberField
                            name="price"
                            label="Önskat pris"
                            helperText={job.listPrice ? `Riktpris: ${formatNumber(job.listPrice, 'SEK')}` : undefined}
                            value={newOffer.price}
                            onChange={onChange}
                            minValue={0}
                            maxValue={100000}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateTimeField
                            name="expiresAt"
                            label="Besvaras senast"
                            value={newOffer.expiresAt}
                            onChange={onChange}
                            minDateTime={new Date()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="message"
                            label="Meddelande"
                            value={newOffer.message}
                            onChange={onChange}
                        />
                    </Grid>
                    {/*
                    <Grid item xs={12}>
                        <Typography variant="caption">
                            {`Om ni tilldelas uppdraget kommer ni att faktureras en avgift från Logic Link på ${formatNumber(job.fee, 'SEK')}.`}
                        </Typography>
                    </Grid>
                    */}
                </Grid>
            </PopupForm>

            <Block
                title={title}
                ActionComponent={action}
            >
                {!isJobFullType(job) && !job.isOpen && (
                    <Box sx={{ pl: 2, pr: 2 }}>
                        <InfoText showBorder>
                            Uppdraget är inte längre aktivt, så ni kan inte ändra er intresseanmälan.
                        </InfoText>
                    </Box>
                )}
                <List
                    data={data}
                />
            </Block>
        </>
    );
};

export default JobOffer;
