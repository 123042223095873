import React from 'react';
import {
    Paper as MuiPaper
} from '@mui/material';

type PropsType = {
    padding?: number,
    variant?: 'elevation' | 'outlined'
    sx?: any,
    onClick?: any
};

const Paper: React.FC<PropsType> = ({
    children,
    padding = 2,
    variant = 'elevation',
    sx = {},
    onClick
}) => {
    return (
        <MuiPaper
            elevation={variant === 'elevation' ? 3 : 0}
            sx={{ ...sx, padding }}
            variant={variant}
            onClick={onClick}
        >
            {children}
        </MuiPaper>
    );
};

export default Paper;
