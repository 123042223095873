import { GroupInvitationType, GroupType } from '../types';
import apiRequest from './api-request';

const resource = 'groups';

export function getGroups() {
    return apiRequest({
        method: 'GET',
        resource
    });
}

export function getGroup(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource,
        id
    });
}

export function createGroup(body: GroupType) {
    return apiRequest({
        method: 'POST',
        resource,
        body
    });
}

export function updateGroup(id: number | string, body: GroupType) {
    return apiRequest({
        method: 'PUT',
        resource,
        id,
        body
    });
}

export function deleteGroup(id: number | string) {
    return apiRequest({
        method: 'DELETE',
        resource,
        id
    });
}

export function joinGroup(id: number | string) {
    return apiRequest({
        method: 'POST',
        resource: `${resource}/${id}/join`
    });
}

export function createGroupInvitation(id: number | string, body: GroupInvitationType | GroupInvitationType[]) {
    return apiRequest({
        method: 'POST',
        resource: `${resource}/${id}/tenants`,
        body
    });
}

export function updateGroupInvitation(id: number | string, body: GroupInvitationType) {
    return apiRequest({
        method: 'PUT',
        resource: `${resource}/${id}/tenants/${body.tenantId}`,
        body
    });
}

export function deleteGroupInvitation(id: number | string, tenantId: number) {
    return apiRequest({
        method: 'DELETE',
        resource: `${resource}/${id}/tenants/${tenantId}`,
    });
}
