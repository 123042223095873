import React from 'react';
import {
    Box,
    Grid,
    SvgIconProps
} from '@mui/material';
import {
    Paper,
    Title
} from '../StyledComponents';

type PropsType = {
    title?: string,
    contentPadding?: boolean,
    ActionComponent?: JSX.Element,
    IconComponent?: React.JSXElementConstructor<SvgIconProps>,
    titleVariant?: 'h6'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit'
    | undefined,
    variant?: 'elevation' | 'outlined'
};

const Block: React.FC<PropsType> = (props) => {
    const {
        title,
        children,
        ActionComponent,
        IconComponent,
        titleVariant = 'h6',
        contentPadding = false,
        variant = 'elevation'
    } = props;

    return (
        <Paper padding={0} variant={variant}>
            {(title || ActionComponent) && (
                <Grid
                    container
                    alignContent="space-between"
                    alignItems="flex-start"
                    sx={{ p: 2, pb: 1 }}
                >
                    {IconComponent && (
                        <Grid item sx={{ pt: '2px', pr: 1 }}>
                            <IconComponent
                                color="primary"
                            />
                        </Grid>
                    )}
                    <Grid item xs>
                        <Title variant={titleVariant}>
                            {title}
                        </Title>
                    </Grid>
                    <Grid item>
                        {ActionComponent}
                    </Grid>
                </Grid>
            )}
            <Box sx={contentPadding ? { p: 2, mt: -2 } : {}}>
                {children}
            </Box>
        </Paper>
    );
};

export default Block;
