import React from 'react';
import { TablePagination } from '@mui/material';

const count = 500;
const page = 10;
const handleChangePage = () => null;
const rowsPerPage = 10;
const handleChangeRowsPerPage = () => null;

const Pagination: React.FC = () => (
    <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />
);

export default Pagination;
