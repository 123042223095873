import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Dashboard from '../Dashboard/Dashboard';
import Notifications from '../Notifications/Notifications';
import Layout from '../Layout/Layout';

import Market from '../Market/Market';

import Jobs from '../Jobs/Jobs';
import JobDetails from '../Jobs/JobDetails';

import Orders from '../Orders/Orders';
import OrderDetails from '../Orders/OrderDetails';
import OrderForm from '../Orders/OrderForm';
import OrderImport from '../Orders/OrderImport/OrderImport';

import CapacityDetails from '../Capacities/CapacityDetails';
import CapacityForm from '../Capacities/CapacityForm';

// import Cars from '../Cars/Cars';
import Feedback from '../Feedback/Feedback';
import Groups from '../Groups/Groups';
import GroupDetails from '../Groups/GroupDetails';
import GroupForm from '../Groups/GroupForm';
import GroupMemberImport from '../Groups/GroupMemberImport/GroupMemberImport';
import Integrations from '../Integrations/Integrations';
import IntegrationTabs from '../Integrations/IntegrationTabs';
import IntegraitonSignup from '../Integrations/IntegrationSignup';
import IntegrationForm from '../Integrations/IntegrationForm';
import Invite from '../Invite/Invite';
// import Invoices from '../Invoices/Invoices';
import TenantProfile from '../TenantProfile/TenantProfile';
import TenantProfileForm from '../TenantProfile/TenantProfileForm';
import Users from '../Users/Users';
import UserDetails from '../Users/UserDetails';
import UserForm from '../Users/UserForm';
import UserImport from '../Users/UserImport';
import UserProfile from '../UserProfile/UserProfile';
import UserProfileForm from '../UserProfile/UserProfileForm';
import Watchlists from '../Watchlists/Watchlists';
import WatchlistDetails from '../Watchlists/WatchlistDetails';
import WatchlistForm from '../Watchlists/WatchlistForm';
import { NotFound } from '../CommonComponents';

const AppRouter: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<Dashboard />} />

                    <Route path="/market" element={<Market />} />

                    <Route path="/orders" element={<Orders />} />
                    <Route path="/orders/:id" element={<OrderDetails />} />
                    <Route path="/orders/create" element={<OrderForm />} />
                    <Route path="/orders/import" element={<OrderImport />} />
                    <Route path="/orders/edit/:id" element={<OrderForm />} />

                    <Route path="/jobs" element={<Jobs />} />
                    <Route path="/jobs/:id" element={<JobDetails />} />
                    <Route path="/jobs/:type/:id" element={<JobDetails />} />

                    <Route path="/capacities/:id" element={<CapacityDetails />} />
                    <Route path="/capacities/create" element={<CapacityForm />} />
                    <Route path="/capacities/edit/:id" element={<CapacityForm />} />

                    <Route path="/company-profile" element={<TenantProfile />} />
                    <Route path="/company-profile/edit" element={<TenantProfileForm />} />

                    <Route path="/users" element={<Users />} />
                    <Route path="/users/:id" element={<UserDetails />} />
                    <Route path="/users/create" element={<UserForm />} />
                    <Route path="/users/import" element={<UserImport />} />
                    <Route path="/users/edit/:id" element={<UserForm />} />

                    <Route path="/groups" element={<Groups />} />
                    <Route path="/groups/:id" element={<GroupDetails />} />
                    <Route path="/groups/:id/import-members" element={<GroupMemberImport />} />
                    <Route path="/groups/create" element={<GroupForm />} />
                    <Route path="/groups/edit/:id" element={<GroupForm />} />

                    {/* <Route path="/cars" element={<Cars />} /> */}
                    {/* <Route path="/invoices" element={<Invoices />} /> */}

                    <Route path="/integrations" element={<Integrations />} />
                    <Route path="/integrations/:id" element={<IntegrationTabs />} />
                    <Route path="/integrations/signup" element={<IntegraitonSignup />} />
                    <Route path="/integrations/edit/:id" element={<IntegrationForm />} />

                    <Route path="/user-profile" element={<UserProfile />} />
                    <Route path="/user-profile/edit" element={<UserProfileForm />} />

                    <Route path="/watchlists" element={<Watchlists />} />
                    <Route path="/watchlists/:id" element={<WatchlistDetails />} />
                    <Route path="/watchlists/create" element={<WatchlistForm />} />
                    <Route path="/watchlists/edit/:id" element={<WatchlistForm />} />

                    <Route path="/invite" element={<Invite />} />
                    <Route path="/feedback" element={<Feedback />} />

                    <Route path="/notifications" element={<Notifications />} />

                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouter;
