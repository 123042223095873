import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { FullForm, useChangeable } from '../CommonComponents/Form';

import {
    createIntegration,
    deleteIntegration,
    getIntegration,
    updateIntegration
} from '../../api/integrations';

import toast from '../../utils/toast';
import TransportorenOpterForm from './SettingsFormFields/TransportorenOpterForm';
import parseIntegraitonType, {
    TRANSPORTOREN_OPTER,
    TCHALLAND_OPTER,
    SNABBUD_OPTER
} from './integrationTypes';

export default function IntegrationForm() {
    const { id } = useParams();
    const [type, setType] = React.useState('');
    const [settings, onChangeSettings] = useChangeable({});
    const navigate = useNavigate();

    React.useEffect(() => {
        (async () => {
            if (id) {
                const integration = await getIntegration(id);
                setType(integration.type);
                onChangeSettings(integration.settings);
            }
        })();
    }, [id, onChangeSettings]);

    const onSubmit = React.useCallback(async () => {
        try {
            if (id) {
                await updateIntegration(id, { settings });
                toast('Integration uppdaterad');
                navigate(-1);
            } else {
                const createdIntegration = await createIntegration({ type, settings });
                toast('Integration skapad');
                navigate(`/integrations/${createdIntegration.id}`, { replace: true });
            }
        } catch (e: any) {
            toast(e, `Kunde inte ${id ? 'uppdatera' : 'skapa'} integrationen, försök igen`);
        }
    }, [id, settings, navigate, type]);

    const onDelete = React.useCallback(async () => {
        try {
            if (id) {
                await deleteIntegration(id);
                toast('Integration borttagen');
                navigate('/integrations', { replace: true });
            }
        } catch (e: any) {
            toast(e, 'Kunde inte ta bort integrationen, försök igen');
        }
    }, [id, navigate]);

    return (
        <FullForm
            title={`${id ? 'Redigera' : 'Skapa'} integration: ${parseIntegraitonType(type)}`}
            entity={settings}
            onSubmit={onSubmit}
            onDelete={id ? onDelete : undefined}
        >
            {(
                type === TRANSPORTOREN_OPTER
                || type === TCHALLAND_OPTER
                || type === SNABBUD_OPTER
            ) && (
                <TransportorenOpterForm
                    settings={settings}
                    onChangeSettings={onChangeSettings}
                />
            )}
        </FullForm>
    );
}
