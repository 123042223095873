import React from 'react';

import { Header } from '..';
import { Container } from '../../StyledComponents';
import DataImportInput from './DataImportInput';
import DataImportPreview from './DataImportPreview';

export type DataType = {
    [key: string]: any
};

// export type FieldType = {
//     key: string,
//     name: string,
//     helperText?: string,
//     exampleText?: string,
//     isRequired?: boolean,
//     defaultValue?: any,
//     type: 'bool'
//     | 'number'
//     | 'string'
//     | 'date'
//     | 'datetime'
//     | 'time'
//     | 'array'
//     | 'custom',
//     parseData?: (s: string, rowIndex?: number) => { [key: string]: any },
//     getPreview?: (o: DataType) => string | JSX.Element
// };

export type ValidationsType = (d: DataType) => {
    type: string,
    errorText: string,
    isValid: boolean
}[];

// export function getObjectWithPreviews({ fields, obj }: { fields: FieldType[], obj: DataType }) {
//     return fields.filter((field) => typeof field.getPreview === 'function')
//         .reduce((rowObject: DataType, field) => ({
//             ...rowObject,
//             ...(field.getPreview ? { [`${field.key}Preview`]: field.getPreview(rowObject) } : {})
//         }), obj);
// }

export type ImportActionType = (d: DataType[]) => Promise<
    { ok: boolean, error: Error, value: { id?: number } }[]
>;

export type ImportFormatType = {
    key: string,
    name: string,
    parseData: (d: { [key: string]: string }[]) => Promise<{
        [key: string]: string | number | boolean | null | number[] | undefined
    }[]>
    templateCells?: string[][]
}

export type PreviewFieldType = {
    key: string,
    name: string,
    type?: 'number' | 'date' | 'datetime' | 'bool' | 'y/n' | 'currency' | 'km' | 'kg',
    getValue: (d: {[key: string]: string }) => string | number | boolean | JSX.Element
};

type PropsType = {
    entityName: string,
    importFormats: ImportFormatType[],
    validations: ValidationsType,
    previewFields: PreviewFieldType[],
    defaultObject: DataType,
    Form: React.FC<{
        entity: DataType,
        onChange: (e: Partial<DataType>) => void,
        skipRequiredValidation: boolean
    }>,
    importText?: string,
    importIcon?: JSX.Element,
    importAction: ImportActionType,
    importAltText?: string,
    importAltIcon?: JSX.Element,
    importAltAction?: ImportActionType
};

// function getDefaultFieldValue(field: FieldType) {
//     switch (field.type) {
//         case 'bool':
//             return false;
//         case 'number':
//             return '';
//         case 'string':
//             return '';
//         case 'date':
//             return '';
//         case 'datetime':
//             return '';
//         case 'time':
//             return '';
//         case 'array':
//             return '';
//         case 'custom':
//             return '';
//         default:
//             return null;
//     }
// }

export default function DataImport(props: PropsType) {
    const {
        entityName,
        importFormats,
        validations,
        previewFields,
        defaultObject,
        Form,
        importText,
        importIcon,
        importAction,
        importAltText,
        importAltIcon,
        importAltAction
    } = props;

    const [rows, setRows] = React.useState<DataType[] | null>(null);

    const changeRows = React.useCallback((editedRows: DataType[]) => {
        setRows((rs) => ([
            ...(rs?.filter((r) => (
                !editedRows
                    .map((row) => row.importRowNumber)
                    .some((importRowNumber) => importRowNumber === r.importRowNumber)
            )) || []),
            ...editedRows.map((er) => ({ ...er, hasError: validations(er).some((v) => !v.isValid) }))
        ]));
    }, [validations]);

    const removeRows = React.useCallback((importRowNumbers: number[]) => {
        setRows((rs) => (
            rs?.filter((r) => !importRowNumbers.some((importRowNumber) => importRowNumber === r.importRowNumber))
            || []
        ));
    }, []);

    return (
        <Container>
            <Header title={`Importera ${entityName}`} />
            {!rows ? (
                <DataImportInput
                    entityName={entityName}
                    importFormats={importFormats}
                    validations={validations}
                    setRows={setRows}
                />
            ) : (
                <DataImportPreview
                    entityName={entityName}
                    previewFields={previewFields}
                    defaultObject={defaultObject}
                    Form={Form}
                    rows={rows}
                    changeRows={changeRows}
                    removeRows={removeRows}
                    setRows={setRows}
                    importText={importText}
                    importIcon={importIcon}
                    importAction={importAction}
                    importAltText={importAltText}
                    importAltIcon={importAltIcon}
                    importAltAction={importAltAction}
                />
            )}
        </Container>
    );
}
