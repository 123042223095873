import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Tabs, Tab } from '@mui/material';

import { Header, Table } from '../CommonComponents';
import {
    Container, Paper,
} from '../StyledComponents';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
    selectMyGroups,
    selectOtherGroups,
    selectActiveGroupTab,
    setActiveGroupTab,
    fetchAllGroups
} from '../../redux/actions/groups';
import { GroupTabType } from '../../types';
import { formatGroupRole, formatGroupType } from '../../utils/parsing';
import { fetchAllOpenJobs, fetchAllOpenCapacities } from '../../redux/actions/market';

const myGroupsColumns = [{
    name: 'Gruppnamn',
    key: 'name'
}, {
    name: 'Administratör',
    key: 'ownerTenantName'
}, {
    name: 'Grupptyp',
    key: 'deliveryCity',
    getValue: formatGroupType
}, {
    name: 'Din roll',
    key: 'customGroupRole',
    getValue: formatGroupRole
}, {
    name: 'Senaste publicerat',
    key: 'lastPublishedDate',
    type: 'datetime' as 'datetime',
}, {
    name: 'Publicerat',
    key: 'publishedCount',
    type: 'number' as 'number',
}];

const otherGroupsColumns = [{
    name: 'Gruppnamn',
    key: 'name'
}, {
    name: 'Administratör',
    key: 'ownerTenantName'
}, {
    name: 'Grupptyp',
    key: 'deliveryCity',
    getValue: formatGroupType
}, {
    name: 'Din roll',
    key: 'customGroupRole',
    getValue: formatGroupRole
}];

const columns = {
    MY: myGroupsColumns,
    OTHER: otherGroupsColumns
};

const tabs = [
    { type: 'MY', title: 'Mina grupper' },
    { type: 'OTHER', title: 'Övriga grupper' }
] as { type: GroupTabType, title: string }[];

const Groups: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const groups = {
        MY: useSelector(selectMyGroups()),
        OTHER: useSelector(selectOtherGroups())
    };

    const activeGroupTab = useSelector(selectActiveGroupTab());
    const changeTab = React.useCallback(async (event, groupType) => {
        dispatch(fetchAllGroups());
        dispatch(setActiveGroupTab(groupType));
    }, [dispatch]);

    React.useEffect(() => {
        changeTab(null, activeGroupTab);
        dispatch(fetchAllOpenJobs(true)); // only fetch if not recently fetched
        dispatch(fetchAllOpenCapacities(true)); // only fetch if not recently fetched
    }, [activeGroupTab, changeTab, dispatch]);

    return (
        <Container>
            <Header title="Grupper" addUrl="/groups/create" />

            <Tabs
                value={activeGroupTab}
                onChange={changeTab}
                aria-label="Grupper"
                variant="fullWidth"
            >
                {tabs.map((tab) => (
                    <Tab
                        key={tab.type}
                        value={tab.type}
                        label={`${tab.title} (${groups[tab.type]?.length ?? '-'})`}
                    />
                ))}
            </Tabs>

            <Paper padding={0}>
                <Table
                    name={`groups${activeGroupTab}`}
                    columns={columns[activeGroupTab]}
                    data={groups[activeGroupTab]}
                    onRowClick={({ id }: { id: number | string }) => {
                        navigate(`/groups/${id}`);
                    }}
                    emptyText={
                        activeGroupTab === 'MY'
                            ? 'Du är inte medlem i någon grupp'
                            : 'Det finns inga grupper att gå med i'
                    }
                />
            </Paper>
        </Container>
    );
};

export default Groups;
