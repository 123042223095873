import apiRequest from './api-request';

const resource = 'capacities';

export function getOpenCapacities() {
    return apiRequest({
        method: 'GET',
        resource: `open-${resource}`
    });
}

export function getOpenCapacity(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `open-${resource}`,
        id
    });
}

export function suggestCapacityOrders({ capacityId, orderIds }: { capacityId: number, orderIds: number[] }) {
    return apiRequest({
        method: 'POST',
        resource: `open-${resource}/${capacityId}/orders`,
        body: { orderIds }
    });
}

export function removeCapacityOrder({ capacityId, orderId }: { capacityId: number, orderId: number }) {
    return apiRequest({
        method: 'DELETE',
        resource: `${resource}/${capacityId}/orders/${orderId}`
    });
}

export function getCapacities() {
    return apiRequest({
        method: 'GET',
        resource
    });
}

export function getCapacity(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource,
        id
    });
}

export function createCapacity(body: any) {
    return apiRequest({
        method: 'POST',
        resource,
        body
    });
}

export function updateCapacity(id: number | string, body: any) {
    return apiRequest({
        method: 'PUT',
        resource,
        id,
        body
    });
}

export function deleteCapacity(id: number | string) {
    return apiRequest({
        method: 'DELETE',
        resource,
        id
    });
}

export function setCapacityPublished(id: number | string, isPublished: boolean) {
    return apiRequest({
        method: 'POST',
        resource: `${resource}/${id}/set-published`,
        body: { isPublished }
    });
}
