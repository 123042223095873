/* eslint-disable no-param-reassign */
// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    IntegrationForListType,
    IntegrationType,
    IntegrationMetaType,
    IntegrationLogForListType
} from '../../types';

type IntegrationsStateType = {
    all: IntegrationForListType[],
    fetchingAll: boolean,
    byId: {[key: number | string]: IntegrationType | undefined},
    fetchingById: {[key: number | string]: boolean},
    metaById: {[key: number | string]: IntegrationMetaType | undefined},
    fetchingMetaById: {[key: number | string]: boolean},
    logsById: {[key: number | string]: IntegrationLogForListType[] | undefined},
    fetchingLogsById: {[key: number | string]: boolean}
};

const initialState: IntegrationsStateType = {
    all: [],
    fetchingAll: false,
    byId: {},
    fetchingById: {},
    metaById: {},
    fetchingMetaById: {},
    logsById: {},
    fetchingLogsById: {}
};

const slice = createSlice({
    name: 'integrations',
    initialState,
    reducers: {
        fetchAll: (state) => {
            state.fetchingAll = true;
        },
        allFetched: (state, action: PayloadAction<{data: IntegrationsStateType['all']}>) => {
            state.all = action.payload.data;
            state.fetchingAll = false;
        },
        fetchById: (state, action) => {
            state.fetchingById[action.payload.id] = true;
        },
        byIdFetched: (state, action: PayloadAction<{ id: number | string, data?: IntegrationType }>) => {
            state.byId[action.payload.id] = action.payload.data;
            state.fetchingById[action.payload.id] = false;
        },
        fetchMetaById: (state, action) => {
            state.fetchingMetaById[action.payload.id] = true;
        },
        metaByIdFetched: (state, action: PayloadAction<{ id: number | string, data?: IntegrationMetaType }>) => {
            state.metaById[action.payload.id] = action.payload.data;
            state.fetchingMetaById[action.payload.id] = false;
        },
        fetchLogsById: (state, action) => {
            state.fetchingLogsById[action.payload.id] = true;
        },
        logsByIdFetched: (state, action: PayloadAction<{ id: number | string, data?: IntegrationLogForListType[] }>) => {
            state.logsById[action.payload.id] = action.payload.data;
            state.fetchingLogsById[action.payload.id] = false;
        }
    }
});

export default slice;
