import capacities from '../slices/capacities';
import type { RootStateType, ThunkDispatchType } from '../index';
import { getCapacities } from '../../api/capacities';

export function fetchAllCapacities() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(capacities.actions.fetchAll());
            const data = await getCapacities();
            dispatch(capacities.actions.allFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching capacities, set redux error state and handle in errorBoundary.', e);
            dispatch(capacities.actions.allFetched({ data: [] }));
        }
    };
}

export const selectAllCapacities = () => (state: RootStateType) => state.capacities.all;
export const selectFetchingAllCapacities = () => (state: RootStateType) => state.capacities.fetchingAll;
