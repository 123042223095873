import React from 'react';
import { FixedSizeList } from 'react-window';

import MarketListItem from './MarketListItem';

import { useDispatch, useSelector } from '../../redux/hooks';
import {
    selectFilteredMarketEntities,
    selectSelectedMarketEntity,
    setSelectedMarketEntity
} from '../../redux/actions/market';

import { InfoText } from '../CommonComponents';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { selectTableSort } from '../../redux/actions/tables';

export const MARKET_LIST_NAME = 'marketList';
export const marketListSortOptions = [
    { name: 'Upphämtningsort A-Ö', value: 'pickupCity-desc' },
    { name: 'Upphämtningsort Ö-A', value: 'pickupCity-asc' },
    { name: 'Leveransort A-Ö', value: 'deliveryCity-desc' },
    { name: 'Leveransort Ö-A', value: 'deliveryCity-asc' },
    { name: 'Upphämtningsdatum stigande', value: 'firstPickupDate-desc' },
    { name: 'Upphämtningsdatum fallande', value: 'firstPickupDate-asc' },
    { name: 'Leveransdatum stigande', value: 'lastDeliveryDate-desc' },
    { name: 'Leveransdatum fallande', value: 'lastDeliveryDate-asc' },
    { name: 'Vikt stigande', value: 'grossWeight-desc' },
    { name: 'Vikt fallande', value: 'grossWeight-asc' },
    { name: 'Flakmeter stigande', value: 'loadingMeters-desc' },
    { name: 'Flakmeter fallande', value: 'loadingMeters-asc' },
    { name: 'Körsträcka stigande', value: 'drivingDistance-desc' },
    { name: 'Körsträcka fallande', value: 'drivingDistance-asc' }
];

class ItemRenderer extends React.PureComponent<{ data: any, index: number, style: any }> {
    render() {
        const { data: { entities, selectedEntity, selectEntity }, index, style } = this.props;
        return (
            <MarketListItem
                key={entities[index].key}
                entity={entities[index]}
                isSelected={selectedEntity?.key === entities[index].key}
                onClick={() => (
                    entities[index].key === selectedEntity?.key
                        ? selectEntity(null)
                        : selectEntity(entities[index].key)
                )}
                style={style}
            />
        );
    }
}

const { compare } = new Intl.Collator('sv', {
    numeric: true,
    sensitivity: 'accent',
    ignorePunctuation: true
});

export default function MarketList() {
    const dispatch = useDispatch();
    const entities = useSelector(selectFilteredMarketEntities());
    const selectedEntity = useSelector(selectSelectedMarketEntity());

    const marketListSort = useSelector(selectTableSort(MARKET_LIST_NAME)) || {
        orderBy: marketListSortOptions[0].value.split('-')[0],
        orderDirection: marketListSortOptions[0].value.split('-')[1] === 'asc'
            ? 'asc'
            : 'desc'
    };

    const listRef = React.useRef<any>(null);
    const windowDimension = useWindowDimensions();
    const marketListRef = React.useRef<HTMLDivElement>(null);
    const marketListHeight = React.useMemo(() => {
        return marketListRef?.current?.offsetHeight;
    }, [windowDimension, marketListRef?.current?.offsetHeight]);

    const sortedEntities = React.useMemo(() => (
        entities.slice().sort((a, b) => compare(
            // @ts-ignore
            `${a[marketListSort.orderBy]}`.replace(/\s/g, ''),
            // @ts-ignore
            `${b[marketListSort.orderBy]}`.replace(/\s/g, '')
        ) * (marketListSort.orderDirection === 'asc' ? -1 : 1))
    ), [entities, marketListSort]);

    React.useEffect(() => {
        if (listRef?.current && selectedEntity) {
            const index = sortedEntities.findIndex((e) => e.key === selectedEntity.key);
            if (index !== -1) {
                listRef.current.scrollToItem(index);
            }
        }
    }, [sortedEntities, selectedEntity]);

    const selectEntity = React.useCallback((key: string | null) => {
        dispatch(setSelectedMarketEntity(key));
    }, [dispatch]);

    return (
        <div ref={marketListRef} style={{ height: '100%', overflowY: 'auto' }}>
            {
                // eslint-disable-next-line no-nested-ternary
                !marketListHeight ? null : (
                    entities.length
                        ? (
                            <FixedSizeList
                                ref={listRef}
                                itemData={{ entities: sortedEntities, selectedEntity, selectEntity }}
                                itemCount={entities.length}
                                itemSize={122}
                                width="100%"
                                height={marketListHeight}
                            >
                                {ItemRenderer}
                            </FixedSizeList>
                        ) : (
                            <InfoText>
                                Inga träffar, justera din sökning
                            </InfoText>
                        )
                )
            }
        </div>
    );
}
