import React from 'react';
import { TextField } from '@mui/material';

import FormContext from '../FormContext';
import useValidation from '../hooks/useValidation';
import { validateRequired } from '../validations/validations';
import type { ValidationType } from '../validations/ValidationContext';

type PropsType = {
    value: any,
    required?: boolean
    name: string,
    type?: string,
    inputProps?: { [key: string]: any },
    label: string,
    helperText?: string,
    submitOnEnter?: boolean,
    fullWidth?: boolean,
    size?: 'small' | 'medium',
    variant?: 'standard' | 'filled' | 'outlined'
};

export default function UncontrolledInputField(props: PropsType) {
    const {
        name,
        label,
        value,
        required,
        helperText,
        submitOnEnter,
        ...restProps
    } = props;

    const allValidations = typeof required !== 'undefined'
        ? [validateRequired({ value: !required || value })]
        : [];

    const errors = allValidations
        // useValidation registers the validation and returns isValid for filter
        // eslint-disable-next-line react-hooks/rules-of-hooks
        .filter((v: ValidationType) => useValidation({ name, type: v.type, isValid: v.isValid }));

    const errorMessage = errors.map((v: ValidationType) => v.errorText).join(' ');

    const formContext = React.useContext(FormContext);
    const isSubmitting = formContext?.isSubmitting;

    return (
        <TextField
            label={`${label}${required ? ' *' : ''}`}
            error={!!errors.length}
            helperText={errorMessage || helperText}
            autoComplete="off"
            disabled={isSubmitting}
            {...restProps}
        />
    );
}
