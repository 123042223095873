import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    Typography
} from '@mui/material';
import {
    ChevronLeft,
    ChevronRight,
    Delete,
    Edit,
    Save
} from '@mui/icons-material';

import { InfoText, Popup, Table } from '..';

import { Paper } from '../../StyledComponents';
import toastMessage, { formatMessage } from '../../../utils/toast';
import {
    DataType,
    ImportActionType,
    PreviewFieldType
} from './DataImport';
import { useChangeable } from '../Form';
import { fillObject } from '../../../utils';

type PropsType = {
    entityName: string,
    defaultObject: DataType,
    previewFields: PreviewFieldType[],
    Form: React.FC<{
        entity: DataType,
        onChange: (e: Partial<DataType>) => void,
        skipRequiredValidation: boolean
    }>,
    rows: any[],
    changeRows: (rows: DataType[]) => void,
    removeRows: (ids: number[]) => void,
    setRows: (r: any) => void,
    importText?: string,
    importIcon?: JSX.Element,
    importAction: ImportActionType,
    importAltText?: string,
    importAltIcon?: JSX.Element,
    importAltAction?: ImportActionType
};

export default function DataImportPreview(props: PropsType) {
    const {
        entityName,
        // fields,
        defaultObject,
        previewFields,
        Form,
        rows,
        changeRows,
        removeRows,
        setRows,
        importText,
        importIcon,
        importAction,
        importAltText,
        importAltIcon,
        importAltAction
    } = props;

    const navigate = useNavigate();
    const [defaultValues, setDefaultValues] = React.useState<DataType>(defaultObject);
    const [editedValues, setEditedValues] = useChangeable({});
    const [rowsToEdit, setRowsToEdit] = React.useState<DataType[] | null>(null);
    const [successfulImport, setSuccessfulImport] = React.useState<boolean>(false);
    const [messages, setMessages] = React.useState<string[]>([]);
    const [isPending, setIsPending] = React.useState(false);

    const errorCount = React.useMemo(() => rows.filter((r) => r.hasError).length, [rows]);

    const reset = React.useCallback(() => {
        setRows(null);
        setMessages([]);
        setSuccessfulImport(false);
    }, [setRows]);

    const closeMessagePopup = React.useCallback(() => {
        if (successfulImport && rows.length === 0) {
            navigate(-1);
        } else {
            setMessages([]);
            setSuccessfulImport(false);
        }
    }, [navigate, rows.length, successfulImport]);

    const runImport = React.useCallback(async (
        { data: rowsToImport, altImportAction }: { data: DataType[], altImportAction: boolean }
    ) => {
        if (rowsToImport && rowsToImport.length) {
            try {
                setIsPending(true);
                const rowNumbersToImport = rowsToImport.map((r) => r.importRowNumber);
                // Get results with importRowNumber
                const results = (
                    altImportAction && typeof importAltAction === 'function'
                        ? await importAltAction(rowsToImport)
                        : await importAction(rowsToImport)
                ).map((result, index) => ({
                    ...result,
                    importRowNumber: rowNumbersToImport[index]
                }));

                setIsPending(false);

                // Remove imported rows from preview:
                const importedRowNumbers = results.reduce((acc: number[], curr) => (
                    curr.ok ? [...acc, curr.importRowNumber] : acc
                ), []);
                setRows((rs: DataType[]) => (
                    rs.filter((r) => !importedRowNumbers.some((irn: number) => irn === r.importRowNumber))
                ));

                // Set result messages
                if (results.every((r) => r.ok)) {
                    setSuccessfulImport(true);
                    setMessages([
                        rowsToImport.length === 1
                            ? 'Den valda raden har importerats.'
                            : `Samtliga ${rowsToImport.length} rader har importerats.`
                    ]);
                } else {
                    setSuccessfulImport(false);
                    const okCount = results.filter((r) => r.ok).length;
                    const errorMessages = results
                        .reduce((acc: string[], curr) => (
                            !curr.ok ? [
                                ...acc,
                                `Rad ${curr.importRowNumber}: ${formatMessage(curr.error, 'Fel vid import.')}`
                            ] : acc
                        ), [
                            rowsToImport.length === 1
                                ? 'Raden kunde inte importeras. Felmeddelande:'
                                : `${okCount} av ${rowsToImport.length} rader importerades. Felmeddelanden:`
                        ]);
                    setMessages(errorMessages);
                }
            } catch (e: any) {
                console.log(e);
                // Only happens on real errors, such as not admin etc
                // Remove imported rowsToImport
                toastMessage(e);
                setIsPending(false);
            }
        }
    }, [importAltAction, importAction, setRows]);

    return (
        <Paper padding={0}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
                open={isPending}
            >
                <CircularProgress />
            </Backdrop>

            <Popup
                open={!!messages.length}
                title={successfulImport ? 'Import utförd' : 'Fel vid import'}
                handleOk={closeMessagePopup}
                handleClose={closeMessagePopup}
                cancelLabel="Stäng"
            >
                {messages.map((message, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Typography key={index}>
                        {message}
                    </Typography>
                ))}
            </Popup>

            {!!rowsToEdit?.length && (
                <Popup
                    open={!!rowsToEdit?.length}
                    title={`Redigera ${rowsToEdit?.length || ''} ${entityName}`}
                    okLabel="Spara"
                    cancelLabel="Avbryt"
                    handleOk={() => {
                        if (rowsToEdit?.length) {
                            changeRows(rowsToEdit.map((r) => fillObject({
                                base: { importRowNumber: r.importRowNumber, ...defaultValues },
                                fill: { ...r, ...editedValues }
                            })));
                        }
                        setRowsToEdit(null);
                    }}
                    handleClose={() => setRowsToEdit(null)}
                    fullWidth
                    maxWidth="xl"
                >
                    <>
                        {Array.isArray(rowsToEdit) && rowsToEdit.length > 1 && (
                            <Typography sx={{ mb: 2 }}>
                                {`Endast ifyllda fält kommer att sparas på dina valda ${entityName}.`}
                            </Typography>
                        )}
                        <Form
                            entity={{ ...defaultValues, ...editedValues }}
                            onChange={setEditedValues}
                            skipRequiredValidation={Array.isArray(rowsToEdit) && rowsToEdit.length > 1}
                        />
                    </>
                </Popup>
            )}

            <Grid container sx={{ p: 2 }} justifyContent="space-between">
                <Grid item>
                    <Typography variant="h6">
                        Förhandsgrandska
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        startIcon={<ChevronLeft />}
                        onClick={reset}
                        disabled={isPending}
                    >
                        Ändra indata
                    </Button>
                </Grid>
            </Grid>

            {!!errorCount && (
                <InfoText>
                    {`${errorCount} rad${errorCount > 1 ? 'er' : ''
                        } innehåller fel, åtgärda innan du importera de${errorCount > 1 ? 'm' : 'n'
                        }.`}
                </InfoText>
            )}

            {rows.length === 0 && !successfulImport && (
                <InfoText>
                    Ingen data, ändra indata för att läsa in på nytt.
                </InfoText>
            )}

            {rows.length !== 0 && (
                <Table
                    name={`dataImportPreview-${entityName}`}
                    columns={[
                        {
                            key: 'importRowNumber',
                            name: 'Rad',
                            type: 'number'
                        },
                        ...previewFields.map((field) => ({
                            key: `${field.key}Preview`,
                            name: field.name,
                            type: field.type,
                            getValue: field.getValue
                        }))
                    ]}
                    keyField="importRowNumber"
                    defaultOrderBy="importRowNumber"
                    disableSort
                    data={rows}
                    getRowStyle={({ hasError }) => (hasError ? { backgroundColor: '#FAA' } : {})}
                    emptyText="Det finns inga importrader"
                    batchActions={[{
                        name: 'Redigera',
                        icon: <Edit />,
                        action: async (rs: DataType[]) => {
                            setDefaultValues(
                                rs.length === 1
                                    ? fillObject({ base: defaultObject, fill: rs[0] })
                                    : defaultObject
                            );
                            setEditedValues(null);
                            setRowsToEdit(rs);
                        },
                    }, {
                        name: 'Ta bort',
                        icon: <Delete />,
                        action: async (rs: DataType[]) => {
                            removeRows(rs.map((r) => r.importRowNumber));
                        },
                        variant: 'contained',
                        color: 'error'
                    }, ...(importAltAction ? [{
                        name: importAltText || 'Alt. import',
                        icon: importAltIcon || <Save />,
                        action: (data: DataType[]) => (
                            runImport({ data, altImportAction: true })
                        ),
                        getDisabled: (rs: { hasError: boolean }[]) => rs.some((r) => r.hasError),
                        variant: 'contained' as 'contained'
                    }] : []), {
                        name: importText || 'Publicera',
                        icon: importIcon || <ChevronRight />,
                        action: (data: DataType[]) => (
                            runImport({ data, altImportAction: false })
                        ),
                        getDisabled: (rs: { hasError: boolean }[]) => rs.some((r) => r.hasError),
                        color: 'primary',
                        variant: 'contained'
                    }]}
                />
            )}
        </Paper>
    );
}
