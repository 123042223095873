import { Typography } from '@mui/material';
import React from 'react';
import { Title } from '../StyledComponents';

type PropsType = {
    title: string,
    text: string
};

const Description: React.FC<PropsType> = (props) => {
    const {
        title,
        text
    } = props;

    return (
        <div style={{
            border: '1px solid #DDD',
            borderRadius: 10,
            padding: '10px 20px 10px 20px'
        }}
        >
            <Title>
                {title}
            </Title>
            <Typography>
                {text || '-'}
            </Typography>
        </div>
    );
};

export default Description;
