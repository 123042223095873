import React from 'react';

import { Typography } from '@mui/material';
import {
    formatDateTime,
    formatLoadArray,
    formatNumber,
    formatTime
} from '../../../utils/parsing';

export default [{
    key: 'pickupDate',
    name: 'Upphämtningsdatum',
    // type: 'number',
    getValue: (d: any) => (
        <>
            {d.lastPickupDate && (
                <Typography variant="caption">
                    Från:
                </Typography>
            )}
            <Typography variant="body2">
                {d.firstPickupDate}
                {d.firstPickupTime ? `, ${formatTime(d.firstPickupTime)}` : ''}
            </Typography>

            {d.lastPickupDate && (
                <>
                    <Typography variant="caption">
                        Till:
                    </Typography>
                    <Typography variant="body2">
                        {d.lastPickupDate}
                        {d.lastPickupTime ? `, ${formatTime(d.lastPickupTime)}` : ''}
                    </Typography>
                </>
            )}
        </>
    )
}, {
    key: 'deliveryDate',
    name: 'Leveransdatum',
    // type: 'number',
    getValue: (d: any) => (
        <>
            {d.lastDeliveryDate && (
                <Typography variant="caption">
                    Från:
                </Typography>
            )}
            <Typography variant="body2">
                {d.firstDeliveryDate}
                {d.firstDeliveryTime ? `, ${formatTime(d.firstDeliveryTime)}` : ''}
            </Typography>

            {d.lastDeliveryDate && (
                <>
                    <Typography variant="caption">
                        Till:
                    </Typography>
                    <Typography variant="body2">
                        {d.lastDeliveryDate}
                        {d.lastDeliveryTime ? `, ${formatTime(d.lastDeliveryTime)}` : ''}
                    </Typography>
                </>
            )}
        </>
    )
}, {
    key: 'pickupLocation',
    name: 'Upphämtningsplats',
    getValue: (d: any) => (
        <>
            <Typography variant="body2">
                {d.pickupStreet}
            </Typography>
            <Typography variant="body2">
                {`${d.pickupZip} ${d.pickupCity}`}
            </Typography>
            {d.pickupQuery && (
                <Typography variant="caption" sx={{ mt: 1 }}>
                    {`Sökterm: ${d.pickupQuery}`}
                </Typography>
            )}
        </>
    )
}, {
    key: 'deliveryLocation',
    name: 'Leveransplats',
    getValue: (d: any) => (
        <>
            <Typography variant="body2">
                {d.deliveryStreet}
            </Typography>
            <Typography variant="body2">
                {`${d.deliveryZip} ${d.deliveryCity}`}
            </Typography>
            {d.deliveryQuery && (
                <Typography variant="caption" sx={{ mt: 1 }}>
                    {`Sökterm: ${d.deliveryQuery}`}
                </Typography>
            )}
        </>
    )
}, {
    key: 'load',
    name: 'Gods',
    getValue: (d: any) => (
        <>
            {formatLoadArray(d).map((l, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Typography key={index} noWrap variant="body2">
                    {l}
                </Typography>
            ))}
        </>
    )
}, {
    key: 'description',
    name: 'Beskrivning',
    getValue: (d: any) => (
        <>
            {d.publicDescription && (
                <>
                    <Typography variant="caption">
                        Publik:
                    </Typography>
                    <Typography variant="body2" sx={{ mb: d.privateDescription ? 1 : undefined }}>
                        {d.publicDescription}
                    </Typography>
                </>
            )}
            {d.privateDescription && (
                <>
                    <Typography variant="caption">
                        Privat:
                    </Typography>
                    <Typography variant="body2">
                        {d.privateDescription}
                    </Typography>
                </>
            )}
        </>
    )
}, {
    key: 'publishing',
    name: 'Publicering',
    getValue: (d: any) => {
        const groupsString = Array.isArray(d.groups) ? d.groups.map((g: any) => g.name).join(', ') : '';
        const values = [
            !!d.isPublic && !groupsString && 'Publiceras publikt',
            !!groupsString && `Publiceras i ${groupsString}`,
            !!d.showTenantName && 'Visa ert företagsnamn',
            !!d.listPrice && `Riktpris: ${formatNumber(Number(d.listPrice), 'SEK')}`,
            !!d.expiresAt && `Anmäl intresse senast: ${formatDateTime(d.expiresAt)}`
        ].filter(Boolean);
        return (
            <>
                {values.map((v, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Typography key={index} variant="body2" sx={{ mt: index ? 1 : undefined }}>
                        {v}
                    </Typography>
                ))}
            </>
        );
    }
}];
