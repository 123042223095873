import React from 'react';
import { Button, Grid } from '@mui/material';
import { NumberField, TextField } from '../../CommonComponents/Form';
import LocationPicker, { LocationType } from '../../CommonComponents/GoogleMap/LocationPicker';
import DraggablePositionMap from '../../CommonComponents/GoogleMap/DraggablePositionMap';

type PropsType = {
    partialKey: 'pickup' | 'delivery',
    partialName: string,
    entity: any,
    onChange: (a: any) => void,
    skipRequiredValidation?: boolean
}

export default function OrderDate({
    partialKey,
    partialName,
    entity,
    onChange,
    skipRequiredValidation
}: PropsType) {
    const [showAllFields, setShowAllFields] = React.useState(false);

    const latKey = React.useMemo(() => `${partialKey}Lat`, [partialKey]);
    const lngKey = React.useMemo(() => `${partialKey}Lng`, [partialKey]);
    const streetKey = React.useMemo(() => `${partialKey}Street`, [partialKey]);
    const zipKey = React.useMemo(() => `${partialKey}Zip`, [partialKey]);
    const cityKey = React.useMemo(() => `${partialKey}City`, [partialKey]);
    const countryKey = React.useMemo(() => `${partialKey}Country`, [partialKey]);
    const attentionKey = React.useMemo(() => `${partialKey}Attention`, [partialKey]);

    const hasError = React.useMemo(() => (
        (!skipRequiredValidation && !entity[cityKey])
        || ((!skipRequiredValidation && !entity[latKey]) || entity[latKey] < -90 || entity[latKey] > 90)
        || ((!skipRequiredValidation && !entity[lngKey]) || entity[lngKey] < -180 || entity[lngKey] > 180)
    ), [cityKey, entity, latKey, lngKey, skipRequiredValidation]);

    return (
        <>
            <Grid container spacing={2} sx={{ mb: 2 }} justifyContent="flex-end">
                <Grid item xs={12} lg>
                    <LocationPicker
                        error={hasError}
                        label={`Sök ${partialName}adress`}
                        defaultText={
                            [entity[streetKey], entity[zipKey], entity[cityKey], entity[countryKey]]
                                .filter(Boolean).join(', ')
                        }
                        onChange={(location: LocationType | null) => {
                            if (location) {
                                onChange({
                                    [latKey]: location.lat,
                                    [lngKey]: location.lng,
                                    [streetKey]: location.street,
                                    [zipKey]: location.zip,
                                    [cityKey]: location.city,
                                    [countryKey]: location.country
                                });
                            }
                        }}
                    />
                </Grid>
                <Grid item>
                    <Button
                        variant="text"
                        onClick={() => setShowAllFields(!showAllFields)}
                    >
                        {showAllFields ? 'Dölj detaljer' : 'Redigera detaljer'}
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mb: 2, display: showAllFields ? undefined : 'none' }}>
                <Grid item xs={12} lg={6}>
                    <NumberField
                        name={latKey}
                        label="Latitud"
                        value={entity[latKey]}
                        onChange={onChange}
                        minValue={-90}
                        maxValue={90}
                        required={!skipRequiredValidation}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <NumberField
                        name={lngKey}
                        label="Longitud"
                        value={entity[lngKey]}
                        onChange={onChange}
                        minValue={-180}
                        maxValue={180}
                        required={!skipRequiredValidation}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        name={attentionKey}
                        label="Referens"
                        value={entity[attentionKey]}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        name={streetKey}
                        label="Gata"
                        value={entity[streetKey]}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        name={zipKey}
                        label="Postnummer"
                        value={entity[zipKey]}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        name={cityKey}
                        label="Stad"
                        value={entity[cityKey]}
                        onChange={onChange}
                        required={!skipRequiredValidation}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        name={countryKey}
                        label="Land"
                        value={entity[countryKey]}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DraggablePositionMap
                        lat={entity[latKey]}
                        lng={entity[lngKey]}
                        onChange={({ lat, lng }: { lat: number, lng: number }) => (
                            onChange({ [latKey]: lat, [lngKey]: lng })
                        )}
                        height={350}
                    />
                </Grid>
            </Grid>
        </>
    );
}
