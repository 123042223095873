import store from '../redux';
import { setMessage } from '../redux/actions/toast';

/* eslint-disable max-len */
const messages: { [key: string]: string } = {
    'Not authorized.': 'Du saknar behörighet.',
    'An account with the given email already exists.': 'Ett konto med den angivna e-postadressen finns redan.',
    'You do not have permission to publish to the selected groups.': 'Du saknar behörighet att publicera till den här gruppen.',
    'Cannot delete current user': 'Du kan inte ta bort ditt eget konto',
    'Order can not be publish in inactive date interval.': 'Du kan inte publicera uppdrag i inaktuellt datumintervall.',
    'The selected user does not belong to your organization.': 'Den valda användaren tillhör inte er oranisation.',
    'You can only archive drafted and published orders without offers.': 'Du kan bara arkivera publicerade uppdrag utan intresseanmälningar.',
    'Only drafted or published orders not archived can be changed.': 'Det går endast att redigera opublicerade och publicerade uppdrag som inte är arkiverade.',
    'Only drafted or published orders not archived can be deleted.': 'Det går endast att ta bort opublicerade och publicerade uppdrag som inte är arkiverade.',
    'Status can not be changed on an archived order.': 'Du kan inte ändra status på arkiverade uppdrag.',
    'Orders with offers can not be changed.': 'Uppdrag med intresseanmälningar kan inte ändras.',
    'Du har inte behörighet att publicera till de valda grupperna.': 'Du har inte behörighet att publicera till de valda grupperna.'
};
/* eslint-enable max-len */

function translateMessage(message: string) {
    return messages[message] || null;
}

export function formatMessage(data: any, fallbackMessage?: string) {
    if (typeof data === 'string') {
        return data;
    }
    // Error from backend in english
    if (typeof data?.message === 'string' && translateMessage(data.message)) {
        return translateMessage(data.message);
    }
    if (typeof fallbackMessage === 'string') {
        return fallbackMessage;
    }
    return '';
}

export default async function toastMessage(data: any, fallbackMessage?: string) {
    store.dispatch(setMessage(null));
    window.setTimeout(() => {
        store.dispatch(setMessage(formatMessage(data, fallbackMessage)));
    }, 0);
}
