import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Clear as ClearIcon, Send as SendIcon } from '@mui/icons-material';

import {
    FullForm,
    EmailsField,
    useChangeable,
} from '../CommonComponents/Form';

import { sendInviteEmail } from '../../api/invites';
import toast from '../../utils/toast';
import { splitString } from '../../utils';

const Invite: React.FC = () => {
    const [form, onChange] = useChangeable({ emails: '' });

    const clear = React.useCallback(() => {
        onChange({ emails: '' });
    }, [onChange]);

    const onSubmit = React.useCallback(async ({ emails }) => {
        try {
            await sendInviteEmail({
                emails: splitString(emails)
            });
            clear();
            toast('Inbjudan skickad!');
        } catch (e: any) {
            toast(e, 'Kunde inte skicka inbjudan just nu, försök igen');
        }
    }, [clear]);

    return (
        <FullForm
            title="Bjud in"
            onSubmit={onSubmit}
            entity={form}
            submitText="Skicka"
            submitEndIcon={<SendIcon />}
            submitDisabled={!form.emails}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Typography variant="h6" gutterBottom>
                        Bjud in till Logic Link
                    </Typography>
                    <Typography>
                        Bredda ditt nätverk och dina möjligheter att dela uppdrag
                        genom att bjuda in företag du vill samarbeta med.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <div style={{ textAlign: 'right' }}>
                        <Button startIcon={<ClearIcon />} onClick={clear}>
                            Rensa
                        </Button>
                    </div>
                    <EmailsField
                        name="emails"
                        label="E-postadresser"
                        value={form.emails}
                        onChange={onChange}
                        multiline
                        minRows={6}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </FullForm>
    );
};

export default Invite;
