import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { formatNumber } from '../../utils/parsing';

type PropsType = {
    data: {
        title: string,
        value: number | null
    }[],
    small?: boolean,
    elevation?: number
};

const Numbers: React.FC<PropsType> = (props) => {
    const {
        data,
        small,
        elevation = 0
    } = props;

    return (
        <Grid container justifyContent="space-around">
            {data.map((d) => (
                <Grid item key={d.title}>
                    <Paper sx={{ p: small ? 1 : 3 }} elevation={elevation}>
                        <Typography variant={small ? 'h5' : 'h4'} color="primary" align="center">
                            {formatNumber(d.value)}
                        </Typography>
                        <Typography variant={small ? 'body2' : 'body1'} align="center">
                            {d.title}
                        </Typography>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};

export default Numbers;
