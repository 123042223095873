const publicApiUri = process.env.REACT_APP_PUBLIC_API_URI;

type RequestPropsType = {
    method: string,
    resource: string,
    id?: number | string,
    body?: any
};

export default async function apiRequest({
    method = 'GET',
    resource,
    id,
    body
}: RequestPropsType) {
    const endpoint = `${publicApiUri}/${resource}${id ? `/${id}` : ''}`;
    const options: any = {
        method,
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(body)
    };

    const response = await fetch(endpoint, options);
    const json = await response.json();

    if (response.status >= 400) {
        throw new Error(json);
    }

    return json;
}
