import apiRequest from './api-request';

const resource = 'feedback';

// eslint-disable-next-line import/prefer-default-export
export function createFeedback(body: { message: string }) {
    return apiRequest({
        method: 'POST',
        resource,
        body
    });
}
