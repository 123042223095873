import React from 'react';
import { Button } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { Popup } from '../CommonComponents';
import { resetLastAllOpenJobsFetch } from '../../redux/actions/market';
import { useDispatch } from '../../redux/hooks';
import toast from '../../utils/toast';
import {
    setOrdersDrafted,
    setOrdersPublished,
    setOrdersStarted,
    setOrdersCompleted
} from '../../api/orders';

type PropsType = {
    data?: any,
    refetch: () => void
};

export default function OrderStatusAction(props: PropsType) {
    const { data, refetch } = props;
    const dispatch = useDispatch();

    const [showSetStatusPopup, setShowSetStatusPopup] = React.useState(false);

    const buttonProps = React.useMemo(() => {
        switch (data.status) {
            case 'DRAFTED':
                return ({
                    text: 'Publicera',
                    endIcon: <ChevronRight />,
                    variant: 'contained' as 'contained',
                    confirmTitle: 'Vill du verkligen publicera uppdraget?',
                    confirmText: 'Uppdraget kommer att visas på marknadsplatsen för samtliga användare, eller för de grupper du valt.',
                    commitAction: async (): Promise<void> => {
                        try {
                            const result = (await setOrdersPublished({ ids: [data.id] })).at(0);
                            if (result.ok) {
                                // Refetch allJobs to get correct group/watchlist stats
                                dispatch(resetLastAllOpenJobsFetch());
                                toast('Uppdraget publicerat');
                            } else {
                                toast(result.error?.message || 'Kunde inte publicera, kontrollera uppdraget');
                            }
                        } catch (e) {
                            toast(e, 'Kunde inte ändra status just nu, försök igen.');
                        }
                        refetch();
                        setShowSetStatusPopup(false);
                    }
                });
            case 'PUBLISHED':
                return ({
                    text: 'Avpublicera',
                    startIcon: <ChevronLeft />,
                    variant: 'outlined' as 'outlined',
                    confirmTitle: 'Vill du verkligen avpublicera uppdraget?',
                    confirmText: 'Uppdraget kommer inte längre att visas på marknadsplaten.',
                    commitAction: async (): Promise<void> => {
                        try {
                            const result = (await setOrdersDrafted({ ids: [data.id] })).at(0);
                            if (result.ok) {
                                // Refetch allJobs to get correct group/watchlist stats
                                dispatch(resetLastAllOpenJobsFetch());
                                toast('Uppdraget avpublicerat');
                            } else {
                                toast(result.error?.message || 'Kunde inte avpublicera, kontrollera uppdraget');
                            }
                        } catch (e) {
                            toast(e, 'Kunde inte ändra status just nu, försök igen.');
                        }
                        refetch();
                        setShowSetStatusPopup(false);
                    }
                });
            case 'ACCEPTED':
                return ({
                    text: 'Bekräfta',
                    endIcon: <ChevronRight />,
                    variant: 'contained' as 'contained',
                    confirmTitle: 'Vill du verkligen bekräfta uppdraget?',
                    confirmText: 'Motparten kommer att få notiser om att du bekräftat.',
                    commitAction: async (): Promise<void> => {
                        try {
                            const result = (await setOrdersStarted({ ids: [data.id] })).at(0);
                            if (result.ok) {
                                toast('Uppdraget bekräftat');
                            } else {
                                toast(result.error?.message || 'Kunde inte bekräfta, kontrollera uppdraget');
                            }
                        } catch (e) {
                            toast(e, 'Kunde inte ändra status just nu, försök igen.');
                        }
                        refetch();
                        setShowSetStatusPopup(false);
                    }
                });
            case 'STARTED':
                return ({
                    text: 'Klarmarkera',
                    endIcon: <ChevronRight />,
                    variant: 'contained' as 'contained',
                    confirmTitle: 'Vill du verkligen klarmarkera uppdraget?',
                    confirmText: 'Motparten kommer att få notiser om att du klarmarkerat.',
                    commitAction: async (): Promise<void> => {
                        try {
                            const result = (await setOrdersCompleted({ ids: [data.id] })).at(0);
                            if (result.ok) {
                                toast('Uppdraget klarmarkerat');
                            } else {
                                toast(result.error?.message || 'Kunde inte klarmarkera, kontrollera uppdraget');
                            }
                        } catch (e) {
                            toast(e, 'Kunde inte ändra status just nu, försök igen.');
                        }
                        refetch();
                        setShowSetStatusPopup(false);
                    }
                });
            default:
                return null;
        }
    }, [data.id, data.status, dispatch, refetch]);

    return (
        <>
            <Popup
                open={showSetStatusPopup}
                title={buttonProps?.confirmTitle || ''}
                body={buttonProps?.confirmText}
                okLabel="Ja"
                cancelLabel="Nej"
                handleOk={buttonProps?.commitAction}
                handleClose={() => setShowSetStatusPopup(false)}
            />
            <Button
                variant={buttonProps?.variant}
                startIcon={buttonProps?.startIcon}
                endIcon={buttonProps?.endIcon}
                onClick={() => setShowSetStatusPopup(true)}
                sx={{ ml: 1 }}
            >
                {buttonProps?.text}
            </Button>
        </>
    );
}
