/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { TenantProfileType } from '../../types';

export const defaultTenantProfile = {
    email: '',
    phone: '',
    website: '',
    billingEmail: '',
    billingMark: '',
    billingStreet: '',
    billingZip: '',
    billingCity: '',
};

type TenantProfileStateType = {
    profile: TenantProfileType | null,
    fetchingProfile: boolean,
};

const initialState: TenantProfileStateType = {
    profile: null,
    fetchingProfile: false
};

const slice = createSlice({
    name: 'tenants',
    initialState,
    reducers: {
        fetchProfile: (state) => {
            state.fetchingProfile = true;
        },
        profileFetched: (state, action) => {
            state.profile = action.payload.data;
            state.fetchingProfile = false;
        }
    }
});

export default slice;
