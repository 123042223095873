/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationType } from '../../types';

type NotificationsStateType = {
    all: NotificationType[],
    fetchingAll: boolean
    unreadCount: number,
    fetchingCount: boolean
};

const initialState: NotificationsStateType = {
    all: [],
    fetchingAll: false,
    unreadCount: 0,
    fetchingCount: false
};

const slice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        fetchAll: (state) => {
            state.fetchingAll = true;
        },
        allFetched: (state, action: PayloadAction<{data: NotificationsStateType['all'], unreadCount: number}>) => {
            state.all = action.payload.data;
            state.unreadCount = action.payload.unreadCount;
            state.fetchingAll = false;
        },
        fetchCount: (state) => {
            state.fetchingCount = true;
        },
        unreadCountFetched: (state, action: PayloadAction<{data: NotificationsStateType['unreadCount']}>) => {
            state.unreadCount = action.payload.data;
            state.fetchingCount = false;
        }
    }
});

export default slice;
