import React from 'react';
import {
    Box,
    Button,
    Grid,
    Typography
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

type PropTypes = {
    show: boolean,
    emailVerified: boolean,
    phoneVerified: boolean,
    height: number,
    verify: () => void,
    dismiss: () => void
}

const VerificationBanner: React.FC<PropTypes> = (props: PropTypes) => {
    const {
        show,
        emailVerified,
        phoneVerified,
        height,
        verify,
        dismiss
    } = props;

    const message = React.useMemo(() => ([
        'Du behöver verifiera',
        ...(!emailVerified ? ['din e-postadress'] : ['']),
        ...(!emailVerified && !phoneVerified ? ['och'] : []),
        ...(!phoneVerified ? ['ditt mobilnummer'] : []),
        'för att få notiser.'
    ].join(' ')
    ), [emailVerified, phoneVerified]);

    return show ? (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
                height,
                backgroundColor: 'secondary.main',
                zIndex: 10000
            }}
            wrap="nowrap"
        >
            <Grid item sx={{ pl: 1, pr: 1 }}>
                <InfoIcon fontSize="small" color="action" />
            </Grid>
            <Grid item sx={{ pr: 1 }}>
                <Typography variant="body2" display="block" sx={{ lineHeight: 1.1, minWidth: 140 }}>
                    {message}
                </Typography>
            </Grid>
            <Grid item sx={{ pr: 1 }}>
                <Button
                    variant="contained"
                    size="small"
                    onClick={verify}
                >
                    Verifiera
                </Button>
            </Grid>
            <Grid item sx={{ pr: 1 }}>
                <Button size="small" onClick={dismiss}>
                    Inte nu
                </Button>
            </Grid>
        </Grid>
    ) : null;
};

export default VerificationBanner;
