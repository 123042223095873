/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderForListType, OrderStatusType } from '../../types';

type StatusCountType = {
    DRAFTED: number | null,
    PUBLISHED: number | null,
    ACCEPTED: number | null,
    STARTED: number | null,
    COMPLETED: number | null,
    ARCHIVED: number | null
};

type OrdersStateType = {
    activeOrderTab: OrderStatusType,
    statusCount: StatusCountType,
    fetchingStatusCount: boolean,
    drafted: OrderForListType[],
    fetchingDrafted: boolean,
    published: OrderForListType[],
    fetchingPublished: boolean,
    accepted: OrderForListType[],
    fetchingAccepted: boolean,
    started: OrderForListType[],
    fetchingStarted: boolean,
    completed: OrderForListType[],
    fetchingCompleted: boolean,
    archived: OrderForListType[],
    fetchingArchived: boolean
};

const initialState: OrdersStateType = {
    activeOrderTab: 'DRAFTED',
    statusCount: {
        DRAFTED: null,
        PUBLISHED: null,
        ACCEPTED: null,
        STARTED: null,
        COMPLETED: null,
        ARCHIVED: null
    },
    fetchingStatusCount: false,
    drafted: [],
    fetchingDrafted: false,
    published: [],
    fetchingPublished: false,
    accepted: [],
    fetchingAccepted: false,
    started: [],
    fetchingStarted: false,
    completed: [],
    fetchingCompleted: false,
    archived: [],
    fetchingArchived: false
};

const slice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setActiveOrderTab: (state, action: PayloadAction<{data: OrderStatusType}>) => {
            state.activeOrderTab = action.payload.data;
        },
        fetchStatusCount: (state) => {
            state.fetchingStatusCount = true;
        },
        statusCountFetched: (state, action: PayloadAction<{data: StatusCountType}>) => {
            state.statusCount = action.payload.data;
            state.fetchingStatusCount = false;
        },
        fetchDrafted: (state) => {
            state.fetchingDrafted = true;
        },
        draftedFetched: (state, action: PayloadAction<{data: OrderForListType[]}>) => {
            state.drafted = action.payload.data;
            state.fetchingDrafted = false;
        },
        fetchPublished: (state) => {
            state.fetchingPublished = true;
        },
        publishedFetched: (state, action: PayloadAction<{data: OrderForListType[]}>) => {
            state.published = action.payload.data;
            state.fetchingPublished = false;
        },
        fetchAccepted: (state) => {
            state.fetchingAccepted = true;
        },
        acceptedFetched: (state, action: PayloadAction<{data: OrderForListType[]}>) => {
            state.accepted = action.payload.data;
            state.fetchingAccepted = false;
        },
        fetchStarted: (state) => {
            state.fetchingStarted = true;
        },
        startedFetched: (state, action: PayloadAction<{data: OrderForListType[]}>) => {
            state.started = action.payload.data;
            state.fetchingStarted = false;
        },
        fetchCompleted: (state) => {
            state.fetchingCompleted = true;
        },
        completedFetched: (state, action: PayloadAction<{data: OrderForListType[]}>) => {
            state.completed = action.payload.data;
            state.fetchingCompleted = false;
        },
        fetchArchived: (state) => {
            state.fetchingArchived = true;
        },
        archivedFetched: (state, action: PayloadAction<{data: OrderForListType[]}>) => {
            state.archived = action.payload.data;
            state.fetchingArchived = false;
        }
    }
});

export default slice;
