import { endOfDay } from 'date-fns';
import {
    validateDateRange,
    validateNumberRange,
    validateRequired
} from '../../CommonComponents/Form/validations/validations';
import { formatDate } from '../../../utils/parsing';

export default function validations(d: { [key: string]: any }) {
    return [
        validateRequired({ value: d.firstPickupDate }),
        validateRequired({ value: d.pickupLat }),
        validateRequired({ value: d.pickupLng }),
        validateRequired({ value: d.pickupCity }),
        validateRequired({ value: d.firstDeliveryDate }),
        validateRequired({ value: d.deliveryLat }),
        validateRequired({ value: d.deliveryLng }),
        validateRequired({ value: d.deliveryCity }),
        validateRequired({ value: d.grossWeight }),
        ...(!d.isPublic ? [validateRequired({ value: d.groups })] : []),
        validateDateRange({ value: d.firstPickupDate, minDate: formatDate(new Date()) }),
        ...(d.firstPickupDate ? [
            validateDateRange({ value: d.lastPickupDate, minDate: formatDate(d.firstPickupDate) }),
            validateDateRange({ value: d.firstDeliveryDate, minDate: formatDate(d.firstPickupDate) })
        ] : []),
        ...(d.firstDeliveryDate || d.firstPickupDate
            ? [
                validateDateRange({
                    value: d.lastDeliveryDate,
                    minDate: formatDate(
                        new Date(d.firstDeliveryDate) > new Date(d.firstPickupDate)
                            ? d.firstDeliveryDate
                            : d.firstPickupDate
                    )
                }),
                validateDateRange({
                    value: d.lastDeliveryDate,
                    minDate: formatDate(
                        new Date(d.firstDeliveryDate) > new Date(d.firstPickupDate)
                            ? d.firstDeliveryDate
                            : d.firstPickupDate
                    )
                })
            ] : []
        ),
        validateNumberRange({ value: d.grossWeight, minValue: 0 }),
        validateNumberRange({ value: d.chargeableWeight, minValue: 0 }),
        validateNumberRange({ value: d.volume, minValue: 0 }),
        validateNumberRange({ value: d.loadingMeters, minValue: 0 }),
        validateNumberRange({ value: d.palletPlaces, minValue: 0 }),
        validateNumberRange({ value: d.pallets, minValue: 0 }),
        validateDateRange({
            value: d.expiresAt,
            minDate: formatDate(new Date()),
            maxDate: d.lastDeliveryDate || d.firstDeliveryDate
                ? endOfDay(new Date(d.lastDeliveryDate || d.firstDeliveryDate))
                : undefined
        })
    ];
}
