import users from '../slices/users';
import type { RootStateType, ThunkDispatchType } from '../index';

import {
    getUserProfile,
    getUsers
} from '../../api/users';

export function fetchUserProfile() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(users.actions.fetchProfile());
            const data = await getUserProfile();
            dispatch(users.actions.profileFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching user profile, set redux error state and handle in errorBoundary.', e);
            dispatch(users.actions.profileFetched({ data: null }));
        }
    };
}

export function clearUserProfile() {
    return async (dispatch: ThunkDispatchType) => {
        dispatch(users.actions.profileFetched({ data: null }));
    };
}

export function fetchAllUsers() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(users.actions.fetchAll());
            const data = await getUsers();
            dispatch(users.actions.allFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching users, set redux error state and handle in errorBoundary.', e);
            dispatch(users.actions.allFetched({ data: [] }));
        }
    };
}

export const selectUserProfile = () => (state: RootStateType) => state.users.profile;
export const selectFetchingUserProfile = () => (state: RootStateType) => state.users.fetchingProfile;

export const selectAllUsers = () => (state: RootStateType) => state.users.all;
export const selectFetchingAllUsers = () => (state: RootStateType) => state.users.fetchingAll;
