import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Avatar,
    Button,
    Grid,
    Typography
} from '@mui/material';
import { Edit, Link as LinkIcon } from '@mui/icons-material';

import { Block, List } from '../CommonComponents';

import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import { IntegrationType } from '../../types';
import {
    fetchIntegrationById,
    selectFetchingIntegrationById,
    selectIntegrationById
} from '../../redux/actions/integrations';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import parseIntegraitonType, {
    TRANSPORTOREN_OPTER,
    TCHALLAND_OPTER,
    SNABBUD_OPTER
} from './integrationTypes';
import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchAllUsers, selectAllUsers } from '../../redux/actions/users';
import { fetchAllGroups, selectMyGroups } from '../../redux/actions/groups';
import ToggleCheckbox from '../CommonComponents/ToggleCheckbox';
import { updateIntegration } from '../../api/integrations';
import toast from '../../utils/toast';

function IntegrationDetails(props: FetchPropsType<IntegrationType>) {
    const { entity, fetch } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const groups = useSelector(selectMyGroups());
    const users = useSelector(selectAllUsers());

    React.useEffect(() => {
        dispatch(fetchAllGroups());
        dispatch(fetchAllUsers());
    }, [dispatch]);

    const getGroupNameById = React.useCallback((id: number | null) => {
        const group = groups.find((g) => g.id === id);
        return group?.name ?? '';
    }, [groups]);

    const getUserNameById = React.useCallback((id: number) => {
        const user = users.find((u) => u.id === id);
        return user ? `${user.firstName} ${user.lastName}` : '';
    }, [users]);

    const setActive = React.useCallback(async (isActive) => {
        await updateIntegration(entity.id, { isActive });
        fetch();
        toast(`Integrationen är ${isActive ? 'aktiverad' : 'avstängd'}`);
    }, [entity.id, fetch]);

    const integrationSettings = React.useMemo(() => {
        if (![TRANSPORTOREN_OPTER, TCHALLAND_OPTER, SNABBUD_OPTER].some((t) => t === entity.type)) {
            return [];
        }
        return (
            [{
                name: 'FTP Host',
                value: entity.settings.ftpHost
            }, {
                name: 'FTP Port',
                value: entity.settings.ftpPort
            }, {
                name: 'FTP Användare',
                value: entity.settings.ftpUser
            }, {
                name: 'FTP Lösenord',
                value: '***'
            }, {
                name: 'FTP Till-katalog',
                value: entity.settings.ftpToPath
            }, {
                name: 'FTP Från-katalog',
                value: entity.settings.ftpFromPath
            }, {
                name: 'E-postadress för felmeddelanden',
                value: entity.settings.errorEmail
            }, {
                name: 'Publicera uppdrag direkt',
                value: entity.settings.publishDirectly,
                type: 'bool' as 'bool'
            }, {
                name: 'Visa företagsnamn',
                value: entity.settings.showTenantName,
                type: 'bool' as 'bool'
            }, {
                name: 'Uppdrag skapas av användare',
                value: getUserNameById(entity.settings.creatingUserId)
            }, {
                name: 'Resource Code / Grupp',
                value: entity.settings.resourceCodeGroups
                    .map((rcg: { resourceCode: string, groupId: number }) => (
                        <Typography>
                            {`${rcg.resourceCode}: ${getGroupNameById(rcg.groupId)}`}
                        </Typography>
                    ))
            }]
        );
        // switch (entity.type) {
        //     case TRANSPORTOREN_OPTER:
        //         ...
        //     case TCHALLAND_OPTER:
        //         ...
        //     case SNABBUD_OPTER:
        //         ...
        //     default:
        //         return [];
        // }
    }, [entity.type, entity.settings, getUserNameById, getGroupNameById]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <Avatar sx={{ width: 'auto', height: '80%', maxHeight: 500 }} variant="rounded">
                    <LinkIcon sx={{ fontSize: 120, p: 2 }} />
                </Avatar>
            </Grid>

            <Grid item xs={12} lg={6}>
                <Block
                    variant="outlined"
                    title="Inställningar"
                    ActionComponent={(
                        <Button
                            variant="outlined"
                            startIcon={<Edit />}
                            onClick={() => navigate(`/integrations/edit/${entity.id}`)}
                            sx={{ ml: 1, mb: 1 }}
                        >
                            Redigera
                        </Button>
                    )}
                >
                    <List
                        data={[{
                            name: 'Aktiv',
                            type: 'raw',
                            valuePadding: 1,
                            value: (
                                <ToggleCheckbox
                                    value={entity.isActive}
                                    onToggle={setActive}
                                    variant="switch"
                                />
                            )
                        }, {
                            name: 'Typ',
                            value: parseIntegraitonType(entity.type)
                        },
                        ...integrationSettings
                        ]}
                    />
                </Block>
            </Grid>
        </Grid>
    );
}

export default function IntegrationDetailsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<IntegrationType>
            fetchEntity={fetchIntegrationById(id)}
            selectEntity={selectIntegrationById(id)}
            selectIsFetching={selectFetchingIntegrationById(id)}
            Component={IntegrationDetails}
            name="integrationen"
            initialFetch={!hasRendered}
        />
    );
}
