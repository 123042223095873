import React from 'react';
import { Button, Grid } from '@mui/material';
import { DateField, TimeField } from '../../CommonComponents/Form';
import { formatDate } from '../../../utils/parsing';
import { capitalize } from '../../../utils';

type PropsType = {
    partialKey: 'pickup' | 'delivery',
    partialName: string,
    entity: any,
    onChange: (a: any) => void,
    skipRequiredValidation?: boolean,
    firstMinDate?: string,
    lastMinDate?: string
}

export default function OrderDate({
    partialKey,
    partialName,
    entity,
    onChange,
    skipRequiredValidation,
    firstMinDate,
    lastMinDate
}: PropsType) {
    const defaultTime = React.useMemo(() => {
        const date = new Date();
        date.setHours(12, 0, 0, 0);
        return date;
    }, []);

    const firstDateKey = React.useMemo(() => `first${capitalize(partialKey)}Date`, [partialKey]);
    const lastDateKey = React.useMemo(() => `last${capitalize(partialKey)}Date`, [partialKey]);
    const firstTimeKey = React.useMemo(() => `first${capitalize(partialKey)}Time`, [partialKey]);
    const lastTimeKey = React.useMemo(() => `last${capitalize(partialKey)}Time`, [partialKey]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6} textAlign="right">
                <DateField
                    name={firstDateKey}
                    label={
                        entity[lastDateKey]
                            ? `Tidigaste ${partialName}datum`
                            : `${capitalize(partialName)}datum`
                    }
                    value={formatDate(entity[firstDateKey])}
                    onChange={onChange}
                    minDate={formatDate(firstMinDate || new Date())}
                    required={!skipRequiredValidation}
                />
                {entity[firstTimeKey] ? (
                    <TimeField
                        sx={{ mt: 1 }}
                        name={firstTimeKey}
                        label={
                            entity[lastDateKey]
                                ? `Tidigaste ${partialName}tid`
                                : `${capitalize(partialName)}tid`
                        }
                        value={entity[firstTimeKey]}
                        onChange={onChange}
                    />
                ) : (
                    <Button
                        variant="text"
                        onClick={() => onChange({ [firstTimeKey]: defaultTime })}
                    >
                        Sätt klockslag
                    </Button>
                )}
            </Grid>
            <Grid item xs={12} lg={6} textAlign="right">
                {entity[lastDateKey] ? (
                    <>
                        <DateField
                            name={lastDateKey}
                            label={`Senaste ${partialName}datum`}
                            value={formatDate(entity[lastDateKey])}
                            onChange={onChange}
                            minDate={
                                // eslint-disable-next-line no-nested-ternary
                                lastMinDate || entity[firstDateKey]
                                    ? (
                                        (lastMinDate || '') > formatDate(entity[firstDateKey])
                                            ? formatDate(lastMinDate)
                                            : formatDate(entity[firstDateKey])
                                    ) : formatDate(new Date())
                            }
                        />
                        {entity[lastTimeKey] ? (
                            <TimeField
                                sx={{ mt: 1 }}
                                name={lastTimeKey}
                                label={`Senaste ${partialName}tid`}
                                value={entity[lastTimeKey]}
                                onChange={onChange}
                            />
                        ) : (
                            <Button
                                variant="text"
                                onClick={() => onChange({ [lastTimeKey]: defaultTime })}
                            >
                                Sätt klockslag
                            </Button>
                        )}
                    </>
                ) : (
                    <Button
                        variant="text"
                        onClick={() => onChange({ [lastDateKey]: formatDate(entity[firstDateKey] || new Date()) })}
                    >
                        Sätt datumspann
                    </Button>
                )}
            </Grid>
        </Grid>
    );
}
