import React from 'react';
import {
    Button,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import { Add, Clear } from '@mui/icons-material';

import {
    AutocompleteField,
    BoolField,
    EmailField,
    NumberField,
    TextField
} from '../../CommonComponents/Form';
import { SectionDivider } from '../../CommonComponents';
import { useDispatch, useSelector } from '../../../redux/hooks';
import { fetchAllGroups, selectMyGroups } from '../../../redux/actions/groups';
import { fetchAllUsers, selectAllUsers } from '../../../redux/actions/users';

type SettingsType = {
    ftpHost: string,
    ftpPort: string,
    ftpUser: string,
    ftpPassword: string,
    ftpToPath: string,
    ftpFromPath: string,
    publishDirectly: boolean,
    showTenantName: boolean,
    creatingUserId: number | null,
    errorEmail: string,
    resourceCodeGroups: { resourceCode: string, groupId: number | null }[]
}

type PropsType = {
    settings: SettingsType,
    onChangeSettings: (e: Partial<SettingsType>) => void
}

export default function TransportorenOpterForm({
    settings,
    onChangeSettings
}: PropsType) {
    const dispatch = useDispatch();
    const groups = useSelector(selectMyGroups());
    const users = useSelector(selectAllUsers());

    React.useEffect(() => {
        dispatch(fetchAllGroups());
        dispatch(fetchAllUsers());
    }, [dispatch]);

    const getGroupById = React.useCallback((id: number | null) => {
        return id ? groups.find((g) => g.id === id) : null;
    }, [groups]);

    const getUserById = React.useCallback((id: number | null) => {
        return id ? users.find((g) => g.id === id) : null;
    }, [users]);

    const addResourceCodeGroup = React.useCallback(() => {
        onChangeSettings({
            resourceCodeGroups: [
                ...settings.resourceCodeGroups,
                { resourceCode: '', groupId: null }
            ]
        });
    }, [onChangeSettings, settings.resourceCodeGroups]);

    const removeResourceCodeGroup = React.useCallback(({ index }) => {
        onChangeSettings({
            resourceCodeGroups: settings.resourceCodeGroups.length ? [
                ...settings.resourceCodeGroups.slice(0, index),
                ...settings.resourceCodeGroups.slice(index + 1)
            ] : []
        });
    }, [onChangeSettings, settings.resourceCodeGroups]);

    const onChangeResourceCodeGroup = React.useCallback(({ index, value }) => {
        onChangeSettings({
            resourceCodeGroups: [
                ...settings.resourceCodeGroups.slice(0, index),
                { ...settings.resourceCodeGroups[index], ...value },
                ...settings.resourceCodeGroups.slice(index + 1)
            ]
        });
    }, [onChangeSettings, settings.resourceCodeGroups]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <SectionDivider label="FTP-inställningar" marginTop={2} marginBottom={1} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="ftpHost"
                            label="FTP Host"
                            value={settings.ftpHost || ''}
                            onChange={onChangeSettings}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <NumberField
                            name="ftpPort"
                            label="FTP Port"
                            value={settings.ftpPort || ''}
                            onChange={onChangeSettings}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="ftpUser"
                            label="FTP Användarnamn"
                            value={settings.ftpUser || ''}
                            onChange={onChangeSettings}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="password"
                            name="ftpPassword"
                            label="FTP Lösenord"
                            value={settings.ftpPassword || ''}
                            onChange={onChangeSettings}
                            helperText="Lämna tomt för oförändrat"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="ftpToPath"
                            label="FTP Till-katalog"
                            value={settings.ftpToPath || ''}
                            onChange={onChangeSettings}
                            required
                            helperText="Den katalog på ftp-servern som uppdragen hämtas från"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="ftpFromPath"
                            label="FTP Från-katalog"
                            value={settings.ftpFromPath || ''}
                            onChange={onChangeSettings}
                            required
                            helperText="Den katalog på ftp-servern som felmeddelanden laddas upp till"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <EmailField
                            name="errorEmail"
                            label="E-postadress för felmeddelanden"
                            value={settings.errorEmail || ''}
                            onChange={onChangeSettings}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <SectionDivider label="Publicering" marginTop={2} marginBottom={1} />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <BoolField
                            name="publishDirectly"
                            label="Publicera direkt"
                            helperText="Välj om uppdrag som hämtas ska publiceras direkt eller om de ska sparas som utkast."
                            value={settings.publishDirectly || false}
                            onChange={onChangeSettings}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <BoolField
                            name="showTenantName"
                            label="Visa företagsnamn"
                            helperText="Välj om ni vill visa ert företgasnamn på de uppdrag som hämtas."
                            value={settings.showTenantName || false}
                            onChange={onChangeSettings}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutocompleteField<{ id: number, firstName: string, lastName: string, title?: string }>
                            label="Uppdrag skapas av"
                            optionIdKey="id"
                            defaultOption={settings.creatingUserId ? getUserById(settings.creatingUserId) : undefined}
                            fetchOptions={() => { }}
                            options={users}
                            getOptionName={(o) => `${o.firstName} ${o.lastName}`}
                            getOptionDescription={(o) => o.title || ''}
                            onChange={(user) => {
                                onChangeSettings({ creatingUserId: user?.id ?? null });
                            }}
                            fetchOnlyOnce
                            disableClearable
                            required
                        />
                    </Grid>

                    {Array.isArray(settings.resourceCodeGroups) && (
                        <>
                            <Grid item xs={12}>
                                <SectionDivider label="Gruppmappning" marginTop={2} marginBottom={1} />
                            </Grid>
                            {settings.resourceCodeGroups.map((rcg: any, index: number) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Grid item xs={12} key={index}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <TextField
                                                name="resourceCode"
                                                label="Resource Code"
                                                value={settings.resourceCodeGroups[index].resourceCode || ''}
                                                onChange={({ target }) => onChangeResourceCodeGroup({ index, value: { resourceCode: target.value } })}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Grid item xs={12}>
                                                <AutocompleteField<{ id: number, name: string, ownerTenantName?: string }>
                                                    label="Publicera i grupp"
                                                    optionIdKey="id"
                                                    fetchOptions={() => { }}
                                                    options={groups.filter(
                                                        (o: { isOwner: boolean; canPublish: boolean; }) => (o.isOwner || o.canPublish)
                                                    )}
                                                    getOptionName={(o) => o.name}
                                                    getOptionDescription={(o) => o.ownerTenantName || ''}
                                                    onChange={(g) => onChangeResourceCodeGroup({ index, value: { groupId: g?.id ?? null } })}
                                                    fetchOnlyOnce
                                                    defaultOption={
                                                        settings.resourceCodeGroups[index].groupId
                                                            ? getGroupById(settings.resourceCodeGroups[index].groupId)
                                                            : null
                                                    }
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item sx={{ mt: 2 }}>
                                            <IconButton
                                                aria-label="Ta bort"
                                                onClick={() => removeResourceCodeGroup({ index })}
                                            >
                                                <Clear />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Välj om uppdrag med en specifik resource code ska publiceras i specifika grupper.
                                    Om resource code för uppdraget som hämtas in inte matchar någon grupp ovan så publiceras uppdraget publikt.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sx={{ textAlign: 'right' }}>
                                <Button
                                    aria-label="Lägg till"
                                    onClick={addResourceCodeGroup}
                                    startIcon={<Add />}
                                >
                                    Lägg till
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
