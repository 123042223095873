import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

type PropsType = {
    children: React.ReactNode | string,
    variant?: 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'overline',
    showBorder?: boolean
};

export default function InfoText(props: PropsType) {
    const { children, variant = 'body1', showBorder } = props;

    const borderStyle = React.useMemo(() => (showBorder ? ({
        borderRadius: '10px',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#DDD'
    }) : ({ })), [showBorder]);

    return (
        <Box sx={{ p: 2, ...borderStyle }}>
            <Grid container spacing={2} alignItems="flex-start">
                <Grid item>
                    <InfoIcon color="action" />
                </Grid>
                <Grid item xs>
                    <Typography variant={variant} sx={{ pt: '2px' }}>
                        {children}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}
