/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type {
    JobForListType,
    JobForMarketListType
} from '../../types';

type JobStatusCountType = {
    CAPACITIES: number | null,
    BOOKMARKED: number | null,
    OFFERED: number | null,
    ACCEPTED: number | null,
    STARTED: number | null,
    COMPLETED: number | null
};

type JobsStateType = {
    statusCount: JobStatusCountType,
    fetchingStatusCount: boolean,
    allBookmarkedJobs: JobForMarketListType[],
    fetchingAllBookmarkedJobs: boolean,
    allOfferedJobs: JobForMarketListType[],
    fetchingAllOfferedJobs: boolean,
    allAcceptedJobs: JobForListType[],
    fetchingAllAcceptedJobs: boolean,
    allStartedJobs: JobForListType[],
    fetchingAllStartedJobs: boolean,
    allCompletedJobs: JobForListType[],
    fetchingAllCompletedJobs: boolean
};

const initialState: JobsStateType = {
    statusCount: {
        CAPACITIES: null,
        BOOKMARKED: null,
        OFFERED: null,
        ACCEPTED: null,
        STARTED: null,
        COMPLETED: null
    },
    fetchingStatusCount: false,
    allBookmarkedJobs: [],
    fetchingAllBookmarkedJobs: false,
    allOfferedJobs: [],
    fetchingAllOfferedJobs: false,
    allAcceptedJobs: [],
    fetchingAllAcceptedJobs: false,
    allStartedJobs: [],
    fetchingAllStartedJobs: false,
    allCompletedJobs: [],
    fetchingAllCompletedJobs: false
};

const slice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        fetchStatusCount: (state) => {
            state.fetchingStatusCount = true;
        },
        statusCountFetched: (state, action: PayloadAction<{data: JobStatusCountType}>) => {
            state.statusCount = action.payload.data;
            state.fetchingStatusCount = false;
        },
        fetchAllBookmarkedJobs: (state) => {
            state.fetchingAllBookmarkedJobs = true;
        },
        allBookmarkedJobsFetched: (state, action) => {
            const { data } = action.payload;
            state.allBookmarkedJobs = data;
            state.fetchingAllBookmarkedJobs = false;
        },
        fetchAllOfferedJobs: (state) => {
            state.fetchingAllOfferedJobs = true;
        },
        allOfferedJobsFetched: (state, action) => {
            const { data } = action.payload;
            state.allOfferedJobs = data;
            state.fetchingAllOfferedJobs = false;
        },
        fetchAllAcceptedJobs: (state) => {
            state.fetchingAllAcceptedJobs = true;
        },
        allAcceptedJobsFetched: (state, action) => {
            const { data } = action.payload;
            state.allAcceptedJobs = data;
            state.fetchingAllAcceptedJobs = false;
        },
        fetchAllStartedJobs: (state) => {
            state.fetchingAllStartedJobs = true;
        },
        allStartedJobsFetched: (state, action) => {
            const { data } = action.payload;
            state.allStartedJobs = data;
            state.fetchingAllStartedJobs = false;
        },
        fetchAllCompletedJobs: (state) => {
            state.fetchingAllCompletedJobs = true;
        },
        allCompletedJobsFetched: (state, action) => {
            const { data } = action.payload;
            state.allCompletedJobs = data;
            state.fetchingAllCompletedJobs = false;
        }
    }
});

export default slice;
