import React from 'react';

import MarketLayout from './MarketLayout';

import { useDispatch } from '../../redux/hooks';
import {
    fetchAllOpenJobs,
    fetchAllOpenCapacities,
    setSelectedMarketEntity
} from '../../redux/actions/market';

const FETCH_INTERVAL = 2 * 60 * 1000;

export default function Market() {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchAllOpenJobs());
        dispatch(fetchAllOpenCapacities());
        dispatch(setSelectedMarketEntity()); // Call with no params to reload the current selected

        const intervalId = window.setInterval(() => {
            dispatch(fetchAllOpenJobs());
            dispatch(fetchAllOpenCapacities());
        }, FETCH_INTERVAL);

        return () => window.clearInterval(intervalId);
    }, [dispatch]);

    return <MarketLayout />;
}
