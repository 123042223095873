import React from 'react';
import {
    Backdrop,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import { SaveAlt, UploadFile } from '@mui/icons-material';

import { Paper } from '../../StyledComponents';
import ClientXlsxFileField from '../Form/fields/ClientXlsxFileField';
import toastMessage from '../../../utils/toast';
import {
    ValidationsType,
    ImportFormatType
} from './DataImport';

const XLSX = require('xlsx');

const MAX_NUM_ROWS = 50;

type PropsType = {
    entityName: string,
    importFormats: ImportFormatType[],
    validations: ValidationsType,
    setRows: (a: any[]) => void
}

export default function DataImportRawData(props: PropsType) {
    const {
        entityName,
        importFormats,
        validations,
        setRows
    } = props;

    const [importFormatKey, setImportFormatKey] = React.useState(importFormats[0].key);
    const [isPending, setIsPending] = React.useState(false);
    // const [importedRowsCount, setImportedRowsCount] = React.useState(0);
    // const [rowsToImportCount, setRowsToImportCount] = React.useState(0);

    const importFormat = React.useMemo(() => (
        importFormats.find((f) => f.key === importFormatKey)
    ), [importFormatKey, importFormats]);

    const downloadTemplate = React.useCallback(() => {
        if (importFormat?.templateCells) {
            const ws = XLSX.utils.aoa_to_sheet(importFormat.templateCells);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, `Importdata ${entityName}`);
            XLSX.writeFile(wb, `${importFormat.name} importmall ${entityName}.xlsx`.replaceAll(' ', '_'));
        }
    }, [entityName, importFormat]);

    const loadData = React.useCallback(async (blob: string) => {
        if (importFormat) {
            setIsPending(true);
            // setImportedRowsCount(0);
            // setRowsToImportCount(0);

            try {
                const wb = XLSX.read(blob, { type: 'string', cellDates: true });
                const ws = wb.Sheets[wb.SheetNames[0]];
                const data = XLSX.utils.sheet_to_json(ws);
                const parsedData = (await importFormat.parseData(data))
                    .slice(0, MAX_NUM_ROWS)
                    .map((d, i) => ({ importRowNumber: i + 1, ...d }));

                const parsedValidatedData = parsedData.map((o) => ({
                    ...o,
                    hasError: validations(o).some((v) => !v.isValid)
                }));

                setRows(parsedValidatedData);
            } catch (e) {
                console.log('Import error', e);
                toastMessage('Något gick fel vid uppladdingen, försök igen');
            }

            // const fieldsInFile = importFormat.fields.filter((field) => typeof field.parseData === 'function');
            // try {
            //     const arrayData = csvToArray({ text: data, separatorSymbol: separator.symbol });
            //     const secondRowIsExample = arrayData.at(1)?.at(0)?.startsWith('Ex: ');
            //     const valuePromisesRows = arrayData
            //         // Remove header and possibly example row, only process MAX_NUM_ROWS rows
            //         .slice(secondRowIsExample ? 2 : 1, MAX_NUM_ROWS + (secondRowIsExample ? 2 : 1))
            //         .map((rowStrings, rowIndex) => { // convert stringRow to valuePromise
            //             return importFormat.fields.map((field) => {
            //                 const inputValueIndex = fieldsInFile.findIndex((f) => f.key === field.key);
            //                 const value = inputValueIndex !== -1
            //                     ? rowStrings.at(inputValueIndex) || field.defaultValue || ''
            //                     : field.defaultValue || '';
            //                 return Promise.resolve(
            //                     field.parseData
            //                         ? field.parseData(value, rowIndex)
            //                         : ({ [field.key]: value }) // is this part needed?
            //                 );
            //             });
            //         });

            //     setRowsToImportCount(valuePromisesRows.length);
            //     const objectRowsPromises = valuePromisesRows.map(async (valuePromisesRow, rowIndex) => {
            //         const values: { [key: string]: any } = await Promise.all(valuePromisesRow);
            //         setImportedRowsCount((irc) => irc + 1);
            //         return importFormat.fields.reduce((rowObject: DataType, field: any, index: number) => ({
            //             ...rowObject,
            //             ...values.at(index)
            //         }), { importRowNumber: rowIndex + 1 });
            //     });

            //     const objectRows = await Promise.all(objectRowsPromises);

            //     const objectRowsWithErrorsAndPreview = objectRows
            //         .map((o) => ({ ...o, hasError: validations(o).some((v) => !v.isValid) }))
            //         .map((o) => getObjectWithPreviews({ fields: importFormat.fields, obj: o }));

            //     setRows(objectRowsWithErrorsAndPreview);
            // } catch (e) {
            //     console.log(e);
            //     setIsPending(false);
            //     setImportedRowsCount(0);
            //     setRowsToImportCount(0);
            //     toastMessage('Något gick fel vid uppladdingen, försök igen');
            // }
            setIsPending(false);
            // setImportedRowsCount(0);
            // setRowsToImportCount(0);
        }
    }, [importFormat, setRows, validations]);

    return (
        <Paper>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Ladda upp importfil
            </Typography>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
                open={isPending}
            >
                <CircularProgress />
            </Backdrop>

            <Grid container direction="column" spacing={2} justifyContent="flex-start" alignItems="flex-start">
                <Grid item>
                    <FormControl variant="standard">
                        <InputLabel id="importFormat-label">Välj importformat</InputLabel>
                        <Select
                            labelId="importFormat-label"
                            id="importFormat"
                            value={importFormatKey}
                            label="Välj importformat"
                            onChange={({ target: { value } }) => {
                                setImportFormatKey(value);
                            }}
                            disabled={isPending}
                            sx={{ minWidth: 200 }}
                        >
                            {importFormats.map((f) => (
                                <MenuItem key={f.key} value={f.key}>{f.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {importFormat?.templateCells && (
                    <Grid item>
                        <Button
                            startIcon={<SaveAlt />}
                            variant="outlined"
                            onClick={downloadTemplate}
                            disabled={isPending}
                        >
                            {`Ladda ner ${importFormat.name}-mallen`}
                        </Button>
                    </Grid>
                )}

                <Grid item>
                    <Typography variant="body2">
                        Önskar du ett annat importformat? Kontakta oss så hjälper vi dig!
                    </Typography>
                </Grid>

                <Grid item>
                    <ClientXlsxFileField
                        startIcon={<UploadFile />}
                        variant="contained"
                        label="Ladda upp importfil"
                        disabled={isPending}
                        onChange={loadData}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}
