import notifications from '../slices/notifications';
import type { RootStateType, ThunkDispatchType } from '../index';

import {
    getNotifications,
    getNotificationsCount
} from '../../api/notifications';
import { NotificationType } from '../../types';

export function fetchAllNotifications() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(notifications.actions.fetchAll());
            const data = await getNotifications();
            dispatch(notifications.actions.allFetched({
                data,
                unreadCount: data.filter((n: NotificationType) => !n.isRead).length
            }));
        } catch (e) {
            console.log('ERROR fetching notifications, set redux error state and handle in errorBoundary.', e);
            dispatch(notifications.actions.allFetched({ data: [], unreadCount: 0 }));
        }
    };
}

export function fetchNotificationsCount() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(notifications.actions.fetchCount());
            const data = await getNotificationsCount();
            dispatch(notifications.actions.unreadCountFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching unread notifications count, set redux error state and handle in errorBoundary.', e);
            dispatch(notifications.actions.unreadCountFetched({ data: 0 }));
        }
    };
}

export const selectAllNotifications = () => (state: RootStateType) => state.notifications.all;
export const selectFetchingAllNotifications = () => (state: RootStateType) => state.notifications.fetchingAll;

export const selectNotificationsCount = () => (state: RootStateType) => state.notifications.unreadCount;
export const selectFetchingNotificationsCount = () => (state: RootStateType) => state.notifications.fetchingCount;
