import React from 'react';
import { endOfTomorrow } from 'date-fns';

import OrderFormFields from '../../Orders/OrderFormComponents/OrderFormFields';
import { PopupForm, useChangeable } from '../../CommonComponents/Form';
import {
    createOrder,
    getOrder,
    setOrdersPublished,
    updateOrder
} from '../../../api/orders';
import getDrivingDistance from '../../../utils/getDrivingDistance';
import { getFakeCoordinate } from '../../../utils/geometry';
import { resetLastAllOpenJobsFetch } from '../../../redux/actions/market';
import toast from '../../../utils/toast';
import { useDispatch } from '../../../redux/hooks';
import { orderStatus } from '../../../utils/status';

type PropsType = {
    isOpen: boolean,
    orderId: number | null,
    refetch: () => void,
    close: ({ orderIdToSuggest }: { orderIdToSuggest?: number }) => void,
}

async function addDistancesAndFakeLocationCoordinates(entity: { [key: string]: any }) {
    let d;
    try {
        d = await getDrivingDistance(entity);
    } catch {
        d = { drivingDistance: null, drivingDuration: null };
    }
    return ({
        ...entity,
        fakePickupLat: getFakeCoordinate(entity.pickupLat),
        fakePickupLng: getFakeCoordinate(entity.pickupLng),
        fakeDeliveryLat: getFakeCoordinate(entity.deliveryLat),
        fakeDeliveryLng: getFakeCoordinate(entity.deliveryLng),
        drivingDistance: d.drivingDistance,
        drivingDuration: d.drivingDuration
    });
}

export default function OrderFormPopup({
    isOpen,
    orderId,
    refetch,
    close
}: PropsType) {
    const defaultOrder = React.useMemo(() => ({
        firstPickupDate: endOfTomorrow().toISOString().slice(0, 10),
        lastPickupDate: null,
        firstDeliveryDate: endOfTomorrow().toISOString().slice(0, 10),
        lastDeliveryDate: null,
        firstPickupTime: null,
        lastPickupTime: null,
        firstDeliveryTime: null,
        lastDeliveryTime: null,

        pickupLat: null,
        pickupLng: null,
        pickupAttention: '',
        pickupStreet: '',
        pickupZip: '',
        pickupCity: '',
        pickupCountry: '',
        deliveryLat: null,
        deliveryLng: null,
        deliveryAttention: '',
        deliveryStreet: '',
        deliveryZip: '',
        deliveryCity: '',
        deliveryCountry: '',
        fakePickupLat: null,
        fakePickupLng: null,
        fakeDeliveryLat: null,
        fakeDeliveryLng: null,

        grossWeight: null,
        chargeableWeight: null,
        volume: null,
        loadingMeters: null,
        palletPlaces: null,
        pallets: null,
        // pieces: null,

        isPublic: false,
        showTenantName: true,
        groups: [],
        listPrice: null,
        expiresAt: null,
        publicDescription: '',
        privateDescription: ''
    }), []);

    const dispatch = useDispatch();
    const [entity, onChange] = useChangeable(defaultOrder);

    React.useEffect(() => {
        (async () => {
            if (orderId) {
                const order = await getOrder(orderId);
                onChange(order);
            } else {
                onChange(defaultOrder);
            }
        })();
    }, [orderId, onChange, defaultOrder]);

    const onSubmit = React.useCallback(async () => {
        try {
            const order = await addDistancesAndFakeLocationCoordinates(entity);
            if (orderId) {
                const updatedOrder = await updateOrder(orderId, order);
                if (updatedOrder.status === orderStatus.DRAFTED) {
                    await setOrdersPublished({ ids: [orderId] }); // Todo: Handle unsuccessful publishing here
                }
                dispatch(resetLastAllOpenJobsFetch()); // Refetch allJobs to get correct group/watchlist stats
                toast('Uppdrag publicerat');
                close({ orderIdToSuggest: orderId });
                refetch();
            } else {
                const createdOrder = await createOrder({ ...order, publishDirectly: true });
                toast('Uppdrag publicerat');
                close({ orderIdToSuggest: createdOrder.id });
                refetch();
            }
        } catch (e: any) {
            toast(e, `Kunde inte ${orderId ? 'uppdatera' : 'publicera'} uppdrag, försök igen`);
        }
    }, [entity, orderId, dispatch, close, refetch]);

    return (
        <PopupForm
            title={`${orderId ? 'Redigera' : 'Publicera'} uppdrag att föreslå`}
            open={isOpen}
            okLabel="Publicera"
            cancelLabel="Avbryt"
            handleOk={onSubmit}
            handleClose={close}
            maxWidth="xl"
        >
            <OrderFormFields
                entity={entity}
                onChange={onChange}
            />
        </PopupForm>
    );
}
