import React from 'react';
import {
    Stack,
    Step,
    StepLabel,
    Stepper,
    Typography
} from '@mui/material';
import {
    Create as CreateIcon,
    PublishedWithChanges as PublishedWithChangesIcon,
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    LocalShipping as LocalShippingIcon,
    SportsScore as SportsScoreIcon,
} from '@mui/icons-material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { styled } from '@mui/material/styles';

import { Paper } from '../StyledComponents';
import { formatDateTime, formatStatus } from '../../utils/parsing';

import { OrderStatusType } from '../../types';
import { COLOR_SKIN, COLOR_TEAL } from '../../theme';

type PropsType = {
    data?: any
};

const completedColor = COLOR_TEAL;
const altColor = COLOR_SKIN;

const CustomConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            background: completedColor
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            background: completedColor
        },
    },
    [`& .${stepConnectorClasses.line}`]: { // ej genomförda linjer
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const CustomStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        // background: altColor,
        // color: completedColor,
        background: completedColor,
        color: altColor,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        background: completedColor,
    }),
}));

function CustomStepIcon(props: StepIconProps) {
    const {
        active,
        completed,
        className,
        icon
    } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <CreateIcon />,
        2: <PublishedWithChangesIcon />,
        3: <AssignmentTurnedInIcon />,
        4: <LocalShippingIcon />,
        5: <SportsScoreIcon />,
    };

    return (
        <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(icon)]}
        </CustomStepIconRoot>
    );
}

function getActiveStep(status: string, steps: { status: string }[]) {
    return steps.findIndex((s) => (s.status === status));
}

export default function OrderStatus(props: PropsType) {
    const { data } = props;

    const steps = React.useMemo(() => ([
        {
            status: 'DRAFTED' as OrderStatusType,
            date: data.draftedAt,
            user: data.draftedByName
        },
        {
            status: 'PUBLISHED' as OrderStatusType,
            date: data.publishedAt,
            user: data.publishedByName
        },
        {
            status: 'ACCEPTED' as OrderStatusType,
            date: data.acceptedAt,
            user: data.acceptedByName
        },
        {
            status: 'STARTED' as OrderStatusType,
            date: data.startedAt,
            user: data.startedByName
        },
        {
            status: 'COMPLETED' as OrderStatusType,
            date: data.completedAt,
            user: data.completedByName,
            actionContent: null
        }
    ]), [data]);

    return (
        <Paper>
            <Stack sx={{ width: '100%' }} spacing={4}>
                <Stepper
                    alternativeLabel
                    activeStep={getActiveStep(data.status, steps)}
                    connector={<CustomConnector />}
                >
                    {steps.map((step) => (
                        <Step key={step.status}>
                            <StepLabel StepIconComponent={CustomStepIcon}>
                                <div>
                                    <Typography variant="h6">
                                        {formatStatus(step.status)}
                                    </Typography>
                                    {step.date && (
                                        <Typography variant="subtitle2">
                                            {formatDateTime(step.date)}
                                        </Typography>
                                    )}
                                    {step.user && (
                                        <Typography variant="subtitle2">
                                            {`av ${step.user}`}
                                        </Typography>
                                    )}
                                </div>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Stack>
        </Paper>
    );
}
