import React from 'react';
import { useParams } from 'react-router-dom';

import { Header, Tabs } from '../CommonComponents';
import { Container } from '../StyledComponents';
import IntegrationDetails from './IntegrationDetails';
import IntegrationLogs from './IntegrationLogs';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import {
    fetchIntegrationMetaById,
    selectFetchingIntegrationMetaById,
    selectIntegrationMetaById
} from '../../redux/actions/integrations';
import parseIntegraitonType from './integrationTypes';

const tabs = [{
    key: 'integration',
    label: 'Inställningar',
    Component: IntegrationDetails,
    padding: 2
}, {
    key: 'logs',
    label: 'Loggar',
    Component: IntegrationLogs
}];

type IntegrationMetaType = {
    [key: string]: number | string
};

function IntegrationTabs(props: FetchPropsType<any>) {
    const { entity, isFetching } = props;

    const getTitle = React.useCallback((tab) => {
        if (!entity) {
            return tab.label;
        }
        const count = entity[`${tab.key}Count` as string];
        return typeof count === 'number'
            ? `${tab.label} (${count})`
            : tab.label;
    }, [entity]);

    return (
        <Container>
            <Header
                title={entity.name as string ?? 'Integration'}
                subtitle={parseIntegraitonType(entity.type)}
            />
            <Tabs
                tabsKey="integrations"
                tabs={tabs}
                getTitle={getTitle}
            />
        </Container>
    );
}

export default function IntegrationTabsFetched() {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<IntegrationMetaType>
            fetchEntity={fetchIntegrationMetaById(id)}
            selectEntity={selectIntegrationMetaById(id)}
            selectIsFetching={selectFetchingIntegrationMetaById(id)}
            Component={IntegrationTabs}
            name="integrationen"
        />
    );
}
