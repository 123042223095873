import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
    EmailField,
    PasswordField,
    PhoneField,
    TextField
} from '../CommonComponents/Form';
import AuthContext from './AuthContext';
import AuthContainer from './AuthContainer';
import SimpleForm from '../CommonComponents/Form/SimpleForm';
import { signup } from '../../api-public/user';
import toast from '../../utils/toast';
import login from '../../utils/login';

export default function RegisterCompany() {
    const {
        values: {
            name,
            email,
            phone,
            password,
            tenantName,
            tenantId
        },
        onChange
    } = React.useContext(AuthContext);

    const navigate = useNavigate();

    React.useEffect(() => {
        if (!tenantId) {
            navigate('/register-company');
        }
    }, []);

    const createUser = React.useCallback(async () => {
        try {
            const newCompany = await signup({
                tenantId,
                tenantName,
                name,
                email,
                phone,
                password
            });
            onChange({ tenantId: newCompany.id, tenantName: newCompany.name });
            navigate('/register-user', { replace: true });
            await login({ email, password });
            navigate('/');
        } catch (e) {
            toast(e, 'Kunde inte skapa konto, försök igen');
        }
    }, [
        onChange,
        tenantId,
        tenantName,
        name,
        email,
        phone,
        password,
        navigate
    ]);

    return (
        <AuthContainer>
            <Typography variant="h5" sx={{ textAlign: 'center', mt: 4 }}>Skapa ditt konto</Typography>
            <Typography variant="subtitle1" sx={{ textAlign: 'center', mb: 4 }}>{tenantName}</Typography>

            <SimpleForm
                onSubmit={createUser}
                submitText="Registrera"
                submitDisabled={!name || !email || !phone || !password}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            name="name"
                            label="Ditt namn"
                            value={name}
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <EmailField
                            variant="outlined"
                            name="email"
                            label="Din e-postadress"
                            value={email}
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <PhoneField
                            variant="outlined"
                            name="phone"
                            label="Ditt mobilnummer"
                            value={phone}
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <PasswordField
                            variant="outlined"
                            name="password"
                            label="Välj ett lösenord"
                            value={password}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs>
                    <Button variant="text" onClick={() => navigate('/register-company')}>
                        Tillbaka
                    </Button>
                </Grid>
            </Grid>
        </AuthContainer>
    );
}
