/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CapacityForListType, OpenCapacityForMarketListType } from '../../types';

type CapacitiesStateType = {
    all: CapacityForListType[],
    fetchingAll: boolean,
    allOpen: OpenCapacityForMarketListType[],
    fetchingAllOpen: boolean
};

const initialState: CapacitiesStateType = {
    all: [],
    fetchingAll: false,
    allOpen: [],
    fetchingAllOpen: false,
};

const slice = createSlice({
    name: 'capacities',
    initialState,
    reducers: {
        fetchAll: (state) => {
            state.fetchingAll = true;
        },
        allFetched: (state, action: PayloadAction<{data: CapacitiesStateType['all']}>) => {
            state.all = action.payload.data;
            state.fetchingAll = false;
        },
        fetchAllOpen: (state) => {
            state.fetchingAllOpen = true;
        },
        allOpenFetched: (state, action: PayloadAction<{data: CapacitiesStateType['allOpen']}>) => {
            state.allOpen = action.payload.data;
            state.fetchingAllOpen = false;
        }
    }
});

export default slice;
