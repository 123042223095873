type EntryType = {
    text: string,
    value: number
};
type ResponseType = {
    rows: {
        elements: {
            distance: EntryType,
            duration: EntryType
        }[]
    }[]
};
type CoordinateType = {
    pickupLat?: number,
    pickupLng?: number,
    deliveryLat?: number,
    deliveryLng?: number
};
type ReturnType = {
    drivingDistance: number,
    drivingDuration: number
};

function parseDrivingDistancesData(response: ResponseType) {
    const { distance, duration } = response.rows[0].elements[0];
    return ({
        drivingDistance: distance.value,
        drivingDuration: duration.value
    });
}

export default function getDrivingDistance(coordinates: CoordinateType) {
    return new Promise<ReturnType>((resolve, reject) => {
        const {
            pickupLat,
            pickupLng,
            deliveryLat,
            deliveryLng
        } = coordinates;

        if (!pickupLat || !pickupLng || !deliveryLat || !deliveryLng) {
            reject();
        }

        const service = new window.google.maps.DistanceMatrixService();

        service.getDistanceMatrix({
            region: 'se',
            origins: [{ lat: Number(pickupLat), lng: Number(pickupLng) }],
            destinations: [{ lat: Number(deliveryLat), lng: Number(deliveryLng) }],
            travelMode: window.google.maps.TravelMode.DRIVING
        }, (response: any, status: any) => {
            try {
                const distances = parseDrivingDistancesData(response);
                resolve(distances);
            } catch {
                reject();
            }
        });
    });
}
