import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Typography } from '@mui/material';

type PropsType = {
    height?: number | string,
    lat?: number,
    lng?: number,
    onChange: (a: any) => void
};

const defaultCenter = { lat: 63, lng: 16 };
const defaultZoomLevel = 4;
const markerZoomLevel = 15;
const options = {
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    clickableIcons: false,
    minZoom: 4,
    maxZoom: 20
};

const DraggablePositionMap: React.FC<PropsType> = (props: PropsType) => {
    const {
        lat,
        lng,
        onChange,
        height = 500
    } = props;

    const [map, setMap] = React.useState<any>(null);
    const [zoom, setZoom] = React.useState<number>(defaultZoomLevel);

    React.useEffect(() => {
        if (map && lat && lng) { // Check for zero ?
            map.panTo({ lat: Number(lat), lng: Number(lng) });
            setZoom(markerZoomLevel);
        }
    }, [lat, lng, map]);

    const onLoad = React.useCallback((newMap) => {
        setMap(newMap);
    }, []);

    const onUnmount = React.useCallback(() => {
        setMap(null);
    }, []);

    const onDragEnd = React.useCallback((d: any) => {
        onChange({ lat: d.latLng.lat(), lng: d.latLng.lng() });
    }, []);

    return (
        <>
            <GoogleMap
                mapContainerStyle={{ height, overflow: 'visible' }}
                center={defaultCenter}
                zoom={zoom}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={options}
            >
                {lat && lng && (
                    <Marker
                        position={{ lat: Number(lat), lng: Number(lng) }}
                        draggable
                        onDragEnd={onDragEnd}
                    />
                )}
            </GoogleMap>
            {lat && lng && (
                <Typography variant="caption">Dra i kartnålen för att flytta positionen.</Typography>
            )}
        </>
    );
};

export default DraggablePositionMap;
