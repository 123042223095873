import React from 'react';
import { Grid } from '@mui/material';
import { searchCompanies } from '../../../api-public/company';
import { InfoText } from '../../CommonComponents';
import {
    AutocompleteField,
    BoolField,
    EmailField,
    PhoneField,
    PopupForm
} from '../../CommonComponents/Form';
import { EditingRowType } from './GroupMemberImportTable';

type PropsType = {
    editingRow: EditingRowType,
    setEditingRow: (r: EditingRowType | ((a: EditingRowType) => EditingRowType)) => void,
    changeRow: ({ rowIndex, value }: { rowIndex: number, value: { [key: string]: any } }) => void,
    isOwner: boolean,
    tenants: {
        tenantId: number,
        tenantName: string,
        isOwner: boolean,
        isInvited: boolean,
        isAccepted: boolean,
        canInvite: boolean,
        canPublish: boolean
    }[]
};

const GroupMemberImportForm: React.FC<PropsType> = (props) => {
    const {
        editingRow,
        setEditingRow,
        changeRow,
        isOwner,
        tenants
    } = props;

    const cancel = React.useCallback(() => {
        setEditingRow(null);
    }, [setEditingRow]);

    const setValue = React.useCallback(() => {
        if (editingRow?.rowIndex !== undefined) {
            changeRow({ rowIndex: editingRow.rowIndex, value: editingRow });
            setEditingRow(null);
        }
    }, [changeRow, editingRow, setEditingRow]);

    return !editingRow ? null : (
        <PopupForm
            open={!!editingRow}
            title="Redigera importrad"
            handleClose={cancel}
            handleOk={setValue}
            okDisabled={
                editingRow.isMember
                || (!editingRow.isRegistered && !editingRow.invitedEmail && !editingRow.invitedPhone)
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AutocompleteField<{ id: number | string, name: string, orgNumber?: string, isRegistered?: boolean }>
                        label="Företag"
                        optionIdKey="id"
                        defaultOption={!editingRow.tenantId ? null : {
                            id: editingRow.tenantId,
                            name: editingRow.name || ''
                        }}
                        fetchOptions={searchCompanies}
                        minQueryLength={3}
                        getOptionName={(o) => o.name}
                        getOptionDescription={(o) => o.orgNumber || ''}
                        onChange={(tenant) => setEditingRow((er) => ({
                            ...er,
                            tenantId: tenant === null ? null : tenant.id,
                            name: tenant === null ? null : tenant.name,
                            orgNumber: tenant === null ? null : tenant.orgNumber,
                            isRegistered: tenant === null ? false : tenant.isRegistered,
                            isMember: tenant === null ? false : tenants.some((t) => t.tenantId === tenant.id)
                        }))}
                    />
                </Grid>

                {editingRow.isMember && (
                    <Grid item xs={12}>
                        <InfoText>
                            Företaget är redan inbjudet till gruppen.
                        </InfoText>
                    </Grid>
                )}

                {!editingRow.isMember && editingRow.isRegistered && (
                    <Grid item xs={12}>
                        <InfoText>
                            Företaget kommer att bjudas in till gruppen.
                        </InfoText>
                    </Grid>
                )}

                {!editingRow.isMember && !editingRow.isRegistered && (
                    <>
                        <Grid item xs={12}>
                            {
                                editingRow.tenantId
                                    ? (
                                        <InfoText>
                                            Företaget har ännu inte registrerat sig.
                                            Komplettera med e-postadress eller mobilnummer för att skicka inbjudan.
                                        </InfoText>
                                    )
                                    : (
                                        <InfoText>
                                            Sök efter företaget du vill bjuda in till gruppen.
                                            Får du ingen träff så kan du fylla i e-postadress eller mobilnummer
                                            för att skicka en inbjudan.
                                        </InfoText>
                                    )
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <EmailField
                                name="invitedEmail"
                                label="E-postadress"
                                value={editingRow.invitedEmail || ''}
                                onChange={({ target: { value } }) => setEditingRow(
                                    (er) => ({ ...er, invitedEmail: value })
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneField
                                name="invitedPhone"
                                label="Mobilnummer"
                                value={editingRow.invitedPhone || ''}
                                onChange={({ target: { value } }) => setEditingRow(
                                    (er) => ({ ...er, invitedPhone: value })
                                )}
                            />
                        </Grid>
                    </>
                )}
                {isOwner && editingRow.tenantId && !editingRow.isMember && (
                    <>
                        <Grid item xs={12}>
                            <BoolField
                                name="canPublish"
                                value={editingRow.canPublish || false}
                                label="Kan publicera"
                                onChange={(partial) => setEditingRow((er) => ({ ...er, ...partial }))}
                            />
                        </Grid>
                        {/* <Grid item xs={6}>
                            <BoolField
                                name="canInvite"
                                value={editingRow.canInvite || false}
                                label="Kan se/bjuda in medlemmar"
                                onChange={(partial) => setEditingRow((er) => ({ ...er, ...partial }))}
                            />
                        </Grid> */}
                    </>
                )}
            </Grid>
        </PopupForm>
    );
};

export default GroupMemberImportForm;
