import React from 'react';
import { Chip } from '@mui/material';
import { Error, Info } from '@mui/icons-material';

import { OpenCapacityType, JobType } from '../../../types';
import { getOfferStatus, offerStatus } from '../../../utils/status';
import { getFormattedOrderExpiryDate, getOrderExpiryDate } from '../../../utils/order';
import { formatNumber } from '../../../utils/parsing';

type PropsType = {
    entity: JobType | OpenCapacityType
}

export default function EntityStatus({ entity }: PropsType) {
    const statusText = React.useMemo(() => {
        if (!entity) {
            return '';
        }

        if (entity.type === 'CAP') {
            const suggestionCount = entity.suggestedOrderIds.length;
            if (suggestionCount) {
                return `Du har föreslagit ${suggestionCount} uppdrag`;
            }
            return `Föreslå uppdrag senast ${getFormattedOrderExpiryDate(entity)}`;
        }

        if (entity.type === 'DHL') {
            return `Ring DHL Trafikcenter för mer info. Välj "avgående område", kontor ${entity.consignorOffice}`;
        }

        if (getOrderExpiryDate(entity) <= new Date() && !entity.offer) {
            return 'Anmälningstiden har löpt ut';
        }

        const offerPriceText = entity.offer?.price
            ? `, önskat pris ${formatNumber(entity.offer.price, 'SEK')}`
            : '';

        switch (getOfferStatus(entity.offer)) {
            case offerStatus.REJECTED:
                return 'Er intresseanmälan har avböjts';
            case offerStatus.EXPIRED:
                return 'Er intresseanmälan har löpt ut';
            case offerStatus.PENDING:
                return `Ni har anmält intresse${offerPriceText}`;
            default:
                return `Anmäl intresse senast ${getFormattedOrderExpiryDate(entity)}`;
        }
    }, [entity]);

    const statusColor = React.useMemo(() => {
        if (!entity) {
            return undefined;
        }

        if (entity.type === 'CAP') {
            if (entity.suggestedOrderIds.length) {
                return 'primary';
            }
            return undefined;
        }

        // We have an order
        if (getOrderExpiryDate(entity) <= new Date() && !entity.offer) {
            return undefined;
        }

        switch (getOfferStatus(entity.offer)) {
            case offerStatus.REJECTED:
                return 'error';
            case offerStatus.EXPIRED:
                return 'error';
            case offerStatus.PENDING:
                return 'primary';
            default:
                return undefined;
        }
    }, [entity]);

    return (
        <Chip
            icon={statusColor === 'error' ? <Error /> : <Info />}
            label={statusText}
            color={statusColor}
        />
    );
}
