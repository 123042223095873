import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Delete,
    // Markunread,
    // Drafts
} from '@mui/icons-material';

import { Header, Table } from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';

import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchAllNotifications, selectAllNotifications } from '../../redux/actions/notifications';

import { NotificationType } from '../../types';
import {
    deleteNotifications,
    // setReadNotifications,
    // setUnreadNotifications
} from '../../api/notifications';
import toast from '../../utils/toast';
import NotificationActionButton from './NotificationActionButton';

export default function Notifications() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const notifications = useSelector(selectAllNotifications());

    React.useEffect(() => {
        dispatch(fetchAllNotifications());
    }, [dispatch]);

    const getActionButton = React.useCallback((notification) => (
        <NotificationActionButton
            notification={notification}
            navigate={navigate}
        />
    ), [navigate]);

    const columns = React.useMemo(() => ([{
        name: 'Datum',
        key: 'createdAt',
        type: 'datetime' as 'datetime'
    }, {
        name: 'Meddelande',
        key: 'message',
    }, {
        name: '',
        key: 'action',
        align: 'right',
        padding: 'checkbox' as 'checkbox',
        getValue: getActionButton
    }]), [getActionButton]);

    // const getRowStyle = React.useCallback((notification: NotificationType) => (
    //     notification.isRead ? {} : { fontWeight: 700 }
    // ), []);

    // const markAsRead = React.useCallback(async (selectedNotifications: NotificationType[]) => {
    //     try {
    //         await setReadNotifications(selectedNotifications.map((n) => n.id));
    //         dispatch(fetchAllNotifications());
    //         if (selectedNotifications.length === 1) {
    //             toast('1 notis markerad som läst');
    //         } else {
    //             toast(`${selectedNotifications.length} notiser markerade som lästa`);
    //         }
    //     } catch (e) {
    //         toast(e, 'Kunde inte markera notiser som lästa, försök igen');
    //     }
    // }, [dispatch]);

    // const markAsUnread = React.useCallback(async (selectedNotifications: NotificationType[]) => {
    //     try {
    //         await setUnreadNotifications(selectedNotifications.map((n) => n.id));
    //         dispatch(fetchAllNotifications());
    //         if (selectedNotifications.length === 1) {
    //             toast('1 notis markerad som oläst');
    //         } else {
    //             toast(`${selectedNotifications.length} notiser markerade som olästa`);
    //         }
    //     } catch (e) {
    //         toast(e, 'Kunde inte markera notiser som olästa, försök igen');
    //     }
    // }, [dispatch]);

    const removeNotifications = React.useCallback(async (selectedNotifications: NotificationType[]) => {
        try {
            await deleteNotifications(selectedNotifications.map((n) => n.id));
            dispatch(fetchAllNotifications());
            if (selectedNotifications.length === 1) {
                toast('1 notis borttagen');
            } else {
                toast(`${selectedNotifications.length} notiser borttagna`);
            }
        } catch (e) {
            toast(e, 'Kunde inte ta bort notiser, försök igen');
        }
    }, [dispatch]);

    return (
        <Container>
            <Header title="Notiser" />

            <Paper padding={0}>
                <Table
                    name="notifications"
                    defaultOrderBy="createdAt"
                    defaultOrderDirection="asc"
                    columns={columns}
                    data={notifications}
                    batchActions={[
                        // {
                        //     name: 'Markera som oläst',
                        //     icon: <Markunread />,
                        //     action: markAsUnread
                        // },
                        // {
                        //     name: 'Markera som läst',
                        //     icon: <Drafts />,
                        //     action: markAsRead
                        // },
                        {
                            name: 'Ta bort',
                            icon: <Delete />,
                            color: 'error',
                            action: removeNotifications
                        },
                    ]}
                    // getRowStyle={getRowStyle}
                    emptyText="Du har inga notiser."
                />
            </Paper>
        </Container>
    );
}
