import React from 'react';

import { TableCell, Tooltip, Typography } from '@mui/material';

type PropsType = {
    column: { [key: string]: any },
    columnIndex: number,
    rowIndex: number,
    row: any[],
    rows: any[][]
};

export default function DataImportValue(props: PropsType) {
    const {
        column,
        columnIndex,
        rowIndex,
        row,
        rows
    } = props;

    const value = React.useMemo(() => row[columnIndex], [columnIndex, row]);

    const isRequiredError = React.useMemo(() => (
        !value && column.isRequired
    ), [column.isRequired, value]);

    const isUniqueError = React.useMemo(() => (
        column.isUnique && rows.some((r, i) => i !== rowIndex && r[columnIndex] === value)
    ), [column.isUnique, columnIndex, rowIndex, rows, value]);

    const validation = React.useMemo(() => (
        column.validate
            ? column.validate({ value })
            : { isValid: true, errorText: '' }
    ), [column, value]);

    const error = isRequiredError || isUniqueError || !validation.isValid;
    const errorText = (
        // eslint-disable-next-line no-nested-ternary
        isRequiredError
            ? 'Obligatoriskt'
            : (isUniqueError
                ? 'Måste vara unikt'
                : validation.errorText
            )
    );

    const Component = React.useMemo(() => {
        const data = (
            <TableCell
                key={columnIndex}
                sx={error ? {
                    backgroundColor: '#FAA',
                    border: '1px solid #F88',
                } : undefined}
            >
                <Typography>
                    {
                        // eslint-disable-next-line no-nested-ternary
                        column.type === 'bool'
                            ? (value ? 'Ja' : 'Nej')
                            // eslint-disable-next-line no-nested-ternary
                            : (typeof value === 'boolean' ? (value ? 'Ja' : 'Nej') : value)
                    }
                </Typography>
            </TableCell>
        );

        return error && errorText ? (
            <Tooltip arrow title={errorText} placement="top-start">
                {data}
            </Tooltip>
        ) : data;
    }, [column.type, columnIndex, error, errorText, value]);

    return Component;
}
