import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

import {
    Block,
    Fetch,
    Header,
    List,
} from '../CommonComponents';
import { Container } from '../StyledComponents';

import { getTenantProfile } from '../../api/tenants';

import type { FetchComponentPropsType } from '../CommonComponents/Fetch/Fetch';
import { TenantProfileType } from '../../types';

const TenantProfileDetailsView: React.FC<FetchComponentPropsType<TenantProfileType>> = (props) => {
    const { entity } = props;

    const navigate = useNavigate();

    return (
        <Container>
            <Header
                title="Företagsuppgifter"
                CustomComponent={(
                    <Button
                        variant="contained"
                        startIcon={<EditIcon />}
                        onClick={() => navigate('/company-profile/edit')}
                    >
                        Redigera
                    </Button>
                )}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Block
                        title="Kontaktuppgifter"
                    >
                        <List
                            data={[{
                                name: 'Namn',
                                value: entity.name
                            }, {
                                name: 'Org. nummer',
                                value: entity.orgNumber
                            }, {
                                name: 'E-post',
                                value: entity.email
                            }, {
                                name: 'Telefon',
                                value: entity.phone
                            }, {
                                name: 'Hemsida',
                                value: entity.website
                            }]}
                        />
                    </Block>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Block
                        title="Faktureringsuppgifter"
                    >
                        <List
                            data={[{
                                name: 'E-post för faktura',
                                value: entity.billingEmail
                            }, {
                                name: 'Märkning',
                                value: entity.billingMark
                            }, {
                                name: 'Gata',
                                value: entity.billingStreet
                            }, {
                                name: 'Postnummer',
                                value: entity.billingZip
                            }, {
                                name: 'Postort',
                                value: entity.billingCity
                            }]}
                        />
                    </Block>
                </Grid>
            </Grid>
        </Container>
    );
};

export default function TenantProfileDetails() {
    const fetchProfile = React.useCallback(() => getTenantProfile(), []);

    return (
        <Fetch<TenantProfileType>
            fetch={fetchProfile}
            Component={TenantProfileDetailsView}
            name="företagsuppgifterna"
        />
    );
}
