import apiRequest from './api-request';

const resource = 'invites';

// eslint-disable-next-line import/prefer-default-export
export function sendInviteEmail(body: { emails: string[] }) {
    return apiRequest({
        method: 'POST',
        resource,
        body
    });
}
