import React from 'react';
import { debounce } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import {
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import {
    Add,
    CheckBoxOutlineBlank,
    CheckBox,
    Clear,
    FilterList,
    Info
} from '@mui/icons-material';

import {
    formatBool,
    formatDate,
    formatDateTime,
    formatNumber,
    formatYesNo
} from '../../../utils/parsing';

import TablePagination from './TablePagination';
import { useDispatch, useSelector } from '../../../redux/hooks';
import { selectTableOptions, setTableOptions } from '../../../redux/actions/tables';
import getTableFiltersByKey, { FiltersType } from './tableFilters';
import TableFilters from './TableFilters';
import Popup from '../Popup';
import toastMessage from '../../../utils/toast';

// All available filters for the table (generated from columns.canFilter = true)
// export type FiltersType = {
//     [key: string]: {
//         getName: (v: string) => string,
//         test: (r: any, v: string) => boolean
//     }
// };

export type FilterValuesType = {
    [key: string]: string
};

type TableOptionsType = {
    query: string,
    page: number,
    orderBy: string,
    orderDirection: 'asc' | 'desc',
    filterValues: FilterValuesType
}

export type ColumnTypeType = (
    'string'
    | 'number'
    | 'date'
    | 'datetime'
    | 'bool'
    | 'y/n'
    | 'currency'
    | 'km'
    | 'kg'
);

export type ColumnType = {
    name: string,
    key: string,
    type?: ColumnTypeType,
    getValue?: (obj: any) => any,
    canFilter?: boolean,
    canSearch?: boolean,
    isHidden?: boolean,
    width?: number,
    align?: string
};

type BatchActionType = {
    name: string,
    icon?: JSX.Element,
    endIcon?: JSX.Element,
    action: (a: any) => Promise<string>,
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    variant?: 'text' | 'outlined' | 'contained',
    getDisabled?: (a: any[]) => boolean,
    confirmText?: string
};

type PropsType = {
    name?: string,
    columns: ColumnType[],
    data: any[],
    maxHeight?: string,
    emptyText?: string,
    onRowClick?: (obj: any) => void,
    getRowStyle?: (obj: any) => any,
    keyField?: string,
    batchActions?: BatchActionType[],
    disableSort?: boolean,
    defaultOrderBy?: string,
    defaultOrderDirection?: 'asc' | 'desc',
    showPagination?: boolean,
    rowsPerPage?: number,
    onAddClick?: () => void
};

const { compare } = new Intl.Collator('sv', {
    numeric: true,
    sensitivity: 'accent',
    ignorePunctuation: true
});

const rightAlignTypes = [
    'number',
    'currency',
    // 'pieces',
    'km',
    'kg'
];

function getAlign({ align, type }: { align?: string, type?: string }) {
    return align === 'right' || rightAlignTypes.some((t) => t === type)
        ? 'right'
        : 'left';
}

export function getRowValue({ column, row }: { column?: ColumnType, row: any }) {
    // eslint-disable-next-line no-nested-ternary
    return !column ? '' : (
        // eslint-disable-next-line no-nested-ternary
        typeof column.getValue === 'function' ? column.getValue(row) : (
            column.key && column.key in row
                ? row[column.key]
                : ''
        )
    );
}

const getFormattedRowValue = ({ column, row }: { column: ColumnType, row: any }) => {
    const value = getRowValue({ column, row });

    if (column.type === 'date') {
        return formatDate(value);
    }
    if (column.type === 'datetime') {
        return formatDateTime(value);
    }
    if (column.type === 'number') {
        return formatNumber(value);
    }
    if (column.type === 'bool') {
        return formatBool(value);
    }
    if (column.type === 'y/n') {
        return formatYesNo(value);
    }
    if (column.type === 'currency') {
        return formatNumber(value, 'SEK');
    }
    // if (column.type === 'pieces') {
    //     return formatNumber(value, 'st');
    // }
    if (column.type === 'km') {
        return formatNumber(value, 'km');
    }
    if (column.type === 'kg') {
        return formatNumber(value, 'kg');
    }
    return value;
};

export default function BasicTable(props: PropsType) {
    const {
        columns,
        data,
        showPagination = false,
        maxHeight,
        emptyText = 'Det finns ingen data.',
        onRowClick,
        onAddClick,
        getRowStyle,
        keyField = 'id',
        batchActions,
        name = '',
        defaultOrderBy,
        defaultOrderDirection,
        disableSort,
        rowsPerPage = 20
    } = props;

    // TABLE OPTIONS
    // =============
    const dispatch = useDispatch();

    const storedTableOptions = useSelector(selectTableOptions(name));
    const tableOptions: TableOptionsType = ({
        query: '',
        page: 1,
        orderBy: defaultOrderBy || columns[0].key,
        orderDirection: defaultOrderDirection || 'desc',
        filterValues: {},
        ...storedTableOptions
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setQuery = React.useCallback(debounce(({ target: { value } }) => {
        dispatch(setTableOptions({ name, query: value }));
    }, 400), [dispatch, name]);

    const setFilterValues = React.useCallback((filterValues) => {
        // dispatch(setTableOptions({ name, filterValues: { ...tableOptions.filterValues, filterValues } }));
        dispatch(setTableOptions({ name, filterValues }));
    }, [dispatch, name]);

    const setPage = React.useCallback((page) => {
        dispatch(setTableOptions({ name, page }));
    }, [dispatch, name]);

    const [editFilterKey, setEditFilterKey] = React.useState<string | null>(null);

    // DATA
    // ====
    const searchRef = React.useRef<HTMLInputElement>();
    const searchKeys = React.useMemo(() => (
        columns.filter((c) => c.canSearch).map(((c) => c.key))
    ), [columns]);

    const filterColumns = React.useMemo(() => (
        columns.filter((c) => c.canFilter)
    ), [columns]);

    const filters = React.useMemo<FiltersType>(() => (
        filterColumns.flatMap(getTableFiltersByKey)
    ), [filterColumns]);

    // REFACTOR THIS:
    const filteredData = React.useMemo(() => {
        let rows = data;
        if (tableOptions.query && searchKeys.length) {
            rows = rows.filter((row) => searchKeys.some((sk) => {
                const column = columns.find((c) => c.key === sk);
                const value = getRowValue({ column, row });
                return typeof value !== 'undefined' && typeof value !== 'object'
                    ? `${value}`.toLowerCase().includes(tableOptions.query.trim().toLowerCase())
                    : false;
            }));
        }
        if (Object.keys(tableOptions.filterValues).length) {
            rows = rows.filter((row) => !Object.entries<string>(tableOptions.filterValues).some(([key, value]) => (
                filters.find((f) => f.key === key)?.test(row, value) === false
            )));
        }
        return rows;
    }, [data, tableOptions.query, tableOptions.filterValues, searchKeys, columns, filters]);

    const sortedFilteredData = React.useMemo(() => {
        const column = columns.find((c) => c.key === tableOptions.orderBy);
        return (column && tableOptions.orderBy
            ? filteredData.slice().sort((a, b) => compare(
                `${getFormattedRowValue({ column, row: a })}`.replace(/\s/g, ''),
                `${getFormattedRowValue({ column, row: b })}`.replace(/\s/g, '')
            ) * (tableOptions.orderDirection === 'asc' ? -1 : 1))
            : filteredData);
    }, [columns, filteredData, tableOptions.orderBy, tableOptions.orderDirection]);

    // PAGINATION
    // ==========

    const firstPage = 1;
    const lastPage = React.useMemo(() => (
        Math.ceil(filteredData.length / rowsPerPage)
    ), [rowsPerPage, filteredData.length]);

    const paginatedSortedFilteredData = React.useMemo(() => {
        if (!showPagination) {
            return sortedFilteredData;
        }
        const cappedPage = Math.max(
            Math.min(
                tableOptions.page,
                lastPage
            ),
            firstPage
        );
        return sortedFilteredData.slice(
            (cappedPage - 1) * rowsPerPage,
            (cappedPage) * rowsPerPage
        );
    }, [lastPage, tableOptions.page, rowsPerPage, showPagination, sortedFilteredData]);

    // SELECTS
    // =======

    const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

    const isItemSelected = React.useCallback((item) => (
        selectedItems.some((i) => i[keyField] === item[keyField])
    ), [selectedItems, keyField]);

    const toggleItemSelect = React.useCallback((item) => {
        if (isItemSelected(item)) {
            setSelectedItems((sis) => sis.filter((i) => i[keyField] !== item[keyField]));
        } else {
            setSelectedItems((sis) => [...sis, item]);
        }
    }, [isItemSelected, keyField]);

    const isAllSelected = React.useMemo(() => {
        return selectedItems.length === data.length;
    }, [data, selectedItems]);

    const toggleAllSelect = React.useCallback(() => {
        if (isAllSelected) {
            setSelectedItems([]);
        } else {
            setSelectedItems(paginatedSortedFilteredData);
        }
    }, [paginatedSortedFilteredData, isAllSelected]);

    const SelectAllCheckbox = React.useMemo(() => {
        return isAllSelected ? <CheckBox /> : <CheckBoxOutlineBlank />;
    }, [isAllSelected]);

    // Uppdatera selected items om data ändras (genom batch actions eller annat)
    React.useEffect(() => {
        setSelectedItems((sis) => sis.filter((si) => data.some((d) => d[keyField] === si[keyField])));
    }, [data, keyField]);

    // Handle batch actions
    // --------------------

    type SelectedBatchActionType = {
        title: string,
        body: string,
        action?: (a: any) => Promise<string>,
        isOpen: boolean
    };

    const [selectedBatchAction, setSelectedBatchAction] = React.useState<SelectedBatchActionType>({
        title: '',
        body: '',
        action: undefined,
        isOpen: false
    });

    const closeBatchActionPopup = React.useCallback(() => {
        setSelectedBatchAction((sba) => ({
            ...sba,
            action: undefined,
            isOpen: false
        }));
    }, []);

    const commitBatchAction = React.useCallback(async (action?: (a: any) => Promise<string>) => {
        if (action && selectedItems) {
            try {
                const resultText = await action(selectedItems);
                if (resultText) {
                    setSelectedBatchAction((sba) => ({
                        ...sba,
                        body: resultText,
                        action: undefined,
                        isOpen: true
                    }));
                } else {
                    closeBatchActionPopup();
                }
            } catch (e) {
                closeBatchActionPopup();
                toastMessage(e, 'Kunde inte hantera raderna, försök igen');
            }
        }
    }, [closeBatchActionPopup, selectedItems]);

    const onBatchActionClick = React.useCallback((batchAction) => {
        setSelectedBatchAction({
            title: batchAction.name,
            body: batchAction.confirmText,
            action: batchAction.action,
            isOpen: !!batchAction.confirmText
        });
        if (!batchAction.confirmText) {
            commitBatchAction(batchAction.action);
        }
    }, [commitBatchAction]);

    return (
        <>
            {!!batchActions && (
                <Popup
                    open={selectedBatchAction.isOpen}
                    title={selectedBatchAction.title}
                    body={selectedBatchAction.body}
                    cancelLabel={!selectedBatchAction.action ? 'Stäng' : undefined}
                    handleOk={
                        selectedBatchAction.action
                            ? () => commitBatchAction(selectedBatchAction.action)
                            : undefined
                    }
                    handleClose={closeBatchActionPopup}
                />
            )}

            {!data.length ? (
                <div style={{ padding: 20 }}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Info color="action" />
                        </Grid>
                        <Grid item xs>
                            <Typography sx={{ ml: 1 }}>
                                {emptyText}
                            </Typography>
                        </Grid>
                        {onAddClick && (
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    startIcon={<Add />}
                                    aria-label="Skapa ny"
                                    onClick={onAddClick}
                                >
                                    Skapa ny
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </div>
            ) : (
                <TableContainer
                    sx={{ ...(maxHeight ? { maxHeight } : {}) }}
                >
                    {(searchKeys.length || showPagination) && (
                        <Grid
                            container
                            spacing={2}
                            justifyContent="flex-start"
                            alignContent="flex-end"
                            alignItems="flex-start"
                            sx={{ p: 2 }}
                        >
                            {!!searchKeys.length && (
                                <Grid item xs={6}>
                                    <TextField
                                        inputRef={searchRef}
                                        label="Sök"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        defaultValue={tableOptions.query}
                                        // InputLabelProps={value ? { shrink: true } : {}}
                                        // value={query}
                                        onChange={setQuery}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" sx={{ mr: -1 }}>
                                                    <IconButton
                                                        aria-label="Rensa sökterm"
                                                        onClick={() => {
                                                            dispatch(setTableOptions({ name, query: '' }));
                                                            if (searchRef.current) {
                                                                searchRef.current.value = '';
                                                            }
                                                        }}
                                                    >
                                                        <Clear />
                                                    </IconButton>

                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            )}

                            <Grid item xs>
                                {!!filterColumns.length && (
                                    <Button
                                        startIcon={<FilterList />}
                                        aria-label="Filtrera"
                                        onClick={() => setEditFilterKey('all')}
                                        sx={{ mr: 1 }}
                                    >
                                        Filter
                                    </Button>

                                )}
                                {!!onAddClick && (
                                    <Button
                                        startIcon={<Add />}
                                        aria-label="Skapa ny"
                                        onClick={onAddClick}
                                    >
                                        Skapa ny
                                    </Button>
                                )}
                            </Grid>

                            {showPagination && (
                                <Grid item>
                                    <TablePagination
                                        page={tableOptions.page}
                                        firstPage={firstPage}
                                        lastPage={lastPage}
                                        rowsPerPage={rowsPerPage}
                                        rowCount={filteredData.length}
                                        setPage={setPage}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    )}
                    <TableFilters
                        filterColumns={filterColumns}
                        filters={filters}
                        filterValues={tableOptions.filterValues}
                        editFilterKey={editFilterKey}
                        setEditFilterKey={setEditFilterKey}
                        setFilterValues={setFilterValues}
                    />

                    {!paginatedSortedFilteredData.length ? (
                        <div style={{ padding: 20 }}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Info color="action" />
                                </Grid>
                                <Grid item xs>
                                    <Typography sx={{ ml: 1 }}>
                                        Inga träffar, justera din sökning
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    ) : (
                        <>
                            {batchActions && (
                                <Grid container spacing={1} alignItems="center" justifyContent="space-between" sx={{ p: 2, pb: 0 }}>
                                    <Grid item xs>
                                        <Typography>
                                            {selectedItems.length === 0 && 'Välj rader att hantera'}
                                            {selectedItems.length === 1 && '1 rad vald'}
                                            {selectedItems.length > 1 && `${selectedItems.length} rader valda`}
                                        </Typography>
                                    </Grid>
                                    {batchActions.map((ba) => (
                                        <Grid item key={ba.name}>
                                            <Button
                                                variant={ba.variant || 'outlined'}
                                                startIcon={ba.icon}
                                                endIcon={ba.endIcon}
                                                onClick={() => onBatchActionClick(ba)}
                                                disabled={!selectedItems.length || (ba.getDisabled && ba.getDisabled(selectedItems))}
                                                color={ba.color}
                                            >
                                                {ba.name}
                                            </Button>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                            <Table
                                stickyHeader
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        {batchActions && (
                                            <TableCell
                                                padding="checkbox"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toggleAllSelect();
                                                }}
                                            >
                                                <IconButton
                                                    aria-label="Markera"
                                                >
                                                    {SelectAllCheckbox}
                                                </IconButton>
                                            </TableCell>
                                        )}
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.key}
                                                align={getAlign(column)}
                                                sx={column.width ? { width: column.width } : {}}
                                            >
                                                {name ? (
                                                    <TableSortLabel
                                                        key={column.key}
                                                        active={!disableSort && !!column.name && tableOptions.orderBy === column.key}
                                                        direction={tableOptions.orderDirection === 'asc' ? 'asc' : 'desc'}
                                                        hideSortIcon={disableSort}
                                                        onClick={() => {
                                                            if (!disableSort && name) {
                                                                dispatch(setTableOptions({
                                                                    name,
                                                                    orderBy: column.key,
                                                                    orderDirection: (
                                                                        tableOptions.orderDirection === 'asc' ? 'desc' : 'asc'
                                                                    )
                                                                }));
                                                            }
                                                        }}
                                                    >
                                                        {column.name}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.name
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedSortedFilteredData.map((row) => (
                                        <TableRow
                                            key={row[keyField]}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 },
                                                backgroundColor: '#FFF',
                                                ...(batchActions && isItemSelected(row) ? { backgroundColor: 'secondary.main' } : {}),
                                                ...(getRowStyle ? getRowStyle(row) : {}),
                                                ':hover': {
                                                    cursor: onRowClick || batchActions ? 'pointer' : 'inherit',
                                                    backgroundColor: {
                                                        filter: 'brightness(0.95)'
                                                    }
                                                }
                                            }}
                                            onClick={() => (
                                                // eslint-disable-next-line no-nested-ternary
                                                onRowClick
                                                    ? onRowClick(row)
                                                    : (
                                                        batchActions
                                                            ? toggleItemSelect(row)
                                                            : null
                                                    )
                                            )}
                                        >
                                            {batchActions && (
                                                <TableCell
                                                    padding="checkbox"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        toggleItemSelect(row);
                                                    }}
                                                >
                                                    <IconButton
                                                        aria-label="Markera"
                                                    >
                                                        {
                                                            isItemSelected(row)
                                                                ? <CheckBox />
                                                                : <CheckBoxOutlineBlank />
                                                        }
                                                    </IconButton>
                                                </TableCell>
                                            )}
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.key}
                                                    align={getAlign(column)}
                                                >
                                                    {getFormattedRowValue({ column, row })}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </>
                    )}
                </TableContainer>
            )}
        </>
    );
}
