import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Table } from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';

import { useDispatch, useSelector } from '../../redux/hooks';
import { fetchAllIntegrations, selectAllIntegrations } from '../../redux/actions/integrations';
import parseIntegrationType from './integrationTypes';
import { IntegrationForListType } from '../../types';

export default function Integrations() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const integrations = useSelector(selectAllIntegrations());

    React.useEffect(() => {
        dispatch(fetchAllIntegrations());
    }, [dispatch]);

    const onRowClick = React.useCallback((integration: { id: number }) => {
        navigate(`/integrations/${integration.id}`);
    }, [navigate]);

    const columns = React.useMemo(() => ([{
        name: 'Integration',
        key: 'type',
        getValue: (i: IntegrationForListType) => parseIntegrationType(i.type)
    }, {
        name: 'Aktiv',
        key: 'isActive',
        type: 'bool' as 'bool'
    }]), []);

    return (
        <Container>
            <Header
                title="Integrationer"
                addUrl="/integrations/signup"
            />

            <Paper padding={0}>
                <Table
                    name="integrations"
                    columns={columns}
                    data={integrations}
                    onRowClick={onRowClick}
                    emptyText="Ni har inga integrationer."
                />
            </Paper>
        </Container>
    );
}
