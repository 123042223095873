import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import {
    ArrowBack as ArrowBackIcon,
    Add as AddIcon,
    Edit as EditIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

type PropsType = {
    title: string,
    subtitle?: string,
    hideBackButton?: boolean,
    addUrl?: string | null,
    addUrlTitle?: string,
    editUrl?: string | null,
    editUrlTitle?: string | null,
    CustomComponent?: JSX.Element | null
};

const Header: React.FC<PropsType> = (props) => {
    const {
        title,
        subtitle,
        addUrl,
        addUrlTitle,
        editUrl,
        editUrlTitle,
        hideBackButton = false,
        CustomComponent
    } = props;

    const navigate = useNavigate();

    return (
        <Grid container spacing={1} sx={{ pb: 2 }} alignItems="flex-end">
            <Grid item xs>
                <Typography variant="h4" noWrap gutterBottom={!subtitle}>
                    {title}
                </Typography>
                {subtitle && (
                    <Typography variant="h6" noWrap>
                        {subtitle}
                    </Typography>
                )}
            </Grid>
            {!hideBackButton && (
                <Grid item>
                    <Button variant="text" startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
                        Tillbaka
                    </Button>
                </Grid>
            )}
            {CustomComponent && (
                <Grid item>
                    {CustomComponent}
                </Grid>
            )}
            {editUrl && (
                <Grid item>
                    <Button variant="contained" startIcon={<EditIcon />} onClick={() => navigate(editUrl)}>
                        {editUrlTitle || 'Redigera'}
                    </Button>
                </Grid>
            )}
            {addUrl && (
                <Grid item>
                    <Button variant="contained" startIcon={<AddIcon />} onClick={() => navigate(addUrl)}>
                        {addUrlTitle || 'Skapa ny'}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export default Header;
