import React from 'react';
import Loading from './Loading';
import NotFound from './NotFound';

export type FetchComponentPropsType<EntityType> = {
    entity: EntityType,
    refetch: () => void,
    isFetching: boolean
};

type PropsType<EntityType> = {
    fetch: () => Promise<EntityType> | void,
    data?: EntityType | null,
    Component: React.FC<FetchComponentPropsType<EntityType>>,
    name?: string,
    notFoundDescription?: string
};

function Fetch<EntityType>(props: PropsType<EntityType>): JSX.Element {
    const {
        fetch,
        data,
        Component,
        name,
        notFoundDescription
    } = props;

    const [isFetching, setIsFetching] = React.useState(false);
    const [entity, setEntity] = React.useState<EntityType | null>(null);

    const refetch = React.useCallback(async () => {
        try {
            setIsFetching(true);
            const newEntity = await fetch();
            if (newEntity !== undefined) { // If direct (non redux) fetch
                setEntity(newEntity);
            }
            setIsFetching(false);
        } catch (e) {
            setIsFetching(false);
        }
    }, [fetch]);

    React.useEffect(() => {
        refetch();
    }, []);

    React.useEffect(() => {
        if (data !== undefined) {
            setEntity(data);
        }
    }, [data]);

    if (!entity) {
        if (isFetching) {
            return <Loading name={name} />;
        }
        return <NotFound name={name} description={notFoundDescription} />;
    }

    return (
        <Component entity={entity} isFetching={isFetching} refetch={refetch} />
    );
}

export default Fetch;
