import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CreateCompany from './CreateCompany';

import ForgotPassword from './ForgotPassword';
import Login from './Login';
import RegisterCompany from './RegisterCompany';
import RegisterUser from './RegisterUser';

const AuthRouter: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/register-company" element={<RegisterCompany />} />
                <Route path="/create-company" element={<CreateCompany />} />
                <Route path="/register-user" element={<RegisterUser />} />
                <Route path="*" element={<Login />} />
            </Routes>
        </BrowserRouter>
    );
};

export default AuthRouter;
