import React from 'react';
import { Circle } from '@react-google-maps/api';
import { COLOR_RED } from '../../../theme';

const circleOptions = {
    strokeColor: COLOR_RED,
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: COLOR_RED,
    fillOpacity: 0.2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1
};

type PropsType = {
    lat: number,
    lng: number
};

export default function ApproximationCircle({ lat, lng }: PropsType) {
    return (
        <Circle
            center={{ lat, lng }}
            radius={3000}
            options={circleOptions}
        />
    );
}
