import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    fetchAllCapacities,
    selectFetchingAllCapacities,
    selectAllCapacities
} from '../../../redux/actions/capacities';
import { CapacityForListType } from '../../../types';
import { Table } from '../../CommonComponents';
import { FetchPropsType } from '../../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../../CommonComponents/Fetch/FetchRedux';
import { getOrderDate, getOrderExpiryDate } from '../../../utils/order';

const columns = [{
    name: 'Datum',
    key: 'customDate',
    getValue: getOrderDate
}, {
    name: 'Start',
    key: 'pickupCity',
}, {
    name: 'Mål',
    key: 'deliveryCity',
}, {
    name: 'Totalvikt',
    key: 'grossWeight',
    type: 'kg' as 'kg'
}, {
    name: 'Flakmeter',
    key: 'loadingMeters',
    type: 'number' as 'number'
}, {
    name: 'Publicerad',
    key: 'isPublished',
    type: 'y/n' as 'y/n'
}, {
    name: 'Uppdragsförslag',
    key: 'orderCount',
    type: 'number' as 'number'
}];

function Capacities(props: FetchPropsType<CapacityForListType[]>) {
    const { entity, isFetching } = props;
    const navigate = useNavigate();

    const onRowClick = React.useCallback((order: { id: number }) => {
        navigate(`/capacities/${order.id}`);
    }, [navigate]);

    const now = React.useMemo(() => new Date(), []);

    return (
        <Table
            name="capacities"
            columns={columns}
            data={entity}
            onRowClick={onRowClick}
            emptyText={isFetching ? 'Hämtar ledig kapacitet...' : 'Ni har ingen publicerad ledig kapacitet.'}
            getRowStyle={(order) => (
                getOrderExpiryDate(order) < now
                    ? ({ backgroundColor: '#FCC' })
                    : undefined
            )}
        />
    );
}

export default function CapacitiesFetched({ hasRendered }: { hasRendered?: boolean }) {
    return (
        <FetchRedux<CapacityForListType[]>
            fetchEntity={fetchAllCapacities()}
            selectEntity={selectAllCapacities()}
            selectIsFetching={selectFetchingAllCapacities()}
            Component={Capacities}
            name="delade kapaciteterna"
            // initialFetch={!hasRendered}
        />
    );
}
