import { Delete, Edit } from '@mui/icons-material';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import React from 'react';
import { validateEmail, validatePhone } from '../../CommonComponents/Form/validations/validations';
import { RowType, rowHasError } from './GroupMemberImport';
import GroupMemberImportForm from './GroupMemberImportForm';

export type EditingRowType = Partial<RowType> & { rowIndex?: number } | null;

type PropsType = {
    rows: any,
    changeRow: ({ rowIndex, value }: { rowIndex: number, value: { [key: string]: string | boolean | number } }) => void
    removeRow: ({ rowIndex }: { rowIndex: number }) => void,
    isOwner: boolean,
    tenants: {
        tenantId: number,
        tenantName: string,
        isOwner: boolean,
        isInvited: boolean,
        isAccepted: boolean,
        canInvite: boolean,
        canPublish: boolean
    }[],
    isPending: boolean
};

export function getRowStatus(row: RowType) {
    const hasValidEmail = row.invitedEmail && validateEmail({ value: row.invitedEmail }).isValid;
    const hasValidPhone = row.invitedPhone && validatePhone({ value: row.invitedPhone }).isValid;
    if (!row.tenantId) {
        return hasValidEmail || hasValidPhone
            ? `Kunde inte hitta företaget, skickar en inbjudan till ${[row.invitedEmail, row.invitedPhone].filter((v) => !!v).join(' och ')
            }.`
            : 'Kunde inte hitta företaget, justera din sökning eller komplettera med kontaktuppgifter för att skicka en inbjudan.';
    }
    if (!row.isRegistered) {
        return hasValidEmail || hasValidPhone
            ? `Företaget har ännu inte registrerat sig, skickar en inbjudan till ${[row.invitedEmail, row.invitedPhone].filter((v) => !!v).join(' och ')
            }.`
            : 'Företaget har ännu inte registrerat sig, komplettera med kontaktuppgifter för att skicka inbjudan.';
    }
    if (row.isMember) {
        return 'Företaget är redan medlem i gruppen.';
    }
    return 'Företaget kommer att bjudas in till gruppen.';
}

const GroupMemberImportTable: React.FC<PropsType> = (props) => {
    const {
        rows,
        changeRow,
        removeRow,
        isOwner,
        tenants,
        isPending
    } = props;

    const [editingRow, setEditingRow] = React.useState<EditingRowType>(null);

    return (
        <>
            {!!editingRow && (
                <GroupMemberImportForm
                    editingRow={editingRow}
                    setEditingRow={setEditingRow}
                    changeRow={changeRow}
                    isOwner={isOwner}
                    tenants={tenants}
                />
            )}
            <Table
                stickyHeader
                aria-label="Group member imoprt table"
                size="small"
            >
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>
                            Företag
                        </TableCell>
                        <TableCell>
                            Status
                        </TableCell>
                        <TableCell>
                            Kan publicera
                        </TableCell>
                        {/* <TableCell>
                            Kan se/bjuda in medlemmar
                        </TableCell> */}
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row: RowType, rowIndex: number) => (
                        <TableRow
                            // eslint-disable-next-line react/no-array-index-key
                            key={rowIndex}
                            sx={
                                // eslint-disable-next-line no-nested-ternary
                                rowHasError(row)
                                    ? { backgroundColor: '#FAA' }
                                    : (
                                        row.isMember
                                            ? { backgroundColor: '#EEE' }
                                            : undefined
                                    )
                            }
                        >
                            <TableCell>
                                <IconButton
                                    aria-label="Redigera"
                                    onClick={() => setEditingRow({ ...row, rowIndex })}
                                    disabled={isPending}
                                >
                                    <Edit />
                                </IconButton>
                            </TableCell>

                            <TableCell>
                                <Typography>
                                    {row.name || '[Ingen träff]'}
                                </Typography>
                                <Typography variant="subtitle2">
                                    {row.orgNumber}
                                </Typography>
                                <Typography variant="caption">
                                    {`Sökterm: "${row.query}"`}
                                </Typography>
                            </TableCell>

                            <TableCell>
                                {getRowStatus(row)}
                            </TableCell>

                            <TableCell>
                                {(row.tenantId && !row.isMember && (row.canPublish ? 'Ja' : 'Nej')) || ''}
                            </TableCell>

                            {/* <TableCell>
                                {(row.tenantId && !row.isMember && (row.canInvite ? 'Ja' : 'Nej')) || ''}
                            </TableCell> */}
                            <TableCell align="right">
                                <IconButton
                                    aria-label="Ta bort"
                                    onClick={() => removeRow({ rowIndex })}
                                    disabled={isPending}
                                >
                                    <Delete />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

export default GroupMemberImportTable;
