import { Grid } from '@mui/material';
import React from 'react';
import { PopupForm } from '../Form';
import DataImportRowFormField from './DataImportRowFormField';

type PropsType = {
    columns: any,
    editingEntity: { [key: string]: any } | null,
    setEditingEntity: (a: any) => void,
    changeRow: ({ rowIndex, row }: { rowIndex: number, row: (string | boolean | number)[] }) => void
};

const DataImportRowForm: React.FC<PropsType> = (props) => {
    const {
        columns,
        editingEntity,
        setEditingEntity,
        changeRow
    } = props;

    const commit = React.useCallback(() => {
        if (editingEntity) {
            changeRow({
                rowIndex: editingEntity?.rowIndex,
                row: columns.reduce((result: any[], column: any) => (
                    [...result, editingEntity[column.key]]
                ), [])
            });
        }
        setEditingEntity(null);
    }, [changeRow, columns, editingEntity, setEditingEntity]);

    return (
        <PopupForm
            open={!!editingEntity}
            title="Redigera importrad"
            handleClose={() => setEditingEntity(null)}
            handleOk={commit}
        >
            <Grid container spacing={2}>
                {columns.map((column: any, columnIndex: number) => (
                    <Grid item xs={12} key={column.key}>
                        <DataImportRowFormField
                            column={column}
                            editingEntity={editingEntity}
                            setEditingEntity={setEditingEntity}
                        />
                    </Grid>
                ))}
            </Grid>
        </PopupForm>
    );
};

export default DataImportRowForm;
