/* eslint-disable react/destructuring-assignment */
import React from 'react';
import InputField from './InputField';
import { validateOrgNumber } from '../validations/validations';

type PropsType = {
    name: string,
    label: string,
    value: string,
    required?: boolean,
    helperText?: string,
    sx?: { [key: string]: string | number },
    variant?: 'filled' | 'outlined' | 'standard',
    onChange: (a: any) => void
};

export default function TextField(props: PropsType) {
    const { onChange, ...baseProps } = props;

    const validations = [validateOrgNumber({ value: props.value })];

    const customOnChange = ({ target: { value } }: { target: { value: string } }) => {
        if (typeof onChange === 'function') {
            const isErasing = props.value.length > value.length;

            if (!isErasing && value.length === 6) {
                return onChange({ [props.name]: `${value}-` });
            }
            if (isErasing && (value.length === 6 || value.length === 7)) {
                return onChange({ [props.name]: value.slice(0, 5) });
            }
            if (value.length < 12) {
                return onChange({ [props.name]: value });
            }
            return null;
        }
        return null;
    };

    return (
        <InputField
            {...baseProps}
            validations={validations}
            onChange={customOnChange}
        />
    );
}
