import tables from '../slices/tables';
import type { RootStateType } from '../index';

export const {
    setTableSort,
    setTableOptions
} = tables.actions;

// selectTableSort should be removed when new table is fully implemented
export const selectTableSort = (name: string) => (state: RootStateType) => state.tables.sortByName[name];
export const selectTableOptions = (name: string) => (state: RootStateType) => state.tables.options[name];
