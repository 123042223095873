import publicApiRequest from './public-api-request';
// import { sendErrorReport } from '../errors';

const endpoint = 'user';

export async function signup(body: unknown) {
    return publicApiRequest({
        method: 'POST',
        resource: `${endpoint}/signup`,
        body
    });
}

export async function sendResetPasswordCode(body: unknown) {
    return publicApiRequest({
        method: 'POST',
        resource: `${endpoint}/reset-password`,
        body
    });
}
