import publicApiRequest from './public-api-request';
// import { sendErrorReport } from '../errors';

const endpoint = 'company';

export async function getCompanyInfoFromOrgNumber(orgNumber: string) {
    try {
        return await publicApiRequest({
            method: 'POST',
            resource: `${endpoint}/info`,
            body: { orgNumber }
        });
    } catch (e) {
        // Fail silently
        return ({});
    }
}

export async function searchCompanies(query?: string) {
    try {
        return await publicApiRequest({
            method: 'POST',
            resource: `${endpoint}/search`,
            body: { query }
        });
    } catch (e) {
        // Fail silently
        return [];
    }
}

export async function signup(body: unknown) {
    return publicApiRequest({
        method: 'POST',
        resource: `${endpoint}/signup`,
        body
    });
}
