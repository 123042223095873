import watchlists from '../slices/watchlists';
import type { RootStateType, ThunkDispatchType } from '../index';

import {
    getWatchlists
} from '../../api/watchlists';
import { filterMarketEntities } from '../../utils/filter-market-entities';
import { pickLastByKey } from '../../utils';

export function fetchAllWatchlists() {
    return async (dispatch: ThunkDispatchType, getState: () => RootStateType) => {
        try {
            dispatch(watchlists.actions.fetchAll());
            const data = (await getWatchlists()).map((w: any) => {
                const watchlistEntities = filterMarketEntities({
                    entities: [...getState().market.allOpenJobs, ...getState().market.allOpenCapacities],
                    filters: w
                });

                return ({
                    ...w,
                    publishedCount: watchlistEntities.length,
                    lastPublishedDate: pickLastByKey(watchlistEntities, 'publishedAt')
                });
            });
            dispatch(watchlists.actions.allFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching watchlists, set redux error state and handle in errorBoundary.', e);
            dispatch(watchlists.actions.allFetched({ data: [] }));
        }
    };
}

export const selectAllWatchlists = () => (state: RootStateType) => state.watchlists.all;
export const selectFetchingAllWatchlists = () => (state: RootStateType) => state.watchlists.fetchingAll;
