import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';

import {
    useChangeable,
    FullForm,
    AutocompleteField,
    BoolField,
    TextField,
    SliderField,
    RangeField
} from '../CommonComponents/Form';

import { LocationPicker, SectionDivider } from '../CommonComponents';
import FilterMap from '../CommonComponents/GoogleMap/FilterMap';
import type { LocationType } from '../CommonComponents/GoogleMap/LocationPicker';

import {
    createWatchlist,
    deleteWatchlist,
    getWatchlist,
    updateWatchlist
} from '../../api/watchlists';

import toast from '../../utils/toast';
import { fetchAllGroups, selectMyGroups } from '../../redux/actions/groups';
import { useDispatch } from '../../redux/hooks';
import { defaultMarketFilters, marketFilterLimits } from '../../redux/slices/market';

export default function WatchlistForm() {
    const { id } = useParams();
    const [entity, onChange] = useChangeable(defaultMarketFilters);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const groups = useSelector(selectMyGroups());

    React.useEffect(() => {
        (async () => {
            if (id) {
                const watchlist = await getWatchlist(id);
                onChange(watchlist);
            }
        })();
    }, [id, onChange]);

    const fetchGroups = React.useCallback(() => {
        dispatch(fetchAllGroups());
    }, [dispatch]);

    const setPickupLocation = React.useCallback((d: any) => {
        onChange({
            pickupLocationName: 'Egen plats',
            pickupLocationLat: d.latLng.lat(),
            pickupLocationLng: d.latLng.lng()
        });
    }, [onChange]);

    const setDeliveryLocation = React.useCallback((d: any) => {
        onChange({
            deliveryLocationName: 'Egen plats',
            deliveryLocationLat: d.latLng.lat(),
            deliveryLocationLng: d.latLng.lng()
        });
    }, [onChange]);

    const onSubmit = React.useCallback(async () => {
        try {
            if (id) {
                await updateWatchlist(id, entity);
                toast('Bevakning uppdaterad');
                navigate(-1);
            } else {
                const createdWatchlist = await createWatchlist(entity);
                toast('Bevakning skapad');
                navigate(`/watchlists/${createdWatchlist.id}`, { replace: true });
            }
        } catch (e: any) {
            toast(e, `Kunde inte ${id ? 'uppdatera' : 'skapa'} bevakningen, försök igen`);
        }
    }, [id, entity, navigate]);

    const onDelete = React.useCallback(async () => {
        try {
            if (id) {
                await deleteWatchlist(id);
                toast('Bevakning borttagen');
                navigate('/watchlists', { replace: true });
            }
        } catch (e: any) {
            toast(e, 'Kunde inte ta bort bevakningen, försök igen');
        }
    }, [id, navigate]);

    return (
        <FullForm
            title={`${entity.id ? 'Redigera' : 'Skapa'} bevakning`}
            entity={entity}
            onSubmit={onSubmit}
            onDelete={entity.id ? onDelete : undefined}
        >
            <Grid container spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
                <Grid item xs={12} lg={6}>

                    <SectionDivider label="Grundläggande" marginTop={0} />

                    <Grid container spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                            <TextField
                                name="name"
                                label="Namn"
                                value={entity.name}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={0} justifyContent="space-between" sx={{ mb: 2 }}>
                        <Grid item xs={12} md={6}>
                            <BoolField
                                name="isJob"
                                label="Uppdrag"
                                value={entity.isJob}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <BoolField
                                name="isCapacity"
                                label="Kapacitet"
                                value={entity.isCapacity}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BoolField
                                name="takesOffer"
                                label="Exkludera externa"
                                value={entity.takesOffer}
                                onChange={({ takesOffer }) => onChange({ takesOffer })}
                            />
                        </Grid>
                    </Grid>

                    <SectionDivider label="Område" marginBottom={1} />

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <LocationPicker
                                label="Upphämtningsplats"
                                defaultText={entity.pickupLocationName}
                                onChange={(location: LocationType | null) => {
                                    onChange({
                                        pickupLocationQuery: location ? `${location.city || 'Namnlös'}` : '',
                                        pickupLocationName: location ? `${location.city || 'Namnlös'}` : '',
                                        pickupLocationLat: location ? location.lat : null,
                                        pickupLocationLng: location ? location.lng : null
                                    });
                                }}
                            />
                            {entity.pickupLocationLat && entity.pickupLocationLng && (
                                <Typography variant="caption">
                                    Dra kartnålen (U) för att finjustera positionen.
                                </Typography>
                            )}
                        </Grid>

                        {entity.pickupLocationLat && entity.pickupLocationLng && (
                            <Grid item xs={12}>
                                <SliderField
                                    name="pickupLocationRadius"
                                    label="Sökradie, upphämtning [km]"
                                    value={entity.pickupLocationRadius / 1000}
                                    minValue={marketFilterLimits.minLocationRadius}
                                    maxValue={marketFilterLimits.maxLocationRadius}
                                    onChange={(v) => onChange({ pickupLocationRadius: v * 1000 })}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <LocationPicker
                                label="Leveranssplats"
                                defaultText={entity.deliveryLocationName}
                                onChange={(location: LocationType | null) => {
                                    onChange({
                                        deliveryLocationQuery: location ? `${location.city || 'Namnlös'}` : '',
                                        deliveryLocationName: location ? `${location.city || 'Namnlös'}` : '',
                                        deliveryLocationLat: location ? location.lat : null,
                                        deliveryLocationLng: location ? location.lng : null
                                    });
                                }}
                            />
                            {entity.deliveryLocationLat && entity.deliveryLocationLng && (
                                <Typography variant="caption">
                                    Dra kartnålen (L) för att finjustera positionen.
                                </Typography>
                            )}
                            {entity.deliveryLocationLat && entity.deliveryLocationLng && (
                                <Grid item xs={12}>
                                    <SliderField
                                        name="deliveryLocationRadius"
                                        label="Sökradie, leverans [km]"
                                        value={entity.deliveryLocationRadius / 1000}
                                        minValue={marketFilterLimits.minLocationRadius}
                                        maxValue={marketFilterLimits.maxLocationRadius}
                                        onChange={(v) => onChange({ deliveryLocationRadius: v * 1000 })}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    <SectionDivider label="Gods" marginTop={4} marginBottom={1} />

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <RangeField
                                name="grossWeight"
                                label="Totalvikt [kg]"
                                minValue={marketFilterLimits.minGrossWeight}
                                maxValue={marketFilterLimits.maxGrossWeight}
                                lowValue={entity.minGrossWeight || marketFilterLimits.minGrossWeight}
                                highValue={entity.maxGrossWeight || marketFilterLimits.maxGrossWeight}
                                onChange={([minGrossWeight, maxGrossWeight]) => {
                                    onChange({ minGrossWeight, maxGrossWeight });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RangeField
                                name="chargeableWeight"
                                label="FDR-vikt [kg]"
                                minValue={marketFilterLimits.minChargeableWeight}
                                maxValue={marketFilterLimits.maxChargeableWeight}
                                lowValue={entity.minChargeableWeight || marketFilterLimits.minChargeableWeight}
                                highValue={entity.maxChargeableWeight || marketFilterLimits.maxChargeableWeight}
                                onChange={([minChargeableWeight, maxChargeableWeight]) => {
                                    onChange({ minChargeableWeight, maxChargeableWeight });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RangeField
                                name="loadingMeters"
                                label="Flakmeter"
                                minValue={marketFilterLimits.minLoadingMeters}
                                maxValue={marketFilterLimits.maxLoadingMeters}
                                lowValue={entity.minLoadingMeters || marketFilterLimits.minLoadingMeters}
                                highValue={entity.maxLoadingMeters || marketFilterLimits.maxLoadingMeters}
                                onChange={([minLoadingMeters, maxLoadingMeters]) => {
                                    onChange({ minLoadingMeters, maxLoadingMeters });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RangeField
                                name="volume"
                                label="Volym [m³]"
                                minValue={marketFilterLimits.minVolume}
                                maxValue={marketFilterLimits.maxVolume}
                                lowValue={entity.minVolume || marketFilterLimits.minVolume}
                                highValue={entity.maxVolume || marketFilterLimits.maxVolume}
                                onChange={([minVolume, maxVolume]) => {
                                    onChange({ minVolume, maxVolume });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RangeField
                                name="palletPlaces"
                                label="Pallplatser"
                                minValue={marketFilterLimits.minPalletPlaces}
                                maxValue={marketFilterLimits.maxPalletPlaces}
                                lowValue={entity.minPalletPlaces || marketFilterLimits.minPalletPlaces}
                                highValue={entity.maxPalletPlaces || marketFilterLimits.maxPalletPlaces}
                                onChange={([minPalletPlaces, maxPalletPlaces]) => {
                                    onChange({ minPalletPlaces, maxPalletPlaces });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RangeField
                                name="pallets"
                                label="Pallar"
                                minValue={marketFilterLimits.minPallets}
                                maxValue={marketFilterLimits.maxPallets}
                                lowValue={entity.minPallets || marketFilterLimits.minPallets}
                                highValue={entity.maxPallets || marketFilterLimits.maxPallets}
                                onChange={([minPallets, maxPallets]) => {
                                    onChange({ minPallets, maxPallets });
                                }}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <RangeField
                                name="pieces"
                                label="Stycken"
                                minValue={marketFilterLimits.minPieces}
                                maxValue={marketFilterLimits.maxPieces}
                                lowValue={entity.minPieces || marketFilterLimits.minPieces}
                                highValue={entity.maxPieces || marketFilterLimits.maxPieces}
                                onChange={([minPieces, maxPieces]) => {
                                    onChange({ minPieces, maxPieces });
                                }}
                            />
                        </Grid> */}
                    </Grid>
                    <SectionDivider label="Publicering" marginTop={4} marginBottom={1} />

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AutocompleteField<{ id: number, name: string, ownerTenantName?: string }>
                                label="Endast i grupp"
                                optionIdKey="id"
                                defaultOption={!entity.groupId ? null : {
                                    id: entity.groupId,
                                    name: entity.groupName
                                }}
                                fetchOptions={fetchGroups}
                                options={groups.filter(
                                    (o: { isOwner: boolean; isAccepted: boolean; }) => (o.isOwner || o.isAccepted)
                                )}
                                getOptionName={(o) => o.name}
                                getOptionDescription={(o) => o.ownerTenantName || ''}
                                onChange={(group) => onChange({
                                    groupId: group ? group.id : null,
                                    groupName: group ? group.name : null
                                })}
                                fetchOnlyOnce
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <BoolField
                                name="hasListPrice"
                                label="Endast uppdrag med riktpris"
                                value={entity.hasListPrice}
                                onChange={({ hasListPrice }) => onChange({ hasListPrice })}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FilterMap
                        pickupLat={entity.pickupLocationLat}
                        pickupLng={entity.pickupLocationLng}
                        pickupRadius={entity.pickupLocationRadius}
                        deliveryLat={entity.deliveryLocationLat}
                        deliveryLng={entity.deliveryLocationLng}
                        deliveryRadius={entity.deliveryLocationRadius}
                        onPickupDragEnd={setPickupLocation}
                        onDeliveryDragEnd={setDeliveryLocation}
                        height={800}
                    />
                </Grid>
            </Grid>
        </FullForm>
    );
}
