export default {
    firstPickupDate: null,
    lastPickupDate: null,
    firstDeliveryDate: null,
    lastDeliveryDate: null,
    firstPickupTime: null,
    lastPickupTime: null,
    firstDeliveryTime: null,
    lastDeliveryTime: null,

    pickupLat: null,
    pickupLng: null,
    pickupAttention: '',
    pickupStreet: '',
    pickupZip: '',
    pickupCity: '',
    pickupCountry: '',
    deliveryLat: null,
    deliveryLng: null,
    deliveryAttention: '',
    deliveryStreet: '',
    deliveryZip: '',
    deliveryCity: '',
    deliveryCountry: '',
    fakePickupLat: null,
    fakePickupLng: null,
    fakeDeliveryLat: null,
    fakeDeliveryLng: null,

    grossWeight: null,
    chargeableWeight: null,
    volume: null,
    loadingMeters: null,
    palletPlaces: null,
    pallets: null,

    isPublic: true,
    showTenantName: true,
    groups: [],
    listPrice: null,
    expiresAt: null,
    publicDescription: '',
    privateDescription: ''
};
